import React, { useEffect, useRef, useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getRelatorioViagemPagined,
  printRelatorioViagem,
  excelRelatorioViagem,
  printColetaEmbarque,
} from 'core/services/FRO/coletaEmbarque';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getApoliceSeguroAutoComplete } from 'core/services/FRO/apoliceSeguro';
import { getEnderecoByPessoa } from 'core/services/SEG/endereco';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';
import {
  getRemetenteAutoComplete,
  getDestinatarioSemRemetenteAutoComplete,
} from 'core/services/FRO/remetente_Destinatario';
import {
  CSDSelPage,
  Autocomplete,
  Textbox,
  RadioButton,
  DatePicker,
  Switch,
  DropdownMulti,
  ToolbarButtons,
} from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import Pagination from 'core/models/pagination';
import GridRelatorios from './gridRelatorios';

const tiposImpresao = [
  { Key: 0, noTipoImpressao: 'Detalhado Por Emissao', nrSeqTipoImpressao: 1 },
  { Key: 1, noTipoImpressao: 'Municipio Origem', nrSeqTipoImpressao: 2 },
  { Key: 2, noTipoImpressao: 'Municipio Destino', nrSeqTipoImpressao: 3 },
  { Key: 3, noTipoImpressao: 'Rementente', nrSeqTipoImpressao: 4 },
  { Key: 4, noTipoImpressao: 'Cliente', nrSeqTipoImpressao: 5 },
  { Key: 5, noTipoImpressao: 'Veiculo', nrSeqTipoImpressao: 6 },
  { Key: 6, noTipoImpressao: 'Veiculo Detalhado', nrSeqTipoImpressao: 7 },
  { Key: 7, noTipoImpressao: 'Produto', nrSeqTipoImpressao: 8 },
  { Key: 8, noTipoImpressao: 'Detalhado', nrSeqTipoImpressao: 9 },
  { Key: 9, noTipoImpressao: 'Por situação', nrSeqTipoImpressao: 10 },
  { Key: 10, noTipoImpressao: 'Rementente Agrupado', nrSeqTipoImpressao: 11 },
  { Key: 11, noTipoImpressao: 'Detalhado Chave', nrSeqTipoImpressao: 12 },
  { Key: 12, noTipoImpressao: 'Detalhado Produto', nrSeqTipoImpressao: 13 },
  {
    Key: 13,
    noTipoImpressao: 'Ori x Dest - Financeiro',
    nrSeqTipoImpressao: 14,
  },
  { Key: 14, noTipoImpressao: 'Simplificado', nrSeqTipoImpressao: 15 },
  { Key: 15, noTipoImpressao: 'Veículo Por Estado', nrSeqTipoImpressao: 16 },
  { Key: 16, noTipoImpressao: 'Por Usuário', nrSeqTipoImpressao: 17 },
  {
    Key: 17,
    noTipoImpressao: 'Veiculo Detalhado-Terceiro',
    nrSeqTipoImpressao: 18,
  },
  { Key: 18, noTipoImpressao: 'Faturamento', nrSeqTipoImpressao: 19 },
  { Key: 19, noTipoImpressao: 'Detalhado Vendedor', nrSeqTipoImpressao: 20 },
  {
    Key: 20,
    noTipoImpressao: 'Detalhado Por situação',
    nrSeqTipoImpressao: 21,
  },
  {
    Key: 21,
    noTipoImpressao: 'Detalhado Contabil',
    nrSeqTipoImpressao: 22,
  },
  {
    Key: 22,
    noTipoImpressao: 'Motorista Detalhado',
    nrSeqTipoImpressao: 23,
  },
  {
    Key: 23,
    noTipoImpressao: 'Consignatario Detalhado',
    nrSeqTipoImpressao: 24,
  },
  {
    Key: 24,
    noTipoImpressao: 'Detalhado Financeiro',
    nrSeqTipoImpressao: 25,
  },
  {
    Key: 25,
    noTipoImpressao: 'Detalhado Frete Mercadoria',
    nrSeqTipoImpressao: 26,
  },
];

export default function relatorioViagem({
  transaction,
  isActive,
  onOpenReport,
  onOpenTransaction,
  findTransaction,
}) {
  const dropDownApolices = useRef();
  const [gridColuns, setGridColuns] = useState({
    data: [],
    pagination: new Pagination(),
  });

  const [filtros, setFiltros] = useState({
    nrSeqTipoImpressao: 9,
    flgStatusAverbacao: 'T',
    flgStatusTipoCte: 'AUT',
    flgContratoTipoCTE: 'T',
    flgFiltroFinanceiro: 'T',
    flgReceitaAcerto: 'N',
    flgTodos: true,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const searchApolices = async () => {
    const {
      status,
      message: msg,
      data: apolices,
    } = await getApoliceSeguroAutoComplete({});

    if (msg) onSetMessage(status, msg);

    return apolices;
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getRelatorioViagemPagined(params ?? filtros);
    if (msg) onSetMessage(status, msg);

    setGridColuns({ data, pagination });

    setLoading(false);
  };
  useEffect(() => {
    if (dropDownApolices && dropDownApolices.current && filtros.flgSeguro)
      dropDownApolices.current.loadDataSource(searchApolices);
  }, [filtros.flgSeguro]);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printRelatorioViagem(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelRelatorioViagem(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'RelatorioViagem.xls';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onAutoCompleteCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return clientes;
  };
  const onAutoCompleteEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };
  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoa: e });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };
  const onSearchPessoaVen = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return vendedores;
  };
  const onAutoCompleteFreteMercadoria = async (e) => {
    const {
      status,
      message: msg,
      data: freteMercadorias,
    } = await getFreteMercadoriaAutoComplete({
      noFreteMercadoria: e,
    });

    if (msg) onSetMessage(status, msg);

    return freteMercadorias;
  };
  const onAutoCompleteTipoFrete = async (e) => {
    const { status, message: msg, tiposFrete } = await getTipoFreteAutoComplete(
      {
        noTipoFrete: e,
      }
    );

    if (msg) onSetMessage(status, msg);

    return tiposFrete;
  };
  const onSearchProprietario = async (e) => {
    const {
      status,
      message: msg,
      transportadoras,
    } = await getTransportadoraAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return transportadoras;
  };
  const onSearchVeiculoCavalo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
      flgPrincipal: true,
    });
    if (msg) setMessage(status, msg);
    return veiculos;
  };
  const onSearchVeiculoCarreta = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
      flgPrincipal: false,
    });
    if (msg) setMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteRemetente = async (e) => {
    const {
      status,
      message: msg,
      data: remetentes,
    } = await getRemetenteAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return remetentes;
  };
  const onAutoCompleteDestinatario = async (e) => {
    const {
      status,
      message: msg,
      data: destinatarios,
    } = await getDestinatarioSemRemetenteAutoComplete({
      noPessoa: e,
      flgTodos: filtros.flgTodos,
    });

    if (msg) onSetMessage(status, msg);

    return destinatarios;
  };
  const getEndereco = async (id) => {
    const endereco = await getEnderecoByPessoa(id);

    if (id !== undefined)
      endereco.noRua = `${endereco.noRua}, ${endereco.numero}, ${endereco.bairro}`;

    return endereco;
  };
  const onSearchCidadeOrigem = async (e) => {
    const {
      status,
      message: msg,
      cidades: cidadesOrigem,
    } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros?.nrSeqEstadoOri ?? null,
    });
    onSetMessage(status, msg);
    return cidadesOrigem;
  };
  const onSearchCidadeDestino = async (e) => {
    const {
      status,
      message: msg,
      cidades: cidadesDestino,
    } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros?.nrSeqEstadoDes ?? null,
    });
    onSetMessage(status, msg);
    return cidadesDestino;
  };
  const onSearchEstadoOrigem = async (e) => {
    const {
      status,
      message: msg,
      estados: estadosColeta,
    } = await getEstadoAutoComplete({
      noEstado: e,
    });
    onSetMessage(status, msg);
    return estadosColeta;
  };
  const onSearchEstadoDestino = async (e) => {
    const {
      status,
      message: msg,
      estados: estadosEntrega,
    } = await getEstadoAutoComplete({
      noEstado: e,
    });
    onSetMessage(status, msg);
    return estadosEntrega;
  };

  const onSearchTipoImpressaoDefault = async () => {
    const filteredTiposImpressaoDefault = tiposImpresao.filter(
      (tipoImpressao) => tipoImpressao.noTipoImpressao
    );
    const listDefault = filteredTiposImpressaoDefault.find(
      (item) => item.nrSeqTipoImpressao === 9
    );

    setFiltros({
      ...filtros,
      tipoImpressao: listDefault,
      nrSeqTipoImpressao: listDefault.nrSeqTipoImpressao,
      noTipoImpressao: listDefault.noTipoImpressao,
    });
    return listDefault;
  };
  useEffect(() => {
    onSearchTipoImpressaoDefault();
  }, []);
  const onSearchTipoImpressao = async (searchText) => {
    const searchTerm = searchText.toLowerCase();

    const filteredTiposImpressao = tiposImpresao
      .filter((tipoImpressao) =>
        tipoImpressao.noTipoImpressao.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => a.noTipoImpressao.localeCompare(b.noTipoImpressao));

    return filteredTiposImpressao;
  };
  const onClickImprimirCte = async (e) => {
    setLoading(true);

    const { status, message: msg, value } = await printColetaEmbarque({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickOpenViagem = async (selectedValue) => {
    setLoading(true);
    const idDocViagem = selectedValue.nrSeqViagem;

    const transactionOpen = findTransaction(TransacationCodes.Viagem);

    if (transactionOpen) {
      transactionOpen.registryKey = idDocViagem;

      onOpenTransaction(transactionOpen, true);
    } else {
      onSetMessage(ResponseStatus.Error, 'Não foi possível abrir a viagem.');
    }

    setLoading(false);
  };

  const onSearchPessoaConsigOri = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaConsigOris,
    } = await getConsignatarioAutoComplete({ noPessoa: e });
    if (msg) setMessage(status, msg);
    return pessoaConsigOris;
  };

  const onSearchPessoaConsigDest = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaConsigDests,
    } = await getConsignatarioAutoComplete({ noPessoa: e });
    if (msg) setMessage(status, msg);
    return pessoaConsigDests;
  };

  const statusAverbacao = [
    { text: 'Todos', value: 'T' },
    { text: 'Somente Averbados', value: 'A' },
    { text: 'Não Averbados', value: 'N' },
  ];
  const statusTipoCTE = [
    { text: 'Autorizados', value: 'AUT' },
    { text: 'Não Transmitidos', value: 'NT' },
    { text: 'Não Autorizados', value: 'NA' },
    { text: 'Cancelados', value: 'CAN' },
    { text: 'Inutilizados', value: 'I' },
    { text: 'Substituido', value: 'SUB' },
    { text: 'Substituto', value: 'S' },
    { text: 'Todos', value: 'T' },
  ];
  const contratoTipoCTE = [
    { text: 'Frete Terceiro', value: 'FT' },
    { text: 'Frete Próprio', value: 'FP' },
    { text: 'Todos', value: 'T' },
  ];
  const filtroFinanceiro = [
    { text: 'Todos', value: 'T' },
    { text: 'Em Aberto', value: 'EA' },
    { text: 'Liquidado', value: 'L' },
  ];
  const receitaAcerto = [
    { text: 'Não', value: 'N' },
    { text: 'Sim', value: 'S' },
    { text: 'Apenas Receita', value: 'AR' },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Relatorio Viagem Periodo 30'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      onPrint={onPrint}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon='file-excel'
          onClick={onDownloadExcel}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Tipo Impressão'
            searchDataSource={onSearchTipoImpressao}
            selectedItem={filtros.tipoImpressao}
            onSelectItem={(tipoImpressao) => {
              setFiltros({
                ...filtros,
                tipoImpressao,
                nrSeqTipoImpressao: tipoImpressao.nrSeqTipoImpressao,
                noTipoImpressao: tipoImpressao.noTipoImpressao,
              });
            }}
            dataSourceTextProperty='noTipoImpressao'
            dataSource={tiposImpresao}
          />
        </div>
        <div className='col-3'>
          <RadioButton
            outline
            label='Filtrar Averbação'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgStatusAverbacao}
            onChange={(flgStatusAverbacao) => {
              setFiltros({ ...filtros, flgStatusAverbacao });
            }}
            buttons={statusAverbacao}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Inicial'
            text={filtros.dtInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicial) => {
              setFiltros({ ...filtros, dtInicial });
            }}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Final'
            text={filtros.dtFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFinal) => {
              setFiltros({ ...filtros, dtFinal });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        {(filtros.nrSeqTipoImpressao === 10 ||
          filtros.nrSeqTipoImpressao === 21) && (
          <div className='col-10'>
            <RadioButton
              outline
              label='Tipo CTE :'
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={filtros.flgStatusTipoCte}
              onChange={(flgStatusTipoCte) => {
                setFiltros({ ...filtros, flgStatusTipoCte });
              }}
              buttons={statusTipoCTE}
            />
          </div>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Fit Cancelados'
            checked={filtros.flgCancelado}
            onChange={(flgCancelado) =>
              setFiltros({ ...filtros, flgCancelado })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Fit Anulado'
            checked={filtros.flgAnulado}
            onChange={(flgAnulado) => setFiltros({ ...filtros, flgAnulado })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Fit Complem.'
            checked={filtros.flgComplementar}
            onChange={(flgComplementar) =>
              setFiltros({ ...filtros, flgComplementar })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Fit Seguro'
            checked={filtros.flgSeguro}
            onChange={(flgSeguro) => {
              setFiltros({ ...filtros, flgSeguro });
            }}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Fit Autorizados Não Sub'
            checked={filtros.flgAutorizadoNaoSub}
            onChange={(flgAutorizadoNaoSub) => {
              setFiltros({ ...filtros, flgAutorizadoNaoSub });
            }}
          />
        </div>
        <div className='col-3'>
          {filtros.flgSeguro && (
            <div className='col-10'>
              <DropdownMulti
                label='Apólices'
                ref={dropDownApolices}
                dataSourcePropertyText='noAutoComplete'
                dataSourcePropertyValue='nrSeqApoliceSeguro'
                selectedItems={selectedItems.apolices ?? []}
                onSelectItem={(apolices) => {
                  setSelectedItems({ ...selectedItems, apolices });
                  setFiltros({
                    ...filtros,
                    nrSeqApoliceSeguro: apolices?.map(
                      (p) => p.nrSeqApoliceSeguro
                    ),
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Nome do Cliente'
            searchDataSource={onAutoCompleteCliente}
            selectedItem={filtros.cliente}
            onSelectItem={(cliente) => {
              setFiltros({
                ...filtros,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                noCliente: cliente.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Mercadoria Principal'
            searchDataSource={onAutoCompleteFreteMercadoria}
            selectedItem={filtros.mercadoriaPrincipal}
            onSelectItem={(mercadoriaPrincipal) => {
              setFiltros({
                ...filtros,
                mercadoriaPrincipal,
                nrSeqFreteMercadoria: mercadoriaPrincipal.nrSeqFreteMercadoria,
                noFreteMercadoria: mercadoriaPrincipal.noFreteMercadoria,
              });
            }}
            dataSourceTextProperty='noFreteMercadoria'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Tipo de Frete'
            searchDataSource={onAutoCompleteTipoFrete}
            selectedItem={filtros.tipoFrete}
            onSelectItem={async (tipoFrete) => {
              if (tipoFrete.nrSeqTipoFrete) {
                const obj = await setFiltros(tipoFrete);

                setFiltros({
                  obj,
                  nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
                  noTipoFrete: tipoFrete.noTipoFrete,
                });
              }
            }}
            dataSourceTextProperty='noTipoFrete'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Unid. Origem'
            searchDataSource={onAutoCompleteEmpresa}
            selectedItem={filtros.unidOrigem}
            onSelectItem={(unidOrigem) => {
              setFiltros({
                ...filtros,
                unidOrigem,
                nrSeqEmpresa: unidOrigem.nrSeqEmpresa,
                nrSeqGrupoEmpresa: unidOrigem.nrSeqGrupoEmpresa,
                noEmpresa: unidOrigem.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Motorista'
            searchDataSource={onSearchPessoaMot}
            selectedItem={filtros.motorista}
            onSelectItem={(motorista) => {
              setFiltros({
                ...filtros,
                motorista,
                nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                noMotorista: motorista.noMotorista,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Vendedor'
            searchDataSource={onSearchPessoaVen}
            selectedItem={filtros.vendedor}
            onSelectItem={(vendedor) => {
              setFiltros({
                ...filtros,
                nrSeqPessoaVen: vendedor.nrSeqPessoa,
                noVendedor: vendedor.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-5'>
          <Autocomplete
            label='Proprietário'
            searchDataSource={onSearchProprietario}
            selectedItem={filtros.proprietario}
            onSelectItem={(proprietario) => {
              setFiltros({
                ...filtros,
                proprietario,
                nrSeqPessoaTran: proprietario.nrSeqPessoaTran,
                noPessoaTran: proprietario.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Cavalo'
            searchDataSource={onSearchVeiculoCavalo}
            selectedItem={filtros.veiculoPrinc}
            onSelectItem={(veiculoPrinc) => {
              setFiltros({
                ...filtros,
                veiculoPrinc,
                nrSeqVeiculo: veiculoPrinc.nrSeqVeiculo,
                noPlaca: veiculoPrinc.noPlaca,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Carreta'
            searchDataSource={onSearchVeiculoCarreta}
            selectedItem={filtros.veiculoComp}
            onSelectItem={(veiculoComp) => {
              setFiltros({
                ...filtros,
                veiculoComp,
                nrSeqVeiculoComp: veiculoComp.nrSeqVeiculo,
                noPlacaComp: veiculoComp.noPlaca,
              });
            }}
            dataSourceTextProperty='noVeiculo'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-5'>
          <Autocomplete
            label='Remetente'
            searchDataSource={onAutoCompleteRemetente}
            selectedItem={filtros.remetente}
            onSelectItem={async (remetente) => {
              remetente.endereco = await getEndereco(remetente.nrSeqPessoa);
              setFiltros({
                ...filtros,
                remetente,
                nrSeqPessoaRem: remetente.nrSeqPessoaRem,
                noRemetente: remetente.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-5'>
          <Autocomplete
            label='Destinatário'
            searchDataSource={onAutoCompleteDestinatario}
            selectedItem={filtros.destinatario}
            onSelectItem={async (destinatario) => {
              destinatario.endereco = await getEndereco(
                destinatario.nrSeqPessoa
              );
              setFiltros({
                ...filtros,
                destinatario,
                nrSeqPessoaDes: destinatario.nrSeqPessoaDes,
                noDestinatario: destinatario.noPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Cidade Origem'
            searchDataSource={onSearchCidadeOrigem}
            selectedItem={filtros.cidadeOrigem}
            onSelectItem={(cidadeOrigem) =>
              setFiltros({
                ...filtros,
                cidadeOrigem,
                nrSeqCidadeOri: cidadeOrigem.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Cidade Destino'
            searchDataSource={onSearchCidadeDestino}
            selectedItem={filtros.cidadeDestino}
            onSelectItem={(cidadeDestino) =>
              setFiltros({
                ...filtros,
                cidadeDestino,
                nrSeqCidadeDes: cidadeDestino.nrSeqCidade,
              })
            }
            dataSourceTextProperty='noCidade'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Estado Origem'
            searchDataSource={onSearchEstadoOrigem}
            selectedItem={filtros.estadoOrigem}
            onSelectItem={(estadoOrigem) =>
              setFiltros({
                ...filtros,
                estadoOrigem,
                nrSeqEstadoOri: estadoOrigem.nrSeqEstado,
              })
            }
            dataSourceTextProperty='noEstado'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Estado Destino'
            searchDataSource={onSearchEstadoDestino}
            selectedItem={filtros.estadoDestino}
            onSelectItem={(estadoDestino) =>
              setFiltros({
                ...filtros,
                estadoDestino,
                nrSeqEstadoDes: estadoDestino.nrSeqEstado,
              })
            }
            dataSourceTextProperty='noEstado'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Nota Fiscal'
            text={filtros.nrFiscal}
            onChangedValue={(nrFiscal) => {
              setFiltros({ ...filtros, nrFiscal });
            }}
          />
        </div>
        <div className='col-3'>
          <RadioButton
            outline
            label='Tipo CTE :'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgContratoTipoCTE}
            onChange={(flgContratoTipoCTE) => {
              setFiltros({ ...filtros, flgContratoTipoCTE });
            }}
            buttons={contratoTipoCTE}
          />
        </div>
        <div className='col-3'>
          <RadioButton
            outline
            label='Filtro Financeiro :'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgFiltroFinanceiro}
            onChange={(flgFiltroFinanceiro) => {
              setFiltros({ ...filtros, flgFiltroFinanceiro });
            }}
            buttons={filtroFinanceiro}
          />
        </div>
        <div className='col-3'>
          <RadioButton
            outline
            label='Receita Acerto :'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgReceitaAcerto}
            onChange={(flgReceitaAcerto) => {
              setFiltros({ ...filtros, flgReceitaAcerto });
            }}
            buttons={receitaAcerto}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Consignatário Origem (Local de Coleta)'
            searchDataSource={onSearchPessoaConsigOri}
            selectedItem={filtros.origemConsig}
            onSelectItem={(origemConsig) => {
              setFiltros({
                ...filtros,
                origemConsig,
                nrSeqPessoaConsigOri: origemConsig.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Consignatário Destino (Local de Entrega)'
            searchDataSource={onSearchPessoaConsigDest}
            selectedItem={filtros.destinoConsig}
            onSelectItem={(destinoConsig) => {
              setFiltros({
                ...filtros,
                destinoConsig,
                nrSeqPessoaConsigDest: destinoConsig.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridRelatorios
            nrSeqTipoImpressao={filtros.nrSeqTipoImpressao}
            transaction={transaction}
            onColumnSort={onColumnSort}
            gridColuns={gridColuns}
            onClickOpenViagem={onClickOpenViagem}
            onClickImprimirCte={onClickImprimirCte}
            sumFields
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
