import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
import {
  DropdownList,
  Textbox,
  Panel,
  Capture,
  Autocomplete,
  DatePicker,
} from 'ui/components';
import { getWithImage } from 'core/services/SEG/pessoa';
import { getEstadoCivilAutoComplete } from 'core/services/SEG/estadocivil';
import { getProfissaoAutoComplete } from 'core/services/SEG/profissao';
import { getResidentialAddressByPerson } from 'core/services/SEG/endereco';
import { getInformacaoBancariaByNrSeqPessoa } from 'core/services/FIN/informacaoBancaria';
import {
  getEmpresaDefaultUsuario,
  getEmpresa,
} from 'core/services/SEG/empresa';
import { getEstadoAutoCompleteViagem } from 'core/services/SEG/estado';
import SearchInformationDocumentButton from 'ui/components/SearchInformationDocumentButton';
import { IPessoa } from 'core/interfaces/SEG';
// @ts-expect-error
import PainelEndereco from './painelEndereco.tsx';
// @ts-expect-error
import PainelContato from './painelContato.tsx';
// @ts-expect-error
import PainelInformacaoBancaria from './painelInformacaoBancaria.tsx';

interface Props {
  title?: string;
  data: any;
  config?: any;
  nrSeqPessoa: number | null;
  children?: any;
  cdPessoa?: any;
  setData: (object: any) => void;
  setLoading: (e: boolean) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  getDataOrigem: (object: any) => void;
  flgFisica: string;
}

const listaSexos = [
  {
    text: 'Masculino',
    value: 1,
    showTextOnSelect: false,
  },
  {
    text: 'Feminino',
    value: 2,
    showTextOnSelect: false,
  },
];

enum EnvioPadrao {
  EMail = 'E-Mail',
  WhatsApp = 'WhatsApp',
  SMS = 'SMS',
}

const envioPadrao = [
  { noEnvioPadrao: 'E-Mail', nrEnvioPadrao: 0 },
  { noEnvioPadrao: 'WhatsApp', nrEnvioPadrao: 1 },
  { noEnvioPadrao: 'SMS', nrEnvioPadrao: 2 },
  { noEnvioPadrao: 'Não Enviar', nrEnvioPadrao: 3 },
];

const CustomerRegistration = forwardRef(
  (
    {
      config,
      data,
      nrSeqPessoa,
      children,
      cdPessoa,
      onSetMessage,
      setLoading,
      flgFisica,
    }: Props,
    ref
  ) => {
    const [formData, setFormData] = useState<IPessoa | any>({
      noTipoPessoa: 'F',
      flgFisica: true,
      disableDoc: false,
      disableTipoPessoa: false,
      disableUfIE: false,
      endereco: {},
      contato: {},
      pessoaImagem: {},
      informacoesBancarias: { tpContaCorrente: 'C', tpPix: 'C' },
    });

    const [dropDownListaSexos, setDropDownListaSexos] = useState<any>([]);
    const documentEndereco = useRef<any>(null);
    const [show, setShow] = useState<any>({
      showDocumentSearchBtn: false,
      showUfIE: false,
    });
    const clearEnderecoPanel = () => {
      documentEndereco?.current?.clear();
    };

    const getUFEmpresa = async (): Promise<any> => {
      const { empresas: empresadefault } = await getEmpresaDefaultUsuario();

      const empresaUsuario =
        empresadefault && empresadefault.length > 0 ? empresadefault[0] : null;

      const { data: empresa } = await getEmpresa(empresaUsuario.nrSeqEmpresa);

      const ufIE = empresa?.endereco?.cidade.estado ?? null;

      return { ufIE, empresadefault };
    };

    const resetForm = async () => {
      const { ufIE, empresadefault } = await getUFEmpresa();

      setFormData({
        noTipoPessoa: 'F',
        flgFisica: true,
        disableDoc: false,
        disableTipoPessoa: false,
        disableUfIE: false,
        endereco: {},
        contato: {},
        pessoaImagem: {},
        ufIe: ufIE,
        nrSeqEstadoIE: ufIE?.nrSeqEstado,
        nrSeqEmpresa: empresadefault?.nrSeqEmpresa,
      });
      clearEnderecoPanel();

      setDropDownListaSexos(listaSexos);
    };

    const onNew = async (): Promise<void> => {
      setShow({ ...show, showUfIE: true });
      await resetForm();
    };

    const loadInfoPerson = async (pessoaInfo: any) => {
      if (
        Number.isInteger(pessoaInfo.nrSeqPessoa) &&
        pessoaInfo.nrSeqPessoa > 0
      ) {
        setLoading(true);

        setShow({ ...show, showUfIE: false });

        setFormData({
          ...formData,
          ...pessoaInfo,
          nrSeqPessoa: pessoaInfo.nrSeqPessoa,
          endereco: pessoaInfo.endereco || {},
          noTipoPessoa: pessoaInfo.flgFisica ? 'F' : 'J',
          pessoaFisica: pessoaInfo.pessoaFisica,
          pessoaJuridica: pessoaInfo.pessoaJuridica,
          contato: pessoaInfo.contato ?? {},
          disableDoc: true,
          disableTipoPessoa: true,
          disableUfIE: true,
          sexo: pessoaInfo.flgFisica
            ? pessoaInfo.pessoaFisica.nrSeqSexo
            : undefined,
          pessoaImagem: pessoaInfo?.pessoaImagem
            ? { ...pessoaInfo?.pessoaImagem }
            : null,
        });

        setLoading(false);
      } else await onNew();
    };

    useImperativeHandle(ref, () => ({
      async clear() {
        await resetForm();
      },
      getData() {
        return formData;
      },
      loadInfoPerson,
    }));

    const validaEnvioPadrao = (
      contato: any,
      validaComMsg: boolean = false,
      objEnvioPadrao: any = null
    ) => {
      let msgEnvio = '';
      let retornoEnvio = true;

      if (!envioPadrao) return false;

      if (
        objEnvioPadrao &&
        objEnvioPadrao.noEnvioPadrao === EnvioPadrao.EMail &&
        !contato.email
      ) {
        msgEnvio =
          'Necessário cadastrar um e-mail para torna-lo modo de envio padrão.';
        retornoEnvio = false;
      }

      if (
        objEnvioPadrao &&
        objEnvioPadrao.noEnvioPadrao === EnvioPadrao.WhatsApp &&
        !contato.celular
      ) {
        msgEnvio =
          'Necessário cadastrar um número de celular para tornar WhatsApp como modo de envio padrão.';
        retornoEnvio = false;
      }

      if (
        objEnvioPadrao &&
        objEnvioPadrao.noEnvioPadrao === EnvioPadrao.SMS &&
        !contato.celular
      ) {
        msgEnvio =
          'Necessário cadastrar um número de celular para tornar SMS como modo de envio padrão.';
        retornoEnvio = false;
      }

      if (validaComMsg && msgEnvio !== '')
        onSetMessage(ResponseStatus.Error, msgEnvio);

      return retornoEnvio;
    };

    useEffect(() => {
      (async function func() {
        if (
          data.pessoa?.nrSeqPessoa &&
          Number.isInteger(data.pessoa?.nrSeqPessoa)
        ) {
          const pessoa: any = await getWithImage(nrSeqPessoa);

          if (pessoa) {
            let sexoPessoa;
            if (pessoa && pessoa.pessoaFisica) {
              sexoPessoa = listaSexos.filter(
                (el) => el.value === pessoa.pessoaFisica.nrSeqSexo
              );
            }

            let enderecoPessoa;

            if (pessoa.pessoaEndereco && pessoa.pessoaEndereco.endereco) {
              enderecoPessoa = pessoa.pessoaEndereco.endereco;
            } else {
              const enderecoInfoPessoa = await getResidentialAddressByPerson(
                nrSeqPessoa
              );
              if (enderecoInfoPessoa) enderecoPessoa = enderecoInfoPessoa;
            }

            if (
              pessoa.contato &&
              pessoa.contato.contato &&
              !pessoa.contato.fone
            ) {
              pessoa.contato.fone = pessoa.contato.contato;
            }

            if (pessoa.contato && pessoa.contato.flgEnvioPadrao != null) {
              pessoa.contato.envioPadrao = envioPadrao.find(
                (el) => el.nrEnvioPadrao === pessoa.contato.flgEnvioPadrao
              );
            }

            const envioPadraoValido = validaEnvioPadrao(
              pessoa.contato,
              false,
              pessoa.contato.envioPadrao
            );

            if (!envioPadraoValido) pessoa.contato.envioPadrao = null;

            if (
              !pessoa.informacoesBancarias ||
              !pessoa.informacoesBancarias.nrSeqPessoaInformacaoBancaria
            ) {
              const infoBancaria = await getInformacaoBancariaByNrSeqPessoa(
                nrSeqPessoa
              );

              pessoa.informacoesBancarias = infoBancaria ?? {};
            }

            if (Object.keys(pessoa.informacoesBancarias).length > 0) {
              pessoa.informacoesBancarias.tpContaCorrente =
                pessoa.informacoesBancarias.tpContaCorrente === 'N' ||
                !pessoa.informacoesBancarias.tpContaCorrente
                  ? 'C'
                  : pessoa.informacoesBancarias.tpContaCorrente;
            }

            setFormData({
              ...formData,
              ...pessoa,
              endereco: enderecoPessoa,
              noTipoPessoa: pessoa.flgFisica ? 'F' : 'J',
              disableDoc: true,
              disableTipoPessoa: true,
              disableUfIE: true,
              pessoaImagem: pessoa?.pessoaImagem
                ? { ...pessoa?.pessoaImagem }
                : null,
              sexo: sexoPessoa && sexoPessoa.length > 0 ? sexoPessoa[0] : null,
            });

            setDropDownListaSexos(listaSexos);

            setLoading(false);
          }
        } else {
          await onNew();
        }
      })();
    }, [data?.pessoa?.nrSeqPessoa]);

    //  # region Search Autocompletes

    const onSearchProfissao = async (e: string) => {
      const { profissoes } = await getProfissaoAutoComplete({
        noProfissao: e,
      });

      return profissoes;
    };

    const onSearchEstadoCivil = async (e: string) => {
      const { estadosCivis } = await getEstadoCivilAutoComplete({
        noEstadoCivil: e,
      });

      return estadosCivis;
    };

    const onAutoCompleteUfIe = async (e: string) => {
      const {
        status,
        message: msg,
        estados,
      } = await getEstadoAutoCompleteViagem({
        cdEstado: e,
      });

      if (msg) onSetMessage(status, msg);

      return estados;
    };

    //  # endregion

    const isValidCNPJSearch = () => {
      const juridica =
        formData?.pessoaJuridica &&
        formData?.pessoaJuridica?.nrCnpj &&
        formData?.pessoaJuridica?.nrCnpj.length === 18 &&
        !formData?.nrSeqPessoa &&
        formData?.ufIe?.cdEstado;

      return juridica;
    };

    const isValidCPFSearch = () => {
      const fisica =
        formData?.pessoaFisica &&
        formData?.pessoaFisica?.nrCpf &&
        formData?.pessoaFisica?.nrCpf.length === 14 &&
        !formData?.nrSeqPessoa &&
        formData?.ufIe?.cdEstado;

      return fisica;
    };

    const showDocumentSearchButton = () => {
      if (show && show.showDocumentSearchBtn)
        return formData.flgFisica ? isValidCPFSearch() : isValidCNPJSearch();
      return false;
    };

    return (
      <div className='mb-5 pb-5'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            title={config.panelTitle || 'Formulário'}
            theme={Theme.Primary}
          />

          <Panel.Body>
            <div className='row'>
              <div className='col-3'>
                <Capture
                  picture={formData?.pessoaImagem?.noImagemBase64}
                  onPictureLoad={(e: any) =>
                    setFormData({
                      ...formData,
                      pessoaImagem: { noImagemBase64: e },
                    })
                  }
                />
              </div>
              <div className='col-9'>
                <div className='row mb-3'>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Código'
                      readOnly
                      text={cdPessoa}
                      maxLength={200}
                    />
                  </div>
                  {show?.showUfIE && (
                    <div className='col-2'>
                      <Autocomplete
                        label='UF Insc. Estadual'
                        id='txtUfInscricaoEstadual'
                        searchDataSource={onAutoCompleteUfIe}
                        enabled={!formData?.disableUfIE}
                        selectedItem={formData.ufIe}
                        onSelectItem={(ufIe: any) =>
                          setFormData({
                            ...formData,
                            ufIe,
                            nrSeqEstadoIE: ufIe.nrSeqEstado,
                          })
                        }
                        dataSourceTextProperty='cdEstado'
                      />
                    </div>
                  )}

                  {!flgFisica ? (
                    <>
                      {showDocumentSearchButton() && (
                        <div className='col-2 mt-3'>
                          <SearchInformationDocumentButton
                            document={
                              formData?.flgFisica
                                ? formData?.pessaFisica?.nrCpf
                                : formData?.pessoaJuridica?.nrCnpj
                            }
                            ufIE={formData?.ufIe?.cdEstado}
                            formData={formData}
                            show={show}
                            setShow={setShow}
                            setFormData={setFormData}
                            onSetMessage={onSetMessage}
                            setLoading={setLoading}
                          />
                        </div>
                      )}

                      <div className='col-5'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Nome Fantasia'
                          id='txtNomeFantasia'
                          text={formData?.pessoaJuridica?.noFantasia}
                          maxLength={80}
                          onChangedValue={(noFantasia: string) => {
                            setFormData({
                              ...formData,
                              pessoaJuridica: {
                                ...formData?.pessoaJuridica,
                                noFantasia,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className='col-3'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Inscrição Estadual'
                          id='txtInscricaoEstadual'
                          text={formData?.pessoaJuridica?.iEstadual}
                          maxLength={30}
                          onChangedValue={(iEstadual: string) =>
                            setFormData({
                              ...formData,
                              pessoaJuridica: {
                                ...formData?.pessoaJuridica,
                                iEstadual,
                              },
                            })
                          }
                        />
                      </div>
                      <div className='col-3 mt-2'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Inscrição Municipal'
                          id='txtInscricaoMunicipal'
                          text={formData?.pessoaJuridica?.ieMunicipal}
                          maxLength={20}
                          onChangedValue={(ieMunicipal: string) =>
                            setFormData({
                              ...formData,
                              pessoaJuridica: {
                                ...formData?.pessoaJuridica,
                                ieMunicipal,
                              },
                            })
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {showDocumentSearchButton() && (
                        <div className='col-2 mt-3'>
                          <SearchInformationDocumentButton
                            document={
                              formData?.flgFisica
                                ? formData?.pessoaFisica?.nrCpf
                                : formData?.pessoaJuridica?.nrCnpj
                            }
                            ufIE={formData?.ufIe?.cdEstado}
                            formData={formData}
                            show={show}
                            setShow={setShow}
                            setFormData={setFormData}
                            onSetMessage={onSetMessage}
                            setLoading={setLoading}
                          />
                        </div>
                      )}

                      <div className='col-3 mb-3'>
                        {/* @ts-expect-error */}
                        <DatePicker
                          label='Data Nascimento'
                          id='txtDtNascimento'
                          text={formData?.pessoaFisica?.dtNascimento}
                          onChange={(dtNascimento: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                dtNascimento,
                              },
                            })
                          }
                        />
                      </div>
                      <div className='col-3 mb-3'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Identidade'
                          id='txtIdentidade'
                          text={formData?.pessoaFisica?.nrRg}
                          maxLength={15}
                          onChangedValue={(nrRg: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: { ...formData?.pessoaFisica, nrRg },
                            })
                          }
                        />
                      </div>

                      <div className='col-2 mb-3'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='UF RG'
                          id='txtUfRg'
                          text={formData.pessoaFisica?.ufRg}
                          maxLength={2}
                          onChangedValue={(ufRg: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: { ...formData?.pessoaFisica, ufRg },
                            })
                          }
                        />
                      </div>
                      <div className='col-3 mb-3'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Orgão Emissor'
                          id='txtOrgaoEmissor'
                          text={formData?.pessoaFisica?.rgOrgaoEmissor}
                          maxLength={5}
                          onChangedValue={(rgOrgaoEmissor: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                rgOrgaoEmissor,
                              },
                            })
                          }
                        />
                      </div>

                      <div className='col-3 mb-3'>
                        {/* @ts-expect-error */}
                        <DatePicker
                          label='Data Emissão RG'
                          id='txtDataEmissaoRG'
                          text={formData?.pessoaFisica?.dtEmissaOrg}
                          onChange={(dtEmissaOrg: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                dtEmissaOrg,
                              },
                            })
                          }
                        />
                      </div>

                      <div className='col-3 mb-3'>
                        <Autocomplete
                          label='Estado Civil'
                          id='txtEstadoCivil'
                          searchDataSource={onSearchEstadoCivil}
                          selectedItem={formData?.pessoaFisica?.estadoCivil}
                          onSelectItem={(estadoCivil: any) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                estadoCivil,
                                nrSeqEstadoCivil: estadoCivil.nrSeqEstadoCivil,
                              },
                            })
                          }
                          dataSourceTextProperty='noEstadoCivil'
                        />
                      </div>
                      <div className='col-3 mb-3'>
                        <Autocomplete
                          label='Profissão'
                          id='txtProfissao'
                          searchDataSource={onSearchProfissao}
                          selectedItem={formData?.pessoaFisica?.profissao}
                          onSelectItem={(profissao: any) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                profissao,
                                nrSeqProfissao: profissao.nrSeqProfissao,
                              },
                            })
                          }
                          dataSourceTextProperty='noProfissao'
                        />
                      </div>

                      <div className='col-2 mb-3'>
                        <DropdownList
                          label='Sexo'
                          id='ddlSexo'
                          dataSource={dropDownListaSexos}
                          dataSourcePropertyText='text'
                          dataSourcePropertyValue='value'
                          selectedItems={formData?.sexo ?? []}
                          onSelectItem={(item: any) => {
                            setFormData({
                              ...formData,
                              sexo: item,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                nrSeqSexo: item.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className='col-4 mb-3'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Nome Pai'
                          id='txtNomePai'
                          text={formData?.pessoaFisica?.noPai}
                          maxLength={80}
                          onChangedValue={(noPai: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                noPai,
                              },
                            })
                          }
                        />
                      </div>
                      <div className='col-4 mb-3'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Nome Mãe'
                          id='txtNomeMae'
                          text={formData?.pessoaFisica?.noMae}
                          maxLength={80}
                          onChangedValue={(noMae: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                noMae,
                              },
                            })
                          }
                        />
                      </div>
                      <div className='col-3 mb-3'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='PIS'
                          id='txtPis'
                          text={formData?.pessoaFisica?.nrPis}
                          maxLength={14}
                          onChangedValue={(nrPis: string) =>
                            setFormData({
                              ...formData,
                              pessoaFisica: {
                                ...formData?.pessoaFisica,
                                nrPis,
                              },
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <div>{children}</div>
        <PainelEndereco
          endereco={formData?.endereco}
          formData={formData}
          setFormData={setFormData}
          setLoading={setLoading}
          onSetMessage={onSetMessage}
          ref={documentEndereco}
        />
        <PainelContato
          data={formData}
          formdata={formData}
          contato={formData?.contato}
          setData={setFormData}
        />
        <PainelInformacaoBancaria
          formData={formData}
          setFormData={setFormData}
          onSetMessage={onSetMessage}
        />
      </div>
    );
  }
);

export default CustomerRegistration;
