import qs from 'qs';
import Pagination from 'core/models/pagination';
import ProdutoCor from 'core/models/EST/produtoCor';
import api from '../api';

const baseURL = `/EST/ProdutoCor`;

export const getProdutoCorList = async (filters) => {
  const response = await api.get(`/$ProdutoCor/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoCor(json)) ?? [],
  };
};

export const getProdutoCorPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ProdutoCor(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getProdutoCor = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ProdutoCor(value) : {};
};

export const getProdutoCorAutoComplete = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ProdutoCor(value) : {};
};

export const saveProdutoCor = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteProdutoCor = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printProdutoCor = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelProdutoCor = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllProdutoCor = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
