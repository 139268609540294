import React from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Modal,
  Notification,
  Button,
  Textbox,
  DatePicker,
} from 'ui/components';
import { savePneuVeiculo } from 'core/services/FRO/pneuVeiculo';

interface IData {
  pneuVeiculo?: any;
  dataList?: any;
  nrSeqPneu?: number;
  placa?: string;
  dtInicioAlt?: string;
  kmInicioAlt?: number;
  dtFimAnterior?: string;
  isUpdated: boolean;
}

interface IProps {
  show: boolean;
  setLoading: any;
  data: IData;
  onClose: (isUpdated: boolean) => void;
}

export function ModalEditarPneuVeiculo({
  show,
  setLoading,
  data,
  onClose,
}: IProps) {
  const [message, setMessage] = React.useState<any>({});
  const [dataVeiculo, setDataVeiculo] = React.useState<IData>({
    isUpdated: false,
  });

  const onSetMessage = (status: number, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = async () => {
    setLoading(true);

    let dtFimAnterior;
    if (data.dataList.length > 1) dtFimAnterior = data.dataList[1].dtFim;

    setDataVeiculo({ ...data, dtFimAnterior, isUpdated: false });

    setLoading(false);
  };

  React.useEffect(() => {
    if (show) load();
  }, [show]);

  const handleOnUpdate = async () => {
    if (
      dataVeiculo?.dtFimAnterior !== undefined &&
      dataVeiculo?.dtInicioAlt !== undefined
    ) {
      const dataInicio = new Date(dataVeiculo.dtInicioAlt);
      const dataFimAnterior = new Date(dataVeiculo.dtFimAnterior);

      if (dataInicio < dataFimAnterior) {
        return onSetMessage(
          ResponseStatus.Error,
          'Data de início não pode ser menor que a data final do vínculo anterior.'
        );
      }
    }

    if (
      dataVeiculo?.dataList.length > 0 &&
      dataVeiculo?.kmInicioAlt !== undefined
    ) {
      if (dataVeiculo.kmInicioAlt < dataVeiculo.dataList[0].kmFim)
        return onSetMessage(
          ResponseStatus.Error,
          'Km Início não pode ser menor do que o Km Fim do vínculo anterior.'
        );
    }

    const pneuVeiculo = dataVeiculo?.pneuVeiculo;
    const objUpdate = {
      nrSeqPneuVeiculo: pneuVeiculo.nrSeqPneuVeiculo,
      nrSeqVeiculo: pneuVeiculo.nrSeqVeiculo,
      nrOrdem: pneuVeiculo.nrOrdem,
      dtInicio: dataVeiculo?.dtInicioAlt,
      dtFim: null,
      nrSeqEixo: pneuVeiculo.nrSeqEixo,
      nrSeqPneu: pneuVeiculo.nrSeqPneu,
      kmInicio: dataVeiculo?.kmInicioAlt,
      kmFim: 0,
      kmAcumulado: 0,
      nrSeqPneuMotivoTroca: pneuVeiculo.nrSeqPneuMotivoTroca,
      nrSulco: pneuVeiculo.nrSulco,
      dtColetaSulco: pneuVeiculo.dtColetaSulco,
      noObsMotivoTroca: pneuVeiculo.noObsMotivoTroca,
    };

    const res = await savePneuVeiculo(objUpdate);

    if (res.status === ResponseStatus.Success)
      setDataVeiculo({ isUpdated: true });

    return onSetMessage(res.status, res.message);
  };

  const handleOnClose = (isUpdated: boolean) => {
    onClose(isUpdated);
  };

  return (
    <div className='row mb-3'>
      {/* @ts-expect-error */}
      <Modal
        show={show}
        title='Vincular Pneus'
        onClose={() => handleOnClose(dataVeiculo?.isUpdated)}
        size={BootstrapSizes.Large}
      >
        {/* @ts-expect-error */}
        <Modal.Body>
          {message && (
            <>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Notification
                    message={message.message}
                    theme={message.theme}
                    onClose={() => setMessage(null)}
                  />
                </div>
              </div>
            </>
          )}

          <div className='row mb-3'>
            <div className='col'>
              {/* @ts-expect-error */}
              <Textbox
                label='Código Pneu'
                readOnly
                text={dataVeiculo?.nrSeqPneu}
              />
            </div>
            <div className='col'>
              {/* @ts-expect-error */}
              <Textbox label='Placa' readOnly text={dataVeiculo?.placa} />
            </div>
            <div className='col'>
              <DatePicker
                label='Data de Início'
                text={dataVeiculo?.dtInicioAlt}
                onChange={(dtInicioAlt: string) =>
                  setDataVeiculo({ ...dataVeiculo, dtInicioAlt })
                }
              />
            </div>
            <div className='col'>
              {/* @ts-expect-error */}
              <Textbox
                label='Odomêtro de Início'
                text={dataVeiculo?.kmInicioAlt}
                mask={MaskTypes.Integer}
                onChangedValue={(kmInicioAlt: number) =>
                  setDataVeiculo({ ...dataVeiculo, kmInicioAlt })
                }
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            <Button
              text='Atualizar'
              theme={Theme.Success}
              onClick={() => handleOnUpdate()}
            />
            <Button
              text='Fechar'
              theme={Theme.Danger}
              onClick={() => handleOnClose(dataVeiculo.isUpdated)}
            />
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
