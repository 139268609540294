import React, { useEffect, useReducer, useState } from 'react';
import {
  Modal,
  Button,
  Textbox,
  Loader,
  Notification,
  Autocomplete,
  FileUpload,
  Switch,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  MaskTypes,
  ResponseStatus,
} from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { MimeTypes } from 'ui/Helpers/utils';

import { gerarCtesPorNota } from 'core/services/FRO/viagem';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';
import { getEspecieFreteAutoComplete } from 'core/services/FRO/especieFrete';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getMotoristaViagemAutoComplete } from 'core/services/FRO/motorista';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';

import {
  ColetaEmbarque,
  TipoEmbarque,
  TipoFrete,
  EspecieFrete,
  FreteMercadoria,
  Veiculo,
  Viagem,
  Motorista,
  ColetaConsignatario,
} from 'core/models/FRO';

interface Props {
  show: boolean;
  dadosCtes: ColetaEmbarque;
  dadosViagem: Viagem;
  adicionaCtes: (ctes: ColetaEmbarque[]) => void;
  onClose: () => void;
  onSelectVeiculoPrincipal: (veiculo: Veiculo) => any;
}

interface ReducerState {
  loading: boolean;
  files: any;
  message: Message | null;
  coletaEmbarque: ColetaEmbarque;
  viagem: Viagem;
  contemNotaDuplicada: boolean;
  randomText: string;
  input: string;
  calculaFrete: boolean;
  efetuaRateio: boolean;
  liberaConsignatarios: boolean;
  buscaParametrosRemetente: boolean;
  agrupaPorDestinatario: boolean;
}

interface ReducerAction {
  type: string;
  payload?: any;
}

function GenerateRandomText(): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

  let word = '';

  for (let i = 0; i < 5; i += 1) {
    const index = Math.floor(Math.random() * characters.length);
    word += characters[index];
  }

  return word;
}

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'openLoader':
      return { ...state, loading: true };
    case 'closeLoader':
      return { ...state, loading: false };
    case 'message':
      return { ...state, message: action.payload };
    case 'updateColetaEmbarque':
      return {
        ...state,
        coletaEmbarque: { ...state.coletaEmbarque, ...action.payload },
      };
    case 'updateViagem':
      return { ...state, viagem: { ...state.viagem, ...action.payload } };
    case 'updateFiles':
      return { ...state, files: action.payload };
    case 'updateNotaDuplicada':
      return { ...state, contemNotaDuplicada: action.payload };
    case 'updateRandomText':
      return { ...state, randomText: GenerateRandomText() };
    case 'updateInput':
      return { ...state, input: action.payload };
    case 'updateCalculaFrete':
      return { ...state, calculaFrete: action.payload };
    case 'updateEfetuaRateio':
      return { ...state, efetuaRateio: action.payload };
    case 'updateLiberaConsignatarios':
      return { ...state, liberaConsignatarios: action.payload };
    case 'updateBuscaParametrosRemetente':
      return { ...state, buscaParametrosRemetente: action.payload };
    case 'updateAgrupaPorDestinatario':
      return {
        ...state,
        agrupaPorDestinatario: action.payload,
        contemNotaDuplicada: false,
      };
    default:
      return state;
  }
}

export default function ModalGerarCtesPorNota({
  show,
  dadosCtes,
  dadosViagem,
  adicionaCtes,
  onClose,
  onSelectVeiculoPrincipal,
}: Props): React.ReactNode {
  const [messages, setMessages] = useState<Message[]>([]);
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    message: null,
    files: [],
    coletaEmbarque: new ColetaEmbarque({}),
    viagem: new Viagem({}),
    contemNotaDuplicada: false,
    randomText: GenerateRandomText(),
    input: '',
    calculaFrete: true,
    efetuaRateio: true,
    liberaConsignatarios: false,
    buscaParametrosRemetente: false,
    agrupaPorDestinatario: false,
  });
  useEffect(() => {
    if (show) {
      dispatch({
        type: 'updateColetaEmbarque',
        payload: dadosCtes,
      });

      dispatch({
        type: 'updateViagem',
        payload: dadosViagem,
      });
    }
  }, [show]);

  const onSend = (): void => {
    dispatch({ type: 'updateFiles', payload: [] });

    onClose();
  };

  const validaImportacaoNotasDuplicadas = (): boolean => {
    if (state.contemNotaDuplicada) {
      if (state.input === '') {
        dispatch({
          type: 'message',
          payload: {
            message: 'Necessário validar os caracteres.',
            theme: Theme.Warning,
          },
        });

        return false;
      }

      if (state.randomText.localeCompare(state.input) !== 0) {
        dispatch({
          type: 'message',
          payload: {
            message: 'Tente Novamente.',
            theme: Theme.Danger,
          },
        });

        dispatch({ type: 'updateRandomText' });
        dispatch({ type: 'updateInput', payload: '' });

        return false;
      }
    }

    return true;
  };

  const onClickGerarCtes = async (): Promise<void> => {
    dispatch({ type: 'openLoader' });

    if (validaImportacaoNotasDuplicadas()) {
      const { status, messages: msgs, data: retorno } = await gerarCtesPorNota(
        new Viagem({
          ...state.viagem,
          coletaEmbarque: new ColetaEmbarque({
            ...state.coletaEmbarque,
          }),
        }),
        state.files,
        state.contemNotaDuplicada,
        state.calculaFrete,
        state.efetuaRateio,
        state.buscaParametrosRemetente,
        state.agrupaPorDestinatario
      );

      if (msgs && msgs.length > 0) setMessages(msgs);

      if (
        status === ResponseStatus.Success &&
        retorno.duplicadas?.length <= 0 &&
        retorno.viagem.conhecimentos?.length > 0
      ) {
        adicionaCtes(retorno.viagem);

        dispatch({ type: 'updateViagem', payload: {} });
        dispatch({ type: 'updateNotaDuplicada', payload: false });
        dispatch({ type: 'updateFiles', payload: [] });
      } else if (retorno.duplicadas?.length > 0) {
        dispatch({ type: 'updateViagem', payload: retorno.viagem });
        dispatch({ type: 'updateNotaDuplicada', payload: true });
        dispatch({ type: 'updateFiles', payload: retorno.duplicadas });
      }
    }

    dispatch({ type: 'closeLoader' });
  };

  const onAutoCompleteTipoEmbarque = async (
    e: string
  ): Promise<TipoEmbarque[]> => {
    const {
      status,
      message: msg,
      data: tiposEmbarque,
    } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
    });

    if (msg)
      dispatch({
        type: 'message',
        payload: {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      });

    return tiposEmbarque;
  };

  const onAutoCompleteTipoFrete = async (e: string): Promise<TipoFrete[]> => {
    const { status, message: msg, tiposFrete } = await getTipoFreteAutoComplete(
      {
        noTipoFrete: e,
      }
    );

    if (msg)
      dispatch({
        type: 'message',
        payload: {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      });

    return tiposFrete;
  };

  const onAutoCompleteEspecieFrete = async (
    e: string
  ): Promise<EspecieFrete[]> => {
    const {
      status,
      message: msg,
      data: especiesFrete,
    } = await getEspecieFreteAutoComplete({
      noEspecieFrete: e,
    });

    if (msg)
      dispatch({
        type: 'message',
        payload: {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      });

    return especiesFrete;
  };

  const onAutoCompleteFreteMercadoria = async (
    e: string
  ): Promise<FreteMercadoria[]> => {
    const {
      status,
      message: msg,
      data: freteMercadorias,
    } = await getFreteMercadoriaAutoComplete({
      noFreteMercadoria: e,
    });

    if (msg)
      dispatch({
        type: 'message',
        payload: {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      });

    return freteMercadorias;
  };

  const onAutoCompleteVeiculo = async (e: string): Promise<Veiculo[]> => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });

    if (msg)
      dispatch({
        type: 'message',
        payload: {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      });

    return veiculos;
  };

  const onAutoCompleteMotorista = async (e: string): Promise<Motorista[]> => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaViagemAutoComplete({
      noPessoa: e,
      nrSeqVeiculo: state.viagem.veiculoPrincipal?.nrSeqVeiculo,
    });

    if (msg)
      dispatch({
        type: 'message',
        payload: {
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      });

    return motoristas;
  };

  const onAutoCompleteOrigem = async (e: string) => {
    const {
      status,
      message: msg,
      data: origens,
    } = await getConsignatarioAutoComplete({
      noPessoa: e,
    });

    if (msg)
      dispatch({
        type: 'message',
        payload: {
          message: msg,
          themm:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        },
      });

    return origens;
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      icon={['fas', 'file-invoice']}
      title='Gerar Conhecimentos por Nota'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Loader loading={state.loading} />
      {messages.length > 0 && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification messages={messages} onClose={() => setMessages([])} />
        </div>
      )}
      {state.message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={state.message.message}
            theme={state.message.theme}
            onClose={() => dispatch({ type: 'message', payload: null })}
          />
        </div>
      )}
      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-3 justify-content-between'>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Switch
              label='Efetuar rateio'
              tooltip='Rateia o valor inserido dentre os CTes gerados.'
              formControl
              checked={state.efetuaRateio}
              onChange={(efetuaRateio: boolean) =>
                dispatch({
                  type: 'updateEfetuaRateio',
                  payload: efetuaRateio,
                })
              }
            />
          </div>
          <div className='col-2'>
            {/* @ts-expect-error */}
            <Switch
              label='Consignatários'
              tooltip='Libera vínculo dos consignatários'
              formControl
              checked={state.liberaConsignatarios}
              onChange={(value: boolean) => {
                dispatch({
                  type: 'updateLiberaConsignatarios',
                  payload: value,
                });
              }}
            />
          </div>
          <div className='col-3'>
            {/* @ts-expect-error */}
            <Switch
              label='Busca Param. Remetente'
              tooltip='Busca dados abaixo do cadastro do Remetente presente na nota.'
              formControl
              checked={state.buscaParametrosRemetente}
              onChange={(value: boolean) => {
                dispatch({
                  type: 'updateBuscaParametrosRemetente',
                  payload: value,
                });
              }}
            />
          </div>
          <div className='col-3'>
            {/* @ts-expect-error */}
            <Switch
              label='Agrupa por Destinatário'
              tooltip='Gera o CT-e com todas as notas de mesmo destinatário'
              formControl
              checked={state.agrupaPorDestinatario}
              onChange={(value: boolean) => {
                dispatch({
                  type: 'updateAgrupaPorDestinatario',
                  payload: value,
                });
              }}
            />
          </div>
        </div>
        {state.agrupaPorDestinatario && (
          <div className='row mb-3'>
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Switch
                label='Libera duplicadas'
                tooltip='Libera a inclusão de notas já importadas em alguma viagem.'
                formControl
                checked={state.contemNotaDuplicada}
                onChange={(value: boolean) => {
                  dispatch({
                    type: 'updateNotaDuplicada',
                    payload: value,
                  });
                }}
              />
            </div>
          </div>
        )}
        <div className='row mb-3'>
          <div className='col-4'>
            <Autocomplete
              required
              label='Tipo Embarque'
              searchDataSource={onAutoCompleteTipoEmbarque}
              selectedItem={state.coletaEmbarque.tipoEmbarque}
              onSelectItem={(tipoEmbarque: TipoEmbarque) => {
                dispatch({
                  type: 'updateColetaEmbarque',
                  payload: {
                    tipoEmbarque,
                    nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                  },
                });
              }}
              dataSourceTextProperty='noTipoEmbarque'
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              required
              label='Tipo de Frete'
              searchDataSource={onAutoCompleteTipoFrete}
              selectedItem={state.coletaEmbarque.tipoFrete}
              onSelectItem={(tipoFrete: TipoFrete) => {
                dispatch({
                  type: 'updateColetaEmbarque',
                  payload: {
                    tipoFrete,
                    nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
                  },
                });
              }}
              dataSourceTextProperty='noTipoFrete'
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              required
              label='Espécie de Frete'
              searchDataSource={onAutoCompleteEspecieFrete}
              selectedItem={state.coletaEmbarque.especieFrete}
              onSelectItem={(especieFrete: EspecieFrete) => {
                dispatch({
                  type: 'updateColetaEmbarque',
                  payload: {
                    especieFrete,
                    nrSeqEspecieFrete: especieFrete.nrSeqEspecieFrete,
                  },
                });
              }}
              dataSourceTextProperty='noEspecieFrete'
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-4'>
            <Autocomplete
              required
              label='Mercadoria Principal'
              searchDataSource={onAutoCompleteFreteMercadoria}
              selectedItem={state.coletaEmbarque.mercadoriaPrincipal}
              onSelectItem={(mercadoriaPrincipal: FreteMercadoria) => {
                dispatch({
                  type: 'updateColetaEmbarque',
                  payload: {
                    mercadoriaPrincipal,
                    nrSeqFreteMercadoria:
                      mercadoriaPrincipal.nrSeqFreteMercadoria,
                  },
                });
              }}
              dataSourceTextProperty='noFreteMercadoria'
            />
          </div>
          <div className='col-3'>
            <Autocomplete
              required
              label='Veículo'
              searchDataSource={onAutoCompleteVeiculo}
              selectedItem={state.viagem.veiculoPrincipal}
              onSelectItem={async (veiculoPrincipal: Veiculo) => {
                dispatch({ type: 'openLoader' });

                const datasource = await onSelectVeiculoPrincipal(
                  veiculoPrincipal
                );

                dispatch({
                  type: 'updateViagem',
                  payload: {
                    ...datasource,
                    veiculoPrincipal,
                    nrSeqVeiculoPrinc: veiculoPrincipal.nrSeqVeiculo,
                  },
                });

                dispatch({ type: 'closeLoader' });
              }}
              dataSourceTextProperty='placa'
            />
          </div>
          {state.efetuaRateio && (
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                label='Valor para o rateio'
                text={state.coletaEmbarque.vlrTotal}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrTotal: number) => {
                  dispatch({
                    type: 'updateColetaEmbarque',
                    payload: { vlrTotal },
                  });
                }}
              />
            </div>
          )}
          {!state.efetuaRateio && (
            <div className='col-3'>
              {/* @ts-expect-error */}
              <Textbox
                required
                readOnly={!state.calculaFrete}
                label='Tarifa'
                text={state.coletaEmbarque.vlrTarifa}
                placeholder='Informar o valor da tarifa'
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrTarifa: any) => {
                  dispatch({
                    type: 'updateColetaEmbarque',
                    payload: { vlrTarifa },
                  });
                }}
              />
            </div>
          )}
          {!state.efetuaRateio && (
            <div className='col-2'>
              {/* @ts-expect-error */}
              <Switch
                label='Calcula Frete'
                tooltip='Se não marcado, valor do frete é definido como R$0,01'
                formControl
                checked={state.calculaFrete}
                onChange={(calculaFrete: boolean) =>
                  dispatch({
                    type: 'updateCalculaFrete',
                    payload: calculaFrete,
                  })
                }
              />
            </div>
          )}
        </div>
        <div className='row mb-3'>
          <div className='col-7'>
            <Autocomplete
              required
              label='Motorista'
              tooltip='É obrigatório a seleção do veículo e apenas os motoristas cadastrados para o veículo principal serão listados'
              searchDataSource={onAutoCompleteMotorista}
              selectedItem={state.viagem.motorista}
              onSelectItem={(motorista: Motorista) => {
                dispatch({
                  type: 'updateViagem',
                  payload: {
                    motorista,
                    nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                  },
                });
                dispatch({
                  type: 'updateColetaEmbarque',
                  payload: { motorista },
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            {/* @ts-expect-error */}
            <Textbox
              label='Observação'
              text={state.coletaEmbarque.observacao}
              onChangedValue={(observacao: string) => {
                dispatch({
                  type: 'updateColetaEmbarque',
                  payload: { observacao },
                });
              }}
            />
          </div>
        </div>
        {state.liberaConsignatarios && (
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Consignatário Origem'
                searchDataSource={onAutoCompleteOrigem}
                selectedItem={state.coletaEmbarque.origemConsig}
                onSelectItem={(origemConsig: ColetaConsignatario) => {
                  dispatch({
                    type: 'updateColetaEmbarque',
                    payload: {
                      origemConsig,
                      nrSeqPessoaConsigOri: origemConsig.nrSeqPessoaConsig,
                    },
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-6'>
              <Autocomplete
                label='Consignatário Destino'
                searchDataSource={onAutoCompleteOrigem}
                selectedItem={state.coletaEmbarque.destinoConsig}
                onSelectItem={(destinoConsig: ColetaConsignatario) => {
                  dispatch({
                    type: 'updateColetaEmbarque',
                    payload: {
                      destinoConsig,
                      nrSeqPessoaConsigDest: destinoConsig.nrSeqPessoaConsig,
                    },
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
        )}
        {state.contemNotaDuplicada && (
          <div className='row'>
            <div className='col-9'>
              <div
                className='alert alert-warning'
                role='alert'
                style={{ textAlign: 'center' }}
              >
                <b>
                  As notas abaixo já estão lançadas para esse remetente com este
                  número/série.
                </b>
                <p />
                Para lança-la novamente é necessário a confirmação dos
                caracteres abaixo.
              </div>
            </div>
            <div className='col-3'>
              <div className='row justify-content-center mb-2'>
                <div className='col'>
                  <h3 className='fw-bold' style={{ color: '#008000' }}>
                    {state.randomText}
                  </h3>
                </div>
                <div className='col mt-1'>
                  <Button
                    outline
                    icon={['fas', 'random']}
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    tooltip='Gerar Nova Sequencia'
                    tooltipDirection='bottom'
                    onClick={() =>
                      dispatch({
                        type: 'updateRandomText',
                      })
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    text={state.input}
                    onChangedValue={(item: string) =>
                      dispatch({ type: 'updateInput', payload: item })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='row'>
          <FileUpload
            files={state.files}
            onChange={(items: any) => {
              dispatch({ type: 'updateFiles', payload: items });
              dispatch({ type: 'message', payload: null });
            }}
            allowedMimeTypes={[MimeTypes.Types.XML]}
            maxFiles={300}
            dropzone
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          theme={Theme.Success}
          onClick={() => onClickGerarCtes()}
          text='Gerar CTEs'
        />
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
