import { Pessoa } from '../SEG';
import Extrato from './extrato';

export default class ConciliacaoBancaria {
  constructor(jsonObject = {}) {
    this.nrSeqConciliacaoBancaria = jsonObject.nrSeqConciliacaoBancaria;
    this.nrSeqExtrato = jsonObject.nrSeqExtrato;
    this.vlrDocumento = jsonObject.vlrDocumento;
    this.nrDocumento = jsonObject.nrDocumento;
    this.dtDocumento = jsonObject.dtDocumento;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.extrato = new Extrato(jsonObject.extrato ?? {});
    this.noPessoa = jsonObject.noPessoa;
    this.nrDocumentoExtrato = jsonObject.nrDocumentoExtrato;
  }

  toJson = () => JSON.stringify(this);
}
