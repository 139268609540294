import React from 'react';
import { PneuVeiculo } from 'core/models/FRO';

import style from './index.module.css';

function Tire({
  onClick,
  nrOrdem,
  active,
  data = new PneuVeiculo(),
  isPrintModel,
}) {
  let className = '';
  let stylePneu = '';
  let stylePneuColor = '';

  if (isPrintModel) {
    stylePneu = style.tire;
    stylePneu += ` ${style.tire_empty}`;
  } else {
    if (active) stylePneu = style.tire;
    else stylePneu = style.tire_spare;

    if (data.nrSulco !== null) {
      if (data.nrSulco <= 4) stylePneuColor = style.tire_filled_low;
      else if (data.nrSulco > 4 && data.nrSulco <= 12)
        stylePneuColor = style.tire_filled_medium;
      else stylePneuColor = style.tire_filled;
    } else stylePneuColor = style.tire_filled_no_sulco;

    if (data.nrSeqPneu) stylePneu += ` ${stylePneuColor} `;
    else stylePneu += ` ${style.tire_empty}`;
  }

  className = `${stylePneu} ${style.font}`;

  if (data.selected) className += ` ${style.selected}`;

  const click = () => {
    if (!isPrintModel) onClick({ ...data, nrOrdem });
  };

  const geraCampoCdPneu = () => (
    <>
      {data.pneu.cdPneu && (
        <span className={style.cdPneu}>Cd: {data.pneu.cdPneu} </span>
      )}
      {data.nrSulco > 0 && (
        <span className={style.nrSulco}>Sulco: {data.nrSulco}</span>
      )}
      {data.pneu?.nrLacre > 0 && (
        <span className={style.nrSulco}>Lacre: {data.pneu?.nrLacre}</span>
      )}
    </>
  );

  const geraCampoCdPneuVazio = () => (
    <span className={style.cdPneuEmpty}> </span>
  );

  return (
    <div className={style.tireContainer}>
      <div
        role='button'
        tabIndex={0}
        onClick={() => {
          click();
        }}
        onKeyDown={() => {
          click();
        }}
        className={className}
      >
        {isPrintModel ? nrOrdem : ' '}
      </div>
      {isPrintModel ? geraCampoCdPneuVazio() : geraCampoCdPneu()}
    </div>
  );
}

export default Tire;
