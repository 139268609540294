import ProdutoGrupo from './produtoGrupo';

export default class ProdutoSubGrupo {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoempresa = jsonObject.nrSeqGrupoempresa;
    this.nrSeqProdutoSubGrupo = jsonObject.nrSeqProdutoSubGrupo;
    this.noProdutoSubGrupo = jsonObject.noProdutoSubGrupo;
    this.nrSeqProdutoGrupo = jsonObject.nrSeqProdutoGrupo;
    this.cdProdutoSubGrupo = jsonObject.cdProdutoSubGrupo;
    this.noProdutoGrupo = jsonObject.noProdutoGrupo;
    this.vlrCusto = jsonObject.vlrCusto;
    this.produtoGrupo =
      jsonObject.produtoGrupo !== undefined && jsonObject.produtoGrupo !== null
        ? new ProdutoGrupo(jsonObject.produtoGrupo)
        : new ProdutoGrupo();
  }

  toJson = () => JSON.stringify(this);
}
