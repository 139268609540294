import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification, ToolbarButtons } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getFuncionario,
  saveFuncionario,
  deleteFuncionario,
  printFuncionario,
} from 'core/services/SEG/funcionario';
import { IFuncionario } from 'core/interfaces/SEG';
// @ts-expect-error
import FuncionarioPanel from './funcionarioPanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';
import PessoaReferencias from './pessoaReferenciaPanel.tsx';

interface IFuncionarioState extends IFuncionario {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Funcionario({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);
  const pessoaReferencias = useRef<any>(null);

  const [funcionarioData, setFuncionarioData] = useState<
    IFuncionarioState | any
  >({
    flgFisica: true,
    flgAtivo: true,
    flgGeraCustoRecorrente: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const clearPessoaReferenciasPanel = () => {
    pessoaReferencias?.current?.clear();
  };

  const fillPessoaReferenciasPanel = () => {
    pessoaReferencias?.current?.fillGrid();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();
  const getDataReferenciasGrid = () =>
    pessoaReferencias?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();
    clearPessoaReferenciasPanel();

    setFuncionarioData({
      flgFisica: true,
      flgAtivo: true,
      flgGeraCustoRecorrente: true,
      vlrSalario: 0,
      vlrComissao: 0,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      funcionarioDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      setLoading(true);

      setFuncionarioData((prevData) => ({
        ...prevData,
        pessoa: { nrSeqPessoa: undefined },
      }));

      const retornoFuncionario: IFuncionario | any = await getFuncionario(
        nrSeqPessoa
      );

      if ('nrSeqPessoaFun' in retornoFuncionario) {
        setFuncionarioData({
          ...retornoFuncionario,
          pessoa: { nrSeqPessoa: retornoFuncionario.nrSeqPessoaFun },
        });

        if (
          retornoFuncionario.funcionarioDocumentos &&
          retornoFuncionario.funcionarioDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();

        if (
          retornoFuncionario.funcionarioReferencias &&
          retornoFuncionario.funcionarioReferencias.length > 0
        ) {
          fillPessoaReferenciasPanel();
        } else clearPessoaReferenciasPanel();
      }
    } else {
      onNew();
    }
    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printFuncionario({
      nrSeqPessoaFun: funcionarioData.nrSeqPessoaFun,
    });

    if (value) {
      const linkSource = `data:application/vnd.ms-pdf;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Funcionário_${funcionarioData.noPessoa}.pdf`;
      downloadLink.click();
    } else {
      onSetMessage(false, 'Erro ao gerar relatório.');
    }

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();

    if (!pessoa) {
      setMessage({
        message: 'Necessário preencher as informações de Funcionário.',
        theme: Theme.Danger,
      });
    } else {
      const documentos = getDataDocumentsGrid();
      const referencias = getDataReferenciasGrid();

      if (referencias !== undefined && referencias.length > 0) {
        referencias.forEach((referencia) => {
          if (referencia.flgPessoal === 'Não') {
            referencia.flgPessoal = false;
          }
        });
      }

      const { status, message: msg, value } = await saveFuncionario({
        ...funcionarioData,
        ...pessoa,
        nrSeqEmpresa: funcionarioData.nrSeqEmpresa,
        flgFisica: pessoa.flgFisica,
        funcionarioDocumentos: documentos,
        funcionarioReferencias: referencias,
      });

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      const text = value && value.length > 0 ? value.join() : null;

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();

        setWarning({ message: text, theme: Theme.Warning });
        onNew();
      }
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteFuncionario(registryKey);

      setFuncionarioData(
        status === ResponseStatus.Success ? {} : funcionarioData
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Funcionário'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={funcionarioData?.nrSeqPessoaFun > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {funcionarioData.nrSeqPessoaFun > 0 && (
        <ToolbarButtons>
          <ToolbarButtons.Button
            text='Imprimir'
            icon='print'
            onClick={() => onPrint()}
          />
        </ToolbarButtons>
      )}
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={funcionarioData.pessoa?.nrSeqPessoa}
            data={funcionarioData}
            setData={setFuncionarioData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Funcionário',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <FuncionarioPanel
                      funcionarioData={funcionarioData}
                      setFuncionarioData={setFuncionarioData}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <PessoaReferencias
                      funcionarioData={funcionarioData}
                      setFuncionarioData={setFuncionarioData}
                      onSetMessage={onSetMessage}
                      ref={pessoaReferencias}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      funcionarioData={funcionarioData}
                      setFuncionarioData={setFuncionarioData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
