import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  Theme,
  ResponseStatus,
  BootstrapSizes,
  JustifyContent,
  MimeTypes,
  toBase64,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Switch,
  Button,
  RadioButton,
  Panel,
  GridView,
  ToolbarButtons,
  FileUpload,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  PrintContratoParcelamento,
  getParcells,
  getTituloReceber,
  getUsuarioTituloReceber,
  saveListTituloReceber,
  saveTituloReceber,
  printDetalhamentoRenegociacaoDocumento,
} from 'core/services/FIN/tituloReceber';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { setTransaction } from 'core/services/api';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import {
  getBancoAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import TituloEspecie from 'core/models/FIN/tituloEspecie';
import {
  getRetornoGridPlanoContaReceber,
  GetTituloEspeciePlanoDeContaTituloReceber,
} from 'core/services/FIN/tituloEspeciePlanoDeConta';
import ModalOcorrencia from './modalOcorrencia';
import ModalDesagrupamento from './ModalDesagrupamento';
import ModalEditarParcela from './modalEditarParcela';

const tipoParcelas = [
  {
    text: 'Registro por mês:',
    value: 'M',
  },
  {
    text: 'Registro por intervalo de dias:',
    value: 'D',
  },
];

export default function TituloReceberItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenTransaction,
  findTransaction,
  onOpenReport,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ noTipoParcela: 'M' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [edicaoDespesa, setDespesa] = useState(false);
  const [edicaoTipoDespesa, setEdicaoTipoDespesa] = useState(false);
  const [showOcorrencia, setModalShowOcorrencia] = useState(false);
  const [importedFile, setImportedFile] = useState([]);
  const [obs, SetObs] = useState([]);
  const gridView = useRef();
  const gridViewOcorrencia = useRef();
  const gridView2 = useRef();
  const gridViewDocumentos = useRef();
  const [modalShow, setModalShow] = useState({});
  const [modalEditarParcelaShow, setModalEditarParcelaShow] = useState({});

  const savePrice = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloEspeciePlanoDeConta === keyValue
    );
    itemParcela.vlrPlanoConta = value;

    for (let i = 0; i < datasource.length; ) {
      if (
        datasource[i].nrSeqTituloEspeciePlanoDeConta ===
        itemParcela.nrSeqTituloEspeciePlanoDeConta
      ) {
        datasource[i].vlrPlanoConta = itemParcela.vlrPlanoConta;
      }
      i += 1;
    }

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);
  };

  const fPrice = function (keyValue, value) {
    savePrice(keyValue, value);
  };

  const columnsOcorrencia = [
    {
      key: 'nrSeqTituloReceberFatura',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'nrSeqTipoOcorrenciaBoleto',
      title: 'NrSeqTipoOcorrenciaBoleto',
      visible: false,
    },
    {
      key: 'dtOcorrencia',
      title: 'Dt. Ocorrência',
      format: GridView.DataTypes.Date,
    },
    { key: 'noOcorrencia', title: 'Ocorrência' },
    {
      key: 'nrSeqArquivoBancario',
      title: 'NrSeqArquivoBancario',
      visible: false,
    },
    { key: 'numeroDocumento', title: 'Numero Documento' },
    { key: 'nossoNumero', title: 'Nosso Número' },
    { key: 'nrArquivoBancario', title: 'Arq. Bancário' },
    {
      key: 'vlrTitulo',
      title: 'Valor Titulo',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Valor Recebido',
      format: GridView.DataTypes.Money,
    },
    { key: 'vlrJuro', title: 'VlrJuro', visible: false },
    { key: 'vlrDesconto', title: 'VlrDesconto', visible: false },
  ];

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getTituloReceber(registryKey);
      setData(retorno.value);
      setImportedFile({ documentos: retorno.value.documentos });

      if (gridViewOcorrencia && gridViewOcorrencia.current) {
        gridViewOcorrencia.current.setDataSource(
          retorno.value.tituloReceberFaturaOcorrencias
        );
      }
      if (retorno.value.tituloReceberS !== undefined) {
        if (retorno.value.tituloReceberS.length > 1) {
          if (gridView && gridView.current) {
            gridView.current.setDataSource(retorno.value.tituloReceberS);
          }
        }
      }
      if (retorno.value.tituloReceberPlanoDeConta !== undefined) {
        if (gridView2 && gridView2.current) {
          gridView2.current.setDataSource(
            retorno.value.tituloReceberPlanoDeConta
          );
        }
      }
      if (retorno.value.documentos !== undefined) {
        if (gridViewDocumentos && gridViewDocumentos.current)
          gridViewDocumentos.current.setDataSource(retorno.value.documentos);
      }

      setMessage({
        message: retorno.message,
        theme: Theme.Info,
      });

      setLoading(false);
    } else {
      const usuario = await getUsuarioTituloReceber(1);
      setData({
        noTipoParcela: 'M',
        empresa: usuario.empresa,
        nrSeqEmpresa: usuario.empresa.nrSeqEmpresa,
        geraSequencia: false,
        vlrSaldoTit: 0,
        vlrMultaRecebida: 0,
        vlrJuroRecebido: 0,
        vlrRecebido: 0,
        tipoParcela: 1,
        vlrTitulo: 0,
        vlrDesconto: 0,
        flgPermiteAlterar: true,
      });
      if (gridView && gridView.current) {
        gridView.current.setDataSource(null);
      }
      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(null);

      if (gridViewOcorrencia && gridViewOcorrencia.current) {
        gridViewOcorrencia.current.setDataSource(null);
      }
    }
  }, [registryKey]);

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onRemoveDocumento = async (selectedValue, datasource) => {
    let documentos = [];
    if (selectedValue.nrSeqTituloReceberDocumento === undefined) {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
    } else {
      documentos = datasource.filter(
        (Documento) => Documento !== selectedValue
      );
      const dataDocumento = datasource.filter(
        (Documento) => Documento === selectedValue
      );
      dataDocumento[0].status = GridView.EnumStatus.Remover;
      documentos.push(dataDocumento[0]);
    }
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(documentos);
  };

  const columnsAnexos = [
    {
      key: 'nrSeqTituloReceberDocumento',
      visible: false,
    },
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'noObs',
      title: 'Observações',
    },
    {
      key: 'nrSeqTituloReceberDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },

    {
      key: 'nrSeqTituloReceberDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickAbrirTitulo = async (e) => {
    setLoading(true);

    if (e.nrSeqTituloReceber && e.nrSeqTituloReceber > 0) {
      registryKey = e.nrSeqTituloReceber;

      await load();
    }

    setLoading(false);
  };

  const onClickEditarTitulo = (e, datasource) => {
    if (!e.nrSeqTituloReceber) {
      const dataSource = datasource.filter((item) => item !== e);

      if (gridView && gridView.current)
        gridView.current.setDataSource(dataSource);

      setModalEditarParcelaShow({
        ...modalEditarParcelaShow,
        show: true,
        data: e,
      });
    }
  };

  const onClickRemoverTitulo = (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const columns = [
    { key: 'nrSeqTituloReceber', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoempresa', visible: false },
    { key: 'nrSeqUsuario', title: 'NrSeqUsuario', visible: false },
    { key: 'vlrTitulo', title: 'VlrTitulo', visible: false },
    { key: 'dtCad', title: 'DtCad', visible: false },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    { key: 'nrTit', title: 'Titulo' },
    { key: 'nrOrdem', title: 'Ordem' },
    { key: 'noObs', title: 'NoObs', visible: false },
    { key: 'vlrRecebido', title: 'VlrRecebido', visible: false },
    { key: 'vlrDesconto', title: 'VlrDesconto', visible: false },
    { key: 'vlrSaldoTit', title: 'Saldo', format: GridView.DataTypes.Decimal },
    { key: 'vlrJurodia', title: 'VlrJurodia', visible: false },
    { key: 'vlrJuroRecebido', title: 'VlrJuroRecebido', visible: false },
    { key: 'vlrMultaRecebida', title: 'VlrMultaRecebida', visible: false },
    { key: 'nrSeqEmpresa', title: 'NrSeqEmpresa', visible: false },
    { key: 'nrSeqNfv', title: 'NrSeqNfv', visible: false },
    { key: 'nrSeqTituloEspecie', title: 'NrSeqTituloEspecie', visible: false },
    { key: 'vlrMulta', title: 'VlrMulta', visible: false },
    { key: 'nrSeqPessoaCli', title: 'NrSeqPessoaCli', visible: false },
    {
      key: 'nrSeqFormaPagamento',
      title: 'NrSeqFormaPagamento',
      visible: false,
    },
    { key: 'nossoNumero', title: 'NossoNumero', visible: false },
    { key: 'nrParcelas', title: 'nrParcelas', visible: false },
    {
      key: 'nrSeqTituloReceberFatura',
      title: 'NrSeqTituloReceberFatura',
      visible: false,
    },
    { key: 'nrSeqFatura', title: 'NrSeqFatura', visible: false },
    {
      key: 'nrSeqRemessaFormaPagamento',
      title: 'NrSeqRemessaFormaPagamento',
      visible: false,
    },
    {
      key: 'nrSeqPessoaInformacaoBancaria',
      title: 'NrSeqPessoaInformacaoBancaria',
      visible: false,
    },
    { key: 'vlrJuroAdicionado', title: 'VlrJuroAdicionado', visible: false },
    { key: 'vlrMultaAdicionado', title: 'VlrMultaAdicionado', visible: false },
    { key: 'dtContab', title: 'DtContab', visible: false },
    { key: 'nrSeqTituloPagar', title: 'NrSeqTituloPagar', visible: false },
    { key: 'nrSeqTituloPagar', title: 'NrSeqTituloPagar', visible: false },
    { key: 'flgGeraSequencia', title: 'flgGeraSequencia', visible: false },
    { key: 'noTipoParcela', title: 'noTipoParcela', visible: false },
    {
      key: 'nrSeqTipoRecebimento',
      title: 'NrSeqTipoRecebimento',
      visible: false,
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickAbrirTitulo(e),
      theme: Theme.Info,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Abrir Título',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgPermiteEditarParcela',
      onClick: (e, datasource) => onClickEditarTitulo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgPermiteRemoverParcela',
      onClick: (e, datasource) => onClickRemoverTitulo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columns2 = [
    {
      key: 'nrSeqTituloEspeciePlanoDeConta',
      visible: false,
    },
    {
      key: 'planoDeContaCre.cdCaminhoCompleto',
      title: 'Plano De Contas Credito',
    },
    {
      key: 'vlrPlanoConta',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      readOnlyDynamic: 'flgPercentual',
      onBlur: fPrice,
      maxLength: 10,
    },
    { key: 'flgPercentual', visible: false },
  ];

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
      flgAtivo: true,
    });
    return clientes;
  };

  const onAdicionarParcelas = async () => {
    setLoading(true);

    const lista = [];

    if (data.status !== GridView.EnumStatus.Alterar) {
      data.status = GridView.EnumStatus.Inserir;
    }

    const newData = {
      nrSeqGrupoEmpresa: data.nrSeqGrupoEmpresa,
      nrSeqUsuario: data.nrSeqUsuario,
      nrSeqTituloReceber: data.nrSeqTituloReceber,
      vlrTitulo: data.vlrTitulo,
      dtCad: data.dtCad,
      dtVencimento: data.dtVencimento,
      nrTit: data.nrTit,
      nrOrdem: data.nrOrdem,
      noObs: data.noObs,
      vlrRecebido: data.vlrRecebido,
      vlrDesconto: data.vlrDesconto,
      vlrSaldoTit: data.vlrSaldoTit,
      vlrJuroDia: data.vlrJuroDia,
      vlrJuroRecebido: data.vlrJuroRecebido,
      vlrMultaRecebida: data.vlrMultaRecebida,

      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqNfv: data.nrSeqNfv,
      nrSeqTituloEspecie: data.nrSeqTituloEspecie,
      vlrMulta: data.vlrMulta,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
      nrSeqTituloReceberFatura: data.nrSeqTituloReceberFatura,
      nrSeqFatura: data.nrSeqFatura,
      nrSeqRemessaFormaPagamento: data.nrSeqRemessaFormaPagamento,
      nrSeqPessoaInformacaoBancaria: data.nrSeqPessoaInformacaoBancaria,
      vlrJuroAdicionado: data.vlrJuroAdicionado,
      nlrMultaAdicionado: data.nlrMultaAdicionado,
      dtContab: data.dtContab,
      nrSeqTituloPagar: data.nrSeqTituloPagar,
      nrSeqTipoRecebimento: data.nrSeqTipoRecebimento,
      vlrOriginal: data.vlrOriginal,

      dtRecebimentoDe: data.dtRecebimentoDe,
      dtRecebimentoAte: data.dtRecebimentoAte,
      dtVencimentoDe: data.dtVencimentoDe,
      dtVencimentoAt: data.dtVencimentoAt,
      dtContabilDe: data.dtContabilDe,
      dtContabilAte: data.dtContabilAte,
      nrnfv: data.nrnfv,
      nrSeqPessoaVen: data.nrSeqPessoaVen,
      nrNossoNumero: data.nrNossoNumero,
      nrDocumento: data.nrDocumento,
      nrTitulo: data.nrTitulo,
      tipoTituloReceber: data.tipoTituloReceber,
      whereNotInConvenioExtrato: data.whereNotInConvenioExtrato,
      competencia: data.competencia,
      nrIdentificado: data.nrIdentificado,
      nrSeqRecebimentoMassivo: data.nrSeqRecebimentoMassivo,

      dtRecebimento: data.dtRecebimento,
      nrSeqPlanoConta: data.nrSeqPlanoConta,
      flgOcorrencia: data.flgOcorrencia,
      noRelatorio: data.noRelatorio,
      empresa: data.empresa,
      planoConta: data.planoConta,
      planosConta: data.planosConta,
      flgGeraSequencia: data.flgGeraSequencia,
      flgPeriodoMe: data.flgPeriodoMe,
      nrParcelas: data.nrParcelas,
      nrDiasParcela: data.nrDiasParcela,
      flgLiquidar: data.flgLiquidar,
      flgPeriodoIntervalo: data.flgPeriodoIntervalo,
      nrIntervalo: data.nrIntervalo,
      flgGeraTituloFatura: data.flgGeraTituloFatura,
      noTipoParcela: data.noTipoParcela,
      vlrJuroAdiconado: data.vlrJuroAdiconado,
      flgPermiteAlterar: data.flgPermiteAlterar,
      nossoNumero: data.nossoNumero,
      dtVencimentoStr: data.dtVencimentoStr,
    };

    const obj = await getParcells(newData);

    if (obj.message) {
      setMessage({
        message: obj.message,
        theme:
          obj.status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);

      return;
    }

    lista.push(...obj.data);

    if (gridView && gridView.current) gridView.current.setDataSource(lista);

    setLoading(false);
  };

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    return formasPagamento;
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
      flgInativo: false,
    });

    return tituloReceberEspecie;
  };

  const onSetMessage = (status, messageshow) => {
    if (messageshow)
      setMessage({
        message: messageshow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    setLoading(false);
  };

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  useEffect(async () => {
    if (importedFile.length > 0) {
      let noImagemBase64 = await toBase64(importedFile[0]);
      noImagemBase64 = String(noImagemBase64).substring(
        String(noImagemBase64).indexOf(',') + 1
      );

      const documento = {
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: String(importedFile[0].size),
        flgExibeDownload: false,
        nrSeqTituloReceber: data.nrSeqTituloReceber,
        tipoDocumento: data.tipoDocumento,
        noObs: obs.noObs,
        noImagemBase64,
        status: GridView.EnumStatus.Inserir,
      };
      let documentos = [];
      if (gridViewDocumentos && gridViewDocumentos.current)
        documentos = gridViewDocumentos.current.getDataSource() ?? [];

      documentos.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(documentos);

      setImportedFile({});
      SetObs({});
    }
  }, [importedFile]);

  const save = async () => {
    if (!data.flgPermiteAlterar) return;

    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const lista = gridView.current.getDataSource() ?? [];
    const despesa = gridView2.current ? gridView2.current.getDataSource() : [];
    const documentos = gridViewDocumentos.current
      ? gridViewDocumentos.current.getDataSource()
      : [];

    if (despesa !== null) {
      for (let i = 0; i < despesa.length; ) {
        despesa[i].vlrTitulo = despesa[i].vlrPlanoConta;
        i += 1;
      }
    }

    if (data.nrSeqTituloReceber > 0) {
      const obj = {
        ...data,
        nrSeqTransacao: transaction.nrSeqTransacao,
        documentos,
        tituloReceberPlanoDeConta: despesa,
      };
      const { status, message: msg, value } = await saveTituloReceber(obj);

      if (status === ResponseStatus.Success) {
        if (obj.formaPagamento.flgGrupo === 'G') {
          onOpenReport(transaction.noTransacao, value);
        } else if (
          obj.flgLiquidar &&
          obj.formaPagamento.flgGeraSequenciaRecibo
        ) {
          onOpenReport(transaction.noTransacao, value);
        }

        formSubmit.current.reset();

        setData({
          noTipoParcela: 'M',
          geraSequencia: false,
          vlrSaldoTit: 0,
          vlrMultaRecebida: 0,
          vlrJuroRecebido: 0,
          vlrRecebido: 0,
          tipoParcela: 1,
          vlrTitulo: 0,
          vlrDesconto: 0,
          flgPermiteAlterar: true,
        });
        if (gridView && gridView.current) gridView.current.setDataSource([]);
      }

      if (status === ResponseStatus.Success) {
        if (gridView && gridView.current) gridView.current.setDataSource(null);

        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(null);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else if (lista.length > 0) {
      for (let i = 0; i < lista.length; ) {
        lista[i].documentos = documentos;
        lista[i].tituloReceberPlanoDeConta = despesa;
        lista[i].nrSeqTransacao = transaction.nrSeqTransacao;
        i += 1;
      }

      const { status } = await saveListTituloReceber(lista);
      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        // onOpenReport(transaction.noTransacao, value);
        setData({
          noTipoParcela: 'M',
          geraSequencia: false,
          vlrSaldoTit: 0,
          vlrMultaRecebida: 0,
          vlrJuroRecebido: 0,
          vlrRecebido: 0,
          tipoParcela: 1,
          vlrTitulo: 0,
          vlrDesconto: 0,
          flgPermiteAlterar: true,
        });
        if (gridView && gridView.current) gridView.current.setDataSource(null);

        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(null);
      }
    } else {
      const obj = {
        ...data,
        documentos,
        tituloReceberPlanoDeConta: despesa,
        nrSeqTransacao: transaction.nrSeqTransacao,
      };

      const { status, message: msg, value } = await saveTituloReceber(obj);

      if (status === ResponseStatus.Success) {
        if (obj.formaPagamento.flgGrupo === 'G') {
          onOpenReport(transaction.noTransacao, value);
        } else if (
          obj.flgLiquidar &&
          obj.formaPagamento.flgGeraSequenciaRecibo
        ) {
          onOpenReport(transaction.noTransacao, value);
        }
        formSubmit.current.reset();
        // onOpenReport(transaction.noTransacao, value);
        setData({
          noTipoParcela: 'M',
          geraSequencia: false,
          vlrSaldoTit: 0,
          vlrMultaRecebida: 0,
          vlrJuroRecebido: 0,
          vlrRecebido: 0,
          tipoParcela: 1,
          vlrTitulo: 0,
          vlrDesconto: 0,
          flgPermiteAlterar: true,
        });
        if (gridView && gridView.current) gridView.current.setDataSource([]);
      }

      if (status === ResponseStatus.Success) {
        if (gridView && gridView.current) gridView.current.setDataSource(null);

        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(null);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onExcluir = async () => {};

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoComplete({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };
  useEffect(() => {
    const fetchInformacaoBancaria = async () => {
      if (data.formaPagamento?.flgGrupo === 'D') {
        const informacoes = await onSearchInformacaoBancaria();
        if (informacoes !== null && informacoes.length > 0) {
          const informacaoBancaria = informacoes[0];
          if (informacaoBancaria) {
            setData({
              ...data,
              informacaoBancaria,
              nrSeqPessoaInformacaoBancaria:
                informacaoBancaria.nrSeqPessoaInformacaoBancaria,
            });
          }
        }
      } else {
        setData({
          ...data,
          informacaoBancaria: null,
          nrSeqPessoaInformacaoBancaria: null,
        });
      }
    };

    fetchInformacaoBancaria();
  }, [data?.formaPagamento?.nrSeqFormaPagamento]);

  const onAdicionarTipoDespesa = async () => {
    if (edicaoDespesa.status !== GridView.EnumStatus.Alterar) {
      edicaoDespesa.status = GridView.EnumStatus.Inserir;
    }

    const gridcompleta = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const itemParcela = gridcompleta.find(
      (item) =>
        item.nrSeqTituloEspeciePlanoDeConta ===
        edicaoDespesa.nrSeqTituloEspeciePlanoDeConta
    );
    itemParcela.vlrPlanoConta = edicaoDespesa.vlrPlanoConta;

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(gridcompleta);
    setEdicaoTipoDespesa(false);
  };

  const Recalcula = async (tituloEspecie) => {
    if (tituloEspecie !== undefined) {
      data.tituloEspecie = tituloEspecie;
    }
    if (data.tituloEspecie === undefined) {
      data.tituloEspecie = new TituloEspecie();
    }
    if (data.tituloEspecie.nrSeqTituloEspecie > 0) {
      const {
        data: planoconta,
      } = await GetTituloEspeciePlanoDeContaTituloReceber({
        nrSeqTituloEspecie: data.tituloEspecie.nrSeqTituloEspecie,
      });
      for (let i = 0; i < planoconta.length; ) {
        planoconta[i].vlrTitulo = data.vlrTitulo;
        planoconta[i].vlrDesconto = data.vlrDesconto;
        i += 1;
      }
      if (planoconta.length > 0) {
        if (data.tituloEspecie.planoDeContas.length === 0) {
          data.tituloEspecie.planoDeContas.push(...planoconta);
        } else {
          for (let i = 0; i < data.tituloEspecie.planoDeContas.length; ) {
            data.tituloEspecie.planoDeContas[i] = planoconta.vlrPlanoConta;
            i += 1;
          }
        }
        const obj = await getRetornoGridPlanoContaReceber(planoconta);
        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(obj);
      } else if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(null);
    } else if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(null);
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    if (msg) onSetMessage(status, msg);
    return bancos;
  };

  const onOpenRecebimento = (selectedValue) => {
    const transactionContaChamada = findTransaction(
      TransacationCodes.Recebimento
    );

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = selectedValue;

      onOpenTransaction(transactionContaChamada, true);
    }
  };

  const onOpenTituloReceberAgrupado = async () => {
    setModalShow({
      ...modalShow,
      TituloReceberAgrupado: true,
    });
  };

  const onPrintContratoParcelamento = async () => {
    setLoading(true);
    const obj = { ...data };
    const value = await PrintContratoParcelamento(obj);
    if (value !== '') {
      onOpenReport(transaction.noTransacao, value);
    }
    setLoading(false);
  };

  const onNew = async () => {
    const usuario = await getUsuarioTituloReceber(1);

    setData({
      empresa: usuario.empresa,
      nrSeqEmpresa: usuario.empresa.nrSeqEmpresa,
      geraSequencia: false,
      vlrSaldoTit: 0,
      vlrMultaRecebida: 0,
      vlrJuroRecebido: 0,
      vlrRecebido: 0,
      tipoParcela: 1,
      vlrTitulo: 0,
      vlrDesconto: 0,
      flgPermiteAlterar: true,
    });

    if (gridView && gridView.current) gridView.current.setDataSource(null);

    if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(null);
    if (gridViewOcorrencia && gridViewOcorrencia.current) {
      gridViewOcorrencia.current.setDataSource(null);
    }
    setMessage(null);
  };

  const onPrintDetalhamentoRenegociacaoDocumento = async (e) => {
    setLoading(true);

    const value = await printDetalhamentoRenegociacaoDocumento(e);

    if (value !== '') {
      onOpenReport(transaction.noTransacao, value);
    }
    setLoading(false);
  };

  const atualizaParcelaEditada = (parcelaEditada) => {
    setModalEditarParcelaShow({
      ...modalEditarParcelaShow,
      show: false,
      data: {},
    });

    setLoading(true);

    const dataSource = gridView.current.getDataSource();
    dataSource.push(parcelaEditada);

    if (gridView && gridView.current)
      gridView.current.setDataSource(dataSource);

    setLoading(false);
  };

  const onCloseModalEditarParcela = (parcela) => {
    setModalEditarParcelaShow({
      ...modalEditarParcelaShow,
      show: false,
      data: {},
    });

    setLoading(true);

    const datasource = gridView.current.getDataSource();
    datasource.push(parcela);

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Titulo Receber'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      transaction={transaction}
      onDelete={
        data?.nrSeqTituloReceber > 0 && data?.flgPermiteAlterar
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      {data.nrSeqTituloReceber > 0 && (
        <ToolbarButtons>
          <ToolbarButtons.Button
            text='Ocorrências'
            onClick={() => setModalShowOcorrencia(true)}
          />
          {data.nrSeqTituloReceberFatura > 0 && (
            <ToolbarButtons.Button
              text='Titulos Agrupados'
              icon='layer-group'
              onClick={onOpenTituloReceberAgrupado}
            />
          )}
          {data.nrSeqTituloReceberFatura > 0 && (
            <>
              <ToolbarButtons.Button
                text='Contrato Parcelamento'
                icon='print'
                onClick={onPrintContratoParcelamento}
              />
              <ToolbarButtons.Button
                text='Detalhamento Renegociação'
                icon='print'
                onClick={() => onPrintDetalhamentoRenegociacaoDocumento(data)}
              />
            </>
          )}

          <ToolbarButtons.Button
            text='Visualizar/Efetuar Recebimento'
            icon='layer-group'
            onClick={() => onOpenRecebimento(data.nrSeqTituloReceber)}
          />
        </ToolbarButtons>
      )}
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onSearchEmpresa}
            selectedItem={data?.empresa}
            onSelectItem={(empresa) => {
              setData({
                ...data,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
            enabled={data?.flgPermiteAlterar}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            required
            searchDataSource={onSearchCliente}
            selectedItem={data?.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(cliente) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
            enabled={data?.flgPermiteAlterar}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textbox
            maxLength={20}
            label='Nr. Documento'
            text={data?.nrTit}
            onChangedValue={(nrTit) =>
              setData({
                ...data,
                nrTit,
              })
            }
            readOnly={data?.flgPermiteAlterarVencimento}
          />
        </div>
        <div className='col'>
          <Textbox
            label='Ordem'
            maxLength={2}
            text={data.nrOrdem}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOrdem) =>
              setData({
                ...data,
                nrOrdem,
              })
            }
            readOnly={data?.flgPermiteAlterarVencimento}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Vencimento'
            required
            text={data.dtVencimento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
            enabled={data.flgPermiteAlterar}
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Contabil'
            text={data.dtContab}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtContab) => setData({ ...data, dtContab })}
            readOnly={data?.flgPermiteAlterarVencimento}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Tipo Receita'
            required
            searchDataSource={onSearchTipo}
            selectedItem={data.tituloEspecie}
            onSelectItem={async (tituloEspecie) => {
              setData({
                ...data,
                tituloEspecie,
                nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
              });
              Recalcula(tituloEspecie);
            }}
            dataSourceTextProperty='noEspecie'
            enabled={data.flgPermiteAlterar}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.TituloEspecie}
            nrseqTransactionToOpen='nrSeqTituloEspecie'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Forma de Recebimento'
            required
            searchDataSource={onSearchFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setData({
                ...data,
                formaPagamento,
                nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
            enabled={data.flgPermiteAlterar}
          />
        </div>
        <div className='col'>
          <Textbox
            maxLength={20}
            required
            label='Valor Titulo'
            mask={MaskTypes.DecimalCustom}
            text={data.vlrTitulo}
            onChangedValue={(vlrTitulo) =>
              setData({
                ...data,
                vlrTitulo,
              })
            }
            onBlur={Recalcula}
            readOnly={data?.flgPermiteAlterarVencimento}
          />
        </div>

        <div className='col mb-3'>
          <Switch
            formControl
            label='Gera Sequencia'
            checked={data.flgGeraSequencia}
            onChange={(flgGeraSequencia) =>
              setData({ ...data, flgGeraSequencia, noTipoParcela: 'M' })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='A Vista '
            checked={data.flgLiquidar}
            onChange={(flgLiquidar) => setData({ ...data, flgLiquidar })}
          />
        </div>
        {data.formaPagamento?.flgGrupo === 'D' && (
          <div className='col'>
            <Autocomplete
              label='Informação Bancária'
              searchDataSource={onSearchInformacaoBancaria}
              selectedItem={data.informacaoBancaria}
              onSelectItem={(informacaoBancaria) =>
                setData({
                  ...data,
                  informacaoBancaria,
                  nrSeqPessoaInformacaoBancaria:
                    informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                })
              }
              dataSourceTextProperty='noDescricao'
              enabled={data.flgPermiteAlterar}
            />
          </div>
        )}
      </div>

      {data.formaPagamento?.flgGrupo === 'C' && data.flgLiquidar && (
        <div className='row mb-3'>
          <div className='col'>
            <DatePicker
              label='Data Cheque'
              required
              text={data.dtCheque}
              mask={MaskTypes.Date}
              onChange={(dtCheque) => setData({ ...data, dtCheque })}
            />
          </div>
          <div className='col-2'>
            <Textbox
              label='Número Cheque'
              required
              text={data.nrCheque}
              onChangedValue={(nrCheque) =>
                setData({
                  ...data,
                  nrCheque,
                })
              }
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Banco'
              searchDataSource={onSearchBanco}
              selectedItem={data.banco}
              required
              onSelectItem={(banco) => {
                setData({
                  ...data,
                  banco,
                  nrSeqBanco: banco.nrSeqBanco,
                });
              }}
              dataSourceTextProperty='noBanco'
            />
          </div>
          <div className='col'>
            <Textbox
              label='Agencia'
              text={data?.noAgencia}
              required
              onChangedValue={(noAgencia) =>
                setData({
                  ...data,
                  noAgencia,
                })
              }
            />
          </div>
          <div className='col'>
            <Textbox
              label='Conta'
              required
              text={data?.noConta}
              onChangedValue={(noConta) =>
                setData({
                  ...data,
                  noConta,
                })
              }
            />
          </div>
        </div>
      )}
      {data.flgGeraSequencia && (
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Sequencia'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-4'>
                  <RadioButton
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={data.noTipoParcela}
                    onChange={(noTipoParcela) => {
                      setData({
                        ...data,
                        noTipoParcela,
                      });
                    }}
                    buttons={tipoParcelas}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Parcelas'
                    mask={MaskTypes.Integer}
                    text={data.nrParcelas}
                    onChangedValue={(nrParcelas) =>
                      setData({ ...data, nrParcelas })
                    }
                  />
                </div>
                {data.noTipoParcela === 'D' && (
                  <div className='col-2'>
                    <Textbox
                      label='Dias'
                      mask={MaskTypes.Integer}
                      text={data.nrDiasParcela}
                      onChangedValue={(nrDiasParcela) =>
                        setData({ ...data, nrDiasParcela })
                      }
                    />
                  </div>
                )}
                <div className='col-2 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Adicionar'
                    onClick={onAdicionarParcelas}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={400}
            label='Obs.'
            text={data.noObs}
            onChangedValue={(noObs) => setData({ ...data, noObs })}
            readOnly={data?.flgPermiteAlterarVencimento}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Rateio do plano de Contas'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-9'>
                  <GridView
                    ref={gridView2}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns2}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
          {edicaoTipoDespesa && (
            <Panel>
              <Panel.Header
                title='Editar Sequencia'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col-2  mb-3'>
                    <Textbox
                      label='Valor'
                      mask={MaskTypes.DecimalCustom}
                      text={edicaoDespesa?.vlrPlanoConta}
                      onChangedValue={(vlrPlanoConta) =>
                        setDespesa({ ...edicaoDespesa, vlrPlanoConta })
                      }
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      text='Adicionar'
                      onClick={onAdicionarTipoDespesa}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          )}
          <div className='row mb-3'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Valor Recebido'
                readOnly
                text={data.vlrRecebido}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrRecebido) =>
                  setData({ ...data, vlrRecebido })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Valor Multa Recebido'
                readOnly
                text={data.vlrMultaRecebida}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrMultaRecebida) =>
                  setData({ ...data, vlrMultaRecebida })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Valor Juros Recebido'
                readOnly
                text={data.vlrJuroRecebido}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrJuroRecebido) =>
                  setData({ ...data, vlrJuroRecebido })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Saldo Título'
                readOnly
                text={data.vlrSaldoTit}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrSaldoTit) =>
                  setData({ ...data, vlrSaldoTit })
                }
              />
            </div>
          </div>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </div>
      <div className='col'>
        <GridView
          ref={gridViewOcorrencia}
          className='table-striped table-hover table-bordered table-sm'
          dataColumns={columnsOcorrencia}
          showPagination={false}
          showSelectSizes={false}
        />
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <div className='row mb-2'>
            <Panel>
              <Panel.Header
                icon={['fas', 'file']}
                title='Documentos Anexados ao Titulo Receber'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-4 mb-3'>
                    <Textbox
                      maxLength={400}
                      label='Observações dos documentos:'
                      text={obs.noObs}
                      onChangedValue={(noObs) => {
                        obs.noObs = noObs;
                      }}
                    />
                  </div>
                  <div className='col-8 mb-3'>
                    <FileUpload
                      files={importedFile}
                      onChange={(files) => setImportedFile(files)}
                      allowedMimeTypes={[
                        MimeTypes.Types.PDF,
                        MimeTypes.Types.Image,
                        MimeTypes.Types.Word,
                      ]}
                    />
                  </div>
                </div>

                <div className='row mb-3' style={{ paddingBottom: '450px' }}>
                  <div className='col'>
                    <GridView
                      ref={gridViewDocumentos}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsAnexos}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
      <ModalOcorrencia
        show={showOcorrencia}
        retornoSave={(retorno) => {
          onSetMessage(retorno.status, retorno.message);
          setModalShowOcorrencia(false);
        }}
        onClose={() => setModalShowOcorrencia(false)}
        tituloreceber={data ?? {}}
      />
      <ModalDesagrupamento
        show={modalShow.TituloReceberAgrupado}
        agrupamentoTituloValor={data}
        onClose={() => {
          setModalShow({
            ...modalShow,
            TituloReceberAgrupado: false,
          });
        }}
      />
      <ModalEditarParcela
        show={modalEditarParcelaShow.show}
        data={modalEditarParcelaShow.data}
        onClose={(parcela) => {
          onCloseModalEditarParcela(parcela);
        }}
        onConfirm={(parcela) => {
          atualizaParcelaEditada(parcela);
        }}
      />
    </CSDManutPage>
  );
}
