import ProdutoGrupoTipo from './produtoGrupoTipo';

export default class ProdutoGrupo {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqProdutoGrupo = jsonObject.nrSeqProdutoGrupo;
    this.cdProdutoGrupo = jsonObject.cdProdutoGrupo;
    this.noProdutoGrupo = jsonObject.noProdutoGrupo;
    this.nrSeqProdutoGrupoTipo = jsonObject.nrSeqProdutoGrupoTipo;
    this.flgTipo = jsonObject.flgTipo;
    this.produtogrupotipo = new ProdutoGrupoTipo(
      jsonObject.produtoGrupoTipo ?? {}
    );
    this.noCBenef = jsonObject.noCBenef;
    this.flgControlaPneu = jsonObject.flgControlaPneu;
    this.cdTipoItemSped = jsonObject.cdTipoItemSped;
    this.produtoGrupoTipo = new ProdutoGrupoTipo(
      jsonObject.produtoGrupoTipo ?? {}
    );
    this.tiposItemSped = jsonObject.tiposItemSped;
    this.tipoItemSped = jsonObject.tipoItemSped;
  }

  toJson = () => JSON.stringify(this);
}
