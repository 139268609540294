import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import {
  getTipoCartao,
  saveTipoCartao,
  deleteTipoCartao,
} from 'core/services/FRO/tipoCartao';

import { getContaCorrenteAutoComplete } from 'core/services/FIN/contaCorrente';

export default function TipoCartaoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getTipoCartao(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoCartao(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoCartao(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchContaCorrente = async (e) => {
    const {
      status,
      message: msg,
      data: contaCorrente,
    } = await getContaCorrenteAutoComplete({ noContaCorrente: e });
    if (msg) onSetMessage(status, msg);
    return contaCorrente;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo Cartao'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTipoCartao > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={20}
            label='Código'
            readOnly
            required
            text={data.nrSeqTipoCartao}
            onChangedValue={(nrSeqTipoCartao) =>
              setData({ ...data, nrSeqTipoCartao })
            }
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            maxLength={80}
            label='Tipo de Cartão'
            required
            text={data.noTipoCartao}
            onChangedValue={(noTipoCartao) =>
              setData({ ...data, noTipoCartao })
            }
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Conta Corrente'
            required
            searchDataSource={onSearchContaCorrente}
            selectedItem={data.contaCorrente}
            onSelectItem={(contaCorrente) => {
              setData({
                ...data,
                contaCorrente,
                nrSeqContaCorrente: contaCorrente.nrSeqContaCorrente,
              });
            }}
            dataSourceTextProperty='noContaCorrente'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exibe no Cadastro de Motorista'
            required
            checked={data.flgMotorista}
            onChange={(flgMotorista) => setData({ ...data, flgMotorista })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exibe no Cadastro de Veículo'
            required
            checked={data.flgVeiculo}
            onChange={(flgVeiculo) => setData({ ...data, flgVeiculo })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Exibe no CTE'
            required
            checked={data.flgExibeCte}
            onChange={(flgExibeCte) => setData({ ...data, flgExibeCte })}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Solicita Senha'
            required
            checked={data.flgSolicitaSenha}
            onChange={(flgSolicitaSenha) =>
              setData({ ...data, flgSolicitaSenha })
            }
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Gera Conta Corrente'
            required
            checked={data.flfGeraContaCorrente}
            onChange={(flfGeraContaCorrente) => {
              setData({
                ...data,
                flfGeraContaCorrente,
              });
            }}
          />
        </div>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Solicita Saldo'
            required
            checked={data.flgSolicitaSaldo}
            onChange={(flgSolicitaSaldo) =>
              setData({ ...data, flgSolicitaSaldo })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
