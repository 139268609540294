import ProdutoSubGrupo from '../EST/produtoSubGrupo';

export default class AgrupamentoRomaneioItem {
  constructor(jsonObject = {}) {
    this.nrSeqAgrupamentoRomaneio = jsonObject.nrSeqAgrupamentoRomaneio;
    this.nrSeqProdutoSubGrupo = jsonObject.nrSeqProdutoSubGrupo;
    this.produtoSubGrupo =
      jsonObject.produtoSubGrupo !== undefined &&
      jsonObject.produtoSubGrupo !== null
        ? new ProdutoSubGrupo(jsonObject.produtoSubGrupo)
        : new ProdutoSubGrupo();
  }

  toJson = () => JSON.stringify(this);
}
