import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getFreteMercadoria,
  saveFreteMercadoria,
  deleteFreteMercadoria,
} from 'core/services/FRO/freteMercadoria';
import FreteMercadoria from 'core/models/FRO/freteMercadoria';
import { Page, Message } from 'ui/Helpers/interfaces';
import { getGrupoIcmsAutoComplete } from 'core/services/FIS/grupoIcms';
import GrupoIcms from 'core/models/FIS/grupoIcms';
import ProdutoUnidadeMedida from 'core/models/EST/produtoUnidadeMedida';
import { getProdutoUnidadeMedidaAutoComplete } from 'core/services/EST/produtoUnidadeMedida';

export default function FreteMercadoriaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit: any = useRef();
  const [data, setData] = useState<FreteMercadoria | {}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getFreteMercadoria(registryKey);
      setData({ ...obj });

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect((): void => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect((): void => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);
    const { status, message: msg } = await saveFreteMercadoria(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const { status, message: msg } = await deleteFreteMercadoria(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchGrupoIcms = async (e: string): Promise<Array<GrupoIcms>> => {
    const {
      grupos,
    }: { grupos: Array<GrupoIcms> } = await getGrupoIcmsAutoComplete({
      noGrupoIcms: e,
    });

    return grupos;
  };

  const onSearchProdutoUnidadeMedida = async (
    e: string
  ): Promise<Array<ProdutoUnidadeMedida>> => {
    const {
      data: produtoUnidadeMedidaData,
    }: {
      data: Array<ProdutoUnidadeMedida>;
    } = await getProdutoUnidadeMedidaAutoComplete({
      noProdutoUnidadeMedida: e,
    });

    return produtoUnidadeMedidaData;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de FreteMercadoria'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={
        'nrSeqFreteMercadoria' in data && data?.nrSeqFreteMercadoria > 0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={20}
            label='Código'
            required
            text={'cdFreteMercadoria' in data ? data.cdFreteMercadoria : null}
            onChangedValue={(cdFreteMercadoria: string) =>
              setData({ ...data, cdFreteMercadoria })
            }
          />
        </div>

        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={60}
            label='Descrição'
            required
            text={'noFreteMercadoria' in data ? data.noFreteMercadoria : null}
            onChangedValue={(noFreteMercadoria: string) =>
              setData({ ...data, noFreteMercadoria })
            }
          />
        </div>
        <div className='col mb-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={20}
            required
            label='Marca'
            text={'noMarca' in data ? data.noMarca : null}
            onChangedValue={(noMarca: string) => setData({ ...data, noMarca })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-3'>
          <Autocomplete
            label='Grupo ICMS CIF'
            searchDataSource={onSearchGrupoIcms}
            required
            selectedItem={'grupoIcmsCif' in data ? data.grupoIcmsCif : null}
            onSelectItem={(grupoIcmsCif: GrupoIcms) => {
              setData({
                ...data,
                grupoIcmsCif,
                nrSeqGrupoIcmsCif: grupoIcmsCif.nrSeqGrupoIcms,
              });
            }}
            dataSourceTextProperty='noGrupoIcms'
          />
        </div>
        <div className='col-5 mb-3'>
          <Autocomplete
            label='Grupo ICMS FOB'
            searchDataSource={onSearchGrupoIcms}
            selectedItem={'grupoIcmsFob' in data ? data.grupoIcmsFob : null}
            required
            onSelectItem={(grupoIcmsFob: GrupoIcms) => {
              setData({
                ...data,
                grupoIcmsFob,
                nrSeqGrupoIcmsFob: grupoIcmsFob.nrSeqGrupoIcms,
              });
            }}
            dataSourceTextProperty='noGrupoIcms'
          />
        </div>
        <div className='col-2 mb-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={20}
            label='NCM'
            text={'cdCnm' in data ? data.cdCnm : null}
            onChangedValue={(cdCnm: string) => setData({ ...data, cdCnm })}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-3'>
          {/* @ts-expect-error */}
          <Textbox
            maxLength={12.2}
            required
            label='Peso'
            text={'peso' in data ? data.peso : null}
            mask={MaskTypes.Decimal}
            onChangedValue={(peso: string) => setData({ ...data, peso })}
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            required
            label='Unidade Medida'
            searchDataSource={onSearchProdutoUnidadeMedida}
            selectedItem={
              'produtoUnidadeMedida' in data ? data.produtoUnidadeMedida : null
            }
            onSelectItem={(produtoUnidadeMedida: ProdutoUnidadeMedida) => {
              setData({
                ...data,
                produtoUnidadeMedida,
                nrSeqProdutoUnidadeMedida:
                  produtoUnidadeMedida.nrSeqProdutoUnidadeMedida,
              });
            }}
            dataSourceTextProperty='noProdutoUnidadeMedida'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
