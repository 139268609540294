import PostoCombustivelTanque from 'core/models/FRO/postoCombustivelTanque';

export default class PostoCombustivelBomba {
  constructor(jsonObject = {}) {
    this.nrSeqPostoBomba = jsonObject.nrSeqPostoBomba;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdBomba = jsonObject.cdBomba;
    this.nrSeqPostoCombustivelTanque = jsonObject.nrSeqPostoCombustivelTanque;
    this.nrOdometroInicial = jsonObject.nrOdometroInicial;
    this.flgAtivo = jsonObject.flgAtivo;
    this.postoCombustivelTanque = new PostoCombustivelTanque(
      jsonObject.postoCombustivelTanque ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
