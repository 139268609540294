import React, { useState, useRef, useEffect } from 'react';
import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';

import { MaskTypes } from 'ui/Helpers/masks';

import {
  Autocomplete,
  Button,
  CSDSelPage,
  DropdownMulti,
  DropdownList,
  GridView,
  Switch,
  Textbox,
  WhatsApp,
  RadioButton,
} from 'ui/components';

import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getAssociadoList, sendFaturas } from 'core/services/ASS/associado';
import { getConvenioExtratoPagined } from 'core/services/ASS/convenioExtrato';
import { getEmailContaList } from 'core/services/ASS/emailConta';

const columns = [
  { key: 'nrSeqPessoaAss', type: GridView.ColumnTypes.Checkbox },
  { key: 'noPessoa', title: 'Cliente' },
  { key: 'celular', title: 'Celular' },
  { key: 'email', title: 'Email' },
  { key: 'noEnvioPadrao', title: 'Padrão de envio' },
  {
    key: 'vlrRecalculado',
    title: 'Vlr Fatura',
    format: GridView.DataTypes.Money,
  },
  { key: 'nrSeqConvenio', title: 'NrSeqConvenio', visible: false },
  { key: 'nrSeqPessoaAss', title: 'NrSeqPessoaAss', visible: false },
  { key: 'anoMes', title: 'AnoMes', visible: false },
  {
    key: 'nrSeqPlanoConvEncargoConv',
    title: 'nrSeqPlanoConvEncargoConv',
    visible: false,
  },
  { key: 'vlrOriginal', title: 'VlrOriginal', visible: false },
  { key: 'nrSeqTituloReceber', title: 'NrSeqTituloReceber', visible: false },
  { key: 'nrMatricula', title: 'NrMatricula', visible: false },
  { key: 'nome', title: 'Nome', visible: false },
  { key: 'cpf', title: 'Cpf', visible: false },
  { key: 'evento', title: 'Evento', visible: false },
  { key: 'plano', title: 'Plano', visible: false },
  {
    key: 'dependencias',
    title: 'Dependencias',
    visible: false,
  },
  { key: 'inclusao', title: 'Inclusao', visible: false },
  { key: 'nascimento', title: 'Nascimento', visible: false },
  { key: 'idade', title: 'Idade', visible: false },
  { key: 'faixa', title: 'Faixa', visible: false },
  { key: 'periodo', title: 'Periodo', visible: false },
  { key: 'subtotal', title: 'Subtotal', visible: false },
  {
    key: 'nrSeqPessoaAssConsolidador',
    title: 'NrSeqPessoaAssConsolidador',
    visible: false,
  },
  {
    key: 'noMotivoAlteracao',
    title: 'NoMotivoAlteracao',
    visible: false,
  },
  {
    key: 'nrSeqUsuarioAlt',
    title: 'NrSeqUsuarioAlt',
    visible: false,
  },
  { key: 'dtCadastro', title: 'DtCadastro', visible: false },
  { key: 'nrProposta', title: 'NrProposta', visible: false },
  { key: 'nrPrestacao', title: 'NrPrestacao', visible: false },
  {
    key: 'percentualComissao',
    title: 'PercentualComissao',
    visible: false,
  },
  { key: 'vlrComissao', title: 'VlrComissao', visible: false },
  { key: 'nrFaturaComissao', title: 'NrFaturaComissao', visible: false },
  {
    key: 'nrSeqConvenioExtratoComissao',
    title: 'NrSeqConvenioExtratoComissao',
    visible: false,
  },
  { key: 'dtRegistro', title: 'DtRegistro', visible: false },
  { key: 'noObsImportacao', title: 'NoObsImportacao', visible: false },
  { key: 'nrSeqTituloPagar', title: 'NrSeqTituloPagar', visible: false },
  { key: 'nrSeqContaRecurso', title: 'NrSeqContaRecurso', visible: false },
  { key: 'vlrPercentualFundo', title: 'VlrPercentualFundo', visible: false },
  { key: 'vlrDescontoConvenio', title: 'VlrDescontoConvenio', visible: false },
  { key: 'vlrDescontoEmpresa', title: 'VlrDescontoEmpresa', visible: false },
  {
    key: 'vlrPercentualDescontoConvenio',
    title: 'VlrPercentualDescontoConvenio',
    visible: false,
  },
  {
    key: 'vlrPercentualDescontoEmpresa',
    title: 'VlrPercentualDescontoEmpresa',
    visible: false,
  },
  { key: 'nrSeqPessoaVen', title: 'NrSeqPessoaVen', visible: false },
  {
    key: 'vlrPercentualComissao',
    title: 'VlrPercentualComissao',
    visible: false,
  },
  { key: 'vlrLucroFundo', title: 'VlrLucroFundo', visible: false },
];

const PadraoEnvio = [
  { text: 'Todos', value: 'T' },
  { text: 'Email', value: 'Email' },
  { text: 'Whatssapp', value: 'WhatsApp' },
];

const pagamento = [
  {
    text: 'Em Aberto',
    value: 'Aberto',
  },
  {
    text: 'Pago',
    value: 'Pago',
  },

  {
    text: 'Todos',
    value: 'Todos',
  },
];

const intialState = {
  flgEnvioPorEmail: true,
  flgEnvioPorWhatsApp: true,
  noFormatoNomeArquivoPdf: 'numero',
  noFormatoEnvioWhatsapp: 'whatsapp_apenas_mensagem',
  noFormatoEnvioEmail: 'email_por_associado_com_anexo_da_faturaboleto',
  flgApenasComEmail: true,
  flgApenasNaoEnviados: true,
  flgApenasContaMaiorZero: false,
  PadraoEnvio: 'Email',
  noTituloRecebido: 'Todos',
};

const EnviaEmailIndividual = ({ isActive, transaction }) => {
  const [filters, setFilters] = useState(intialState);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropDownEmailContaSource, setDropDownEmailContaSource] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const gridView = useRef();
  const dropDownConvenios = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchAssociado = async (e) => {
    const { status, message: msg, associados } = await getAssociadoList({
      noPessoa: e,
    });

    onSetMessage(status, msg);
    return associados;
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });

    onSetMessage(status, msg);
    return convenios;
  };

  const searchEmailConta = async () => {
    const { status, message: msg, emails } = await getEmailContaList({});
    setDropDownEmailContaSource(emails);

    onSetMessage(status, msg);
  };

  useEffect(async () => {
    dropDownConvenios?.current.loadDataSource(onSearchConvenio);
    searchEmailConta();
  }, []);

  const search = async (params = null) => {
    setLoading(true);

    if (params) params.totalByPage = 5000;
    else if (filters) filters.totalByPage = 5000;

    const { data, pagination } = await getConvenioExtratoPagined(
      params ?? filters
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const enviarFaturasEmail = async () => {
    const param = {
      ...filters,
      nrSeqConvenioExtratos: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
      anoMes: filters.anoMes,
      nrSeqConvenio: filters.nrSeqConvenio,
    };
    onSetMessage();
    setLoading(true);

    const { status, message: msg } = await sendFaturas(param);
    onSetMessage(status, msg);
    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filtro = { ...filters, sortBy };
    setFilters(filtro);
    await search(filtro);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filtro = { ...filters, totalByPage };
    setFilters(filtro);
    await search(filtro);
  };
  const onPageChange = async (page) => {
    const filtro = { ...filters, page };
    setFilters(filtro);
    await search(filtro);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Envia E-mail Associado'
      transaction={transaction}
      loading={loading}
      onSearch={search}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row mb-3'>
        <div className='col'>
          <WhatsApp />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-8'>
          <div className='card'>
            <div className='card-body'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Associados'
                  searchDataSource={onSearchAssociado}
                  selectedItem={filters.associado}
                  onSelectItem={(associado) =>
                    setFilters({
                      ...filters,
                      associado,
                      nrSeqPessoaAss: associado.nrSeqPessoaAss,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='row mb-3'>
                <div className='col-4'>
                  <DropdownMulti
                    label='Convenios'
                    ref={dropDownConvenios}
                    dataSourcePropertyText='noConvenio'
                    dataSourcePropertyValue='nrSeqConvenio'
                    selectedItems={selectedItems.convenios ?? []}
                    onSelectItem={(convenios) => {
                      setSelectedItems({
                        ...selectedItems,
                        convenios,
                      });
                      setFilters({
                        ...filters,
                        convenios,
                        nrSeqsConvenios: convenios?.map((p) => p.nrSeqConvenio),
                      });
                    }}
                  />
                </div>

                <div className='col mb-3'>
                  <Textbox
                    label='Competência'
                    text={filters.anoMes}
                    mask={MaskTypes.Competence}
                    onChangedValue={(anoMes) =>
                      setFilters({ ...filters, anoMes })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col mb-3'>
                  <Textbox
                    label='Filtro por Iniciais do Associado'
                    text={filters.noPessoaIniciais}
                    placeholder='A, B, C'
                    onChangedValue={(noPessoaIniciais) =>
                      setFilters({ ...filters, noPessoaIniciais })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='col-auto'>
                  <Switch
                    formControl
                    label='Listar somente Associados com email'
                    checked={filters.flgApenasComEmail}
                    onChange={(flgApenasComEmail) =>
                      setFilters({
                        ...filters,
                        flgApenasComEmail,
                      })
                    }
                  />
                </div>
                <div className='col-auto'>
                  <Switch
                    formControl
                    label='Listar somente não enviados'
                    checked={filters.flgApenasNaoEnviados}
                    onChange={(flgApenasNaoEnviados) =>
                      setFilters({
                        ...filters,
                        flgApenasNaoEnviados,
                      })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-auto'>
                  <Switch
                    formControl
                    label='Listar somente conta maior que zero'
                    checked={filters.flgApenasContaMaiorZero}
                    onChange={(flgApenasContaMaiorZero) =>
                      setFilters({
                        ...filters,
                        flgApenasContaMaiorZero,
                      })
                    }
                  />
                </div>
                <div className='col-auto'>
                  <RadioButton
                    outline
                    label='Padrão de Envio'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    buttons={PadraoEnvio}
                    selectedButton={filters.padraoEnvio}
                    onChange={(padraoEnvio) =>
                      setFilters({
                        ...filters,
                        padraoEnvio,
                        NoEnvioPadrao: padraoEnvio,
                      })
                    }
                  />
                </div>
                <div className='col-auto'>
                  <RadioButton
                    label='Pagamento'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={filters.noTituloRecebido}
                    buttons={pagamento}
                    onChange={(noTituloRecebido) =>
                      setFilters({
                        ...filters,
                        noTituloRecebido,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-6' />
        <Textbox
          label='E-mail Cópia. Para enviar para mais de um e-mail, separe por ponto e vírgula (;)'
          text={filters.noEmailCopia}
          onChangedValue={(noEmailCopia) => {
            setFilters({ ...filters, noEmailCopia });
          }}
        />
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <DropdownList
            label='Mensagem da Conta'
            dataSource={dropDownEmailContaSource}
            dataSourcePropertyText='cdEmailConta'
            dataSourcePropertyValue='nrSeqEmailConta'
            selectedItems={filters.emailconta ?? []}
            onSelectItem={(item) =>
              setFilters({
                ...filters,
                emailconta: item,
                nrSeqEmailConta: item.nrSeqEmailConta,
              })
            }
          />
        </div>
        {filters.padraoEnvio === 'WhatsApp' && (
          <div className='col-2'>
            <Switch
              formControl
              label='Não enviar fatura'
              checked={filters.flgApenasMensagem}
              onChange={(flgApenasMensagem) =>
                setFilters({
                  ...filters,
                  flgApenasMensagem,
                })
              }
            />
          </div>
        )}

        <div className='col-3 mt-3'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Enviar Mensagem'
            onClick={enviarFaturasEmail}
          />
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            gridSizeList={[5000]}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
};

export default EnviaEmailIndividual;
