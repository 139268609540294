import { Endereco } from 'core/models/SEG';
import {
  getCep,
  getCidadeAutoComplete,
  getEstadoAutoComplete,
  getPaisAutoComplete,
} from 'core/services/SEG';
import { getOperadoraAutoComplete } from 'core/services/TEL';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  FileUpload,
  GridView,
  Panel,
  Switch,
  Textarea,
  Textbox,
} from 'ui/components';
import {
  getClienteAutoCompleteDemanda,
  getClienteFromCentroDeCustoByEmpresa,
} from 'core/services/FIN';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import ModalGeraBoleto from './modalGeraBoleto';

export default function ManutencaoSolicitacaoChip({
  data,
  setData,
  dataMassivo,
  setDataMassivo,
  onSetMessage,
  addFiles = false,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  setLoading,
  onOpenReport,
  transaction,
  onOpenTransaction,
  findTransaction,
}) {
  const gridView = useRef();
  const gridViewDocuments = useRef();
  const gridViewChips = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [showModal, setShowModal] = useState({ show: false });
  const [flgClienteFinal, setFlgClienteFinal] = useState(false);
  const [gridHasItem, setGridHasItem] = useState(false);
  const [dataChip, setDataChip] = useState({});

  useEffect(() => {
    if (gridView && gridView.current) {
      gridView.current.setDataSource(dataMassivo);
    }

    if (dataMassivo.length > 0) {
      setGridHasItem(true);
    } else {
      setGridHasItem(false);
    }
  }, [dataMassivo]);

  useEffect(() => {
    if (data.documentos) {
      if (data.documentos.length > 0) {
        if (gridViewDocuments && gridViewDocuments.current) {
          gridViewDocuments.current.setDataSource(data.documentos);
        }
      }
    }

    if (data.chipsSolicitados) {
      if (data.chipsSolicitados.length > 0) {
        if (gridViewChips && gridViewChips.current) {
          gridViewChips.current.setDataSource(data.chipsSolicitados);
        }
      }
    }
  }, []);

  const onSearchOperadora = async (e) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);
      return [];
    }

    const result = operadoras.filter(
      (operadora) => !operadora.noOperadora.includes('Importação Manual')
    );

    return result;
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridViewDocuments?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridViewDocuments && gridViewDocuments.current) {
        gridViewDocuments.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    }

    setDocumentos(list);

    if (gridViewDocuments && gridViewDocuments.current)
      gridViewDocuments.current.setDataSource(list);
  };

  const columnsDocuments = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSearchCep = async () => {
    setLoading(true);

    const cep = data.endereco?.cep.replace(/\D/g, '');

    if (cep.length === 8) {
      const result = await getCep({
        cep,
      });

      const endereco = new Endereco({
        cep: result.cep,
        bairro: result.bairro,
        noRua: result.noRua,
        cidade: result.cidade,
        nrSeqCidade: result.cidade.nrSeqCidade,
        estado: result.cidade.estado,
        nrSeqEstado: result.estado.nrSeqEstado,
        pais: result.cidade.estado.pais,
        nrSeqPais: result.pais.NrSeqPais,
      });

      setData({ ...data, endereco });
    } else {
      onSetMessage(ResponseStatus.Danger, 'O CEP deve ter 8 digítos');
    }

    setLoading(false);
  };

  const onSearchPais = async (e) => {
    const { status, message: msg, paises } = await getPaisAutoComplete({
      noPais: e,
    });

    onSetMessage(status, msg);

    return paises;
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
      nrSeqPais: data.endereco?.pais?.nrSeqPais,
    });

    onSetMessage(status, msg);

    return estados;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: data.endereco?.estado?.nrSeqEstado,
    });

    onSetMessage(status, msg);

    return cidades;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });

    return clientes;
  };

  const onSearchClienteWithContratoServico = async (e) => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Danger, 'Selecione a Empresa');
      return [];
    }

    const { clientes } = await getClienteFromCentroDeCustoByEmpresa({
      noPessoa: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    return clientes;
  };

  const validaEndereco = () => {
    if (!data.endereco) {
      onSetMessage(ResponseStatus.Danger, 'Informe o Endereço');

      return false;
    }

    if (data.endereco?.cep) {
      if (data.endereco?.cep.replace(/\D/g, '').length !== 8) {
        onSetMessage(ResponseStatus.Danger, 'Informe um CEP valido');

        return false;
      }
    } else {
      onSetMessage(ResponseStatus.Danger, 'Informe o CEP');

      return false;
    }

    if (!data.endereco?.noRua) {
      onSetMessage(ResponseStatus.Danger, 'Informe o Endereço');

      return false;
    }

    if (!data.endereco?.numero) {
      onSetMessage(ResponseStatus.Danger, 'Informe o Número');

      return false;
    }

    if (!data.endereco?.bairro) {
      onSetMessage(ResponseStatus.Danger, 'Informe o Bairro');

      return false;
    }

    if (!data.endereco?.nrSeqCidade) {
      onSetMessage(ResponseStatus.Danger, 'Informe a Cidade');

      return false;
    }

    return true;
  };

  const validaCampos = () => {
    if (!data.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Danger, 'Informe a Empresa');

      return false;
    }

    if (!data.nrSeqPessoaCli) {
      onSetMessage(ResponseStatus.Danger, 'Informe o Cliente');

      return false;
    }

    if (!validaEndereco()) {
      return false;
    }

    if (!data.nrSeqOperadoraDestino) {
      onSetMessage(ResponseStatus.Danger, 'Informe a Operadora');

      return false;
    }

    if (!data.qtdChips) {
      onSetMessage(ResponseStatus.Danger, 'Informe a Quantidade');

      return false;
    }

    if (!data.nrPrefixoChipsSolicitado) {
      onSetMessage(ResponseStatus.Danger, 'Informe o Prefixo');

      return false;
    }

    return true;
  };

  const addOrder = () => {
    if (validaCampos()) {
      const dataSource = gridView?.current?.getDataSource() ?? [];

      dataSource.push({ ...data, status: GridView.EnumStatus.Inserir });

      gridView?.current?.setDataSource(dataSource);

      setDataMassivo(dataSource);

      setGridHasItem(true);

      setData({
        ...data,
        operadoraDestino: undefined,
        nrSeqOperadora: undefined,
        qtdChips: undefined,
        nrPrefixoChipsSolicitado: undefined,
        status: undefined,
      });
    }
  };

  const onRemoveOrder = async (e, datasource) => {
    const list = datasource.filter((el) => el !== e);

    if (gridView && gridView.current) gridView.current.setDataSource(list);

    setDataMassivo(list);

    if (list.length === 0) {
      setGridHasItem(false);
    }
  };

  const columns = [
    { key: 'operadoraDestino.noOperadora', title: 'Operadora' },
    { key: 'nrPrefixoChipsSolicitado', title: 'Prefixo' },
    { key: 'qtdChips', title: 'Quantidade' },
    {
      key: 'nrSeqDemanda',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveOrder(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const validaCamposChip = (chip) => {
    if (!chip.nrIccid) {
      onSetMessage(ResponseStatus.Error, 'Informe o ICCID');

      return false;
    }

    if (!chip.nrSeqOperadora) {
      onSetMessage(ResponseStatus.Error, 'Informe a Operadora do Chip');

      return false;
    }

    if (!chip.nrSeqEmpresa) {
      onSetMessage(ResponseStatus.Error, 'Informe a Empresa');

      return false;
    }

    if (!chip.nrPrefixo) {
      onSetMessage(ResponseStatus.Error, 'Informe o Prefixo do Chip');

      return false;
    }

    return true;
  };

  const addChip = () => {
    const obj = {
      ...dataChip,
      nrSeqEmpresa: data.nrSeqEmpresa,
      status: GridView.EnumStatus.Inserir,
    };

    if (validaCamposChip(obj)) {
      const dataSource = gridViewChips?.current?.getDataSource() ?? [];

      const orderDataSource = gridView?.current?.getDataSource() ?? [];

      const qtdTotalChips = orderDataSource.reduce(
        (acc, cur) => acc + cur.qtdChips,
        0
      );

      if (dataSource.length < qtdTotalChips) {
        const qtdChipsOrderedByOperadora = orderDataSource
          .filter(
            (item) =>
              item.nrSeqOperadoraDestino === obj.nrSeqOperadora &&
              item.nrPrefixo === obj.nrPrefixo
          )
          .reduce((acc, cur) => acc + cur.qtdChips, 0);

        const qtdChipsAddedByOperadora = dataSource
          .filter(
            (item) =>
              item.nrSeqOperadoraDestino === obj.nrSeqOperadora &&
              item.nrPrefixo === obj.nrPrefixo
          )
          .reduce((acc, cur) => acc + cur.qtdChips, 0);

        if (qtdChipsAddedByOperadora < qtdChipsOrderedByOperadora) {
          const chipAlreadyAdded = dataSource.some(
            (item) => item.nrIccid === obj.nrIccid
          );

          if (!chipAlreadyAdded) {
            dataSource.push(obj);

            gridViewChips.current.setDataSource(dataSource);

            dataMassivo[0].chipsSolicitados = dataSource;
          } else {
            onSetMessage(
              ResponseStatus,
              'Chip com esse ICCID ja foi adicionado'
            );
          }
        } else {
          onSetMessage(
            ResponseStatus.Error,
            `Todos chips da ${obj.operadora.noOperadora} com esse Prefixo já foram adicionados`
          );
        }
      } else {
        onSetMessage(ResponseStatus.Error, 'Todos chips já foram registrados');
      }
    }
  };

  const onRemoveChip = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    if (gridViewChips && gridViewChips.current) {
      gridViewChips.current.setDataSource(list);
    }

    dataMassivo[0].chipsSolicitados = list;
  };

  const columnsChips = [
    { key: 'nrPrefixo', title: 'Prefixo' },
    { key: 'nrLinha', title: 'Linha' },
    { key: 'nrIccid', title: 'ICCID' },
    { key: 'operadora.noOperadora', title: 'Operadora' },
    {
      key: 'nrSeqChip',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveChip(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            required
            enabled={!gridHasItem}
            searchDataSource={
              flgClienteFinal
                ? onSearchCliente
                : onSearchClienteWithContratoServico
            }
            minLengthTextSearch={flgClienteFinal ? 3 : 0}
            placeholder={
              flgClienteFinal ? 'Digite ao menos 3 caracteres para buscar' : ''
            }
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={async (cliente) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                endereco: {
                  ...cliente?.endereco,
                  estado: cliente?.endereco?.cidade?.estado,
                  pais: cliente?.endereco?.cidade?.estado?.pais,
                },
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2'>
          <Switch
            formControl
            disabled={gridHasItem}
            label='Cliente Final'
            checked={flgClienteFinal}
            onChange={(checked) => {
              setFlgClienteFinal(checked);
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              title='Endereço de Entrega dos Chips'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-2'>
                  <Textbox
                    label='Cep'
                    readOnly={gridHasItem}
                    text={data.endereco?.cep}
                    maxLength={9}
                    onChangedValue={(cep) => {
                      setData({ ...data, endereco: { ...data.endereco, cep } });
                    }}
                  />
                </div>
                <div className='col-1 d-flex align-items-end'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    className='py-2'
                    icon='search'
                    tooltip='Pesquisar o endereço pelo CEP digitado.'
                    onClick={gridHasItem ? null : onSearchCep}
                  />
                </div>
                <div className='col-4'>
                  <Textbox
                    label='Endereço'
                    readOnly={gridHasItem}
                    text={data.endereco?.noRua}
                    maxLength={80}
                    onChangedValue={(noRua) => {
                      setData({
                        ...data,
                        endereco: { ...data.endereco, noRua },
                      });
                    }}
                  />
                </div>

                <div className='col-2'>
                  <Textbox
                    label='Número'
                    readOnly={gridHasItem}
                    text={data.endereco?.numero}
                    maxLength={20}
                    onChangedValue={(numero) => {
                      setData({
                        ...data,
                        endereco: { ...data.endereco, numero },
                      });
                    }}
                  />
                </div>

                <div className='col-3'>
                  <Textbox
                    label='Complemento'
                    readOnly={gridHasItem}
                    text={data.endereco?.complemento}
                    maxLength={80}
                    onChangedValue={(complemento) => {
                      setData({
                        ...data,
                        endereco: { ...data.endereco, complemento },
                      });
                    }}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-3'>
                  <Textbox
                    label='Bairro'
                    readOnly={gridHasItem}
                    text={data.endereco?.bairro}
                    maxLength={60}
                    onChangedValue={(bairro) => {
                      setData({
                        ...data,
                        endereco: { ...data.endereco, bairro },
                      });
                    }}
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='País'
                    enabled={!gridHasItem}
                    searchDataSource={onSearchPais}
                    selectedItem={data.endereco?.pais}
                    onSelectItem={(pais) => {
                      setData({
                        ...data,
                        endereco: {
                          ...data.endereco,
                          pais,
                          nrSeqPais: pais.nrSeqPais,
                          estado: undefined,
                          nrSeqEstado: undefined,
                          cidade: undefined,
                          nrSeqCidade: undefined,
                        },
                      });
                    }}
                    dataSourceTextProperty='noPais'
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='Estado'
                    enabled={!gridHasItem}
                    searchDataSource={onSearchEstado}
                    selectedItem={data.endereco?.estado}
                    onSelectItem={(estado) => {
                      setData({
                        ...data,
                        endereco: {
                          ...data.endereco,
                          estado,
                          nrSeqEstado: estado.nrSeqEstado,
                          cidade: undefined,
                          nrSeqCidade: undefined,
                        },
                      });
                    }}
                    dataSourceTextProperty='noEstado'
                  />
                </div>

                <div className='col-3'>
                  <Autocomplete
                    label='Cidade'
                    enabled={!gridHasItem}
                    searchDataSource={onSearchCidade}
                    selectedItem={data.endereco?.cidade}
                    onSelectItem={(cidade) => {
                      setData({
                        ...data,
                        endereco: {
                          ...data.endereco,
                          cidade,
                          nrSeqCidade: cidade.nrSeqCidade,
                        },
                      });
                    }}
                    dataSourceTextProperty='noCidade'
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Operadora'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchOperadora}
            selectedItem={data.operadoraDestino}
            onSelectItem={(operadoraDestino) => {
              setData({
                ...data,
                operadoraDestino,
                nrSeqOperadoraDestino: operadoraDestino.nrSeqOperadora,
              });
            }}
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Prefixo'
            required
            enabled={data.flgPermiteAlterar}
            maxLength={3}
            mask={MaskTypes.Integer}
            text={data.nrPrefixoChipsSolicitado}
            onChangedValue={(nrPrefixoChipsSolicitado) => {
              setData({
                ...data,
                nrPrefixoChipsSolicitado,
              });
            }}
          />
        </div>
        <div className='col'>
          <Textbox
            label='Quantidade de Chip'
            required
            enabled={data.flgPermiteAlterar}
            mask={MaskTypes.Integer}
            text={data.qtdChips}
            maxLength={8}
            onChangedValue={(qtdChips) => setData({ ...data, qtdChips })}
          />
        </div>
        <div className='col-2 d-flex align-items-end'>
          <Button
            outline
            text='Adicionar'
            theme={Theme.Primary}
            template={Button.Templates.Default}
            className='py-2'
            icon='plus'
            onClick={addOrder}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>

      {!!data.nrSeqDemanda && (
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Registrar Chips para Envio'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Operadora'
                    searchDataSource={onSearchOperadora}
                    selectedItem={dataChip.operadora}
                    onSelectItem={(operadora) => {
                      setDataChip({
                        ...dataChip,
                        operadora,
                        nrSeqOperadora: operadora.nrSeqOperadora,
                      });
                    }}
                    dataSourceTextProperty='noOperadora'
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Prefixo'
                    text={dataChip.nrPrefixo}
                    maxLength={3}
                    onChangedValue={(nrPrefixo) =>
                      setDataChip({ ...dataChip, nrPrefixo })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Nr Linha'
                    text={dataChip.nrLinha}
                    maxLength={9}
                    onChangedValue={(nrLinha) =>
                      setDataChip({ ...dataChip, nrLinha })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='ICCID'
                    maxLength={20}
                    text={dataChip.nrIccid}
                    onChangedValue={(nrIccid) =>
                      setDataChip({ ...dataChip, nrIccid })
                    }
                  />
                </div>
                <div className='col d-flex align-items-end'>
                  <Button
                    text='Adicionar Chip'
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    className='py-2'
                    icon='plus'
                    onClick={addChip}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <GridView
                    ref={gridViewChips}
                    dataColumns={columnsChips}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}

      {!!data.nrSeqDemanda && (
        <div className='row mb-3'>
          <div className='col-3'>
            <Textbox
              visible={data.nrSeqDemanda}
              label='Código de Rastreamento'
              text={data.noProtocolo}
              maxLength={13}
              onChangedValue={(noProtocolo) =>
                setData({ ...data, noProtocolo: noProtocolo.toUpperCase() })
              }
            />
          </div>
          {!data.nrSeqTituloReceber && (
            <div className='col d-flex align-items-end'>
              <Button
                text='Gerar Boleto'
                theme={Theme.Primary}
                template={Button.Templates.Default}
                onClick={() => setShowModal({ ...showModal, show: true })}
              />
            </div>
          )}
        </div>
      )}

      <div className='row mb-3'>
        <div className='col-6'>
          <Textarea
            label='Observação'
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            rows={5}
            text={data.noObservacao}
            onChangedValue={(noObservacao) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
        {addFiles && (
          <div className='col'>
            <div className='row mt-4 mb-3'>
              <div className='col'>
                <FileUpload
                  files={importedFile}
                  onChange={(files) => {
                    if (files[0].size <= 5000000) {
                      setImportedFile(files);
                    } else {
                      onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                    }
                  }}
                  allowedMimeTypes={[
                    MimeTypes.Types.PDF,
                    MimeTypes.Types.Image,
                    MimeTypes.Types.Word,
                  ]}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col d-flex justify-content-center'>
                <Button
                  text='Adicionar Documento'
                  className='px-5'
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  onClick={() => onClickAddDocumento()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {addFiles && (
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridViewDocuments}
              dataColumns={columnsDocuments}
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      )}

      <ModalGeraBoleto
        show={showModal.show}
        onClose={() => setShowModal({ ...showModal, show: false })}
        data={data}
        setData={setData}
        transaction={transaction}
        onOpenReport={onOpenReport}
        dataMassivo={dataMassivo}
      />
    </>
  );
}
