import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { storeLogin } from 'store/actions';
import { login } from 'core/services/auth';
import { Usuario } from 'core/models/SEG';
import { Button, Form, Notification, Textbox } from 'ui/components/index';
import LogoCelebre from 'ui/theme/img/LogoCelebreVertical.jpg';
import LogoGestel from 'ui/theme/img/LogoGestelHorizontal.png';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!document.body.classList.contains('login'))
      document.body.classList.add('login');
  }

  loginSubmit = async () => {
    const { storeLogin: storeLoginProp, history: historyProp } = this.props;
    const { username, password } = this.state;

    const user = new Usuario({
      noLogin: username,
      senha: password,
    });
    const { type, message, state } = await login(user);
    if (state) {
      if (state.flgAuditoria)
        localStorage.setItem('celaudit', state.flgAuditoria);
      else {
        localStorage.removeItem('celaudit');
        localStorage.removeItem('celauditlog');
      }
    }

    if (type && type === 'ERROR') {
      this.setState({ message, password: undefined });
    } else {
      storeLoginProp(state);
      historyProp.push('/');
      document.location.reload(true);
    }
  };

  render() {
    const logo = `/static/media/${
      document.baseURI.split('//')[1].split('.')[0]
    }.jpg`;

    const { username, password, message } = this.state;

    return (
      <div className='login-container'>
        <Form onSubmit={this.loginSubmit}>
          <div className='row'>
            <div className='col-md-4 col-lg boxCelebre'>
              <img
                id='logoCelebre'
                alt='Logo Célebre Soluções Digitais'
                className='img-fluid'
                src={LogoCelebre}
              />
            </div>
            <div className='col-md-7 col-lg'>
              <div>
                <img
                  className='img-fluid mb-3'
                  alt='Logo Gestel'
                  src={
                    document.baseURI.includes('localhost') ? LogoGestel : logo
                  }
                />
              </div>
              {message && (
                <div className='mb-3'>
                  <Notification
                    message={message}
                    theme='danger'
                    onClose={() => this.setState({ message: undefined })}
                  />
                </div>
              )}
              <div className='mb-3'>
                <Textbox
                  prependIcon='user'
                  placeholder='Usuário'
                  required
                  text={username}
                  onChangedValue={(e) => this.setState({ username: e })}
                />
              </div>
              <div className='mb-3'>
                <Textbox
                  type='password'
                  prependIcon='key'
                  placeholder='Senha'
                  required
                  text={password}
                  onChangedValue={(e) => this.setState({ password: e })}
                />
              </div>
              <div className='mb-3 d-grid gap-1'>
                <Button
                  icon='sign-in-alt'
                  theme='primary'
                  text='Entrar'
                  type='submit'
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ storeLogin }, dispatch);

export default connect(null, mapDispatchToProps)(Login);
