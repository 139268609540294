import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getProdutoGrupo,
  getTiposItemSpedAutocomplete,
  saveProdutoGrupo,
  deleteProdutoGrupo,
} from 'core/services/EST/produtoGrupo';
import { getProdutoGrupoTipoAutoComplete } from 'core/services/EST/produtoGrupoTipo';

export default function ProdutoGrupo({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onNew = async () => {
    setData({});
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const produtogrupo = await getProdutoGrupo(registryKey);

      setData({ ...produtogrupo, flgTipo: produtogrupo.flgTipo });
      setLoading(false);
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onSearchTipoItemSped = async (e) => {
    const tiposItemSped = await getTiposItemSpedAutocomplete(e);
    return tiposItemSped;
  };

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveProdutoGrupo(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteProdutoGrupo(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchProdutogrupotipo = async (e) => {
    const {
      status,
      message: msg,
      data: produtogrupotipo,
    } = await getProdutoGrupoTipoAutoComplete({
      noProdutoGrupoTipo: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtogrupotipo;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      transaction={transaction}
      title='Manutenção de Produto Grupo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={data?.nrSeqProdutogrupo > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            required
            maxLength={4}
            label='Codigo'
            text={data.cdProdutoGrupo}
            mask={MaskTypes.Integer}
            onChangedValue={(cdProdutoGrupo) =>
              setData({ ...data, cdProdutoGrupo })
            }
          />
        </div>
        <div className='col-5'>
          <Textbox
            required
            maxLength={60}
            label='Nome do Produto Grupo'
            text={data.noProdutoGrupo}
            onChangedValue={(noProdutoGrupo) =>
              setData({ ...data, noProdutoGrupo })
            }
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            required
            label='Produto Grupo Tipo'
            searchDataSource={onSearchProdutogrupotipo}
            selectedItem={data.produtogrupotipo}
            onSelectItem={(produtogrupotipo) => {
              setData({
                ...data,
                produtogrupotipo,
                nrSeqProdutoGrupoTipo: produtogrupotipo.nrSeqProdutoGrupoTipo,
              });
            }}
            dataSourceTextProperty='noProdutoGrupoTipo'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Switch
            formControl
            label='Controla Pneu?'
            checked={data.flgControlaPneu}
            onChange={(flgControlaPneu) => {
              setData({ ...data, flgControlaPneu });
            }}
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            required
            label='Tipo Item Sped'
            searchDataSource={onSearchTipoItemSped}
            selectedItem={data.tipoItemSped}
            onSelectItem={(tipoItemSped) => {
              setData({
                ...data,
                tipoItemSped,
                cdTipoItemSped: tipoItemSped.cdTipoItemSped,
                noTipoItemSped: tipoItemSped.noTipoItemSped,
              });
            }}
            dataSourceTextProperty='noTipoItemSped'
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
