import AgrupamentoRomaneioItem from './agrupamentoRomaneioItem';

export default class AgrupamentoRomaneio {
  constructor(jsonObject = {}) {
    this.nrSeqAgrupamentoRomaneio = jsonObject.nrSeqAgrupamentoRomaneio;
    this.cdAgrupamentoRomaneio = jsonObject.cdAgrupamentoRomaneio;
    this.noAgrupamentoRomaneio = jsonObject.noAgrupamentoRomaneio;
    this.agrupamentoRomaneioItem =
      jsonObject.agrupamentoRomaneioItem?.map(
        (json) => new AgrupamentoRomaneioItem(json)
      ) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
