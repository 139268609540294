import React from 'react';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { Modal, Button } from 'ui/components';

export default function ModalAvisoPneus({ show, onClose }) {
  return (
    <Modal
      show={show}
      title='Deseja cadastrar os produtos Pneus?'
      onClose={() => onClose('fechar')}
      size={BootstrapSizes.Small}
    >
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => onClose('cadastrar')}
          text='Cadastrar'
        />
        <Button
          size={BootstrapSizes.Medium}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onClose('naoCadastrar')}
          text='Não'
        />
      </Modal.Footer>
    </Modal>
  );
}
