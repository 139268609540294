import ManifestoCTE from 'core/models/FRO/manifestoCTE';
import Empresa from 'core/models/SEG/empresa';
import Veiculo from 'core/models/FRO/veiculo';
import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';
import ColetaEmbarqueDocumento from 'core/models/FRO/coletaEmbarque_Documento';
import Motorista from 'core/models/FRO/motorista';

export default class Viagem {
  constructor(jsonObject = {}) {
    this.nrSeqViagem = jsonObject.nrSeqViagem;
    this.cdViagem = jsonObject.cdViagem;
    this.dtSaida = jsonObject.dtSaida;
    this.dtChegada = jsonObject.dtChegada;
    this.flgColeta = jsonObject.flgColeta;
    this.nrSeqVeiculoPrinc = jsonObject.nrSeqVeiculoPrinc;
    this.dtEfetivacao = jsonObject.dtEfetivacao;
    this.vlrFrete = jsonObject.vlrFrete;
    this.vlrDespesa = jsonObject.vlrDespesa;
    this.vlrComissao = jsonObject.vlrComissao;
    this.vlrAbastecimento = jsonObject.vlrAbastecimento;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqViagemGrupo = jsonObject.nrSeqViagemGrupo;
    this.nrSeqVeiculoComp = jsonObject.nrSeqVeiculoComp;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqVeiculoCompb = jsonObject.nrSeqVeiculoCompb;
    this.nrOdometroFim = jsonObject.nrOdometroFim;
    this.nrOdometroIni = jsonObject.nrOdometroIni;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.vlrBaseComissao = jsonObject.vlrBaseComissao;
    this.vlrPercentualComissao = jsonObject.vlrPercentualComissao;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqVeiculoCompc = jsonObject.nrSeqVeiculoCompc;
    this.dtEntregaCiot = jsonObject.dtEntregaCiot;
    this.dtEncerramentoCiot = jsonObject.dtEncerramentoCiot;
    this.noCiotEncerramento = jsonObject.noCiotEncerramento;
    this.noStatusCiot = jsonObject.noStatusCiot;
    this.flgTaCarregado = jsonObject.flgTaCarregado;
    this.dtCancelamentoCiot = jsonObject.dtCancelamentoCiot;
    this.noMotivoCancelamentoCiot = jsonObject.noMotivoCancelamentoCiot;
    this.noCiot = jsonObject.noCiot;
    this.flgCiotDeclaradoPag = jsonObject.flgCiotDeclaradoPag;
    this.flgCiotDeclaradoViagem = jsonObject.flgCiotDeclaradoViagem;
    this.nrDistanciaPercorrida = jsonObject.nrDistanciaPercorrida;
    this.nrDistanciaRetorno = jsonObject.nrDistanciaRetorno;
    this.noConhecimentos = jsonObject.noConhecimentos;
    this.nrOdometroInicial = jsonObject.nrOdometroInicial;
    this.nrOdometroFinal = jsonObject.nrOdometroFinal;
    this.nrSeqControleMercadoria = jsonObject.nrSeqControleMercadoria;

    // #region Propriedades Axuliares
    this.nrKmRodados = jsonObject.nrKmRodados;
    this.noNotas = jsonObject.noNotas;
    this.pedagioFrete = jsonObject.pedagioFrete;
    this.vlrLucro = jsonObject.vlrLucro;
    this.informaVlrApolice = jsonObject.informaVlrApolice;
    this.horaSaida = jsonObject.horaSaida;
    this.horaChegada = jsonObject.horaChegada;
    this.flgHora = jsonObject.flgHora;
    this.veiculoPrincipal = new Veiculo(jsonObject.veiculoPrincipal ?? {});
    this.veiculoCompA = new Veiculo(jsonObject.veiculoCompA ?? {});
    this.veiculoCompB = new Veiculo(jsonObject.veiculoCompB ?? {});
    this.veiculoCompC = new Veiculo(jsonObject.veiculoCompC ?? {});
    this.manifestoCte = new ManifestoCTE(jsonObject.manifestoCte ?? {});
    this.unidOrigem = new Empresa(jsonObject.unidOrigem ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.coletaEmbarque = new ColetaEmbarque(jsonObject.coletaEmbarque ?? {});
    this.coletaEmbarqueDocumento = new ColetaEmbarqueDocumento(
      jsonObject.coletaEmbarqueDocumento ?? {}
    );
    this.conhecimentos = jsonObject.conhecimentos
      ? jsonObject.conhecimentos.map((item) => new ColetaEmbarque(item ?? {}))
      : [];
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
