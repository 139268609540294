import qs from 'qs';
import Pagination from 'core/models/pagination';
import Associado from 'core/models/ASS/associado';
import GeracaoEtiquetas from 'core/models/ASS/geracaoEtiquetas';
import { MimeTypes } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = `/ASS/Associado`;

export const getDownloadContaAssociado = async (codigodownload) => {
  const response = await api.get(`${baseURL}/DownloadAccounts`, {
    params: { codigodownload },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const getAssociadoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    associados: value?.map((json) => new Associado(json)) ?? [],
  };
};

export const getAssociadoAutoCompleteConv = async (noPessoa, idConvenio) => {
  const response = await api.get(`${baseURL}/SearchAutoCompleteConv`, {
    params: { noPessoa, idConvenio },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    associados: value?.map((json) => new Associado(json)) ?? [],
  };
};
export const getAssociadoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Associado(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getGeracaoEtiquetasPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginedGeracaoEtiquetas`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new GeracaoEtiquetas(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAssociado = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Associado(value) : {};
};

export const saveAssociado = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAssociado = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAssociado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelSel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAssociado = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelSel`, {
    params: filters,
  });
  return response.data ?? {};
};
export const excelAssociadoLista = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelAssociadoLista`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const deleteAllAssociado = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const sendFaturas = async (filters) =>
  (await api.post(`${baseURL}/EnviarFaturas`, filters)).data ?? {};

export const getAssociadoPagedList = async (filters) => {
  const response = await api.get(`${baseURL}/PagedSearch`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    associados: value?.map((json) => new Associado(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAssociadoCompleteInfoById = async (id) => {
  const response = await api.get(`${baseURL}/GetCompleteInfoById`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Associado(value) : {};
};

export const saveAss = async (data) => {
  const response = await api.post(`${baseURL}/SaveAssociado`, data);
  return response.data ?? {};
};

export const printAssociadoCarteirinha = async (filters) => {
  const response = await api.get(`${baseURL}/PrintAssociadoCarteirinha`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printAssociadoDeclaracao = async (id) => {
  const response = await api.get(`${baseURL}/PrintAssociadoDeclaracao`, {
    params: { id },
  });
  return response.data ?? {};
};

export const importarArquivoAssociados = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/ImportarAssociados`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data ?? {};
};

export const printAssociadoRelMensalDetalhado = async (filters) => {
  const response = await api.get(
    `/ASS/LancamentoAssociado/PrintAssociadoRelMensalDetalhado`,
    {
      params: filters,
    }
  );
  return response.data ?? {};
};

export const printAssociadoDeclaracaoIRPF = async (filters) => {
  const response = await api.get(`${baseURL}/PrintDeclaracaoIRPFAssociado`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printAssociadoRelMensal = async (filters) => {
  const response = await api.get(`${baseURL}/PrintAssociadoRelMensal`, {
    params: filters,
  });

  return response.data ?? {};
};

export const gerarEtiquetas = async (ids) => {
  const response = await api.get(`${baseURL}/PrintEtiquetas`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
