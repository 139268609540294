import { Motorista, Veiculo } from '../FRO';

export default class AcertoMotorista {
  constructor(jsonObject = {}) {
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    this.cdAcertoMotorista = jsonObject.cdAcertoMotorista;
    this.dtAcerto = jsonObject.dtAcerto;
    this.vlrBonificacaoViagem = jsonObject.vlrBonificacaoViagem;
    this.dtCadastro = jsonObject.dtCadastro;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.vlrSaldoMotorista = jsonObject.vlrSaldoMotorista;
    this.vlrLucroPercentual = jsonObject.vlrLucroPercentual;
    this.nrOdometroInicial = jsonObject.nrOdometroInicial;
    this.nrOdomentroFinal = jsonObject.nrOdomentroFinal;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.vlrAcerto = jsonObject.vlrAcerto;
    this.nrSeqTituloReceber = jsonObject.nrSeqTituloReceber;
    this.nrSeqTituloPagar = jsonObject.nrSeqTituloPagar;
    this.dtSaida = jsonObject.dtSaida;
    this.dtChegada = jsonObject.dtChegada;
    this.nrSeqVeiculoPrinc = jsonObject.nrSeqVeiculoPrinc;
    this.nrSeqVeiculoComp = jsonObject.nrSeqVeiculoComp;
    this.nrSeqVeiculoCompB = jsonObject.nrSeqVeiculoCompB;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.dtCheque = jsonObject.dtCheque;
    this.nrCheque = jsonObject.nrCheque;
    this.noObs = jsonObject.noObs;
    this.anoMesRef = jsonObject.anoMesRef;
    this.nrSeqVeiculoCompC = jsonObject.nrSeqVeiculoCompC;
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.veiculoComp = new Veiculo(jsonObject.veiculoComp ?? {});
    this.veiculoCompB = new Veiculo(jsonObject.veiculoCompB ?? {});
    this.veiculoCompC = new Veiculo(jsonObject.veiculoCompC ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.acertoMotoristaReceitaS = jsonObject.acertoMotoristaReceitaS;

    this.mesAnoRef = jsonObject.mesAnoRef;
    this.viagens = jsonObject.viagens;
    this.adiantamentos = jsonObject.adiantamentos;
    this.abastecimentos = jsonObject.abastecimentos;
    this.manutencaoVeiculoS = jsonObject.manutencaoVeiculoS;
    this.outrasDespesas = jsonObject.outrasDespesas;
    this.pedagioS = jsonObject.pedagioS;
    this.acertoMotoristaReceitaS = jsonObject.acertoMotoristaReceitaS;
    this.extratoFinanceiroS = jsonObject.extratoFinanceiroS;
    this.acertoMotoristaDespesa = jsonObject.acertoMotoristaDespesa;
    this.acertoMotoristaTipoDescontoPeriodo =
      jsonObject.acertoMotoristaTipoDescontoPeriodo;
    this.acertoMotoristaTipoDescontoPeriodoS =
      jsonObject.acertoMotoristaTipoDescontoPeriodoS;
    this.recebimentos = jsonObject.recebimentos;

    this.tipoAcertoMotorista = jsonObject.tipoAcertoMotorista;
    this.cliente = jsonObject.cliente;
    this.formaPagamento = jsonObject.formaPagamento;
    this.tipoReceita = jsonObject.tipoReceita;
    this.vlrSaldoCartao = jsonObject.vlrSaldoCartao;

    this.totalAdiantamentos = jsonObject.totalAdiantamentos;
    this.totalConhecimento = jsonObject.totalConhecimento;
    this.totalPeso = jsonObject.totalPeso;
    this.pesoReceitas = jsonObject.pesoReceitas;
    this.pesoTotalReceitas = jsonObject.pesoTotalReceitas;
    this.totalAbastecimentos = jsonObject.totalAbastecimentos;
    this.totalAbastecimentosVista = jsonObject.totalAbastecimentosVista;
    this.totalManutencoes = jsonObject.totalManutencoes;
    this.totalManutencoesAVista = jsonObject.totalManutencoesAVista;
    this.totalPedagios = jsonObject.totalPedagios;
    this.totalOutrasDespesas = jsonObject.totalOutrasDespesas;
    this.totalOutrasDespesasVista = jsonObject.totalOutrasDespesasVista;
    this.totalComissao = jsonObject.totalComissao;
    this.totalReceitas = jsonObject.totalReceitas;
    this.totalKMRodados = jsonObject.totalKMRodados;
    this.totalLitros = jsonObject.totalLitros;
    this.mediaKM = jsonObject.mediaKM;
    this.mediaOutrosKM = jsonObject.mediaOutrosKM;
    this.totalMotorista = jsonObject.totalMotorista;
    this.pesoTotal = jsonObject.pesoTotal;
    this.motoristaTipoDescontoPeriodoS =
      jsonObject.motoristaTipoDescontoPeriodoS;
    this.noColor = jsonObject.noColor;
    this.pessoaMot = jsonObject.pessoaMot;
    this.veiculo = jsonObject.veiculo;
    this.vlrBonus = jsonObject.vlrBonus;
    this.bonus = jsonObject.bonus;
    this.vlrBonusRecebido = jsonObject.vlrBonusRecebido;
    this.coletaEmbarqueS = jsonObject.coletaEmbarqueS;
    this.porcentagemLucroViagem = jsonObject.porcentagemLucroViagem;
    this.placasVinculadas = jsonObject.placasVinculadas;
    this.totalLitrosComp = jsonObject.totalLitrosComp;
    this.flgHora = jsonObject.flgHora;
    this.horaSaida = jsonObject.horaSaida;
    this.horaChegada = jsonObject.horaChegada;
    this.noCidadesReceita = jsonObject.noCidadesReceita;
    this.noClientes = jsonObject.noClientes;
    this.vlrTotalReceitas = jsonObject.vlrTotalReceitas;
  }

  toJson = () => JSON.stringify(this);
}
