/* eslint-disable import/extensions */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  createUcInDigitalGrid,
  deleteEnergiaUnidadeConsumidora,
  getEnergiaUnidadeConsumidora,
  getEnergiaUnidadeConsumidoraAutoComplete,
  saveEnergiaUnidadeConsumidora,
} from 'core/services/ENE/energiaUnidadeConsumidora';
import { getClienteAutoComplete } from 'core/services/FIN';
import { getEnergiaDistribuidoraAutoComplete } from 'core/services/ENE/energiaDistribuidora';
import { getEnergiaTipoTarifaAutoComplete } from 'core/services/ENE/energiaTipoTarifa';
import { getCidadeEstadoAutoComplete } from 'core/services/SEG';
import EnergiaUnidadeConsumidora from 'core/models/ENE/energiaUnidadeConsumidora';
import { Cliente } from 'core/models/FIN';
import EnergiaDistribuidora from 'core/models/ENE/energiaDistribuidora';
import EnergiaTipoTarifa from 'core/models/ENE/energiaTipoTarifa';
import EnergiaConta from 'core/models/ENE/energiaConta';
import EnergiaUnidadeConsumidoraUsina from 'core/models/ENE/energiaUnidadeConsumidoraUsina';
import { Cidade } from 'core/models/SEG';
import {
  Autocomplete,
  Button,
  CSDManutPage,
  DatePicker,
  FileUpload,
  GridView,
  Panel,
  RadioButton,
  Switch,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import {
  BootstrapSizes,
  ColumnDataTypes,
  ColumnTypes,
  MaskTypes,
  ResponseStatus,
  StatusGrid,
  Theme,
} from 'ui/Helpers/enums';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { MimeTypes, PageTypes, toBase64 } from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { ModalDetalhamentoConsumoFatura } from './modalDetalhamentoConsumoFatura';
import { ModalEncargosFatura } from './modalEncargosFatura';

const tiposFornecimento = [
  {
    text: 'Monofásico',
    value: 'MON',
  },
  {
    text: 'Bifásico',
    value: 'BI',
  },
  {
    text: 'Trifásico',
    value: 'TRI',
  },
];

const tiposUsinas = [
  {
    text: 'Mini',
    value: 'Mini',
  },
  {
    text: 'Micro',
    value: 'Micro',
  },
];

const modalidadesUsinas = [
  {
    text: 'Geração Compartilhada',
    value: 'Geração Compartilhada',
  },
  {
    text: 'Autoconsumo',
    value: 'Autoconsumo',
  },
  {
    text: 'Geração Compartilhada + Autoconsumo',
    value: 'Geração Compartilhada + Autoconsumo',
  },
];

const tiposFonteGeradora = [
  {
    text: 'UFV',
    value: 'UFV',
  },
  {
    text: 'HID',
    value: 'HID',
  },
  {
    text: 'AEO',
    value: 'AEO',
  },
  {
    text: 'BIO',
    value: 'BIO',
  },
];

const tipoDesconto = [
  {
    text: 'Desconto sobre a economia',
    value: 'DSE',
  },
  {
    text: 'Desconto sobre a fatura com bandeira',
    value: 'DFB',
  },
];

export default function EnergiaUnidadeConsumidoraItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}: Page) {
  const formSubmit: any = useRef();
  const gridViewContas: any = useRef();
  const gridViewConsumos: any = useRef();
  const gridViewEncargos: any = useRef();
  const gridViewUcs: any = useRef();

  const [data, setData] = useState<EnergiaUnidadeConsumidora | {}>({});
  const [contaModal, setContaModal] = useState<EnergiaConta>();
  const [ucUsina, setUcUsina] = useState<EnergiaUnidadeConsumidora>();
  const [kwhContratado, setKwhContratado] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [showModalConsumos, setShowModalConsumos] = useState<boolean>(false);
  const [showModalEncargos, setShowModalEncargos] = useState<boolean>(false);

  const onSetMessage = (status: number, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const openModalConsumos = async (conta: EnergiaConta): Promise<void> => {
    setContaModal(conta);
    setShowModalConsumos(true);
  };

  const openModalEncargos = async (conta: EnergiaConta): Promise<void> => {
    setContaModal(conta);
    setShowModalEncargos(true);
  };

  const columnsContas: GridviewColumns[] = [
    { key: 'anoMesRefFormated', title: 'Ano/Mês Ref.', sortable: false },
    { key: 'qtdDiasConsumo', title: 'Qtd. Dias Consumo', sortable: false },
    {
      key: 'vlrConta',
      title: 'Valor',
      format: ColumnDataTypes.Money,
      sortable: false,
    },
    {
      key: 'dtVencimento',
      title: 'Dt. Vencimento',
      format: ColumnDataTypes.Date,
      sortable: false,
    },
    {
      key: 'dtLeitura',
      title: 'Dt. Leitura',
      format: ColumnDataTypes.Date,
      sortable: false,
    },
    {
      key: 'dtLeituraAnterior',
      title: 'Dt. Leitura Anterior',
      format: ColumnDataTypes.Date,
      sortable: false,
    },
    {
      key: 'nrSeqEnergiaConta',
      title: 'Consumos',
      type: ColumnTypes.Button,
      icon: 'search',
      theme: Theme.Primary,
      size: BootstrapSizes.ExtraSmall,
      onClick: (conta: EnergiaConta) => openModalConsumos(conta),
      sortable: false,
    },
    {
      key: 'nrSeqEnergiaConta',
      title: 'Encargos',
      type: ColumnTypes.Button,
      icon: 'search',
      theme: Theme.Primary,
      size: BootstrapSizes.ExtraSmall,
      onClick: (conta: EnergiaConta) => openModalEncargos(conta),
      sortable: false,
    },
  ];

  const onAddUcUsina = (): void => {
    if (!ucUsina) {
      onSetMessage(
        ResponseStatus.Warning,
        'É necessário selecionar uma UC para adicionar.'
      );

      return;
    }

    const list = gridViewUcs.current.getDataSource();

    const obj = {
      nrSeqEnergiaUnidadeConsumidora: ucUsina.nrSeqEnergiaUnidadeConsumidora,
      nrSeqEnergiaUnidadeConsumidoraUsina: registryKey,
      vlrKwhContratado: kwhContratado,
      energiaUnidadeConsumidora: ucUsina,
      status: StatusGrid.Inserir,
    };

    list.push(obj);

    if (gridViewUcs && gridViewUcs.current) {
      gridViewUcs.current.setDataSource(list);
    }

    setData({
      ...data,
      ucsUsina: list,
    });

    setUcUsina(undefined);
  };

  const onRemoveUcUsina = (
    e: EnergiaUnidadeConsumidoraUsina,
    datasource: EnergiaUnidadeConsumidoraUsina[]
  ): void => {
    datasource = datasource.map((uc) => {
      if (
        uc.nrSeqEnergiaUnidadeConsumidora === e.nrSeqEnergiaUnidadeConsumidora
      ) {
        uc.status = StatusGrid.Remover;
      }

      return uc;
    });

    if (gridViewUcs && gridViewUcs.current) {
      gridViewUcs.current.setDataSource(datasource);
    }

    setData({
      ...data,
      ucsUsina: datasource,
    });
  };

  const columnsUcs: GridviewColumns[] = [
    {
      key: 'energiaUnidadeConsumidora.nrUc',
      title: 'UCs que recebem energia compartilada da usina',
      sortable: false,
    },
    {
      key: 'vlrKwhContratado',
      title: 'KwH Contratado',
    },
    {
      key: 'nrSeqEnergiaUnidadeConsumidora',
      title: 'Excluir',
      type: ColumnTypes.Button,
      onClick: (
        e: EnergiaUnidadeConsumidoraUsina,
        datasource: EnergiaUnidadeConsumidoraUsina[]
      ) => onRemoveUcUsina(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
    },
  ];

  const onNew = (): void => {
    setData({});

    if (gridViewContas && gridViewContas.current) {
      gridViewContas.current.setDataSource([]);
    }
  };

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const uc = await getEnergiaUnidadeConsumidora(registryKey);

      setData(uc);

      if (gridViewContas && gridViewContas.current) {
        gridViewContas.current.setDataSource('contas' in uc ? uc.contas : []);
      }

      if (gridViewUcs && gridViewUcs.current) {
        gridViewUcs.current.setDataSource('ucsUsina' in uc ? uc.ucsUsina : []);
      }

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    load();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const { status, message: msg } = await saveEnergiaUnidadeConsumidora(data);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
    }

    setData(status === ResponseStatus.Success ? {} : data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const { status, message: msg } = await deleteEnergiaUnidadeConsumidora(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setLoading(false);
    }
  };

  const handleImportToDigitalDrid = async (): Promise<void> => {
    setLoading(true);

    const { message: msg, status } = await createUcInDigitalGrid(registryKey);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onSearchCliente = async (e: string): Promise<Cliente[]> => {
    const {
      clientes,
      message: msg,
      status,
    }: {
      clientes: Cliente[];
      message: string;
      status: number;
    } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return clientes;
  };

  const onSearchDistribuidora = async (
    e: string
  ): Promise<EnergiaDistribuidora[]> => {
    const {
      distribuidoras,
      message: msg,
      status,
    }: {
      distribuidoras: EnergiaDistribuidora[];
      message: string;
      status: number;
    } = await getEnergiaDistribuidoraAutoComplete({
      noEnergiaDistribuidora: e,
      cdEnergiaDistribuidora: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return distribuidoras;
  };

  const onSearchTipoTarifa = async (
    e: string
  ): Promise<EnergiaTipoTarifa[]> => {
    const {
      tipoTarifas,
      message: msg,
      status,
    }: {
      tipoTarifas: EnergiaTipoTarifa[];
      message: string;
      status: number;
    } = await getEnergiaTipoTarifaAutoComplete({
      noEnergiaTipoTarifa: e,
      cdEnergiaTipoTarifa: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return tipoTarifas;
  };

  const onSearchCidade = async (e: string): Promise<Cidade[]> => {
    const {
      cidades,
      message: msg,
      status,
    }: {
      cidades: Cidade[];
      message: string;
      status: number;
    } = await getCidadeEstadoAutoComplete({
      noCidade: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return cidades;
  };

  const onSearchUc = async (
    e: string
  ): Promise<EnergiaUnidadeConsumidora[]> => {
    const {
      message: msg,
      status,
      ucs,
    }: {
      message: string;
      status: number;
      ucs: EnergiaUnidadeConsumidora[];
    } = await getEnergiaUnidadeConsumidoraAutoComplete({
      nrUc: e,
    });

    if (msg) {
      onSetMessage(status, msg);
    }

    return ucs;
  };

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Unidade Consumidora'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={onNew}
      onSave={save}
      onDelete={
        'nrSeqEnergiaUnidadeConsumidora' in data &&
        data.nrSeqEnergiaUnidadeConsumidora > 0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <ToolbarButtons>
        {Number.isInteger(registryKey) && (
          <ToolbarButtons.Button
            text='Importar'
            icon='cloud-upload-alt'
            onClick={() => handleImportToDigitalDrid()}
          />
        )}
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='UC'
            text={'nrUc' in data ? data?.nrUc : null}
            readOnly={'nrSeqEnergiaUnidadeConsumidora' in data}
            maxLength={60}
            required
            onChangedValue={(value: string) => {
              setData({ ...data, nrUc: value });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Titular'
            searchDataSource={onSearchCliente}
            required
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            selectedItem={'cliente' in data ? data?.cliente : null}
            onSelectItem={(cliente: Cliente) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Distribuidora'
            searchDataSource={onSearchDistribuidora}
            required
            selectedItem={'distribuidora' in data ? data?.distribuidora : null}
            onSelectItem={(distribuidora: EnergiaDistribuidora) => {
              setData({
                ...data,
                distribuidora,
                nrSeqEnergiaDistribuidora:
                  distribuidora.nrSeqEnergiaDistribuidora,
              });
            }}
            dataSourceTextProperty='noEnergiaDistribuidora'
          />
        </div>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Cod. Cliente'
            text={'nrClienteUc' in data ? data?.nrClienteUc : null}
            readOnly={'nrSeqEnergiaUnidadeConsumidora' in data}
            maxLength={60}
            onChangedValue={(value: string) => {
              setData({ ...data, nrClienteUc: value });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <RadioButton
            label='Tipo de Forncimento'
            outline
            theme={Theme.Primary}
            size={BootstrapSizes.Small}
            buttons={tiposFornecimento}
            selectedButton={
              'tipoFornecimento' in data ? data.tipoFornecimento : null
            }
            onChange={(item: string) =>
              setData({
                ...data,
                tipoFornecimento: item,
              })
            }
            disabled={false}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Tipo de Tarifa'
            searchDataSource={onSearchTipoTarifa}
            required
            selectedItem={'tipoTarifa' in data ? data?.tipoTarifa : null}
            onSelectItem={(tipoTarifa: EnergiaTipoTarifa) => {
              setData({
                ...data,
                tipoTarifa,
                nrSeqEnergiaTipoTarifa: tipoTarifa.nrSeqEnergiaTipoTarifa,
              });
            }}
            dataSourceTextProperty='cdEnergiaTipoTarifa'
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Tensão'
            text={'nrTensao' in data ? data?.nrTensao : null}
            maxLength={8}
            required
            mask={MaskTypes.Integer}
            onChangedValue={(value: number) => {
              if (Number.isNaN(value)) {
                onSetMessage(
                  ResponseStatus.Warning,
                  'São permitidos apenas numeros nesse campo.'
                );
              }
              setData({ ...data, nrTensao: value });
            }}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Tensão Min.'
            text={'nrTensaoMin' in data ? data?.nrTensaoMin : null}
            maxLength={8}
            required
            mask={MaskTypes.Integer}
            onChangedValue={(value: string) => {
              if (Number.isNaN(value)) {
                onSetMessage(
                  ResponseStatus.Warning,
                  'São permitidos apenas numeros nesse campo.'
                );
              }
              setData({ ...data, nrTensaoMin: value });
            }}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Tensão Max.'
            text={'nrTensaoMax' in data ? data?.nrTensaoMax : null}
            maxLength={8}
            required
            mask={MaskTypes.Integer}
            onChangedValue={(value: string) => {
              if (Number.isNaN(value)) {
                onSetMessage(
                  ResponseStatus.Warning,
                  'São permitidos apenas numeros nesse campo.'
                );
              }
              setData({ ...data, nrTensaoMax: value });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Login'
            text={'noLogin' in data ? data?.noLogin : null}
            maxLength={60}
            onChangedValue={(value: string) =>
              setData({ ...data, noLogin: value })
            }
          />
        </div>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Senha'
            text={'noSenha' in data ? data?.noSenha : null}
            maxLength={80}
            onChangedValue={(value: string) =>
              setData({ ...data, noSenha: value })
            }
          />
        </div>
        <div className='col-4'>
          <RadioButton
            label='Tipo de Desconto'
            outline
            theme={Theme.Primary}
            size={BootstrapSizes.Small}
            buttons={tipoDesconto}
            selectedButton={
              'flgTipoDesconto' in data ? data?.flgTipoDesconto : null
            }
            onChange={(value: string) => {
              setData({
                ...data,
                flgTipoDesconto: value,
              });
            }}
            disabled={false}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Switch
            formControl
            label='Unidade Geradora'
            checked={'flgGeradora' in data ? data.flgGeradora : false}
            onChange={(check: boolean) => {
              setData({
                ...data,
                flgGeradora: check,
              });
            }}
          />
        </div>
      </div>

      {'flgGeradora' in data && data.flgGeradora ? (
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Dados da Unidade Geradora'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Código'
                    text={
                      'cdEnergiaUnidadeConsumidora' in data
                        ? data?.cdEnergiaUnidadeConsumidora
                        : null
                    }
                    maxLength={10}
                    onChangedValue={(value: string) =>
                      setData({ ...data, cdEnergiaUnidadeConsumidora: value })
                    }
                  />
                </div>
                <div className='col-4'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Apelido'
                    text={'noApelido' in data ? data?.noApelido : null}
                    maxLength={200}
                    onChangedValue={(value: string) =>
                      setData({ ...data, noApelido: value })
                    }
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Matrícula'
                    text={'noMatricula' in data ? data?.noMatricula : null}
                    maxLength={10}
                    onChangedValue={(value: string) =>
                      setData({ ...data, noMatricula: value })
                    }
                  />
                </div>
                <div className='col-2'>
                  <Autocomplete
                    label='Comarca'
                    searchDataSource={onSearchCidade}
                    selectedItem={
                      'cidadeRegistroImovel' in data
                        ? data?.cidadeRegistroImovel
                        : null
                    }
                    onSelectItem={(cidade: Cidade) => {
                      setData({
                        ...data,
                        cidadeRegistroImovel: cidade,
                        nrSeqCidadeRegistroImovel: cidade.nrSeqCidade,
                      });
                    }}
                    dataSourceTextProperty='noCidade'
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Coordenadas Geográficas'
                    text={
                      'noCoordenadasGeograficas' in data
                        ? data?.noCoordenadasGeograficas
                        : null
                    }
                    maxLength={60}
                    onChangedValue={(value: string) =>
                      setData({ ...data, noCoordenadasGeograficas: value })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  <DatePicker
                    label='Previsão para Início da Operação'
                    text={
                      'dtPrevisaoInicio' in data ? data?.dtPrevisaoInicio : null
                    }
                    onChange={(value: Date) =>
                      setData({ ...data, dtPrevisaoInicio: value })
                    }
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Potência Instalada (kW)'
                    text={
                      'vlrPotenciaInstalada' in data
                        ? data?.vlrPotenciaInstalada
                        : null
                    }
                    mask={MaskTypes.Decimal}
                    maxLength={13}
                    onChangedValue={(value: number) =>
                      setData({ ...data, vlrPotenciaInstalada: value })
                    }
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Fator Capacidade'
                    text={
                      'vlrFatorCapacidade' in data
                        ? data?.vlrFatorCapacidade
                        : null
                    }
                    mask={MaskTypes.Decimal}
                    maxLength={6}
                    onChangedValue={(value: number) =>
                      setData({ ...data, vlrFatorCapacidade: value })
                    }
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Tarifa de Locação'
                    text={
                      'nrTarifaLocacao' in data ? data?.nrTarifaLocacao : null
                    }
                    mask={MaskTypes.Decimal}
                    maxLength={7}
                    onChangedValue={(value: number) =>
                      setData({ ...data, nrTarifaLocacao: value })
                    }
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Valor do Aluguel'
                    text={'vlrAluguel' in data ? data?.vlrAluguel : null}
                    mask={MaskTypes.Decimal}
                    maxLength={7}
                    onChangedValue={(value: number) =>
                      setData({ ...data, vlrAluguel: value })
                    }
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Geração Mensal'
                    text={
                      'nrGeracaoMensal' in data ? data?.nrGeracaoMensal : null
                    }
                    mask={MaskTypes.Integer}
                    maxLength={8}
                    onChangedValue={(value: number) =>
                      setData({ ...data, nrGeracaoMensal: value })
                    }
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-3'>
                  <p className='m-0'>Imagem da Curva de Geração</p>
                  {!data.noImagemCurvaGeracao ? (
                    <div className='mt-1'>
                      <FileUpload
                        files={data.noImagemCurvaGeracao}
                        onChange={async (file: File[]) => {
                          let base64 = await toBase64(file[0]);
                          base64 = String(base64).substring(
                            String(base64).indexOf(',') + 1
                          );

                          setData({
                            ...data,
                            noImagemCurvaGeracao: base64,
                          });
                        }}
                        allowedMimeTypes={[MimeTypes.Types.Image]}
                      />
                    </div>
                  ) : (
                    <Button
                      text='Trocar imagem importada'
                      theme={Theme.Danger}
                      size={BootstrapSizes.Small}
                      icon='trash-alt'
                      onClick={() =>
                        setData({ ...data, noImagemCurvaGeracao: null })
                      }
                      className='mt-1'
                    />
                  )}
                </div>
                <div className='col-2'>
                  <RadioButton
                    label='Tipo de Fonte Geradora'
                    outline
                    theme={Theme.Primary}
                    size={BootstrapSizes.Small}
                    buttons={tiposFonteGeradora}
                    selectedButton={data?.noTipoFonteGeradora}
                    onChange={(value: string) => {
                      setData({
                        ...data,
                        noTipoFonteGeradora: value,
                      });
                    }}
                    disabled={false}
                  />
                </div>
                <div className='col-2'>
                  <RadioButton
                    label='Tipo da Usina'
                    outline
                    theme={Theme.Primary}
                    size={BootstrapSizes.Small}
                    buttons={tiposUsinas}
                    selectedButton={data.noTipoUsina}
                    onChange={(value: string) => {
                      setData({
                        ...data,
                        noTipoUsina: value,
                      });
                    }}
                    disabled={false}
                  />
                </div>
                <div className='col-5'>
                  <RadioButton
                    label='Modalidade da Compensação'
                    outline
                    theme={Theme.Primary}
                    size={BootstrapSizes.Small}
                    buttons={modalidadesUsinas}
                    selectedButton={data.noModalidadeCompensacao}
                    onChange={(value: string) => {
                      setData({
                        ...data,
                        noModalidadeCompensacao: value,
                      });
                    }}
                    disabled={false}
                  />
                </div>
              </div>

              {Number.isInteger(registryKey) && (
                <>
                  <div className='row mb-3'>
                    <div className='col-3'>
                      <Autocomplete
                        label='UC'
                        searchDataSource={onSearchUc}
                        selectedItem={ucUsina}
                        onSelectItem={(
                          unidadeConsumidora: EnergiaUnidadeConsumidora
                        ) => setUcUsina(unidadeConsumidora)}
                        dataSourceTextProperty='nrUc'
                      />
                    </div>
                    <div className='col-3'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Kwh Contratado'
                        text={kwhContratado}
                        mask={MaskTypes.Integer}
                        maxLength={7}
                        onChangedValue={(value: number) =>
                          setKwhContratado(value)
                        }
                      />
                    </div>
                    <div className='col-3 align-self-end'>
                      <Button
                        text='Adicionar'
                        theme={Theme.Primary}
                        icon='plus'
                        onClick={() => onAddUcUsina()}
                      />
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <GridView
                      ref={gridViewUcs}
                      // @ts-expect-error
                      dataColumns={columnsUcs}
                      showSelectSizes={false}
                      showPagination={false}
                    />
                  </div>
                </>
              )}
            </Panel.Body>
          </Panel>
        </div>
      ) : null}

      {'nrSeqEnergiaUnidadeConsumidora' in data &&
      data.nrSeqEnergiaUnidadeConsumidora > 0 ? (
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Faturas' theme={Theme.Primary} />
            <Panel.Body>
              <GridView
                ref={gridViewContas}
                // @ts-expect-error
                dataColumns={columnsContas}
                showSelectSizes={false}
                showPagination={false}
              />
            </Panel.Body>
          </Panel>
        </div>
      ) : null}

      <div className='row mb-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Endereço' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='CEP'
                  text={'endereco' in data ? data?.endereco?.cep : null}
                  maxLength={8}
                  required
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, cep: value }
                          : { cep: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Bairro'
                  text={'endereco' in data ? data?.endereco?.bairro : null}
                  maxLength={60}
                  required
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, bairro: value }
                          : { bairro: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Rua'
                  text={'endereco' in data ? data?.endereco?.noRua : null}
                  maxLength={80}
                  required
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, noRua: value }
                          : { noRua: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Numero'
                  text={'endereco' in data ? data?.endereco?.numero : null}
                  maxLength={20}
                  required
                  onChangedValue={(value: number) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, numero: value }
                          : { numero: value },
                    });
                  }}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Complemento'
                  text={'endereco' in data ? data?.endereco?.complemento : null}
                  maxLength={40}
                  onChangedValue={(value: string) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? { ...data.endereco, complemento: value }
                          : { complemento: value },
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Cidade'
                  searchDataSource={onSearchCidade}
                  required
                  selectedItem={
                    'endereco' in data ? data?.endereco?.cidade : null
                  }
                  onSelectItem={(cidade: Cidade) => {
                    setData({
                      ...data,
                      endereco:
                        'endereco' in data
                          ? {
                              ...data.endereco,
                              nrSeqCidade: cidade.nrSeqCidade,
                              cidade,
                              estado: cidade.estado,
                            }
                          : {
                              nrSeqCidade: cidade.nrSeqCidade,
                              cidade,
                              estado: cidade.estado,
                            },
                    });
                  }}
                  dataSourceTextProperty='noCidade'
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Estado'
                  text={
                    'endereco' in data ? data?.endereco?.estado?.noEstado : null
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <ModalDetalhamentoConsumoFatura
        conta={contaModal}
        gridView={gridViewConsumos}
        onClose={() => setShowModalConsumos(false)}
        show={showModalConsumos}
      />

      <ModalEncargosFatura
        conta={contaModal}
        gridView={gridViewEncargos}
        onClose={() => setShowModalEncargos(false)}
        show={showModalEncargos}
      />
    </CSDManutPage>
  );
}
