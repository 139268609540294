export default class PneuRecapagem {
  constructor(jsonObject = {}) {
    this.nrSeqPneuRecapagem = jsonObject.nrSeqPneuRecapagem;
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.nrSeqPneuModeloRecapagem = jsonObject.nrSeqPneuModeloRecapagem;
    this.dtRecapagem = jsonObject.dtRecapagem;
    this.nomeBanda = jsonObject.nomeBanda;
    this.nrSeqPneuVida = jsonObject.nrSeqPneuVida;
    this.nrSeqPneuVeiculo = jsonObject.nrSeqPneuVeiculo;
    this.nrSeqManutencaoVeiculoOS = jsonObject.nrSeqManutencaoVeiculoOS;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;

    // Instancias
    this.pneuModeloRecapagem = jsonObject.pneuModeloRecapagem;
    this.pneuVida = jsonObject.pneuVida;
    this.manutencaoVeiculoOS = jsonObject.manutencaoVeiculoOS;
    this.usuarioCad = jsonObject.usuarioCad;
  }
}
