export default class ProdutoTamanho {
  constructor(jsonObject = {}) {
    this.nrSeqProdutoTamanho = jsonObject.nrSeqProdutoTamanho;
    this.cdProdutoTamanho = jsonObject.cdProdutoTamanho;
    this.noProdutoTamanho = jsonObject.noProdutoTamanho;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrOrdem = jsonObject.nrOrdem;
  }

  toJson = () => JSON.stringify(this);
}
