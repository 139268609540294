import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  MimeTypes,
  Theme,
  ResponseStatus,
  JustifyContent,
  PageTypes,
  BootstrapSizes,
} from 'ui/Helpers/utils';

import {
  Button,
  FileUpload,
  GridView,
  Panel,
  DropdownMulti,
  Autocomplete,
  DatePicker,
  CSDManutPage,
} from 'ui/components';
import {
  getConciliacaoBancaria,
  postUploadAbastecimentosXml,
  saveAndGenerateTitulos,
  saveConciliacaoBancaria,
} from 'core/services/FIN/conciliacaoBancaria';
import {
  getContaCorrenteByUser,
  getExtratoList,
  getFormaPagamentoAutoComplete,
  getTipoDespesaAutoComplete,
} from 'core/services/FIN';
import { getEmpresasUsuario } from 'core/services/SEG';
import { MaskTypes } from 'ui/Helpers/masks';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';

export default function ConciliacaoBancaria({
  transaction,
  isActive,
  onSelectPage,
  registryKey,
  reload,
}) {
  const [importedFile, setImportedFile] = useState([]);
  const [message, onSetMessage] = useState();
  const [loading, setLoading] = useState();
  const [data, setData] = useState({});
  const gridView = useRef();
  const gridView2 = useRef();
  const gridView3 = useRef();
  const dropDownContaCorrente = useRef();
  const [selectedItems, setSelectedItems] = useState({});
  const formSubmit = useRef();

  const onNew = async () => {
    setData({});

    setImportedFile([]);

    if (gridView && gridView.current) gridView.current.setDataSource(null);

    if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);

    if (gridView3 && gridView3.current) gridView3.current.setDataSource(null);
  };

  const load = useCallback(async () => {
    setLoading(true);

    if (Number.isInteger(registryKey)) {
      const ConciliacaoBancariaS = [];
      const conciliacaoBancaria = await getConciliacaoBancaria(registryKey);

      setData({
        ...conciliacaoBancaria,
      });

      ConciliacaoBancariaS.push(conciliacaoBancaria);

      if (gridView3 && gridView3.current)
        gridView3.current.setDataSource(ConciliacaoBancariaS);
    } else {
      onNew();
    }

    setLoading(false);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  useEffect(() => {
    if (
      data.nrSeqConciliacaoBancaria === null ||
      data.nrSeqConciliacaoBancaria === undefined
    ) {
      onSetMessage({
        message:
          'Arquivos importados sem a informação do cliente, terão que ser conciliados manualmente.',
        theme: Theme.Danger,
      });
    }
  }, [registryKey]);

  const onSearchContaCorrenteByEmpresa = async () => {
    const { status, message: msg, data: contas } = await getContaCorrenteByUser(
      {
        nrSeqEmpresa: data.nrSeqEmpresa,
      }
    );

    if (msg) {
      onSetMessage(status, msg);
    }

    return contas;
  };

  const onSearchContaCorrente = async () => {
    const {
      status,
      message: msg,
      data: contas,
    } = await getContaCorrenteByUser();

    if (msg) {
      onSetMessage(status, msg);
    }

    return contas;
  };

  useEffect(async () => {
    if (data.nrSeqEmpresa) {
      setSelectedItems({});

      dropDownContaCorrente.current.loadDataSource(
        onSearchContaCorrenteByEmpresa
      );
    } else {
      setSelectedItems({});

      dropDownContaCorrente.current.loadDataSource(onSearchContaCorrente);
    }
  }, [data.nrSeqEmpresa]);

  const onClickRemoveConciliacao = async (selectedValue, datasource) => {
    let placas = [];
    if (
      selectedValue.nrSeqConciliacaoBancaria === undefined ||
      selectedValue.nrSeqConciliacaoBancaria === null
    ) {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
    } else {
      placas = datasource.filter((Placa) => Placa !== selectedValue);
      const dataPlaca = datasource.filter((Placa) => Placa === selectedValue);
      dataPlaca[0].status = GridView.EnumStatus.Remover;
      placas.push(dataPlaca[0]);
    }
    if (gridView && gridView.current) gridView.current.setDataSource(placas);
  };

  const columns = [
    { key: 'nrDocumento', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPessoa',
      title: 'Pessoa',
      colorText: 'noColorText',
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
      colorText: 'noColorText',
    },
    {
      key: 'nrDocumento',
      title: 'Nr Doc.',
      colorText: 'noColorText',
      visible: false,
    },
    {
      key: 'vlrDocumento',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      colorText: 'noColorText',
    },
    {
      key: 'nrDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveConciliacao(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columns2 = [
    { key: 'nrSeqExtrato', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'contaCorrente.noContaCorrente',
      title: 'Conta',
      sortable: false,
    },
    {
      key: 'nrDocumento',
      title: 'Documento',
      sortable: false,
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
      sortable: false,
    },

    {
      key: 'extratoCategoria.noExtratoCategoria',
      title: 'Detalhe',
      sortable: false,
      filterable: true,
    },

    {
      key: 'noPessoa',
      title: 'Nome',
      sortable: false,
      filterable: true,
    },
    {
      key: 'nrDocumentoPessoa',
      title: 'Documento',
      visible: false,
      sortable: false,
      filterable: true,
    },
    {
      key: 'noDespesaReceita',
      title: 'Despesa/Receita',
      visible: true,
      sortable: false,
      filterable: true,
    },
    {
      key: 'vlrCredito',
      title: 'Crédito',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    {
      key: 'vlrDebito',
      title: 'Débito',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
  ];

  const columns3 = [
    { key: 'nrSeqConciliacaoBancaria', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPessoa',
      title: 'Pessoa',
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrDocumento',
      title: 'Nr Doc.',
    },
    {
      key: 'vlrDocumento',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrDocumentoExtrato',
      title: 'Nr Doc. Extrato',
    },
  ];

  const validatePeriodo = () => {
    if (data.dtInicial && data.dtFinal) {
      const dataInicialPieces = data.dtInicial.split('/');

      const dataInicial = new Date(
        parseInt(dataInicialPieces[2], 10),
        parseInt(dataInicialPieces[1], 10) - 1,
        parseInt(dataInicialPieces[0], 10)
      );

      const dataFinalPieces = data.dtFinal.split('/');

      const dataFinal = new Date(
        parseInt(dataFinalPieces[2], 10),
        parseInt(dataFinalPieces[1], 10) - 1,
        parseInt(dataFinalPieces[0], 10)
      );

      if (dataInicial > dataFinal) {
        return false;
      }
    }

    if (data.dtCadInicial && data.dtCadFinal) {
      const dataCadInicialPieces = data.dtCadInicial.split('/');

      const dataCadInicial = new Date(
        parseInt(dataCadInicialPieces[2], 10),
        parseInt(dataCadInicialPieces[1], 10) - 1,
        parseInt(dataCadInicialPieces[0], 10)
      );

      const dataCadFinalPieces = data.dtCadFinal.split('/');

      const dataCadFinal = new Date(
        parseInt(dataCadFinalPieces[2], 10),
        parseInt(dataCadFinalPieces[1], 10) - 1,
        parseInt(dataCadFinalPieces[0], 10)
      );

      if (dataCadInicial > dataCadFinal) {
        return false;
      }
    }

    return true;
  };

  const conciliarItens = async () => {
    setLoading(true);

    const nrItensConciliados = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    const nrSeqExtratoS = gridView2.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqExtratoS.length === 0) {
      setLoading(false);
      onSetMessage({
        message: 'Selecione um item para atualizar.',
        theme: Theme.Danger,
      });
      return;
    }

    if (nrSeqExtratoS.length > 1) {
      setLoading(false);

      onSetMessage({
        message: 'Por favor concilie apenas um extrato por vez.',
        theme: Theme.Danger,
      });

      return;
    }

    const conciliacoeS = gridView.current.getDataSource();

    const [primeiroNrSeqExtrato] = nrSeqExtratoS;

    let extratoS = gridView2.current.getDataSource();

    const objetoFiltrado = extratoS.filter(
      (obj) => obj.nrSeqExtrato === primeiroNrSeqExtrato
    );

    conciliacoeS.forEach((obj) => {
      if (nrItensConciliados.includes(obj.nrDocumento)) {
        obj.noColorText = '';
        obj.nrSeqExtrato = primeiroNrSeqExtrato;
        obj.nrDocumentoExtrato = objetoFiltrado[0].nrDocumento;
      }
    });

    extratoS = extratoS.filter(
      (obj) => !nrSeqExtratoS.some((numero) => numero === obj.nrSeqExtrato)
    );

    const newItensConciliados = conciliacoeS.filter((obj) =>
      nrItensConciliados.includes(obj.nrDocumento)
    );

    const itensJaConciliados = gridView3.current.getDataSource();

    itensJaConciliados.push(...newItensConciliados);

    const movimentacoesFiltradas = conciliacoeS.filter((itemA) =>
      conciliacoeS.some(() => itemA.noColorText === 'text-danger')
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(movimentacoesFiltradas);

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(extratoS);

    if (gridView3 && gridView3.current)
      gridView3.current.setDataSource(itensJaConciliados);

    setLoading(false);
  };

  const uploadFileXml = async () => {
    setLoading(true);
    if (!importedFile) {
      onSetMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    }

    let conciliacao = [];

    if (data.nrSeqsContaCorrente?.length > 0) {
      if (
        (data.dtInicial && data.dtFinal) ||
        (data.dtCadInicial && data.dtCadFinal)
      ) {
        if (validatePeriodo) {
          data.flgBuscarApenasNaoConciliados = true;

          const { data: itensExtrato } = await getExtratoList(data);

          conciliacao = itensExtrato;
        } else {
          onSetMessage(
            false,
            'O inicio do periodo de busca não pode ser maior que o final'
          );
        }
      } else {
        onSetMessage(false, 'Defina o periodo de busca corretamente');
      }
    } else {
      onSetMessage(false, 'Selecione ao menos uma conta');
    }

    if (!importedFile)
      onSetMessage({
        message: 'Não há arquivos selecionados para importação.',
        theme: Theme.Danger,
      });
    else {
      const {
        status,
        message: msg,
        data: movimentacoesBancarias,
      } = await postUploadAbastecimentosXml(
        importedFile[0],
        data.nrSeqsContaCorrente
      );

      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Success) {
        const commonItems = movimentacoesBancarias.filter((itemA) =>
          conciliacao.some((itemB) => itemA.nrSeqExtrato === itemB.nrSeqExtrato)
        );

        conciliacao = conciliacao.filter(
          (itemA) =>
            !movimentacoesBancarias.some(
              (itemB) => itemA.nrSeqExtrato === itemB.nrSeqExtrato
            )
        );

        const movimentacoesFiltradas = movimentacoesBancarias.filter((itemA) =>
          movimentacoesBancarias.some(() => itemA.noColorText === 'text-danger')
        );

        if (gridView && gridView.current)
          gridView.current.setDataSource(movimentacoesFiltradas);

        if (gridView2 && gridView2.current)
          await gridView2.current.setDataSource(conciliacao);

        if (gridView3 && gridView3.current)
          await gridView3.current.setDataSource(commonItems);
      }
    }
    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const save = async () => {
    setLoading(true);
    // setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const despesa = gridView3.current ? gridView3.current.getDataSource() : [];

    const obj = {
      ...data,
      conciliacaoBancariaS: despesa,
    };

    const { status, message: msg } = await saveConciliacaoBancaria(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
      if (gridView3 && gridView3.current) gridView3.current.setDataSource(null);

      setImportedFile([]);
    }

    setData(status === ResponseStatus.Success ? {} : data);
    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const saveAndGenerateTitulo = async () => {
    setLoading(true);

    const itemNaoConciliado = gridView.current
      ? gridView.current.getDataSource()
      : [];
    const conciliados = gridView3.current
      ? gridView3.current.getDataSource()
      : [];

    const obj = {
      ...data,
      conciliacaoBancariaS: conciliados,
      itensNaoConciliados: itemNaoConciliado,
    };

    const { status, message: msg } = await saveAndGenerateTitulos(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);
      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
      if (gridView3 && gridView3.current) gridView3.current.setDataSource(null);

      setImportedFile([]);
    }

    setData(status === ResponseStatus.Success ? {} : data);
    onSetMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const OnSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Conciliação Bancaria'
      loading={loading}
      message={message}
      onMessagerClose={() => onSetMessage(null)}
      onSave={save}
      onNew={onNew}
      onBack={() => onSelectPage(idSelecao)}
      ref={formSubmit}
      transaction={transaction}
    >
      {(data.nrSeqConciliacaoBancaria === null ||
        data.nrSeqConciliacaoBancaria === undefined) && (
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header title='Filtros' theme={Theme.Primary} />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onSearchEmpresa}
                      selectedItem={data.empresa}
                      onSelectItem={(empresa) => {
                        setData({
                          ...data,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <DropdownMulti
                      required
                      label='Conta Corrente'
                      ref={dropDownContaCorrente}
                      dataSourcePropertyText='noContaCorrente'
                      dataSourcePropertyValue='nrSeqContaCorrente'
                      selectedItems={selectedItems.contaCorrente ?? []}
                      onSelectItem={(contaCorrente) => {
                        setSelectedItems({ ...selectedItems, contaCorrente });
                        setData({
                          ...data,
                          nrSeqsContaCorrente: contaCorrente?.map(
                            (cc) => cc.nrSeqContaCorrente
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <div className='row'>
                      <div className='col-3'>
                        <DatePicker
                          label='Data Inicial'
                          text={data.dtInicial}
                          mask={MaskTypes.Date}
                          onChange={(dtInicial) =>
                            setData({ ...data, dtInicial })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <DatePicker
                          label='Data Final'
                          text={data.dtFinal}
                          mask={MaskTypes.Date}
                          onChange={(dtFinal) => setData({ ...data, dtFinal })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <FileUpload
                      files={importedFile}
                      onChange={(files) => setImportedFile(files)}
                      allowedMimeTypes={[MimeTypes.Types.Excel]}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}

      {(data.nrSeqConciliacaoBancaria === null ||
        data.nrSeqConciliacaoBancaria === undefined) && (
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Valores Para Gerar Itens Não Conciliados'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-7 mb-3'>
                    <Autocomplete
                      label='Fornecedor'
                      required
                      searchDataSource={OnSearchFornecedor}
                      selectedItem={data.fornecedor}
                      onSelectItem={(fornecedor) => {
                        setData({
                          ...data,
                          fornecedor,
                          nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                        });
                      }}
                      dataSourceTextProperty='nomeDocumentoCidadeEstado'
                    />
                  </div>
                  <div className='col-5 mb-3'>
                    <Autocomplete
                      label='Tipo Despesa'
                      required
                      searchDataSource={onSearchTipoDespesa}
                      onSelectItem={(tipoDespesa) => {
                        setData({
                          ...data,
                          tipoDespesa,
                          nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                        });
                      }}
                      selectedItem={data.tipoDespesa}
                      dataSourceTextProperty='noTipoDespesa'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4 mb-3'>
                    <Autocomplete
                      label='Forma Pagamento'
                      searchDataSource={onSearchFormaPagamento}
                      required
                      selectedItem={data.formaPagamento}
                      onSelectItem={(formaPagamento) => {
                        setData({
                          ...data,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        });
                      }}
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}

      {(data.nrSeqConciliacaoBancaria === null ||
        data.nrSeqConciliacaoBancaria === undefined) && (
        <div className='row mt-5  mb-4'>
          <div className='col-3'>
            <Button
              text='Carregar Arquivo'
              icon='folder-open'
              theme={Theme.Success}
              onClick={uploadFileXml}
            />
          </div>
          <div className='col-4'>
            <Button
              text='Conciliar Itens Selecionados'
              icon='folder-open'
              theme={Theme.Success}
              onClick={conciliarItens}
            />
          </div>
          <div className='col-4'>
            <Button
              text='Salvar e gerar itens não Conciliados'
              theme={Theme.Success}
              onClick={saveAndGenerateTitulo}
            />
          </div>
        </div>
      )}

      <div className='row mb-3'>
        {(data.nrSeqConciliacaoBancaria === null ||
          data.nrSeqConciliacaoBancaria === undefined) && (
          <div className='col-5'>
            <Panel>
              <Panel.Header
                title='Movimentações do banco importação'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='col-12'>
                  <GridView
                    ref={gridView}
                    canControlVisibility
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    transaction={transaction}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
        {(data.nrSeqConciliacaoBancaria === null ||
          data.nrSeqConciliacaoBancaria === undefined) && (
          <div className='col-7'>
            <Panel>
              <Panel.Header
                title='Movimentações extrato'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='col-12'>
                  <GridView
                    ref={gridView2}
                    canControlVisibility
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns2}
                    transaction={transaction}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </Panel.Body>
            </Panel>
          </div>
        )}
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <Panel>
            <Panel.Header
              title='Itens Conciliados'
              theme={Theme.Light}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='col-12'>
                <GridView
                  ref={gridView3}
                  canControlVisibility
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns3}
                  transaction={transaction}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </CSDManutPage>
  );
}
