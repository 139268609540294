import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getFreteMercadoriaPagined,
  printFreteMercadoria,
  deleteAllFreteMercadoria,
  excelFreteMercadoria,
} from 'core/services/FRO/freteMercadoria';
import { CSDSelPage, GridView, Textbox } from 'ui/components';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { ColumnTypes } from 'ui/Helpers/enums';

export interface Filters {
  noFreteMercadoria?: string;
}

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqFreteMercadoria', type: ColumnTypes.Checkbox },
  { key: 'cdFreteMercadoria', title: 'Código' },
  { key: 'noFreteMercadoria', title: 'Descrição' },
];

export default function FreteMercadoria({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView: any = useRef();

  const [filtros, setFiltros] = useState<Filters>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const search = async (params: Object | null = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getFreteMercadoriaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getFreteMercadoriaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'FreteMercadoria/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow: Array<any>): void => {
    const nrSeqFreteMercadoria = sourceRow.find(
      (e) => e.key === 'nrSeqFreteMercadoria'
    );

    onOpenMaintenance(nrSeqFreteMercadoria.value);
  };
  const onPrint = async () => {
    setLoading(true);
    const { value } = await printFreteMercadoria(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelFreteMercadoria(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'FreteMercadoria.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllFreteMercadoria(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Manutenção de Frete Mercadoria'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 mb-4'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome:'
            text={filtros.noFreteMercadoria}
            onChangedValue={(noFreteMercadoria: string) =>
              setFiltros({
                ...filtros,
                noFreteMercadoria,
              })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
