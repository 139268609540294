import { RecalculaResumo } from 'core/services/FIN/acertoMotorista';
import {
  GetAbastecimentosAcerto,
  GetAbastecimentoSemAcerto,
} from 'core/services/FRO/abastecimento';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import {
  DatePicker,
  Button,
  GridView,
  Notification,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  JustifyContent,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import ModalNewAbastecimento from './ModalNewAbastecimento';

const Abastecimentos = forwardRef(
  ({
    data,
    registryKey,
    setData,
    transaction,
    acerto,
    findTransaction,
    onOpenTransaction,
  }) => {
    const [dataCadastroCliente, setDataCadastroCliente] = useState({});
    const gridView = useRef();
    const [message, onSetMessage] = useState(null);
    const [modalShow, setModalShow] = useState({});
    const gridView2 = useRef();

    const setMessage = (status, msg) => {
      if (msg)
        onSetMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    };

    const columns = [
      {
        key: 'nrSeqAbastecimento',
        type: GridView.ColumnTypes.Checkbox,
      },
      {
        key: 'veiculo.noVeiculo',
        title: 'Veiculo',
      },
      {
        key: 'postoCombustivel.noPessoa',
        title: 'Posto',
      },
      {
        key: 'tipoCombustivel.noTipoCombustivel',
        title: 'Combustivel',
      },
      {
        key: 'nrDocumento',
        title: 'Documento',
      },
      {
        key: 'dtAbastecimento',
        title: 'Data',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'vlrTotal',
        title: 'Valor',
        format: GridView.DataTypes.Decimal,
      },
    ];

    const onClickOpenEditarAbastecimento = async (sourceRow) => {
      const transactionAbastecimento = findTransaction(
        TransacationCodes.Abastecimento
      );

      if (transactionAbastecimento) {
        transactionAbastecimento.registryKey = sourceRow.nrSeqAbastecimento;

        onOpenTransaction(transactionAbastecimento, true);
      }
    };

    const columns2 = [
      {
        key: 'nrSeqAbastecimento',
        type: GridView.ColumnTypes.Checkbox,
      },
      {
        key: 'veiculo.noVeiculo',
        title: 'Veiculo',
      },
      {
        key: 'postoCombustivel.noPessoa',
        title: 'Posto',
      },
      {
        key: 'tipoCombustivel.noTipoCombustivel',
        title: 'Combustivel',
      },
      {
        key: 'dtAbastecimento',
        title: 'Data',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'nrDocumento',
        title: 'Documento',
      },
      {
        key: 'nrOdometro',
        title: 'Odometro',
      },
      {
        key: 'qtdeLitro',
        title: 'Qtde',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'vlrLitro',
        title: 'VlrLitro',
        format: GridView.DataTypes.Decimal,
        colorText: 'noColorTipoCombustivel',
      },
      {
        key: 'quilometragemPercorrida',
        title: 'Km',
      },
      {
        key: 'vlrTotal',
        title: 'Valor',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'noObs',
        title: 'Observação',
      },
      {
        key: 'nrSeqAbastecimento',
        type: GridView.ColumnTypes.Button,
        onClick: (e) => onClickOpenEditarAbastecimento(e),
        theme: Theme.Info,
        icon: 'folder-open',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar Abastecimento',
        tooltipDirection: 'bottom',
        title: 'Editar Abastecimento',
      },
    ];

    /* const onClickOpenAbastecimento = async () => {
      const transactionAbastecimento = findTransaction(
        TransacationCodes.Abastecimento
      );

      if (data !== null || data !== undefined) {
        if (data.nrSeqVeiculoPrinc > 0) {
          transactionAbastecimento.setValues = data;
          transactionAbastecimento.setValues.readValues = true;
        }
      }
      if (transactionAbastecimento) {
        onOpenTransaction(transactionAbastecimento, true);
      }
    }; */

    const load = async () => {
      if (data !== undefined) {
        if (data.abastecimentos !== undefined) {
          if (gridView2 && gridView2.current)
            gridView2.current.setDataSource(data.abastecimentos);
        }
      }
    };

    const onSearchAbastecimentosAdicionados = async () => {
      if (data.nrSeqAcertoMotorista > 0) {
        const itensJaAdicionados = gridView2.current.getDataSource();

        const listaFiltrada = itensJaAdicionados.filter(
          (item) =>
            item.status === 'Inserir' && item.nrSeqAcertoMotorista === null
        );

        const despesa = { nrSeqAcertoMotorista: data.nrSeqAcertoMotorista };

        const abastecimentoS = await GetAbastecimentosAcerto(despesa);

        const novaLista = [...abastecimentoS, ...listaFiltrada];

        const valorObj = { ...data, ...acerto, abastecimentos: novaLista };

        const obj = await RecalculaResumo(valorObj);

        setData({
          totalLitrosComp: obj.totalLitrosComp,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });

        if (gridView2 && gridView2.current)
          gridView2.current.setDataSource(novaLista);
      }
    };

    const onSearchRight = async () => {
      const importacaocerta = [];
      let placas = [];
      let abastecimentosNaoAdicionados = [];

      const nrSeqVendaDiariaS = gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      if (tabelacerta !== null) {
        if (tabelacerta.length > 0) {
          importacaocerta.push(...tabelacerta);
        }
      }

      abastecimentosNaoAdicionados = gridView.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = abastecimentosNaoAdicionados.filter(
          (Placa) => Placa.nrSeqAbastecimento !== nrSeqVendaDiariaS[i]
        );

        abastecimentosNaoAdicionados = placas;
        i += 1;
      }

      const datasourcefiltrado = datasource.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqAbastecimento, 0)
      );

      if (datasourcefiltrado !== null) {
        for (let i = 0; i < datasourcefiltrado.length; ) {
          if (tabelacerta !== null) {
            if (tabelacerta.length > 0) {
              for (let j = 0; j < tabelacerta.length; ) {
                if (
                  datasourcefiltrado[i].nrSeqAbastecimento ===
                    tabelacerta[j].nrSeqAbastecimento &&
                  datasourcefiltrado[i].nrSeqAcertoMotorista > 0
                ) {
                  datasourcefiltrado.pop(datasourcefiltrado[i]);
                  tabelacerta[i].status = GridView.EnumStatus.Alterar;
                } else if (datasourcefiltrado[i].status === null) {
                  datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
                }

                j += 1;
              }
            } else {
              datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
            }
          }
          i += 1;
        }
      }

      importacaocerta.push(...datasourcefiltrado);

      if (gridView && gridView.current)
        gridView.current.setDataSource(abastecimentosNaoAdicionados);

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(importacaocerta);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valorObj = { ...data, ...acerto, abastecimentos: importacaocerta };

      const obj = await RecalculaResumo(valorObj);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          totalLitrosComp: obj.totalLitrosComp,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          totalLitrosComp: obj.totalLitrosComp,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
    };

    const onSearchLeft = async () => {
      const importacaocerta = [];
      let placas = [];
      let abastecimentosAdicionados = [];

      const nrSeqVendaDiariaS = gridView2.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      importacaocerta.push(...datasource);

      abastecimentosAdicionados = gridView2.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = abastecimentosAdicionados.filter(
          (Placa) => Placa.nrSeqAbastecimento !== nrSeqVendaDiariaS[i]
        );

        abastecimentosAdicionados = placas;
        i += 1;
      }

      const datasourcefiltrado = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqAbastecimento, 0)
      );

      const datasourcefiltradoList2 = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqAbastecimento, 0)
      );

      for (let i = 0; i < datasourcefiltrado.length; ) {
        if (datasourcefiltrado[i].nrSeqAcertoMotorista > 0) {
          datasourcefiltrado[i].status = GridView.EnumStatus.Alterar;
        }
        i += 1;
      }

      importacaocerta.push(...datasourcefiltrado);

      if (gridView && gridView.current)
        gridView.current.setDataSource(importacaocerta);

      for (let i = 0; i < datasourcefiltradoList2.length; ) {
        if (datasourcefiltradoList2[i].nrSeqAcertoMotorista > 0) {
          datasourcefiltradoList2[i].status = GridView.EnumStatus.Remover;
          abastecimentosAdicionados.push(datasourcefiltradoList2[i]);
        }
        i += 1;
      }

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(abastecimentosAdicionados);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valorObj = {
        ...data,
        ...acerto,
        abastecimentos: abastecimentosAdicionados,
      };

      const obj = await RecalculaResumo(valorObj);

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          totalLitrosComp: obj.totalLitrosComp,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          totalLitrosComp: obj.totalLitrosComp,
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.viagens,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: obj.outrasReceitasComissao,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
    };

    const onSearchAbastecimento = async () => {
      if (!data.nrSeqVeiculoPrinc > 0) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel buscar um abastecimento sem selecionar o veiculo'
        );
        return;
      }

      const obj = {
        ...dataCadastroCliente,
        nrSeqVeiculo: data.nrSeqVeiculoPrinc,
        nrSeqVeiculoComp: data.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: data.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: data.nrSeqVeiculoCompC,
      };
      const abastecimento = await GetAbastecimentoSemAcerto(obj);

      let clonedList = [];
      if (abastecimento !== undefined && abastecimento !== null) {
        clonedList = [...abastecimento];
      }

      const tabela2 = gridView2.current.getDataSource();

      if (tabela2 !== null && tabela2 !== undefined) {
        for (let j = 0; j < tabela2.length; ) {
          clonedList = clonedList.filter(
            (item) => item.nrSeqAbastecimento !== tabela2[j].nrSeqAbastecimento
          );

          j += 1;
        }
      }

      if (gridView && gridView.current)
        gridView.current.setDataSource(clonedList);
    };

    useEffect(() => {
      (async function func() {
        load();
      })();
    }, [data.nrSeqPessoaCli, registryKey]);

    const onClickOpenAbastecimento = async () => {
      if (data.dtAcerto !== null && data.dtAcerto !== undefined) {
        setMessage(
          ResponseStatus.Error,
          'Você não pode adicionar um abastecimento a um acerto ja efetivado'
        );
        return;
      }

      if (data.nrSeqAcertoMotorista > 0) {
        setModalShow({
          ...modalShow,
          openAbastecimento: true,
          veiculoAcerto: data.veiculo,
        });
      } else {
        setMessage(
          ResponseStatus.Error,
          'Para Salvar um novo abastecimento, por favor salve o acerto primeiro '
        );
      }
    };

    return (
      <div>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='display-8'>3 - Abastecimentos</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='De'
                      text={dataCadastroCliente.dtAbastecimentoInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAbastecimentoInicial) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          dtAbastecimentoInicial,
                        })
                      }
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <DatePicker
                      label='Ate'
                      text={dataCadastroCliente.dtAbastecimentoFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAbastecimentoFinal) =>
                        setDataCadastroCliente({
                          ...dataCadastroCliente,
                          dtAbastecimentoFinal,
                        })
                      }
                    />
                  </div>
                  <div className='col-1 mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='search'
                      onClick={onSearchAbastecimento}
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='plus'
                      text='Novo'
                      onClick={onClickOpenAbastecimento}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3 mt-3'>
                <Panel>
                  <Panel.Header
                    title='Abastecimento para adicionar'
                    theme={Theme.Primary}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row mb-3 mt-3'>
                      <div className='col-12 mb-3'>
                        <GridView
                          ref={gridView}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columns}
                          showPagination={false}
                          showSelectSizes={false}
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
              <div className='col-4 mt-3 mb-3'>
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='plus'
                  text='Adicionar'
                  onClick={onSearchRight}
                />
              </div>
              <div className='col-4 mt-3 mb-3'>
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  icon='arrow-circle-left'
                  text='Remover'
                  onClick={onSearchLeft}
                />
              </div>
              <div className='row'>
                <Panel>
                  <Panel.Header
                    title='Abastecimentos adicionados'
                    theme={Theme.Success}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row mb-3'>
                      <div className='col-12'>
                        <GridView
                          ref={gridView2}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columns2}
                          showPagination={false}
                          showSelectSizes={false}
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
              <ModalNewAbastecimento
                show={modalShow.openAbastecimento}
                veiculoAcerto={data.veiculo}
                nrSeqAcertoMotorista={data.nrSeqAcertoMotorista}
                vlrAcerto={modalShow.vlrAcerto}
                transaction={transaction}
                onClose={({ message: msg, theme }) => {
                  onSearchAbastecimentosAdicionados();
                  setModalShow({
                    ...modalShow,
                    openAbastecimento: false,
                  });
                  onSetMessage({
                    message: msg,
                    theme,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default Abastecimentos;
