import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
  dateNow,
} from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Switch,
  Panel,
  Button,
  Textbox,
  GridView,
  ToolbarButtons,
  Textarea,
  Notification,
} from 'ui/components';
import {
  getViagem,
  getNotaPelaChave,
  adicionaEmbarque,
  geraRecibo,
  manifestarViagem,
  recalculateFreight,
  geraCteSubstituto,
  buscaMotoristaEVeiculos,
  getParameters,
  replaceCte,
  validaChaveCteRef,
  printViagem,
  printMultiplosCtes,
  certificateValidation,
  saveVeiculoMotorista,
  transmitirMultiplosCtes,
  removerMultiplosCtes,
  excelViagemRomaneio,
  getSearchPaginedModeSimplifiedColumns,
  getLatestOdometerByVehicle,
} from 'core/services/FRO/viagem';
import {
  printColetaEmbarque,
  transmitCte,
  getColetaEmbarque,
  downloadCte,
  convertInternDocumentToCte,
} from 'core/services/FRO/coletaEmbarque';
import {
  buscaCamposChaveNfe,
  validaDuplicidadeDocumento,
} from 'core/services/FRO/coletaEmbarque_Documento';
import { MaskTypes, mask } from 'ui/Helpers/masks';

import Viagem from 'core/models/FRO/viagem';
import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';
import ColetaEmbarqueDocumento from 'core/models/FRO/coletaEmbarque_Documento';
import ModalImportNF from 'ui/pages/FRO/Viagem/modalImportNF';
import ModalAcimaSeguro from 'ui/pages/FRO/Viagem/modalAcimaSeguro';
import ModalDetalhesConhecimento from 'ui/pages/FRO/Viagem/modalDetalhesConhecimento';
import ModalManifestarViagem from 'ui/pages/FRO/Viagem/modalManifestarViagem';
import ModalAvisoFerias from 'ui/pages/FRO/Viagem/modalAvisoFerias';
import ModalNotaDuplicada from 'ui/pages/FRO/Viagem/modalNotaDuplicada';
import ModalDistribuirPedagio from 'ui/pages/FRO/Viagem/modalDistribuirPedagio';
import ModalCadastroConsignatario from 'ui/pages/FRO/Viagem/modalCadastroConsignatario.tsx';
import ModalGerarCtesPorNota from 'ui/pages/FRO/Viagem/modalGerarCtesPorNota.tsx';
import ModalComprovanteEntrega from 'ui/pages/FRO/Viagem/modalComprovanteEntrega.tsx';
import ModalMudaCtesViagem from 'ui/pages/FRO/Viagem/modalMudaCtesViagem';
import ModalImportaSubcontratado from 'ui/pages/FRO/Viagem/modalImportaSubcontratado.tsx';
import ModalDownloadNfes from 'ui/pages/FRO/Viagem/modalDownloadNfes.tsx';
import ModalOcorrenciasCte from 'ui/pages/FRO/GerenciarCte/modalOcorrenciasCte';

import {
  getVeiculoPorVinculoAutoComplete,
  getVeiculoAutoComplete,
} from 'core/services/FRO/veiculo';
import {
  getRemetenteDestinatarioAutoComplete,
  getRemetenteAutoComplete,
  getDestinatarioAutoComplete,
  buscaCamposViagem,
} from 'core/services/FRO/remetente_Destinatario';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';
import { getPessoaAutoCompleteTipoNome } from 'core/services/SEG/pessoa';
import { getMotoristaViagemAutoComplete } from 'core/services/FRO/motorista';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import {
  getEnderecoByPessoa,
  getEnderecoByPersonList,
} from 'core/services/SEG/endereco';
import { getColetaTipoDocumentoAutoComplete } from 'core/services/FRO/coletaTipoDocumento';
import { getEstadoAutoCompleteViagem } from 'core/services/SEG/estado';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';
import { getEspecieFreteAutoComplete } from 'core/services/FRO/especieFrete';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';
import { getColetaAutoComplete, getColeta } from 'core/services/FRO/coleta';
import { getApoliceSeguroAutoComplete } from 'core/services/FRO/apoliceSeguro';

export default function ViagemItem({
  registryKey,
  transaction,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const modalImportNfRef = useRef({});
  const modalComprovanteEntregaRef = useRef({});
  const modalMudaCtesViagemRef = useRef({});
  const [data, setData] = useState(new Viagem({}));
  const [coletaEmbarque, setColetaEmbarque] = useState(
    new ColetaEmbarque({ flgGeraSeguro: true })
  );
  const [documento, setDocumento] = useState(
    new ColetaEmbarqueDocumento({
      flgRemetente: true,
    })
  );

  const [
    columnsVisibilitySimplified,
    setColumnsVisibilitySimplified,
  ] = useState(false);

  const [resumoConhecimento, setResumoConhecimento] = useState({
    qtdDocs: 0,
    pesoDoc: 0,
    qtdTotalDoc: 0,
    valorDoc: 0,
    valorFaturas: 0,
  });
  const [resumoViagem, setResumoViagem] = useState({
    autorizados: 0,
    cancelados: 0,
    naoAutorizados: 0,
    totalFrete: 0,
    totalConhecimentos: 0,
  });
  const [manterInfo, setManterInfo] = useState({
    peso: false,
    tarifa: false,
    remetente: false,
    destinatario: false,
    tipoEmbarque: false,
    pedagio: false,
    buscaSefaz: true,
    buscaNfe: true,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [remetenteDest, setRemetenteDest] = useState(true);
  const [flgTodos, setFlgTodos] = useState(false);
  const [selectAddress, setSelectAddress] = useState(false);

  const [consignatarios, setConsignatarios] = useState(false);
  const [tomador, setTomador] = useState(false);
  const [modalShow, setModalShow] = useState({});
  const gridViewDocumentos = useRef();
  const gridViewEmbarques = useRef();
  const [tipoDocumentoPadrao, setTipoDocumentoPadrao] = useState();
  const [coletaEmbarquePadrao, setColetaEmbarquePadrao] = useState();
  const [modalNotaDuplicada, setModalNotaDuplicada] = useState({});
  const [modalimportaNf, setModalimportaNf] = useState({});
  const [modalDistribuirPedagio, setModalDistribuirPedagio] = useState({});
  const [modalCadastroConsignatario, setModalCadastroConsignatario] = useState(
    {}
  );
  const [modalGerarCtesPorNota, setModalGerarCtesPorNota] = useState({});
  const [modalComprovanteEntrega, setModalComprovanteEntrega] = useState({});
  const [modalMudaCtesViagem, setModalMudaCtesViagem] = useState({});
  const [modalImportaSubcontratado, setModalImportaSubcontratado] = useState(
    {}
  );
  const [modalDownloadNfes, setModalDownloadNfes] = useState({});
  const [parameters, setParameters] = useState({
    enabledMotorista: true,
  });

  const onSetMessage = (status, msg) => {
    let theme;

    if (status === ResponseStatus.Success) theme = Theme.Success;
    else if (status === ResponseStatus.Warning) theme = Theme.Warning;
    else theme = Theme.Danger;

    if (msg)
      setMessage({
        message: msg,
        theme,
      });
  };

  const validaCertificado = async () => {
    const { message: msgCertificado } = await certificateValidation();
    return msgCertificado;
  };

  const newData = async () => {
    setLoading(true);

    if (gridViewEmbarques && gridViewEmbarques.current)
      gridViewEmbarques.current.setDataSource([]);

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource([]);

    const notifications = [];

    const { status, message: msg, data: viagem } = await getViagem(0);

    if (msg)
      notifications.push({
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        message: msg,
      });

    const msgCertificado = await validaCertificado();

    if (msgCertificado) {
      notifications.push({
        theme: Theme.Primary,
        message: msgCertificado,
      });
    }

    setMessages(notifications);

    if (status === ResponseStatus.Success) {
      const dataParameters = await getParameters();

      setData(viagem);
      setParameters({ ...parameters, ...dataParameters });
      setColetaEmbarque(viagem.coletaEmbarque);
      setDocumento(viagem.coletaEmbarqueDocumento);
      setColetaEmbarquePadrao(viagem.coletaEmbarque);
      setTipoDocumentoPadrao(viagem.coletaEmbarqueDocumento.tipoDocumento);
      setResumoConhecimento({
        qtdDocs: 0,
        pesoDoc: 0,
        qtdTotalDoc: 0,
        valorDoc: 0,
        valorFaturas: 0,
      });
    }

    setLoading(false);
  };

  const atualizaResumoViagem = (conhecimentos) => {
    let autorizados = 0;
    let cancelados = 0;
    let naoAutorizados = 0;
    let totalFrete = 0;
    let totalConhecimentos = 0;

    for (let i = 0; i < conhecimentos.length; i += 1) {
      if (
        conhecimentos[i].statusCte === 2 ||
        conhecimentos[i].statusCte === 5 ||
        conhecimentos[i].statusCte === 11
      )
        autorizados += 1;
      else if (conhecimentos[i].statusCte === 3) cancelados += 1;
      else naoAutorizados += 1;

      if (
        conhecimentos[i].statusCte !== 4 &&
        conhecimentos[i].statusCte !== 3
      ) {
        totalFrete += conhecimentos[i].vlrFrete;
        totalConhecimentos += conhecimentos[i].vlrTotal;
      }
    }

    setResumoViagem({
      naoAutorizados,
      autorizados,
      cancelados,
      totalFrete,
      totalConhecimentos,
    });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const notifications = [];

      const { status, message: msg, data: viagem } = await getViagem(
        registryKey
      );

      if (msg) {
        notifications.push({
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          message: msg,
        });
      }

      const msgCertificado = await validaCertificado();

      if (msgCertificado) {
        notifications.push({
          theme: Theme.Primary,
          message: msgCertificado,
        });
      }

      setMessages(notifications);

      if (status === ResponseStatus.Success) {
        const dataParameters = await getParameters({});

        setData(viagem);
        setParameters({ ...parameters, ...dataParameters });

        viagem.conhecimentos.forEach((item) => {
          item.statusCteGrid = ColetaEmbarque.EnumStatusCte[item.statusCte];
          return item;
        });

        if (gridViewEmbarques && gridViewEmbarques.current)
          gridViewEmbarques.current.setDataSource(viagem.conhecimentos);

        if (gridViewDocumentos && gridViewDocumentos.current)
          gridViewDocumentos.current.setDataSource([]);

        setDocumento(viagem.coletaEmbarqueDocumento);
        setColetaEmbarque(viagem.coletaEmbarque);
        setColetaEmbarquePadrao(viagem.coletaEmbarque);
        setTipoDocumentoPadrao(viagem.coletaEmbarqueDocumento.tipoDocumento);

        atualizaResumoViagem(viagem.conhecimentos);
      }

      setLoading(false);
    } else newData();
  }, [registryKey]);

  const calculateFrameResumoConhecimento = () => {
    let qtdDocs = 0;
    let pesoDoc = 0;
    let qtdTotalDoc = 0;
    let valorDoc = 0;

    const { qtdFaturas, valorFaturas } = coletaEmbarque;

    const dataDocuments = gridViewDocumentos.current.getDataSource();
    dataDocuments
      .filter((elem) => elem.status !== 'Remover')
      .forEach((elem) => {
        qtdDocs += 1;
        pesoDoc += elem.peso;
        qtdTotalDoc += elem.quantidade;
        valorDoc += elem.vlrNota;
      });

    setResumoConhecimento({
      ...resumoConhecimento,
      qtdDocs,
      pesoDoc,
      qtdTotalDoc,
      valorDoc,
      qtdFaturas,
      valorFaturas,
    });
  };

  const countQtdNotasEmbarque = (
    coletaEmbarqueDocumento,
    calculaDocumentoAtual
  ) => {
    let quantidade = 0;
    const documentos = gridViewDocumentos.current.getDataSource();

    if (documentos) {
      documentos
        .filter((item) => item.status !== GridView.EnumStatus.Remover)
        .forEach((item) => {
          quantidade += item.quantidade;
        });

      if (calculaDocumentoAtual)
        quantidade += coletaEmbarqueDocumento.quantidade;
    }

    return quantidade;
  };

  const recalculaFrete = async (cteCurrent) => {
    const cte = cteCurrent ?? coletaEmbarque;
    if (cte?.nrSeqTipoEmbarque !== 0) {
      setLoading(true);

      const documentosCte = gridViewDocumentos.current.getDataSource();

      const obj = {
        ...data,
        coletaEmbarque: {
          ...cte,
          qtdeEmbarque: countQtdNotasEmbarque(undefined, false),
          documentos:
            documentosCte.length > 0
              ? documentosCte
              : coletaEmbarque.documentos,
        },
        mantemTarifa: manterInfo.tarifa,
      };

      const { data: frete, status, message: msg } = await recalculateFreight(
        obj
      );

      if (status === ResponseStatus.Success) {
        setColetaEmbarque(frete);
      } else if (msg) {
        onSetMessage(status, msg);
      }

      setLoading(false);
    }
  };
  const returnLatestOdometer = async (nrSeqVeiculoPrinc) => {
    if (Number.isInteger(nrSeqVeiculoPrinc)) {
      setLoading(true);

      const notifications = [];

      const { status, message: msg, value } = await getLatestOdometerByVehicle(
        nrSeqVeiculoPrinc
      );

      if (msg) {
        notifications.push({
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          message: msg,
        });
      }

      setMessages(notifications);

      if (status === ResponseStatus.Success) {
        setData({
          ...data,
          nrOdometroIni: value,
        });
      }

      setLoading(false);
    }
  };

  const getParametersColumnsVisibility = async () => {
    const value = await getSearchPaginedModeSimplifiedColumns();
    setColumnsVisibilitySimplified(value);
  };

  useEffect(() => {
    (async function func() {
      await load();
      await getParametersColumnsVisibility();
    })();
  }, [load, registryKey, reload]);

  useEffect(() => {
    calculateFrameResumoConhecimento();
  }, [coletaEmbarque.faturas]);

  useEffect(() => {
    if (coletaEmbarque.nrSeqTipoFrete) recalculaFrete();
  }, [
    coletaEmbarque.nrSeqTipoEmbarque,
    coletaEmbarque.nrSeqPessoaRem,
    coletaEmbarque.nrSeqPessoaDes,
    coletaEmbarque.nrSeqTipoFrete,
    data.pedagioFrete,
    coletaEmbarque.nrSeqEspecieFrete,
    coletaEmbarque.nrSeqFreteMercadoria,
  ]);
  useEffect(() => {
    if (data?.nrSeqViagem === 0 && data?.nrSeqVeiculoPrinc) {
      returnLatestOdometer(data?.nrSeqVeiculoPrinc);
    }
  }, [data?.nrSeqVeiculoPrinc]);

  const onClickEditDocumento = (e, datasource) => {
    const dataDocumento = datasource.find((item) => item === e);

    if (!dataDocumento.status)
      dataDocumento.status = GridView.EnumStatus.Alterar;

    const dataDocumentos = datasource.filter((item) => item !== e);

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(dataDocumentos);

    calculateFrameResumoConhecimento();

    setDocumento(dataDocumento);
  };

  const onClickRemoveDocumento = (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(datasource);

    calculateFrameResumoConhecimento();
  };

  const columnsDocumentos = [
    { key: 'tipoDocumento.cdTipoDocumento', title: 'Tipo Documento' },
    { key: 'chaveNfe', title: 'ChNfe' },
    { key: 'nrNotaFiscal', title: 'Número' },
    { key: 'dtEmissao', title: 'Data', format: GridView.DataTypes.Date },
    {
      key: 'quantidade',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'vlrNota', title: 'Valor', format: GridView.DataTypes.Decimal },
    { key: 'peso', title: 'Peso', format: GridView.DataTypes.Decimal },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditDocumento(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickEditColetaEmbarque = async (e, datasource) => {
    setLoading(true);

    const cte = await getColetaEmbarque(e.nrSeqColetaEmbarque);

    if (cte.statusCte !== 0 && cte.statusCte !== 1 && cte.statusCte !== 10) {
      if (cte.statusCte === 11) {
        if (
          cte.coletasEletronicas.filter((x) => x.retconsrecicteCStat === 100)
            .length > 0
        ) {
          onSetMessage(1, 'CTe já autorizado, não pode ser alterado.');

          setLoading(false);
          return;
        }
      } else {
        onSetMessage(1, 'CTe já autorizado, não pode ser alterado.');

        const dataEmbarques = datasource.filter((item) => item !== e);
        cte.statusCteGrid = ColetaEmbarque.EnumStatusCte[cte.statusCte];
        dataEmbarques.push(cte);

        if (gridViewEmbarques && gridViewEmbarques.current)
          gridViewEmbarques.current.setDataSource(dataEmbarques);

        atualizaResumoViagem(dataEmbarques);

        setLoading(false);
        return;
      }
    }

    if (
      data.nrSeqAcertoMotorista <= 0 ||
      data.nrSeqAcertoMotorista === undefined
    ) {
      cte.status = GridView.EnumStatus.Alterar;

      const dataEmbarques = datasource.filter((item) => item !== e);

      if (gridViewEmbarques && gridViewEmbarques.current)
        gridViewEmbarques.current.setDataSource(dataEmbarques);

      atualizaResumoViagem(dataEmbarques);

      setColetaEmbarque(cte);

      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(cte.documentos);
    } else {
      onSetMessage(1, 'Viagem vinculada a um acerto, não pode ser alterada.');
    }

    setLoading(false);
  };

  const onClickImprimirCte = async (e) => {
    setLoading(true);

    const { status, message: msg, value } = await printColetaEmbarque(e);

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickMaisInfo = (e) => {
    setModalShow({
      ...modalShow,
      modalDetalhesConhecimento: {
        show: true,
        data: e,
      },
    });
  };

  const onClickTrasmiteCte = async (e) => {
    setLoading(true);

    const {
      status,
      messages: msgs,
      data: coletaEmbarqueDto,
    } = await transmitCte(e);

    if (msgs) setMessages(msgs);

    if (status === ResponseStatus.Success) {
      let datasource = gridViewEmbarques.current.getDataSource();

      datasource = datasource.map((item) => {
        if (item === e) {
          item = coletaEmbarqueDto;
          item.botaoSubstituirVisible =
            coletaEmbarqueDto.botaoSubstituirVisible;
          item.flgMostraTransmitir = coletaEmbarqueDto.flgMostraTransmitir;
          item.averbado = coletaEmbarqueDto.averbado;
          item.noColorAverbado = coletaEmbarqueDto.noColorAverbado;

          item.statusCteGrid =
            ColetaEmbarque.EnumStatusCte[coletaEmbarqueDto.statusCte];
        }

        return item;
      });

      if (gridViewEmbarques && gridViewEmbarques.current)
        gridViewEmbarques.current.setDataSource(datasource);

      atualizaResumoViagem(datasource);

      const statusCteString =
        ColetaEmbarque.EnumStatusCte[coletaEmbarqueDto.statusCte].string;

      if (statusCteString === 'Autorizado') {
        const { value } = await printColetaEmbarque(coletaEmbarqueDto);

        onOpenReport(transaction.noTransacao, value);
      }
    }

    setLoading(false);
  };

  const onClickGerenciarCte = (e) => {
    setLoading(true);

    const transactionOpen = findTransaction(TransacationCodes.GerenciarCte);

    if (transactionOpen) {
      transactionOpen.registryKey = e.nrSeqColetaEmbarque;
      onOpenTransaction(transactionOpen, false);
    }

    setLoading(false);
  };

  const onClickGeraCteSubstituto = async (e) => {
    setLoading(true);

    const { status, message: msg, data: cte } = await geraCteSubstituto({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    if (cte) {
      setColetaEmbarque(cte);

      if (gridViewDocumentos && gridViewDocumentos.current) {
        for (let i = 0; i < cte.documentos.length; i += 1) {
          cte.documentos[i].status = 'Inserir';
        }

        gridViewDocumentos.current.setDataSource(cte.documentos);
      }
    }

    setLoading(false);
  };

  const onClickDownload = async (e) => {
    setLoading(true);

    const { data: xmls } = await downloadCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (xmls) {
      for (let i = 0; i < xmls.length; i += 1) {
        const xmlURI = encodeURIComponent(xmls[i].xml);
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
        downloadLink.download = `CTe_${e.cdColetaEmbarque}.xml`;
        downloadLink.download = `${xmls[i].nome}_${e.cdColetaEmbarque}.xml`;
        downloadLink.click();
      }
    }
    setLoading(false);
  };

  const onClickDownloadAndPdf = async (e) => {
    setLoading(true);

    const { data: xmls } = await downloadCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (xmls) {
      for (let i = 0; i < xmls.length; i += 1) {
        const xmlURI = encodeURIComponent(xmls[i].xml);
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
        downloadLink.download = `CTe_${e.cdColetaEmbarque}.xml`;
        downloadLink.download = `${xmls[i].nome}_${e.cdColetaEmbarque}.xml`;
        downloadLink.click();
      }
    }

    const { status, message: msg, value } = await printColetaEmbarque(e);

    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:application/vnd.ms-pdf;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `Cte-${e.cdColetaEmbarque}.pdf`;
        downloadLink.click();
      }
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onClickOpenModalComprovanteEntrega = (e) => {
    setModalComprovanteEntrega({
      show: true,
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });
  };

  const onClickOpenModalDownloadNfes = (e) => {
    setModalDownloadNfes({
      show: true,
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });
  };

  const onClickConvertToCte = async (e) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: cteUpdated,
    } = await convertInternDocumentToCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      const dataSource = gridViewEmbarques.current.getDataSource();

      const newDataSource = dataSource.map((cte) => {
        if (cte.nrSeqColetaEmbarque === cteUpdated.nrSeqColetaEmbarque) {
          cteUpdated.statusCteGrid =
            ColetaEmbarque.EnumStatusCte[cteUpdated.statusCte];

          return cteUpdated;
        }

        return cte;
      });

      if (gridViewEmbarques && gridViewEmbarques.current)
        gridViewEmbarques.current.setDataSource(newDataSource);
    }

    setLoading(false);
  };

  const onClickOcorrenciasCte = async (e) => {
    setModalShow({
      ...modalShow,
      ocorrenciasCte: {
        show: true,
        cte: e,
      },
    });
  };

  const columnsEmbarques = [
    { key: 'nrSeqColetaEmbarque', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdColetaEmbarque', title: 'Número' },
    { key: 'dtEmissao', title: 'Data', format: GridView.DataTypes.Date },
    { key: 'cliente.noPessoa', title: 'Cliente' },
    { key: 'stringDocumentos', title: 'Documentos' },
    { key: 'vlrPedagio', title: 'Pedágio', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrTotal',
      title: 'Total Frete',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'averbado', title: 'Averbado', color: 'noColorAverbado' },
    {
      key: 'statusCteGrid.string',
      title: 'Status',
      color: 'statusCteGrid.color',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditColetaEmbarque(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraTransmitir',
      onClick: (e) => onClickTrasmiteCte(e),
      theme: Theme.Success,
      icon: 'share',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Transmitir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickGerenciarCte(e),
      theme: Theme.Primary,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Gerenciar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      visibleDynamic: 'flgMostraDownload',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Info,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      visibleDynamic: 'flgMostraDownload',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownloadAndPdf(e, datasource),
      theme: Theme.Warning,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Dowload do PDF e do XML',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickMaisInfo(e),
      theme: Theme.Success,
      icon: 'list',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Mais Informações',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'botaoSubstituirVisible',
      onClick: (e) => onClickGeraCteSubstituto(e),
      theme: Theme.Warning,
      icon: ['fas', 'exchange-alt'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Substituir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenModalComprovanteEntrega(e),
      theme: Theme.Info,
      icon: ['far', 'image'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Comprovante de entrega',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenModalDownloadNfes(e),
      theme: Theme.Primary,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download NFes',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgConverteCte',
      onClick: (e) => onClickConvertToCte(e),
      theme: Theme.Success,
      icon: 'plus',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Converter para CT-e',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Ocorr',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOcorrenciasCte(e),
      theme: Theme.Warning,
      icon: 'cogs',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ocorrências Cte',
      tooltipDirection: 'bottom',
    },
  ];

  const validaDuplicidade = async (document) => {
    const { data: modal } = await validaDuplicidadeDocumento({
      coletaEmbarque,
      coletaEmbarqueDocumento: document ?? documento,
    });

    return modal;
  };

  const validaDocumento = () => {
    const status = ResponseStatus.Danger;

    if (!coletaEmbarque.remetente.nrSeqPessoa) {
      onSetMessage(
        status,
        'Para adicionar a Nota é preciso selecionar o remetente.'
      );
      return false;
    }

    if (!documento.dtEmissao) {
      onSetMessage(status, 'Campo obrigatório: Data do documento.');
      return false;
    }

    if (!documento.vlrNota) {
      onSetMessage(status, 'Campo obrigatório: Valor do documento.');
      return false;
    }

    if (!documento.serie) {
      onSetMessage(status, 'Campo obrigatório: Série do documento.');
      return false;
    }

    if (!documento.nrNotaFiscal) {
      onSetMessage(status, 'Campo obrigatório: Número do documento.');
      return false;
    }

    if (!documento.quantidade) {
      onSetMessage(status, 'Campo obrigatório: Quantidade do documento.');
      return false;
    }

    if (!documento.peso) {
      onSetMessage(status, 'Campo obrigatório: Peso do documento.');
      return false;
    }

    if (!documento.nrSeqEstadoIE) {
      onSetMessage(status, 'Campo obrigatório: Estado IE do documento.');
      return false;
    }

    if (!documento.cdTipoDocumento) {
      onSetMessage(status, 'Campo obrigatório: Tipo de Documento.');
      return false;
    }

    if (documento.tipoDocumento.cdTipoDocumento === 'NF') {
      if (!documento.cfOp) {
        onSetMessage(status, 'Campo obrigatório: CFOP do documento.');
        return false;
      }

      if (documento.cfOp.length < 3) {
        onSetMessage(status, 'Campo Inváldo: CFOP do Documento.');
        return false;
      }
    }

    if (!documento.flgRemetente && !documento.nrCnpj) {
      onSetMessage(
        status,
        'Quando o documento não pertence ao remetente do conhecimento, é obrigatório informar o CNPJ do remetente do documento.'
      );
      return false;
    }

    return true;
  };

  const incluirDocumentoNaGrid = (newDocument) => {
    const dataSource = gridViewDocumentos.current.getDataSource();
    newDocument.status = newDocument.status ?? GridView.EnumStatus.Inserir;
    dataSource.push(newDocument);

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(dataSource);
  };

  const limparDocumentoEmbarque = () => {
    setDocumento(
      new ColetaEmbarqueDocumento({
        tipoDocumento: tipoDocumentoPadrao,
        flgRemetente: true,
        dtEmissao: dateNow(),
        quantidade: 0,
        vlrNota: 0,
        peso: 0,
      })
    );
  };

  const calcularQuantidadeEmbarcadaNoCTE = () => {
    let qtdeEmbarque = 0;
    const dataSource = gridViewDocumentos.current.getDataSource();
    dataSource
      .filter((item) => item.status !== GridView.EnumStatus.Remover)
      .forEach((item) => {
        qtdeEmbarque += item.quantidade;
      });

    setColetaEmbarque((state) => ({ ...state, qtdeEmbarque }));
  };

  const calculaTotaisEmbarque = (limpaDocumento) => {
    calcularQuantidadeEmbarcadaNoCTE();

    if (limpaDocumento) limparDocumentoEmbarque();

    calculateFrameResumoConhecimento();
  };

  const adicionaDocumento = async (recalcula, document, naoCalcula) => {
    const newDocument = document ?? documento;
    incluirDocumentoNaGrid(newDocument);

    if (recalcula) {
      await recalculaFrete();
    }

    if (!naoCalcula) {
      calculaTotaisEmbarque();
    }
  };

  const adicionaVariosDocumentos = async (dataSource) => {
    await Promise.all(
      dataSource.map(async (item) => {
        await adicionaDocumento(false, item, true);
      })
    );
  };

  const carregarDocumentoImportado = async (
    nota,
    naoAdicionaDados,
    calculaDocumentoAtual
  ) => {
    const qtdeEmbarque = countQtdNotasEmbarque(
      nota.coletaEmbarqueDocumento,
      calculaDocumentoAtual
    );

    nota.coletaEmbarqueDocumento.status = GridView.EnumStatus.Inserir;

    const { coletaEmbarque: embarque } = nota;

    const objColetaEmbarque = {
      ...coletaEmbarque,
      remetente: nota.remetente,
      nrSeqPessoaRem: nota.remetente?.nrSeqPessoaRem,
      destinatario: nota.destinatario,
      nrSeqPessoaDes: nota.destinatario?.nrSeqPessoaDes,
      origemConsig: embarque.origemConsig,
      nrSeqPessoaConsigOri: embarque.origemConsig?.nrSeqPessoaConsig,
      nrSeqPessoaConsigDest: embarque.destinoConsig?.nrSeqPessoaConsig,
      destinoConsig: embarque.destinoConsig,
      motorista: nota.motorista ?? {},
      tipoFrete: embarque.tipoFrete ?? {},
      nrSeqTipoFrete: embarque.tipoFrete?.nrSeqTipoFrete,
      tipoEmbarque: embarque.tipoEmbarque ?? coletaEmbarque.tipoEmbarque,
      nrSeqTipoEmbarque:
        embarque.tipoEmbarque?.nrSeqTipoEmbarque ??
        coletaEmbarque.tipoEmbarque?.nrSeqTipoEmbarque,
      apoliceSeguro: embarque.apoliceSeguro ?? {},
      nrSeqApoliceSeguro: embarque.apoliceSeguro?.nrSeqApoliceSeguro,
      especieFrete: embarque.especieFrete ?? {},
      nrSeqEspecieFrete: embarque.especieFrete?.nrSeqEspecieFrete,
      mercadoriaPrincipal: embarque.mercadoriaPrincipal ?? {},
      nrSeqFreteMercadoria: embarque.mercadoriaPrincipal?.nrSeqFreteMercadoria,
      vlrTarifa: embarque.vlrTarifa ?? 0,
      vlrFrete: embarque.vlrFrete ?? 0,
      vlrTotal: embarque.vlrTotal ?? 0,
      vlrPedagio: embarque.vlrPedagio ?? 0,
      observacao: embarque.observacao,
      remetenteDestinatario: embarque.remetenteDestinatario,
      qtdeEmbarque,
    };

    if (!naoAdicionaDados) setColetaEmbarque(objColetaEmbarque);

    let objData = {};

    if (nota.nrSeqVeiculoPrinc) {
      objData = {
        ...data,
        nrSeqPessoaMot: nota.motorista?.nrSeqPessoaMot,
        motorista: nota.motorista,
        nrSeqVeiculoPrinc: nota.veiculoPrincipal?.nrSeqVeiculo,
        veiculoPrincipal: nota.veiculoPrincipal,
        nrSeqVeiculoComp: nota.veiculoCompA?.nrSeqVeiculo,
        veiculoCompA: nota.veiculoCompA,
        nrSeqVeiculoCompB: nota.veiculoCompB?.nrSeqVeiculo,
        veiculoCompB: nota.veiculoCompB,
      };

      if (!naoAdicionaDados) setData(objData);
    }

    calculateFrameResumoConhecimento();

    return {
      coletaEmbarque: objColetaEmbarque,
      nota: nota.coletaEmbarqueDocumento,
    };
  };

  const onImportaDocumento = async (datasource) => {
    const { viagem: nota } = datasource;

    let notaRetorno = {};

    const document = nota.coletaEmbarqueDocumento;
    const duplicated = await validaDuplicidade(document);

    if (!duplicated) {
      const documentoImportado = await carregarDocumentoImportado(nota);
      notaRetorno = documentoImportado.nota;
      setDocumento(nota.coletaEmbarqueDocumento);
      return notaRetorno;
    }

    setModalNotaDuplicada({
      show: true,
      data: document,
      dataCte: nota,
    });

    return notaRetorno;
  };

  const modalImportaNFCarregaDocumentosimportados = async (
    datasource,
    naoAdicionaDados
  ) => {
    const documentoImportado = await carregarDocumentoImportado(
      datasource[0].data.viagem,
      naoAdicionaDados
    );

    return documentoImportado.nota;
  };

  const validaArquivosComErro = (dataSource) => {
    let msg = '';

    const documentosInvalidos = dataSource
      .filter((item) => item.status === ResponseStatus.Warning)
      .map((item) => item.data.archiveName);

    if (documentosInvalidos.length > 0) {
      msg = `Documentos inválidos: ${documentosInvalidos
        .map((item) => item)
        .join(', ')}`;
    }

    const documentosComErro = dataSource.filter(
      (item) => item.status === ResponseStatus.Error
    );

    if (documentosComErro.length > 0) {
      if (documentosInvalidos.length) msg += '; ';

      msg += 'Erro desconhecido ao adicionar nota(as).';
    }

    if (msg) onSetMessage(1, msg);
  };

  const geraObservacaoCte = (dataSource) => {
    let observacao = '';
    let cdProdutos = '';
    let nrRemessa = '';
    let nrOt = '';

    dataSource.forEach((item) => {
      item.data.viagem.coletaEmbarqueDocumento.produtosNfe.forEach(
        (produtoNfe) => {
          cdProdutos += ` '${produtoNfe.prod.cProd}' `;
        }
      );

      if (item.data.viagem.remetente.flgRemessa)
        nrRemessa += `Nr. Remessa: '${item.data.viagem.coletaEmbarqueDocumento.nrRemessa}' `;

      if (
        item.data.viagem.remetente.flgOrdemTransporte &&
        !nrOt.includes(
          item.data.viagem.coletaEmbarqueDocumento.nrOrdemTransporte
        )
      )
        nrOt += ` OT: ${item.data.viagem.coletaEmbarqueDocumento.nrOrdemTransporte}`;
    });

    observacao = `Cd. Produtos: ${cdProdutos}`;

    observacao += nrRemessa;

    observacao += nrOt;

    return observacao;
  };

  const onCloseModalImportaNF = async (datasource) => {
    let duplicated = false;
    let coletaEmbarqueCarregado = null;

    if (datasource.length === 1) {
      if (datasource[0].status === ResponseStatus.Success) {
        const nota = await modalImportaNFCarregaDocumentosimportados(
          datasource,
          true
        );

        duplicated = await validaDuplicidade(nota);

        if (!duplicated) {
          if (parameters.addDocumentoAuto) {
            adicionaDocumento(false, nota, true);
          } else {
            nota.status = nota.status ?? GridView.EnumStatus.Inserir;
            setDocumento(nota);
          }

          const documentoImportado = await carregarDocumentoImportado(
            datasource[0].data.viagem,
            false,
            false
          );

          coletaEmbarqueCarregado = documentoImportado.coletaEmbarque;
        } else {
          setModalimportaNf({
            show: false,
          });

          setModalNotaDuplicada({
            show: true,
            data: nota,
            dataCte: datasource[0].data.viagem,
          });
        }
      } else {
        onSetMessage(1, datasource[0].data.message);
      }
    } else {
      await adicionaVariosDocumentos(
        datasource
          .filter((item) => item.status === ResponseStatus.Success)
          .map((item) => item.data.viagem.coletaEmbarqueDocumento)
      );

      validaArquivosComErro(datasource);

      datasource[0].data.viagem.coletaEmbarque.observacao = geraObservacaoCte(
        datasource
      );

      const documentoImportado = await carregarDocumentoImportado(
        datasource[0].data.viagem,
        false
      );

      coletaEmbarqueCarregado = documentoImportado.coletaEmbarque;
    }

    if (!duplicated) {
      await recalculaFrete(coletaEmbarqueCarregado);

      calculaTotaisEmbarque(false);

      setModalimportaNf({
        show: false,
      });
    }
  };

  const onCloseModalNotaDuplicada = async (liberadaImportacao, dataSource) => {
    if (liberadaImportacao) {
      let retornoDocumentoImportado = {};

      await adicionaVariosDocumentos(dataSource);

      if (modalNotaDuplicada.dataCte) {
        retornoDocumentoImportado = await carregarDocumentoImportado(
          modalNotaDuplicada.dataCte,
          false,
          false
        );
      }

      await recalculaFrete(retornoDocumentoImportado?.coletaEmbarque);

      calculaTotaisEmbarque();
    }

    setModalNotaDuplicada({
      show: false,
      dataCte: {},
    });
  };

  const onClickAdicionaDocumento = async () => {
    setLoading(true);

    if (validaDocumento()) {
      if (await validaDuplicidade()) {
        setModalNotaDuplicada({
          show: true,
          data: documento,
        });
      } else {
        adicionaDocumento(true);
      }
    }

    setLoading(false);
  };

  const onAutoCompleteEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteVeiculoCompAB = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoPorVinculoAutoComplete({
      placa: e,
      nrSeqVeiculo: data.veiculoPrincipal?.nrSeqVeiculo,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteMotorista = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaViagemAutoComplete({
      noPessoa: e,
      nrSeqVeiculo: data.veiculoPrincipal?.nrSeqVeiculo,
    });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onAutoCompleteRemetenteDestinatario = async (e) => {
    const {
      status,
      message: msg,
      data: remetentesDests,
    } = await getRemetenteDestinatarioAutoComplete({
      nomeSearch: e,
    });

    if (msg) onSetMessage(status, msg);

    return remetentesDests;
  };

  const onAutoCompleteRemetente = async (e) => {
    const {
      status,
      message: msg,
      data: remetentes,
    } = await getRemetenteAutoComplete({
      nrSeqEmpresa: data.unidOrigem?.nrSeqEmpresa,
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return remetentes;
  };

  const enablePedagioFreteManual = () =>
    coletaEmbarque.tipoFrete?.cdTipoFrete === '01' ||
    coletaEmbarque.tipoFrete?.cdTipoFrete === '02';

  const onAutoCompleteDestinatario = async (e) => {
    const {
      status,
      message: msg,
      data: destinatarios,
    } = await getDestinatarioAutoComplete({
      flgTodos,
      nrSeqEmpresa: data.unidOrigem?.nrSeqEmpresa,
      noPessoa: e,
      nrSeqRemetente: coletaEmbarque.remetente.nrSeqPessoaRem,
    });

    if (msg) onSetMessage(status, msg);

    return destinatarios;
  };

  const onAutoCompleteOrigem = async (e) => {
    const {
      status,
      message: msg,
      data: origens,
    } = await getConsignatarioAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return origens;
  };

  const onAutoCompleteDestino = async (e) => {
    const {
      status,
      message: msg,
      data: origens,
    } = await getConsignatarioAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return origens;
  };

  const onAutoCompleteTomador = async (e) => {
    const {
      status,
      message: msg,
      data: clientes,
    } = await getPessoaAutoCompleteTipoNome({
      noPessoa: e,
      Documento: e,
      noPessoaTipo: 'CLI',
    });

    if (msg) onSetMessage(status, msg);

    return clientes;
  };

  const onAutoCompleteTipoDocumento = async (e) => {
    const {
      status,
      message: msg,
      data: tiposDocumento,
    } = await getColetaTipoDocumentoAutoComplete({
      noTipoDocumento: e,
    });

    if (msg) onSetMessage(status, msg);

    return tiposDocumento;
  };

  const onAutoCompleteUfIe = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoCompleteViagem(
      {
        cdEstado: e,
      }
    );

    if (msg) onSetMessage(status, msg);

    return estados;
  };

  const onAutoCompleteTipoEmbarque = async (e, nrSeqEmpresa) => {
    const {
      status,
      message: msg,
      data: tiposEmbarque,
    } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
      nrSeqEmpresa: nrSeqEmpresa ?? data.nrSeqEmpresa,
    });

    if (msg) onSetMessage(status, msg);

    return tiposEmbarque;
  };

  const onAutoCompleteEspecieFrete = async (e) => {
    const {
      status,
      message: msg,
      data: especiesFrete,
    } = await getEspecieFreteAutoComplete({
      noEspecieFrete: e,
    });

    if (msg) onSetMessage(status, msg);

    return especiesFrete;
  };

  const onAutoCompleteFreteMercadoria = async (e) => {
    const {
      status,
      message: msg,
      data: freteMercadorias,
    } = await getFreteMercadoriaAutoComplete({
      noFreteMercadoria: e,
    });

    if (msg) onSetMessage(status, msg);

    return freteMercadorias;
  };

  const onAutoCompleteOrdemColeta = async () => {
    if (data.unidOrigem.nrSeqEmpresa !== undefined) {
      const {
        status,
        message: msg,
        data: ordensColeta,
      } = await getColetaAutoComplete({
        nrSeqEmpresa: data.unidOrigem.nrSeqEmpresa,
      });

      if (msg) onSetMessage(status, msg);

      return ordensColeta;
    }

    onSetMessage(
      1,
      'Obrigatório a seleção da Unidade de Origem para seleção de ordem de coleta.'
    );

    return null;
  };

  const onAutoCompleteApoliceSeguro = async (object) => {
    const {
      status,
      message: msg,
      data: apolicesSeguro,
    } = await getApoliceSeguroAutoComplete({
      nrSeqPessoaTomador:
        object.nrSeqPessoaCli ?? coletaEmbarque.tomador?.nrSeqPessoa,
      nrSeqPessoaRem:
        object.nrSeqPessoaRem ?? coletaEmbarque.remetente?.nrSeqPessoaRem,
      nrSeqPessoaDes:
        object.nrSeqPessoaDes ?? coletaEmbarque.destinatario?.nrSeqPessoaDes,
      nrSeqUnidadeOrigem:
        object.nrSeqEmpresa || object.nrSeqEmpresa > 0
          ? object.nrSeqEmpresa
          : data.unidOrigem?.nrSeqEmpresa,
    });

    if (msg) onSetMessage(status, msg);

    return apolicesSeguro;
  };

  const onAutoCompleteTipoFrete = async (e) => {
    const { status, message: msg, tiposFrete } = await getTipoFreteAutoComplete(
      {
        noTipoFrete: e,
      }
    );

    if (msg) onSetMessage(status, msg);

    return tiposFrete;
  };

  const onBuscaPelaChave = async () => {
    if (documento.chaveNfe) {
      setLoading(true);
      const { status, message: msg, data: nota } = await getNotaPelaChave({
        chNfe: documento.chaveNfe,
        nrSeqEmpresa: data.nrSeqEmpresa,
      });

      if (
        status === ResponseStatus.Success ||
        status === ResponseStatus.Warning
      ) {
        await onImportaDocumento(nota);
      }

      onSetMessage(status, msg);
      setLoading(false);
    }
  };

  const getEndereco = async (id) => {
    const endereco = await getEnderecoByPessoa(id);

    if (id !== undefined)
      endereco.noRua = `${endereco.noRua}, ${endereco.numero}, ${endereco.bairro}`;

    return endereco;
  };

  const remetenteDestinatarioSelectedValue = async (obj) => {
    setLoading(true);

    if (obj.nrSeqPessoaRem !== undefined && obj.nrSeqPessoaDes !== undefined) {
      obj.remetente.endereco = await getEndereco(obj.nrSeqPessoaRem);
      obj.destinatario.endereco = await getEndereco(obj.nrSeqPessoaDes);
    }

    const { status, message: msg, data: campos } = await buscaCamposViagem({
      nrSeqPessoaRem: obj.nrSeqPessoaRem,
      nrSeqPessoaDes: obj.nrSeqPessoaDes,
    });

    if (msg) onSetMessage(status, msg);

    const camposCte = {
      mercadoriaPrincipal: campos?.freteMercadoria,
      nrSeqFreteMercadoria: campos?.nrSeqFreteMercadoria,
      tipoEmbarque: campos?.tipoEmbarque,
      nrSeqTipoEmbarque: campos?.nrSeqTipoEmbarque,
      tipoFrete: campos?.tipoFrete,
      nrSeqTipoFrete: campos?.nrSeqTipofrete,
      especieFrete: campos?.especieFrete,
      nrSeqEspecieFrete: campos?.nrSeqEspecieFrete,
      destinoConsig: campos?.destinoConsig,
      nrSeqPessoaConsigDest: campos?.nrSeqPessoaConsigDest,
      origemConsig: campos?.origemConsig,
      nrSeqPessoaConsigOri: campos?.nrSeqPessoaConsigOri,
      vlrTarifa: campos?.vlrFrete,
      vlrPedagio: campos?.vlrPedagio,
    };

    const cte = {
      ...coletaEmbarque,
      ...camposCte,
      remetenteDestinatario: obj,
      remetente: obj.remetente,
      nrSeqPessoaRem: obj.remetente?.nrSeqPessoaRem,
      destinatario: obj.destinatario,
      nrSeqPessoaDes: obj.destinatario?.nrSeqPessoaDes,
    };

    setColetaEmbarque(cte);

    setDocumento({
      ...documento,
      ufIe: obj.remetente?.endereco?.estado,
      nrSeqEstadoIE: obj.remetente?.endereco?.estado?.nrSeqEstado,
    });

    setLoading(false);
  };

  const onImportaNf = async () => {
    setModalimportaNf({ importaNf: true });
  };

  const buscaVeiculoEMotorista = async (id) => {
    setLoading(true);

    const { status, message: msg, value } = await buscaMotoristaEVeiculos({
      nrSeqVeiculo: id,
      nrSeqFreteMercadoria: coletaEmbarque.nrSeqFreteMercadoria,
    });

    setLoading(false);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Error) return {};

    setParameters({
      ...parameters,
      enabledMotorista: value.motoristaEnabled,
    });

    return {
      veiculoCompA: value.veiculoCompA ?? {},
      veiculoCompB: value.veiculoCompB ?? {},
      veiculoCompC: value.veiculoCompC ?? {},
      motorista: value.motorista ?? {},
      nrSeqPessoaMot: value.motorista?.nrSeqPessoaMot,
      nrSeqVeiculoComp: value.veiculoCompA?.nrSeqVeiculo,
      nrSeqVeiculoCompb: value.veiculoCompB?.nrSeqVeiculo,
      nrSeqVeiculoCompc: value.veiculoCompC?.nrSeqVeiculo,
    };
  };

  const newColetaEmbarque = () => {
    gridViewDocumentos.current.setDataSource([]);

    setColetaEmbarque(
      new ColetaEmbarque({
        ...coletaEmbarquePadrao,
        observacao: '',
        tarifa: manterInfo.tarifa ? coletaEmbarque.tarifa : {},
        tipoEmbarque: manterInfo.tipoEmbarque
          ? coletaEmbarque.tipoEmbarque
          : {},
        pedagio: manterInfo.pedagio ? coletaEmbarque.pedagio : {},
        especieFrete: manterInfo.especieMercadoria
          ? coletaEmbarque.especieFrete
          : {},
        mercadoriaPrincipal: manterInfo.mercadoriaPrincipal
          ? coletaEmbarque.mercadoriaPrincipal
          : {},
        flgGeraSeguro: true,
      })
    );

    setDocumento(
      new ColetaEmbarqueDocumento({
        dtEmissao: dateNow(),
        flgRemetente: true,
        tipoDocumento: tipoDocumentoPadrao,
        modelo: parameters.modeloDocumento,
        serie: parameters.serieDocumento,
        quantidade: parameters.quantidadeDocumento,
      })
    );
  };

  const substituirEmbarque = async () => {
    setLoading(true);

    const obj = {
      ...data,
      coletaEmbarque: {
        ...coletaEmbarque,
        documentos: gridViewDocumentos.current.getDataSource(),
      },
    };

    const { status, message: msg, data: value } = await replaceCte(obj);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (msg) onSetMessage(status, msg);

      if (Array.isArray(value)) {
        value.forEach((cte) => {
          cte.statusCteGrid = ColetaEmbarque.EnumStatusCte[cte.statusCte];
          return cte;
        });

        if (gridViewEmbarques && gridViewEmbarques.current)
          gridViewEmbarques.current.setDataSource(value);

        newColetaEmbarque();
      } else if (!value.seguro?.validaSeguro && value.seguro?.modalSeguro) {
        setModalShow({
          ...modalShow,
          modalAcimaSeguro: { show: true, data: value.coletaEmbarque },
        });
      }
    }

    setLoading(false);
  };

  const validaAdicionarEmbarque = () => {
    if (coletaEmbarque.nrSeqTipoFrete === 0 || !coletaEmbarque.nrSeqTipoFrete) {
      onSetMessage(1, 'Selecione o tipo de frete.');

      setColetaEmbarque((state) => ({
        ...state,
        tipoFrete: {},
        nrSeqTipoFrete: null,
      }));

      return false;
    }

    return true;
  };

  const onAdicionaEmbarque = async ({ modal, confirmModal, datasource }) => {
    setLoading(true);

    const adicionar = async (item, msg) => {
      const cte = new ColetaEmbarque(item.coletaEmbarque);
      cte.statusCteGrid = ColetaEmbarque.EnumStatusCte[cte.statusCte];

      const dataSource = gridViewEmbarques.current.getDataSource();
      dataSource.push(cte);

      if (gridViewEmbarques && gridViewEmbarques.current) {
        gridViewEmbarques.current.setDataSource(dataSource);
      }

      atualizaResumoViagem(dataSource);

      const messagesAdicionaEmbarque = [];

      if (item.mensagemTransmissao)
        messagesAdicionaEmbarque.push({
          theme: item.statusTransmissao ? 0 : 1,
          message: item.mensagemTransmissao,
        });

      if (item.mensagemEnviaEmail)
        messagesAdicionaEmbarque.push({
          theme: Theme.Danger,
          message: item.mensagemEnviaEmail,
        });

      if (msg)
        messagesAdicionaEmbarque.push({
          theme: Theme.Danger,
          message: msg,
        });

      if (messagesAdicionaEmbarque.length > 0)
        setMessages(messagesAdicionaEmbarque);

      setData({
        ...data,
        nrSeqViagem: cte.nrSeqViagem,
        cdViagem: cte.nrSeqViagem,
        dtCad: dateNow(),
      });

      newColetaEmbarque();

      if (cte.statusCteGrid.string === 'Autorizado') {
        const { value } = await printColetaEmbarque(cte);

        onOpenReport(transaction.noTransacao, value);
      }
    };

    if (!modal) {
      if (validaAdicionarEmbarque()) {
        const documentosCte = gridViewDocumentos.current.getDataSource();

        const obj = {
          ...data,
          coletaEmbarque: {
            ...coletaEmbarque,
            documentos: documentosCte,
            vlrDoc: resumoConhecimento.valorDoc,
          },
        };

        const { status, message: msg, data: retorno } = await adicionaEmbarque(
          obj
        );

        if (retorno.coletaEmbarque !== undefined) adicionar(retorno, msg);
        else if (
          retorno.seguro !== undefined &&
          !retorno.seguro.validaSeguro &&
          retorno.seguro.modalSeguro
        ) {
          setModalShow({
            ...modalShow,
            modalAcimaSeguro: { show: true, data: retorno.coletaEmbarque },
          });
        } else {
          onSetMessage(status, msg);
        }
      } else {
        setLoading(false);

        return;
      }
    } else if (modal && confirmModal) adicionar(datasource);

    setLoading(false);
  };

  const onClickGeraRecibo = async () => {
    const conhecimentos = gridViewEmbarques.current.getDataSource();

    const obj = new Viagem({
      ...data,
      conhecimentos,
    });

    const { status, message: msg } = await geraRecibo(obj);

    if (msg) onSetMessage(status, msg);
  };

  const onClickManifestarViagem = async () => {
    const conhecimentos = gridViewEmbarques.current.getDataSource();

    const obj = new Viagem({
      ...data,
      conhecimentos,
    });

    const { status, message: msg, data: manifesto } = await manifestarViagem(
      obj
    );

    if (status === ResponseStatus.Error) {
      if (msg) onSetMessage(status, msg);
    } else {
      setModalShow({
        ...modalShow,
        manifestarViagem: {
          show: true,
          data: {
            ...manifesto,
            viagem: obj,
          },
        },
      });
    }
  };

  const validaBuscaPelaChave = () => {
    if (!documento) {
      setDocumento({});
      return false;
    }
    if (documento.chaveNfe?.length === 0) {
      setDocumento({});
      return false;
    }
    if (documento.chaveNfe?.length !== 44 && documento.chaveNfe?.length > 0) {
      onSetMessage(1, 'Chave Inválida!');
      return false;
    }

    return true;
  };
  const preencheCamposPelaChave = async () => {
    setLoading(true);

    if (!validaBuscaPelaChave()) {
      return;
    }

    const { data: document, status, message: msg } = await buscaCamposChaveNfe({
      chaveNfe: documento.chaveNfe,
    });

    if (status === ResponseStatus.Success) {
      setDocumento(document);
    } else if (msg) {
      onSetMessage(status, msg);
      if (documento.chaveNfe) {
        documento.chaveNfe = documento.chaveNfe.replace(' ', '');
        documento.chaveNfe = documento.chaveNfe.replace('.', '');
        documento.chaveNfe = documento.chaveNfe.replace('-', '');
        documento.chaveNfe = documento.chaveNfe.replace('/', '');
        setDocumento(documento);
      }
    }

    setLoading(false);
  };

  const buscaCamposRemetenteDestinatario = async (object) => {
    const { status, message: msg, data: campos } = await buscaCamposViagem({
      nrSeqPessoaRem: object.nrSeqPessoaRem,
      nrSeqPessoaDes: object.nrSeqPessoaDes,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Error) return {};

    return {
      freteMercadoria: campos.freteMercadoria,
      tipoEmbarque: campos.tipoEmbarque,
      tipoFrete: campos.tipoFrete,
      especieFrete: campos.especieFrete,
      destinoConsig: campos.destinoConsig,
      origemConsig: campos.origemConsig,
      vlrFrete: campos.vlrFrete,
      vlrPedagio: campos.vlrPedagio,
    };
  };

  const RenderTextMask = (props) => {
    const { label, value, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  };

  const carregaApolice = async (object) => {
    if (object.flgGeraSeguro) {
      const apolices = await onAutoCompleteApoliceSeguro(object);

      const [firstApolice] = apolices;

      if (firstApolice) {
        object.apoliceSeguro = firstApolice;
        object.nrSeqApoliceSeguro = firstApolice.nrSeqApoliceSeguro;
      }
    }

    return object;
  };

  const enderecoDestinatarioSelectedValue = (endereco) => {
    setLoading(true);

    if (endereco)
      endereco.noRua = `${endereco.noRua}, ${endereco.numero}, ${endereco.bairro}`;

    setColetaEmbarque({
      ...coletaEmbarque,
      nrSeqEnderecoDes: endereco?.nrSeqEndereco,
      destinatario: {
        ...coletaEmbarque.destinatario,
        endereco,
      },
    });

    setSelectAddress(false);

    setLoading(false);
  };

  const getEnderecosDestinatario = async () => {
    const enderecos = await getEnderecoByPersonList(
      coletaEmbarque.nrSeqPessoaDes
    );

    return enderecos;
  };

  const destinatarioSelectedValue = async (destinatario) => {
    setLoading(true);

    const enderecos = await getEnderecoByPersonList(destinatario.nrSeqPessoa);

    if (enderecos) {
      if (enderecos.length > 1) {
        setSelectAddress(true);
      } else if (enderecos.length === 1) {
        const [endereco] = enderecos;

        destinatario.endereco = endereco;

        destinatario.endereco.noRua = `${endereco.noRua}, ${endereco.numero}, ${endereco.bairro}`;
      }
    }

    const campos = await buscaCamposRemetenteDestinatario({
      nrSeqPessoaRem: coletaEmbarque.nrSeqPessoaRem,
      nrSeqPessoaDes: destinatario.nrSeqPessoa,
    });

    let objectColetaEmbarque = {
      ...coletaEmbarque,
    };

    if (campos.freteMercadoria) {
      objectColetaEmbarque.mercadoriaPrincipal = campos.freteMercadoria;
      objectColetaEmbarque.nrSeqFreteMercadoria =
        campos.freteMercadoria?.nrSeqFreteMercadoria;
    }

    if (campos.tipoEmbarque) {
      objectColetaEmbarque.tipoEmbarque = campos.tipoEmbarque;
      objectColetaEmbarque.nrSeqTipoEmbarque =
        campos.tipoEmbarque?.nrSeqTipoEmbarque;
    }

    if (campos.tipoFrete) {
      objectColetaEmbarque.tipoFrete = campos.tipoFrete;
      objectColetaEmbarque.nrSeqTipoFrete = campos.tipoFrete?.nrSeqTipoFrete;
    }

    if (campos.especieFrete) {
      objectColetaEmbarque.especieFrete = campos.especieFrete;
      objectColetaEmbarque.nrSeqEspecieFrete =
        campos.especieFrete?.nrSeqEspecieFrete;
    }

    if (campos.origemConsig) {
      objectColetaEmbarque.origemConsig = campos.origemConsig;
      objectColetaEmbarque.nrSeqPessoaConsigOri =
        campos.origemConsig?.nrSeqPessoaConsig;

      setDocumento({
        ...documento,
        flgRemetente: false,
        nrCnpj: campos.origemConsig?.nrCNPJ,
      });
    }

    if (campos.destinoConsig) {
      objectColetaEmbarque.destinoConsig = campos.destinoConsig;
      objectColetaEmbarque.nrSeqPessoaConsigDest =
        campos.destinoConsig?.nrSeqPessoaConsig;
    }

    objectColetaEmbarque.destinatario = destinatario;
    objectColetaEmbarque.nrSeqPessoaDes = destinatario.nrSeqPessoaDes;

    objectColetaEmbarque = await carregaApolice(objectColetaEmbarque);

    setColetaEmbarque({
      ...objectColetaEmbarque,
      vlrFrete: coletaEmbarque.vlrFrete ?? campos?.vlrFrete,
      vlrPedagio: coletaEmbarque.vlrPedagio ?? campos?.vlrPedagio,
    });

    setLoading(false);
  };

  const remetenteSelectedValue = async (remetente) => {
    setLoading(true);

    remetente.endereco = await getEndereco(remetente.nrSeqPessoa);

    let objectColetaEmbarque = { ...coletaEmbarque };

    objectColetaEmbarque.remetente = remetente;
    objectColetaEmbarque.nrSeqPessoaRem = remetente.nrSeqPessoaRem;

    objectColetaEmbarque = await carregaApolice(objectColetaEmbarque);

    setColetaEmbarque(objectColetaEmbarque);

    setDocumento({
      ...documento,
      ufIe: remetente?.endereco?.estado,
      nrSeqEstadoIE: remetente?.endereco?.estado?.nrSeqEstado,
    });

    setLoading(false);
  };

  const tipoFreteSelectedValue = async (tipoFrete) => {
    if (tipoFrete.nrSeqTipoFrete) {
      setLoading(true);

      const apolices = await onAutoCompleteApoliceSeguro({ tipoFrete });

      let apoliceSeguro = {};

      if (apolices.length > 0) apoliceSeguro = { ...apolices[0] };

      const cte = new ColetaEmbarque({
        ...coletaEmbarque,
        tipoFrete,
        nrSeqTipoFrete: tipoFrete.nrSeqTipoFrete,
        nrSeqApoliceSeguro: apoliceSeguro.nrSeqApoliceSeguro,
        apoliceSeguro,
      });

      setColetaEmbarque(cte);

      setLoading(false);
    }
  };

  const unidadeOrigemSelectedValue = async (unidOrigem) => {
    setLoading(true);

    let objectColetaEmbarque = { ...coletaEmbarque };

    objectColetaEmbarque.unidOrigem = unidOrigem;

    if (unidOrigem.nrSeqEmpresa) {
      objectColetaEmbarque.nrSeqEmpresa = unidOrigem.nrSeqEmpresa;

      objectColetaEmbarque = await carregaApolice(objectColetaEmbarque);
    }

    setData({
      ...data,
      unidOrigem,
      nrSeqEmpresa: unidOrigem.nrSeqEmpresa,
      nrSeqGrupoEmpresa: unidOrigem.nrSeqGrupoEmpresa,
    });

    if (unidOrigem.nrSeqEmpresa) {
      const tipoEmbarques = await onAutoCompleteTipoEmbarque(
        null,
        unidOrigem.nrSeqEmpresa
      );

      if (tipoEmbarques.length > 0) {
        const [first] = tipoEmbarques.filter(
          (item) => item.nrSeqEmpresa === unidOrigem.nrSeqEmpresa
        );

        if (first) {
          objectColetaEmbarque.tipoEmbarque = first;
          objectColetaEmbarque.nrSeqTipoEmbarque = first.nrSeqTipoEmbarque;
        }
      }
    }

    setColetaEmbarque(objectColetaEmbarque);

    setLoading(false);
  };

  const geraSeguroSelectedValue = async (flgGeraSeguro) => {
    setLoading(true);

    let objectColetaEmbarque = { ...coletaEmbarque, flgGeraSeguro };

    objectColetaEmbarque = await carregaApolice(objectColetaEmbarque);

    setColetaEmbarque(objectColetaEmbarque);

    setLoading(false);
  };

  const tomadorSelectedValue = async (clienteTomador) => {
    setLoading(true);

    clienteTomador.endereco = await getEndereco(clienteTomador.nrSeqPessoa);

    let objectColetaEmbarque = { ...coletaEmbarque };

    objectColetaEmbarque.tomador = clienteTomador;
    objectColetaEmbarque.nrSeqPessoaCli = clienteTomador.nrSeqPessoa;

    objectColetaEmbarque = await carregaApolice(objectColetaEmbarque);

    setColetaEmbarque(objectColetaEmbarque);

    setLoading(false);
  };

  const removeEspacos = (str) => str.replace(/\s/g, '');

  const chaveNfeSelectedValue = (chaveNfe) => {
    chaveNfe = removeEspacos(chaveNfe);

    setDocumento({ ...documento, chaveNfe });
  };

  const onBlurChaveCteRef = async () => {
    setLoading(true);

    const { status, message: msg } = await validaChaveCteRef(
      new Viagem({
        coletaEmbarque: new ColetaEmbarque({
          chaveCteRef: coletaEmbarque.chaveCteRef,
        }),
      })
    );

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);

    const { status, message: msg, value } = await printViagem({
      nrSeqViagem: data.nrSeqViagem,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };
  const onClickGerarImprimirRomaneio = async () => {
    setLoading(true);

    const { status, message: msg, value: base64 } = await excelViagemRomaneio({
      nrSeqViagem: data.nrSeqViagem,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = 'Romaneio.csv';
        downloadLink.click();
      }
      onSetMessage(
        status,
        'O arquivo foi gerado com sucesso, confira nos seus downloads.'
      );
    }

    setLoading(false);
  };

  const onImprimirTodosCtes = async (ctes) => {
    setLoading(true);

    const { status, message: msg, value } = await printMultiplosCtes({
      conhecimentos: ctes ?? data.conhecimentos,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqViagem: data.nrSeqViagem,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:image/pdf;base64, ${value.split('-')[1]}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `${value.split('-')[0]}.pdf`;
        downloadLink.click();

        onSetMessage(
          status,
          'O arquivo de impressão foi gerado com sucesso, confira nos seus downloads.'
        );
      }
    }

    setLoading(false);
  };

  const save = async () => {
    setLoading(true);

    const { status, message: msg } = await saveVeiculoMotorista({
      nrSeqViagem: data.nrSeqViagem,
      nrSeqVeiculoPrinc: data.nrSeqVeiculoPrinc,
      nrSeqVeiculoComp: data.nrSeqVeiculoComp,
      nrSeqVeiculoCompB: data.nrSeqVeiculoCompb,
      nrSeqVeiculoCompC: data.nrSeqVeiculoCompc,
      nrSeqPessoaMot: data.nrSeqPessoaMot,
      dtSaida: data.dtSaida,
      dtChegada: data.dtChegada,
      horaSaida: data.horaSaida,
      horaChegada: data.horaChegada,
      nrOdometroIni: data.nrOdometroIni,
      nrOdometroFim: data.nrOdometroFim,
    });

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onChangeDataChegada = (dtChegada) => {
    setData({ ...data, dtChegada });
    if (columnsVisibilitySimplified) {
      setColetaEmbarque({
        ...coletaEmbarque,
        dtPrevisaoEntrega: dtChegada,
      });
    }
  };

  const onChangeDataSaida = (dtSaida) => {
    setData({ ...data, dtSaida });
    if (columnsVisibilitySimplified) {
      setDocumento({ ...documento, dtEmissao: dtSaida });
    }
  };

  const abrirConhecimentosAFaturar = () => {
    setLoading(true);

    const transactionOpen = findTransaction(
      TransacationCodes.ConhecimentosFaturar
    );

    if (transactionOpen) {
      const dataSource = gridViewEmbarques.current.getDataSource();

      if (dataSource.length <= 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Necessário adicionar ao menos um conhecimento na viagem.'
        );

        setLoading(false);

        return;
      }

      transactionOpen.register = dataSource.map(
        (item) => item.nrSeqColetaEmbarque
      );

      onOpenTransaction(transactionOpen, false);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Não foi possível abrir Conhecimentos a Faturar, contate o suporte.'
      );
    }

    setLoading(false);
  };

  const onSelectVeiculoPrincipal = async (veiculoPrincipal) => {
    setLoading(true);

    let dataSource = {};

    if (veiculoPrincipal?.nrSeqVeiculo !== undefined)
      dataSource = await buscaVeiculoEMotorista(veiculoPrincipal.nrSeqVeiculo);

    setColetaEmbarque({
      ...coletaEmbarque,
      motorista: dataSource?.motorista ?? {},
    });

    setData({
      ...data,
      ...dataSource,
      veiculoPrincipal,
      nrSeqVeiculoPrinc: veiculoPrincipal?.nrSeqVeiculo,
    });

    setLoading(false);

    return dataSource;
  };

  const onClickGerarCtesNotas = () => {
    if (
      coletaEmbarque.flgGeraSeguro &&
      !coletaEmbarque.apoliceSeguro?.nrSeqApoliceSeguro
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Gera Seguro marcado. Necessário selecionar uma Apólice de Seguro.'
      );

      return;
    }

    if (data.nrSeqPessoaMot <= 0 || !data.nrSeqPessoaMot) {
      onSetMessage(
        ResponseStatus.Error,
        'Necessário informar o motorista da Viagem.'
      );

      return;
    }

    setModalGerarCtesPorNota({
      show: true,
      dadosCtes: coletaEmbarque,
      dadosViagem: data,
    });
  };

  const adicionaCtesPorNota = (viagem) => {
    setModalGerarCtesPorNota({
      show: false,
      notas: [],
      dadosCtes: {},
      dadosViagem: {},
    });

    setLoading(true);

    viagem.conhecimentos.forEach((item) => {
      item.statusCteGrid = ColetaEmbarque.EnumStatusCte[item.statusCte];
      return item;
    });

    if (gridViewEmbarques && gridViewEmbarques.current)
      gridViewEmbarques.current.setDataSource(viagem.conhecimentos);

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource([]);

    setDocumento(viagem.coletaEmbarqueDocumento);
    setColetaEmbarque(viagem.coletaEmbarque);
    setColetaEmbarquePadrao(viagem.coletaEmbarque);
    setTipoDocumentoPadrao(viagem.coletaEmbarqueDocumento.tipoDocumento);
    setData({
      ...data,
      nrSeqViagem: viagem.nrSeqViagem,
      conhecimentos: viagem.conhecimentos,
    });

    atualizaResumoViagem(viagem.conhecimentos);

    setLoading(false);
  };

  const transmitirCtesSelecionados = async () => {
    const selecteds = gridViewEmbarques.current
      .getCheckBoxValuesAt(0)
      .map((row) => row[0].value);

    if (selecteds.length <= 0) {
      onSetMessage(1, 'Necessário selecionar pelo menos um conhecimento.');

      return;
    }

    setLoading(true);

    const {
      status,
      messages: msgs,
      data: ctes,
    } = await transmitirMultiplosCtes({
      nrSeqsColetaEmbarque: selecteds,
    });

    setMessages(msgs);

    if (status === ResponseStatus.Success) {
      ctes.forEach((item) => {
        item.statusCteGrid = ColetaEmbarque.EnumStatusCte[item.statusCte];
        return item;
      });

      if (ctes.length > 0) gridViewEmbarques.current.setDataSource(ctes);

      atualizaResumoViagem(ctes);

      await onImprimirTodosCtes(ctes);
    }

    setLoading(false);
  };

  const removerCtes = async () => {
    setLoading(true);

    const { status, messages: msgs, data: ctes } = await removerMultiplosCtes({
      nrSeqViagem: data.nrSeqViagem,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    setMessages(msgs);

    if (status === ResponseStatus.Success) {
      if (gridViewEmbarques && gridViewEmbarques.current)
        gridViewEmbarques.current.setDataSource(ctes);
    }

    setLoading(false);
  };

  const onHandleGenerateNewViagem = async () => {
    setLoading(true);

    setModalMudaCtesViagem({
      show: true,
      nrSeqViagem: data.nrSeqViagem,
    });

    setLoading(false);
  };

  const handleViagemGeradaComSucesso = (msg) => {
    setModalMudaCtesViagem({
      show: false,
      nrSeqViagem: null,
    });

    if (msg) onSetMessage(0, msg);
  };

  const onImportaCteSubcontratado = () => {
    setModalImportaSubcontratado({
      show: true,
    });
  };

  const onBlurChangeTime = () => {
    if (data.horaChegada) {
      let timeValue = '';

      if (data.horaChegada.length === 1)
        timeValue = `${data.horaChegada}0:00:00`;

      if (data.horaChegada.length === 2)
        timeValue = `${data.horaChegada}:00:00`;

      if (data.horaChegada.length === 3) {
        const result = data.horaChegada.split('');

        timeValue = `${result[0]}${result[1]}:${result[2]}0:00`;
      }

      if (data.horaChegada.length === 4) {
        const result = data.horaChegada.split('');

        timeValue = `${result[0]}${result[1]}:${result[2]}${result[3]}:00`;
      }

      if (data.horaChegada.length === 5) {
        const result = data.horaChegada.split('');

        timeValue = `${result[0]}${result[1]}:${result[2]}${result[3]}:${result[4]}0`;
      }

      if (timeValue !== '') setData({ ...data, horaChegada: timeValue });
    }

    if (data.horaSaida) {
      let timeValue = '';

      if (data.horaSaida.length === 1) timeValue = `${data.horaSaida}0:00:00`;

      if (data.horaSaida.length === 2) timeValue = `${data.horaSaida}:00:00`;

      if (data.horaSaida.length === 3) {
        const result = data.horaSaida.split('');

        timeValue = `${result[0]}${result[1]}:${result[2]}0:00`;
      }

      if (data.horaSaida.length === 4) {
        const result = data.horaSaida.split('');

        timeValue = `${result[0]}${result[1]}:${result[2]}${result[3]}:00`;
      }

      if (data.horaSaida.length === 5) {
        const result = data.horaSaida.split('');

        timeValue = `${result[0]}${result[1]}:${result[2]}${result[3]}:${result[4]}0`;
      }

      if (timeValue !== '') setData({ ...data, horaSaida: timeValue });
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      transaction={transaction}
      title='Manutenção de Viagem'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={newData}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Salvar'
          icon={['far', 'save']}
          onClick={() => save()}
        />
        <ToolbarButtons.Button
          text='Gerar Recibo Frete'
          onClick={() => onClickGeraRecibo()}
        />
        <ToolbarButtons.Button
          text='Manifestar Viagem'
          onClick={() => onClickManifestarViagem()}
        />
        <ToolbarButtons.Button
          text='Gerar CTes por Nota'
          icon={['fas', 'file-invoice']}
          onClick={() => onClickGerarCtesNotas()}
        />
        {data.nrSeqViagem !== null && (
          <ToolbarButtons.Button
            text='Gerar Excel Romaneio'
            icon={['fas', 'file-excel']}
            onClick={() => onClickGerarImprimirRomaneio()}
          />
        )}
      </ToolbarButtons>
      {messages.length > 0 && (
        <Notification
          floatable
          messages={messages}
          onClose={() => {
            setMessages([]);
          }}
        />
      )}
      <div className='row'>
        <div className='row mb-3'>
          <div className='col-7'>
            <div className='row mb-3'>
              <div className='col-7'>
                <Autocomplete
                  label='Unid. Origem'
                  enabled={!data.nrSeqViagem && data.nrSeqViagem <= 0}
                  searchDataSource={onAutoCompleteEmpresa}
                  selectedItem={data.unidOrigem}
                  onSelectItem={async (unidOrigem) => {
                    await unidadeOrigemSelectedValue(unidOrigem);
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Ordem de Coleta'
                  searchDataSource={onAutoCompleteOrdemColeta}
                  selectedItem={coletaEmbarque.ordemColeta}
                  onSelectItem={async (item) => {
                    const ordemColeta = await getColeta(item.nrSeqColeta);
                    setColetaEmbarque({
                      ...coletaEmbarque,
                      ordemColeta,
                      remetente: ordemColeta.remetente,
                      destinatario: ordemColeta.destinatario,
                      origemConsig: ordemColeta.consignatarioOrigem,
                      destinoConsig: ordemColeta.consignatarioDestino,
                      nrSeqColeta: ordemColeta.nrSeqColeta,
                    });
                    setData({
                      ...data,
                      veiculoPrincipal: ordemColeta.veiculoPrincipal,
                      veiculoCompA: ordemColeta.veiculoCompA,
                      veiculoCompB: ordemColeta.veiculoCompB,
                      motorista: ordemColeta.motorista,
                    });
                  }}
                  dataSourceTextProperty='noOrdemColeta'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <DatePicker
                  label='Data Emissão'
                  text={coletaEmbarque.dtEmissao}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtEmissao) =>
                    setColetaEmbarque({ ...coletaEmbarque, dtEmissao })
                  }
                />
              </div>
              <div className='col-3'>
                <DatePicker
                  label='Data Chegada'
                  text={data.dtChegada}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtChegada) => onChangeDataChegada(dtChegada)}
                />
              </div>
              <div className='col-3'>
                <DatePicker
                  label='Data Saída'
                  text={data.dtSaida}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtSaida) => onChangeDataSaida(dtSaida)}
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  label='Informar Hora'
                  checked={data.flgHora}
                  onChange={(flgHora) => setData({ ...data, flgHora })}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Veículo'
                  searchDataSource={onAutoCompleteVeiculo}
                  selectedItem={data.veiculoPrincipal}
                  onSelectItem={onSelectVeiculoPrincipal}
                  dataSourceTextProperty='placa'
                />
              </div>
              {data.veiculoPrincipal?.nrSeqVeiculo > 0 && (
                <div className='col-4'>
                  <Autocomplete
                    label='Veículo Comp A'
                    tooltip='Serão listados apenas os veículos vinculados ao veículo principal'
                    searchDataSource={onAutoCompleteVeiculoCompAB}
                    selectedItem={data.veiculoCompA}
                    onSelectItem={(veiculoCompA) =>
                      setData({
                        ...data,
                        veiculoCompA,
                        nrSeqVeiculoComp: veiculoCompA.nrSeqVeiculo,
                      })
                    }
                    dataSourceTextProperty='placa'
                  />
                </div>
              )}
              {data.veiculoPrincipal?.nrSeqVeiculo > 0 && (
                <div className='col-4'>
                  <Autocomplete
                    label='Veículo Comp B'
                    tooltip='Serão listados apenas os veículos vinculados ao veículo principal'
                    searchDataSource={onAutoCompleteVeiculoCompAB}
                    selectedItem={data.veiculoCompB}
                    onSelectItem={(veiculoCompB) => {
                      setData({
                        ...data,
                        veiculoCompB,
                        nrSeqVeiculoCompb: veiculoCompB.nrSeqVeiculo,
                      });
                    }}
                    dataSourceTextProperty='placa'
                  />
                </div>
              )}
            </div>
            {data.veiculoPrincipal?.nrSeqVeiculo > 0 && (
              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Veículo Comp C'
                    tooltip='Serão listados apenas os veículos vinculados ao veículo principal'
                    searchDataSource={onAutoCompleteVeiculoCompAB}
                    selectedItem={data.veiculoCompC}
                    onSelectItem={(veiculoCompC) => {
                      setData({
                        ...data,
                        veiculoCompC,
                        nrSeqVeiculoCompc: veiculoCompC.nrSeqVeiculo,
                      });
                    }}
                    dataSourceTextProperty='placa'
                  />
                </div>
                <div className='col-3'>
                  <Textbox
                    label='Cd Acerto Motorista'
                    readOnly
                    text={data.nrSeqAcertoMotorista}
                  />
                </div>
              </div>
            )}
            <div className='row mb-3'>
              <div className='col-12'>
                <Autocomplete
                  label='Motorista'
                  tooltip='É obrigatório a seleção do veículo e apenas os motoristas cadastrados para o veículo principal serão listados'
                  searchDataSource={onAutoCompleteMotorista}
                  selectedItem={data.motorista}
                  onSelectItem={(motorista) => {
                    setData({
                      ...data,
                      motorista,
                      nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                    });
                    setColetaEmbarque({ ...coletaEmbarque, motorista });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <Textbox
                  label='Odometro Inicial'
                  text={data.nrOdometroIni}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrOdometroIni) => {
                    setData({
                      ...data,
                      nrOdometroIni,
                      nrKmRodados: data.nrOdometroFim - nrOdometroIni,
                    });
                  }}
                />
              </div>
              <div className='col-3'>
                <Textbox
                  label='Odometro Final'
                  text={data.nrOdometroFim}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrOdometroFim) => {
                    setData({
                      ...data,
                      nrOdometroFim,
                      nrKmRodados: nrOdometroFim - data.nrOdometroIni,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox label='KmRodados' text={data.nrKmRodados} readOnly />
              </div>
              {data.flgHora && (
                <div className='col-2'>
                  <Textbox
                    label='Hora Chegada'
                    text={data.horaChegada}
                    mask={MaskTypes.Time}
                    onChangedValue={(horaChegada) =>
                      setData({ ...data, horaChegada })
                    }
                    onBlur={onBlurChangeTime}
                  />
                </div>
              )}
              {data.flgHora && (
                <div className='col-2'>
                  <Textbox
                    label='Hora Saída'
                    text={data.horaSaida}
                    mask={MaskTypes.Time}
                    onChangedValue={(horaSaida) =>
                      setData({ ...data, horaSaida })
                    }
                    onBlur={onBlurChangeTime}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='col-5'>
            <div className='row mb-3'>
              <Panel>
                <Panel.Header
                  icon={['far', 'save']}
                  title='Manter Informações'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='row mb-3'>
                      <div className='col-3'>
                        <Switch
                          formControl
                          label='Peso'
                          checked={manterInfo.peso}
                          onChange={(peso) =>
                            setManterInfo({ ...manterInfo, peso })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Switch
                          formControl
                          label='Tarifa'
                          checked={manterInfo.tarifa}
                          onChange={(tarifa) =>
                            setManterInfo({ ...manterInfo, tarifa })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Switch
                          formControl
                          label='Remetente'
                          checked={manterInfo.remetente}
                          onChange={(remetente) =>
                            setManterInfo({ ...manterInfo, remetente })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Switch
                          formControl
                          label='Destinatário'
                          checked={manterInfo.destinatario}
                          onChange={(destinatario) =>
                            setManterInfo({ ...manterInfo, destinatario })
                          }
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-4'>
                        <Switch
                          formControl
                          label='Tipo Embarque'
                          checked={manterInfo.tipoEmbarque}
                          onChange={(tipoEmbarque) =>
                            setManterInfo({ ...manterInfo, tipoEmbarque })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Switch
                          formControl
                          label='Pedágio'
                          checked={manterInfo.pedagio}
                          onChange={(pedagio) =>
                            setManterInfo({ ...manterInfo, pedagio })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Switch
                          formControl
                          tooltip='Define se irá buscar na SEFAZ ou em outros sites.'
                          label='Busca Sefaz'
                          checked={manterInfo.buscaSefaz}
                          onChange={(buscaSefaz) =>
                            setManterInfo({ ...manterInfo, buscaSefaz })
                          }
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-4'>
                        <Switch
                          formControl
                          label='Busca NFe'
                          tooltip='Define se irá ser realizada a busca online pela chave da nfe.'
                          checked={manterInfo.buscaNfe}
                          onChange={(buscaNfe) =>
                            setManterInfo({ ...manterInfo, buscaNfe })
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Switch
                          formControl
                          label='Seguro Info'
                          tooltip='Informar se o seguro cobre o valor da carga cadastrada.'
                          checked={data.informaVlrApolice}
                          onChange={(informaVlrApolice) =>
                            setData({ ...data, informaVlrApolice })
                          }
                        />
                      </div>
                      <div className='col-4'>
                        <Switch
                          formControl
                          label='Espécie e Mercad.'
                          checked={manterInfo.especieMercadoria}
                          onChange={(especieMercadoria) =>
                            setManterInfo({ ...manterInfo, especieMercadoria })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              icon={['fas', 'users']}
              title='Envolvidos'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                {remetenteDest && (
                  <div className='row'>
                    <div className='row mb-3'>
                      <div className='col'>
                        <Autocomplete
                          label='Remetente/Destinatário'
                          searchDataSource={onAutoCompleteRemetenteDestinatario}
                          selectedItem={coletaEmbarque.remetenteDestinatario}
                          onSelectItem={async (remetenteDestinatario) => {
                            await remetenteDestinatarioSelectedValue(
                              remetenteDestinatario
                            );
                          }}
                          dataSourceTextPropertyLabel='DE: '
                          dataSourceTextProperty='nomeRem'
                          dataSourceTextPropertyLabel2='PARA: '
                          dataSourceTextProperty2='nomeDes'
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-6'>
                        <Autocomplete
                          label='Remetente'
                          searchDataSource={onAutoCompleteRemetente}
                          selectedItem={coletaEmbarque.remetente}
                          onSelectItem={async (remetente) => {
                            await remetenteSelectedValue(remetente);
                          }}
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>
                      <div className='col-1'>
                        <Switch
                          formControl
                          label='Todos'
                          checked={flgTodos}
                          onChange={(item) => setFlgTodos(item)}
                        />
                      </div>
                      <div className='col-5'>
                        <Autocomplete
                          label='Destinatário'
                          searchDataSource={onAutoCompleteDestinatario}
                          selectedItem={coletaEmbarque.destinatario}
                          onSelectItem={async (destinatario) => {
                            await destinatarioSelectedValue(destinatario);
                          }}
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>
                    </div>
                    {selectAddress && (
                      <div className='row mb-3 flex-row-reverse'>
                        <div className='col-5'>
                          <Autocomplete
                            label='Endereços destinatário'
                            searchDataSource={getEnderecosDestinatario}
                            selectedItem={coletaEmbarque.destinatario?.endereco}
                            onSelectItem={enderecoDestinatarioSelectedValue}
                            dataSourceTextPropertyLabel='Rua: '
                            dataSourceTextProperty='noRua'
                            dataSourceTextPropertyLabel2='Número: '
                            dataSourceTextProperty2='numero'
                            dataSourceTextPropertyLabel3='Bairro: '
                            dataSourceTextProperty3='bairro'
                          />
                        </div>
                      </div>
                    )}
                    <div className='row mb-3'>
                      <div className='col-3'>
                        <Textbox
                          label='Endereço'
                          readOnly
                          text={coletaEmbarque.remetente?.endereco?.noRua}
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='Cidade'
                          readOnly
                          text={
                            coletaEmbarque.remetente?.endereco?.cidade?.noCidade
                          }
                        />
                      </div>
                      <div className='col-1'>
                        <Textbox
                          label='UF'
                          readOnly
                          text={
                            coletaEmbarque.remetente?.endereco?.estado
                              ?.cdEstado ??
                            coletaEmbarque.remetente?.endereco?.cidade?.estado
                              ?.cdEstado
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Textbox
                          label='Endereço'
                          readOnly
                          text={coletaEmbarque.destinatario?.endereco?.noRua}
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='Cidade'
                          readOnly
                          text={
                            coletaEmbarque.destinatario?.endereco?.cidade
                              ?.noCidade
                          }
                        />
                      </div>
                      <div className='col-1'>
                        <Textbox
                          label='UF'
                          readOnly
                          text={
                            coletaEmbarque.destinatario?.endereco?.estado
                              ?.cdEstado ??
                            coletaEmbarque.destinatario?.endereco?.cidade
                              ?.estado?.cdEstado
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className='row'>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <Textbox
                        maxLength={200}
                        label='Complemento do local do remetente .'
                        text={coletaEmbarque.noObservacaoRemetente}
                        onChangedValue={(noObservacaoRemetente) =>
                          setColetaEmbarque({
                            ...coletaEmbarque,
                            noObservacaoRemetente,
                          })
                        }
                      />
                    </div>
                    <div className='col-6'>
                      <Textbox
                        maxLength={200}
                        label='Complemento do local destinatário.'
                        text={coletaEmbarque.noObservacaoDestinatario}
                        onChangedValue={(noObservacaoDestinatario) =>
                          setColetaEmbarque({
                            ...coletaEmbarque,
                            noObservacaoDestinatario,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                {consignatarios && (
                  <div className='row'>
                    <div className='row mb-3'>
                      <div className='col-6'>
                        <Autocomplete
                          label='Origem'
                          searchDataSource={onAutoCompleteOrigem}
                          selectedItem={coletaEmbarque.origemConsig}
                          onSelectItem={async (origemConsig) => {
                            setLoading(true);

                            origemConsig.endereco = await getEndereco(
                              origemConsig.nrSeqPessoa
                            );

                            setColetaEmbarque({
                              ...coletaEmbarque,
                              origemConsig,
                              nrSeqPessoaConsigOri:
                                origemConsig.nrSeqPessoaConsig,
                            });

                            setLoading(false);
                          }}
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>
                      <div className='col-6'>
                        <Autocomplete
                          label='Destino'
                          searchDataSource={onAutoCompleteDestino}
                          selectedItem={coletaEmbarque.destinoConsig}
                          onSelectItem={async (destinoConsig) => {
                            setLoading(true);

                            destinoConsig.endereco = await getEndereco(
                              destinoConsig.nrSeqPessoa
                            );

                            setColetaEmbarque({
                              ...coletaEmbarque,
                              destinoConsig,
                              nrSeqPessoaConsigDest:
                                destinoConsig.nrSeqPessoaConsig,
                            });

                            setLoading(false);
                          }}
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-3'>
                        <Textbox
                          label='Endereço'
                          readOnly
                          text={coletaEmbarque.origemConsig?.endereco?.noRua}
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='Cidade'
                          readOnly
                          text={
                            coletaEmbarque.origemConsig?.endereco?.cidade
                              ?.noCidade
                          }
                        />
                      </div>
                      <div className='col-1'>
                        <Textbox
                          label='UF'
                          readOnly
                          text={
                            coletaEmbarque.origemConsig?.endereco?.estado
                              ?.cdEstado
                          }
                        />
                      </div>
                      <div className='col-3'>
                        <Textbox
                          label='Endereço'
                          readOnly
                          text={coletaEmbarque.destinoConsig?.endereco?.noRua}
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='Cidade'
                          readOnly
                          text={
                            coletaEmbarque.destinoConsig?.endereco?.cidade
                              ?.noCidade
                          }
                        />
                      </div>
                      <div className='col-1'>
                        <Textbox
                          label='UF'
                          readOnly
                          text={
                            coletaEmbarque.destinoConsig?.endereco?.estado
                              ?.cdEstado
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {tomador && (
                  <div className='row'>
                    <div className='row mb-3'>
                      <div className='col-6'>
                        <Autocomplete
                          label='Cliente/Tomador'
                          searchDataSource={onAutoCompleteTomador}
                          selectedItem={coletaEmbarque.tomador}
                          onSelectItem={async (clienteTomador) => {
                            await tomadorSelectedValue(clienteTomador);
                          }}
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-3'>
                        <Textbox
                          label='Endereço'
                          readOnly
                          text={coletaEmbarque.tomador?.endereco?.noRua}
                        />
                      </div>
                      <div className='col-2'>
                        <Textbox
                          label='Cidade'
                          readOnly
                          text={
                            coletaEmbarque.tomador?.endereco?.cidade?.noCidade
                          }
                        />
                      </div>
                      <div className='col-1'>
                        <Textbox
                          label='UF'
                          readOnly
                          text={
                            coletaEmbarque.tomador?.endereco?.estado?.cdEstado
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className='row flex-row-reverse'>
                  <Button
                    className='col-2 m-1'
                    outline
                    text='Tomador'
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    size={BootstrapSizes.Medium}
                    onClick={() => {
                      setConsignatarios(false);
                      setRemetenteDest(false);
                      setTomador(true);
                    }}
                  />
                  <Button
                    className='col-2 m-1'
                    outline
                    text='Consignatários'
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    size={BootstrapSizes.Medium}
                    onClick={() => {
                      setRemetenteDest(false);
                      setTomador(false);
                      setConsignatarios(true);
                    }}
                  />
                  <Button
                    className='col-2 m-1'
                    outline
                    text='Remetente/Destinatário'
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    size={BootstrapSizes.Medium}
                    onClick={() => {
                      setConsignatarios(false);
                      setTomador(false);
                      setRemetenteDest(true);
                    }}
                  />
                  {consignatarios && (
                    <Button
                      className='col-2 m-1'
                      text='Novo Consignatário'
                      icon='plus'
                      theme={Theme.Success}
                      size={BootstrapSizes.Medium}
                      onClick={() => {
                        setModalCadastroConsignatario({
                          show: true,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              icon={['fas', 'file']}
              title='Documentos'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-3'>
                    <Autocomplete
                      label='Tipo de Documento'
                      searchDataSource={onAutoCompleteTipoDocumento}
                      selectedItem={documento.tipoDocumento}
                      onSelectItem={(tipoDocumento) => {
                        setDocumento({
                          tipoDocumento,
                          cdTipoDocumento: tipoDocumento.cdTipoDocumento,
                          flgRemetente: true,
                          dtEmissao: dateNow(),
                          modelo: parameters.modeloDocumento,
                          serie: parameters.serieDocumento,
                          quantidade: parameters.quantidadeDocumento,
                          ufIe: documento.ufIe,
                          nrSeqEstadoIE: documento.nrSeqEstadoIE,
                          vlrNota: 0,
                          peso: 0,
                        });
                      }}
                      dataSourceTextProperty='noTipoDocumento'
                    />
                  </div>
                  <div className='col-4'>
                    <Textbox
                      label='Importa NFE'
                      onBlur={preencheCamposPelaChave}
                      text={documento.chaveNfe}
                      onChangedValue={(chaveNfe) => {
                        chaveNfeSelectedValue(chaveNfe);
                      }}
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      className='m-1'
                      icon={['fas', 'search']}
                      tooltip='Buscar Pela Chave da Nota'
                      tooltipDirection='bottom'
                      onClick={onBuscaPelaChave}
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                    />
                    <Button
                      className='m-1'
                      icon='cloud-upload-alt'
                      tooltip='Importar'
                      tooltipDirection='bottom'
                      onClick={onImportaNf}
                      theme={Theme.Warning}
                      template={Button.Templates.Default}
                    />
                    <Button
                      className='m-1'
                      icon='file'
                      tooltip='Importar CT-e Subcontratado'
                      tooltipDirection='bottom'
                      onClick={onImportaCteSubcontratado}
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Autocomplete
                      label='UF IE'
                      required
                      searchDataSource={onAutoCompleteUfIe}
                      selectedItem={documento.ufIe}
                      onSelectItem={(ufIe) =>
                        setDocumento({
                          ...documento,
                          ufIe,
                          nrSeqEstadoIE: ufIe.nrSeqEstado,
                        })
                      }
                      dataSourceTextProperty='cdEstado'
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Número'
                      readOnly={
                        documento.tipoDocumento?.cdTipoDocumento === 'NFE'
                      }
                      text={documento.nrNotaFiscal}
                      onChangedValue={(nrNotaFiscal) =>
                        setDocumento({ ...documento, nrNotaFiscal })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    <Textbox
                      label='Modelo'
                      readOnly={
                        documento.tipoDocumento?.cdTipoDocumento === 'NFE'
                      }
                      text={documento.modelo}
                      onChangedValue={(modelo) =>
                        setDocumento({ ...documento, modelo })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    <Textbox
                      label='Série'
                      readOnly={
                        documento.tipoDocumento?.cdTipoDocumento === 'NFE'
                      }
                      text={documento.serie}
                      onChangedValue={(serie) =>
                        setDocumento({ ...documento, serie })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data'
                      text={documento.dtEmissao}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtEmissao) =>
                        setDocumento({ ...documento, dtEmissao })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Switch
                      formControl
                      label='Remetente'
                      tooltip='Remetente do conhecimento é mesmo do documento?'
                      checked={documento.flgRemetente}
                      onChange={(flgRemetente) =>
                        setDocumento({ ...documento, flgRemetente })
                      }
                    />
                  </div>
                  {!documento.flgRemetente && (
                    <div className='col-2'>
                      <Textbox
                        label='CNPJ Remetente'
                        text={documento.nrCnpj}
                        onChangedValue={(nrCnpj) =>
                          setDocumento({ ...documento, nrCnpj })
                        }
                      />
                    </div>
                  )}
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Insc. Estadual'
                      text={documento.iEstadual}
                      onChangedValue={(iEstadual) =>
                        setDocumento({ ...documento, iEstadual })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Quantidade'
                      required
                      mask={MaskTypes.DecimalCustom}
                      text={documento.quantidade}
                      onChangedValue={(quantidade) =>
                        setDocumento({ ...documento, quantidade })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor'
                      required
                      mask={MaskTypes.DecimalCustom}
                      text={documento.vlrNota}
                      onChangedValue={(vlrNota) =>
                        setDocumento({ ...documento, vlrNota })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Peso (kg)'
                      required
                      mask={MaskTypes.DecimalCustom}
                      text={documento.peso}
                      onChangedValue={(peso) =>
                        setDocumento({ ...documento, peso })
                      }
                    />
                  </div>

                  <div className='col-3'>
                    <Textbox
                      label='Nr OT'
                      text={documento.nrOrdemTransporte}
                      onChangedValue={(nrOrdemTransporte) =>
                        setDocumento({ ...documento, nrOrdemTransporte })
                      }
                    />
                  </div>

                  {coletaEmbarque.remetente?.flgRemessa && (
                    <div className='col-2'>
                      <Textbox
                        label='Remessa'
                        text={documento.nrRemessa}
                        mask={MaskTypes.Integer}
                        onChangedValue={(nrRemessa) =>
                          setDocumento({ ...documento, nrRemessa })
                        }
                      />
                    </div>
                  )}
                </div>
                {documento.tipoDocumento?.cdTipoDocumento === 'NF' && (
                  <div className='row mb-3'>
                    <div className='col-1'>
                      <Textbox
                        label='CFOP'
                        text={documento.cfOp}
                        onChangedValue={(cfOp) =>
                          setDocumento({ ...documento, cfOp })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        label='B. ICMS'
                        mask={MaskTypes.DecimalCustom}
                        text={documento.vbcIcms}
                        onChangedValue={(vbcIcms) =>
                          setDocumento({ ...documento, vbcIcms })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        label='V. ICMS'
                        mask={MaskTypes.DecimalCustom}
                        text={documento.vlrIcms}
                        onChangedValue={(vlrIcms) =>
                          setDocumento({ ...documento, vlrIcms })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        label='B. CST'
                        mask={MaskTypes.DecimalCustom}
                        text={documento.vbCst}
                        onChangedValue={(vbCst) =>
                          setDocumento({ ...documento, vbCst })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        label='V. CST'
                        mask={MaskTypes.DecimalCustom}
                        text={documento.vlrCst}
                        onChangedValue={(vlrCst) =>
                          setDocumento({ ...documento, vlrCst })
                        }
                      />
                    </div>
                  </div>
                )}
                {documento.infoComplementar && (
                  <div className='row mb-3'>
                    <div className='col-8'>
                      <Textarea
                        readOnly
                        label='Observação'
                        rows={3}
                        maxLength={5000}
                        text={documento.infoComplementar}
                      />
                    </div>
                  </div>
                )}
                <div className='row mb-3'>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      icon='plus'
                      text='Adicionar Documento'
                      theme={Theme.Success}
                      template={Button.Templates.Default}
                      size={BootstrapSizes.Small}
                      onClick={onClickAdicionaDocumento}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewDocumentos}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsDocumentos}
                      offlineData
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              icon={['fas', 'list']}
              title='Outras Informações'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-3'>
                    <Autocomplete
                      required
                      label='Tipo Embarque'
                      searchDataSource={onAutoCompleteTipoEmbarque}
                      selectedItem={coletaEmbarque.tipoEmbarque}
                      onSelectItem={async (tipoEmbarque) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          tipoEmbarque,
                          nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                        });
                      }}
                      dataSourceTextProperty='noTipoEmbarque'
                    />
                  </div>
                  <div className='col-2'>
                    <Autocomplete
                      required
                      label='Tipo de Frete'
                      searchDataSource={onAutoCompleteTipoFrete}
                      selectedItem={coletaEmbarque.tipoFrete}
                      onSelectItem={async (tipoFrete) => {
                        await tipoFreteSelectedValue(tipoFrete);
                      }}
                      dataSourceTextProperty='noTipoFrete'
                    />
                  </div>
                  <div className='col-2'>
                    <Switch
                      formControl
                      visible={enablePedagioFreteManual}
                      label='Pedágio E Frete Manuais'
                      checked={data.pedagioFrete}
                      onChange={(pedagioFrete) => {
                        setData({
                          ...data,
                          pedagioFrete,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Autocomplete
                      required
                      label='Espécie de Frete'
                      searchDataSource={onAutoCompleteEspecieFrete}
                      selectedItem={coletaEmbarque.especieFrete}
                      onSelectItem={(especieFrete) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          especieFrete,
                          nrSeqEspecieFrete: especieFrete.nrSeqEspecieFrete,
                        });
                      }}
                      dataSourceTextProperty='noEspecieFrete'
                    />
                  </div>
                  <div className='col-3'>
                    <Autocomplete
                      required
                      label='Mercadoria Principal'
                      searchDataSource={onAutoCompleteFreteMercadoria}
                      selectedItem={coletaEmbarque.mercadoriaPrincipal}
                      onSelectItem={(mercadoriaPrincipal) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          mercadoriaPrincipal,
                          nrSeqFreteMercadoria:
                            mercadoriaPrincipal.nrSeqFreteMercadoria,
                        });
                      }}
                      dataSourceTextProperty='noFreteMercadoria'
                    />
                  </div>
                </div>
                {coletaEmbarque.chaveCteRefVisible ||
                coletaEmbarque.tipoFrete?.cdTipoFrete === '03' ||
                coletaEmbarque.tipoFrete?.cdTipoFrete === '07' ? (
                  <div className='row mb-3'>
                    <div className='col-7'>
                      <Textbox
                        label='Chave do CTE Referenciado'
                        onBlur={onBlurChaveCteRef}
                        readOnly={coletaEmbarque.chaveCteRefReadOnly}
                        text={coletaEmbarque.chaveCteRef}
                        onChangedValue={(chaveCteRef) => {
                          chaveCteRef = removeEspacos(chaveCteRef);

                          setColetaEmbarque({
                            ...coletaEmbarque,
                            chaveCteRef,
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Tarifa'
                      readOnly={data.pedagioFrete}
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrTarifa}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrTarifa) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrTarifa,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Frete'
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrFrete}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrFrete) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrFrete,
                        })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    <Textbox
                      label='Marca'
                      text={coletaEmbarque.noMarca}
                      onChangedValue={(noMarca) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          noMarca,
                        })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    <Switch
                      formControl
                      label='Comissão'
                      checked={coletaEmbarque.flgComissao}
                      onChange={(flgComissao) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          flgComissao,
                        })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    <Switch
                      formControl
                      label='Seguro'
                      checked={coletaEmbarque.flgGeraSeguro}
                      onChange={async (flgGeraSeguro) => {
                        await geraSeguroSelectedValue(flgGeraSeguro);
                      }}
                    />
                  </div>
                  {coletaEmbarque.flgGeraSeguro && (
                    <div className='col-3'>
                      <Autocomplete
                        label='Apólice de Seguro'
                        searchDataSource={onAutoCompleteApoliceSeguro}
                        selectedItem={coletaEmbarque.apoliceSeguro}
                        onSelectItem={(apoliceSeguro) =>
                          setColetaEmbarque({
                            ...coletaEmbarque,
                            apoliceSeguro,
                            nrSeqApoliceSeguro:
                              apoliceSeguro.nrSeqApoliceSeguro,
                          })
                        }
                        dataSourceTextProperty='noAutoComplete'
                      />
                    </div>
                  )}
                  <div className='col-2'>
                    <Textbox
                      label='Remessa (%)'
                      readOnly={data.pedagioFrete}
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrAdValorRem}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrAdValorRem) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrAdValorRem,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Despacho'
                      readOnly={data.pedagioFrete}
                      text={coletaEmbarque.vlrDespacho}
                      mask={MaskTypes.DecimalCustom}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrDespacho) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrDespacho,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Outros'
                      readOnly={data.pedagioFrete}
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrOutros}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrOutros) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrOutros,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Pedágio'
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrPedagio}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrPedagio) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrPedagio,
                        });
                      }}
                    />
                  </div>
                  {coletaEmbarque.flgGeraSeguro && (
                    <div className='col-2'>
                      <Textbox
                        label='Valor Seguro'
                        mask={MaskTypes.DecimalCustom}
                        text={coletaEmbarque.vlrSeguro}
                        onChangedValue={(vlrSeguro) => {
                          setColetaEmbarque({
                            ...coletaEmbarque,
                            vlrSeguro,
                          });
                        }}
                      />
                    </div>
                  )}
                  <div className='col-2'>
                    <Textbox
                      label='SEC'
                      readOnly={data.pedagioFrete}
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrSec}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrSec) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrSec,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='SEC/CAT'
                      readOnly={data.pedagioFrete}
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrSecCat}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrSecCat) => {
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrSecCat,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Frete Alterado'
                      readOnly={data.pedagioFrete}
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrFretePeso}
                      onBlur={recalculaFrete}
                      onChangedValue={(vlrFretePeso) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrFretePeso,
                          vlrFrete: vlrFretePeso,
                          vlrTotal: vlrFretePeso,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Total Frete'
                      readOnly
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrTotal}
                      onChangedValue={(vlrTotal) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrTotal,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Previsão de Entrega'
                      text={coletaEmbarque.dtPrevisaoEntrega}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtPrevisaoEntrega) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          dtPrevisaoEntrega,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Aliquota ICMS'
                      readOnly
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.aliqIcms}
                      onChangedValue={(aliqIcms) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          aliqIcms,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor ICMS'
                      readOnly
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrIcms}
                      onChangedValue={(vlrIcms) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrIcms,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Base ICMS'
                      readOnly
                      mask={MaskTypes.DecimalCustom}
                      text={coletaEmbarque.vlrBaseCalIcms}
                      onChangedValue={(vlrBaseCalIcms) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          vlrBaseCalIcms,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Quantidade'
                      readOnly
                      mask={MaskTypes.Integer}
                      text={coletaEmbarque.qtdeEmbarque}
                      onBlur={recalculaFrete}
                      onChangedValue={(qtdeEmbarque) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          qtdeEmbarque,
                        })
                      }
                    />
                  </div>
                  <div className='col-10'>
                    <Textarea
                      label='Observação'
                      text={coletaEmbarque.observacao}
                      onChangedValue={(observacao) =>
                        setColetaEmbarque({
                          ...coletaEmbarque,
                          observacao,
                        })
                      }
                      maxLength={2000}
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-1'>
          <div className='col-sm-12'>
            <div className='alert alert-info mx-auto' role='alert'>
              <div className='row'>
                <span className='fw-bold fs-6'>Resumo Conhecimento</span>
              </div>
              <div className='row'>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Qtde. Doc.'
                    masktype={MaskTypes.Integer}
                    value={resumoConhecimento.qtdDocs}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Peso Doc.'
                    masktype={MaskTypes.Decimal}
                    value={resumoConhecimento.pesoDoc}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Qtde. Total Doc.'
                    masktype={MaskTypes.Integer}
                    value={resumoConhecimento.qtdTotalDoc}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Valor Doc.'
                    value={resumoConhecimento.valorDoc}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Valor Faturas'
                    value={resumoConhecimento.valorFaturas}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Valor Cto'
                    value={coletaEmbarque.vlrTotal}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Quantidade Faturas'
                    masktype={MaskTypes.Integer}
                    value={resumoConhecimento.qtdFaturas}
                  />
                </div>
              </div>
              <div className='row mt-4'>
                {coletaEmbarque.substituicao &&
                  coletaEmbarque.nrSeqColetaEmbarque === 0 && (
                    <div className='col-2'>
                      <Button
                        icon={['fas', 'exchange-alt']}
                        text='Substituir Embarque'
                        size={BootstrapSizes.Small}
                        theme={Theme.Warning}
                        template={Button.Templates.Default}
                        onClick={() => substituirEmbarque()}
                      />
                    </div>
                  )}
                {!coletaEmbarque.substituicao &&
                  coletaEmbarque.nrSeqColetaEmbarque >= 0 && (
                    <div className='col-2'>
                      <Button
                        icon='plus'
                        text='Adicionar Embarque'
                        size={BootstrapSizes.Small}
                        theme={Theme.Success}
                        template={Button.Templates.Default}
                        onClick={() => onAdicionaEmbarque({ modal: false })}
                      />
                    </div>
                  )}
                <div className='col-2'>
                  <Button
                    icon='check'
                    text='Distribuir Pedágio'
                    size={BootstrapSizes.Small}
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={() => {
                      setModalDistribuirPedagio({
                        show: true,
                        data: {
                          conhecimentos: gridViewEmbarques.current.getDataSource(),
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <ModalImportNF
                    show={modalimportaNf.importaNf}
                    ref={modalImportNfRef}
                    nrSeqEmpresa={data.unidOrigem?.nrSeqEmpresa}
                    nrSeqVeiculo={data.veiculoPrincipal?.nrSeqVeiculo}
                    onClose={(datasource) => {
                      if (datasource) {
                        onCloseModalImportaNF(datasource);
                      } else {
                        setModalimportaNf({
                          show: false,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridViewEmbarques}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsEmbarques}
                gridSizeList={[500]}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              >
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.ToogleText}
                  text='Gerar Nova Viagem'
                  icon='plus'
                  onClick={onHandleGenerateNewViagem}
                />
                <Button
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.ToogleText}
                  icon='share'
                  text='Transmitir CTes selecionados'
                  onClick={transmitirCtesSelecionados}
                />
                <Button
                  outline
                  theme={Theme.Info}
                  template={Button.Templates.ToogleText}
                  icon={['far', 'credit-card']}
                  text='Gerenciar Faturas'
                  onClick={abrirConhecimentosAFaturar}
                />
                <Button
                  outline
                  theme={Theme.Primary}
                  template={Button.Templates.ToogleText}
                  icon='print'
                  text='Imprimir Todos Conhecimentos'
                  onClick={() => onImprimirTodosCtes()}
                />
                <Button
                  outline
                  theme={Theme.Danger}
                  template={Button.Templates.ToogleText}
                  icon='trash-alt'
                  text='Remover Todos CTes'
                  onClick={removerCtes}
                />
              </GridView>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalAcimaSeguro
                show={modalShow.modalAcimaSeguro?.show}
                data={modalShow.modalAcimaSeguro?.data}
                onClose={(e, datasource) => {
                  setModalShow({
                    ...modalShow,
                    modalAcimaSeguro: { show: false, data: null },
                  });
                  onAdicionaEmbarque({
                    modal: true,
                    confirmModal: e,
                    datasource,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalDetalhesConhecimento
                show={modalShow.modalDetalhesConhecimento?.show}
                data={modalShow.modalDetalhesConhecimento?.data}
                onClose={() =>
                  setModalShow({
                    ...modalShow,
                    modalDetalhesConhecimento: {
                      show: false,
                      data: undefined,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalManifestarViagem
                show={modalShow.manifestarViagem?.show}
                data={modalShow.manifestarViagem?.data}
                onOpenReport={(e) => {
                  onOpenReport(transaction.noTransacao, e);
                }}
                onClose={() => {
                  setModalShow({
                    ...modalShow,
                    manifestarViagem: {
                      show: false,
                      data: undefined,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalAvisoFerias
                show={modalShow.modalAvisoFerias?.show}
                data={modalShow.modalAvisoFerias?.data}
                onClose={(continuar, item) => {
                  setModalShow({
                    ...modalShow,
                    modalAvisoFerias: {
                      show: false,
                      data: undefined,
                    },
                  });
                  if (continuar) onImportaDocumento(item, true);
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalNotaDuplicada
                show={modalNotaDuplicada.show}
                data={modalNotaDuplicada.data}
                onClose={(liberadaImportacao, dataSource) => {
                  onCloseModalNotaDuplicada(liberadaImportacao, dataSource);
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalDistribuirPedagio
                show={modalDistribuirPedagio.show}
                data={modalDistribuirPedagio.data}
                onClose={() => {
                  setModalDistribuirPedagio({ show: false });
                }}
                updateGrid={(datasource, msg) => {
                  setModalDistribuirPedagio({ show: false });

                  setLoading(true);

                  if (msg) onSetMessage(msg);

                  datasource = datasource.map((item) => {
                    item.statusCteGrid =
                      ColetaEmbarque.EnumStatusCte[item.statusCte];

                    return item;
                  });

                  if (gridViewEmbarques && gridViewEmbarques.current)
                    gridViewEmbarques.current.setDataSource(datasource);

                  setLoading(false);
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalCadastroConsignatario
                show={modalCadastroConsignatario.show}
                onClose={(status, msg) => {
                  setModalCadastroConsignatario({
                    show: false,
                  });

                  if (msg) onSetMessage(status, msg);
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalGerarCtesPorNota
                show={modalGerarCtesPorNota.show}
                notas={modalGerarCtesPorNota.notas}
                dadosCtes={modalGerarCtesPorNota.dadosCtes}
                dadosViagem={modalGerarCtesPorNota.dadosViagem}
                adicionaCtes={adicionaCtesPorNota}
                onSelectVeiculoPrincipal={onSelectVeiculoPrincipal}
                onClose={() => {
                  setModalGerarCtesPorNota({
                    show: false,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalComprovanteEntrega
                ref={modalComprovanteEntregaRef}
                show={modalComprovanteEntrega.show}
                nrSeqColetaEmbarque={
                  modalComprovanteEntrega.nrSeqColetaEmbarque
                }
                onClose={(status, msg) => {
                  if (msg) onSetMessage(status, msg);

                  setModalComprovanteEntrega({
                    show: false,
                    nrSeqColetaEmbarque: null,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalMudaCtesViagem
                ref={modalMudaCtesViagemRef}
                show={modalMudaCtesViagem.show}
                nrSeqViagem={modalMudaCtesViagem.nrSeqViagem}
                generateTravelSuccessful={(msg) =>
                  handleViagemGeradaComSucesso(msg)
                }
                onClose={() => {
                  setModalMudaCtesViagem({
                    show: false,
                    nrSeqViagem: null,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalImportaSubcontratado
                show={modalImportaSubcontratado.show}
                onClose={() => {
                  setModalImportaSubcontratado({
                    show: false,
                  });
                }}
                onImportaCte={(cte) => {
                  setColetaEmbarque(cte);

                  setModalImportaSubcontratado({
                    show: false,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <ModalDownloadNfes
                show={modalDownloadNfes.show}
                nrSeqColetaEmbarque={modalDownloadNfes.nrSeqColetaEmbarque}
                onClose={() => {
                  setModalDownloadNfes({
                    show: false,
                    nrSeqColetaEmbarque: null,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='alert alert-info mx-auto' role='alert'>
              <div className='row mb-2'>
                <span className='fw-bold fs-6'>Resumo Viagem</span>
              </div>
              <div className='row mb-1'>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Não Autorizados'
                    value={resumoViagem.naoAutorizados}
                    masktype={MaskTypes.Integer}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Autorizados'
                    value={resumoViagem.autorizados}
                    masktype={MaskTypes.Integer}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Cancelados'
                    value={resumoViagem.cancelados}
                    masktype={MaskTypes.Integer}
                  />
                </div>
                <div className='col-3 fs-8'>
                  <RenderTextMask
                    label='Total Conhecimentos'
                    value={resumoViagem.totalConhecimentos}
                  />
                </div>
                <div className='col-2 fs-8'>
                  <RenderTextMask
                    label='Total Frete'
                    value={resumoViagem.totalFrete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalOcorrenciasCte
              show={modalShow?.ocorrenciasCte?.show}
              cte={modalShow?.ocorrenciasCte?.cte}
              onClose={() => {
                setModalShow({
                  ...modalShow,
                  ocorrenciasCte: {
                    show: false,
                    cte: undefined,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </CSDManutPage>
  );
}
