import React, { useState, useRef, useEffect } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Modal,
  Button,
  GridView,
  Notification,
  Textbox,
  Autocomplete,
} from 'ui/components';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import { getManutencaoVeiculoOSAutoCompleteByOpenDate } from 'core/services/FRO/manutencaoVeiculoOS';
import { getUltimoAbastecimento } from 'core/services/FRO/abastecimento';
import Produto from 'core/models/EST/produto';
import NfItem from 'core/models/COP/nfItem';
import AlmoxarifadoLocal from 'core/models/EST/almoxarifadoLocal';
import { MaskTypes } from 'ui/Helpers/masks';

export default function ModalSepararQuantidadeProduto(props) {
  const gridViewProdutosVeiculos = useRef();
  const { show, dataItem } = props;
  const [message, setMessage] = useState(null);
  const [dataNfItem, setDataNfItem] = useState({ qtdeTotal: 0 });
  const [dataNfItemList, setDataNfItemList] = useState([
    {
      qtdeTotal: 0,
      vlrUnit: 0,
      vlrTotal: 0,
      nrSeqProduto: null,
      nrSeqVeiculo: null,
    },
  ]);

  const clearFatura = () => {
    setDataNfItem({ qtdeTotal: 0 });
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = () => {
    if (show) {
      if (gridViewProdutosVeiculos && gridViewProdutosVeiculos.current) {
        if (
          dataItem.nrSeqVeiculo === undefined ||
          dataItem.nrSeqVeiculo === null
        ) {
          const produtosDuplicados = [];
          const quantidade = dataItem.qtdeItNf || 0;
          for (let i = 0; i < quantidade; i += 1) {
            const produto = new NfItem({
              flgTribIpi: dataItem.flgTribIpi,
              flgTribIcms: dataItem.flgTribIcms,
              qtdeItNf: 1,
              vlrUnit: dataItem.vlrUnit,
              vlrTotal: dataItem.vlrUnit * 1,
              vlrDespesa: dataItem.vlrDespesa,
              vlrServico: dataItem.vlrServico,
              vlrDesc: dataItem.vlrDesc,
              vlrFrete: dataItem.vlrFrete,
              vlrBaseCalcIcms: dataItem.vlrBaseCalcIcms,
              vlrBaseCalcIpi: dataItem.vlrBaseCalcIpi,
              vlrIcms: dataItem.vlrIcms,
              vlrIcmsFrete: dataItem.vlrIcmsFrete,
              vlrIpi: dataItem.vlrIpi,
              percBaseCalcIpi: dataItem.percBaseCalcIpi,
              percBaseCalcIcms: dataItem.percBaseCalcIcms,
              aliqIcms: dataItem.aliqIcms,
              nrSeqAlmoxarifado: dataItem.nrSeqAlmoxarifado,
              nrSeqAlmoxarifadoLocal: dataItem.nrSeqAlmoxarifadoLocal,
              nrSeqProduto: dataItem.nrSeqProduto,
              nrSeqCfo: dataItem.nrSeqCfo,
              nrSeqEstoqueMovimento: dataItem.nrSeqEstoqueMovimento,
              aliqIpi: dataItem.aliqIpi,
              nrOrdem: `${dataItem.nrOrdem}_${i + 1}`,
              flgRecIpi: dataItem.flgRecIpi,
              nrSeqNf: dataItem.nrSeqNf,
              flgRecIcms: dataItem.flgRecIcms,
              cdNcm: dataItem.cdNcm,
              nrSeqPedidoCompra: dataItem.nrSeqPedidoCompra,
              cdCstIcms: dataItem.cdCstIcms,
              cdCstIpi: dataItem.cdCstIpi,
              vlrBaseCalcPis: dataItem.vlrBaseCalcPis,
              cdCstPis: dataItem.cdCstPis,
              aliqPis: dataItem.aliqPis,
              vlrPis: dataItem.vlrPis,
              cdCstCofins: dataItem.cdCstCofins,
              vlrBaseCalcCofins: dataItem.vlrBaseCalcCofins,
              aliqCofins: dataItem.aliqCofins,
              vlrCofins: dataItem.vlrCofins,
              saldo: dataItem.saldo,
              cstIcmsOrigem: dataItem.cstIcmsOrigem,
              observacao: dataItem.observacao,
              nrSeqCstIpi: dataItem.nrSeqCstIpi,
              noCodigo: dataItem.noCodigo,
              nrSeqVeiculo: dataItem.nrSeqVeiculo,
              vlrBcST: dataItem.vlrBcST,
              vlrPIcmsST: dataItem.vlrPIcmsST,
              vlrIcmsST: dataItem.vlrIcmsST,
              dtEmis: dataItem.dtEmis,
              dtReceb: dataItem.dtReceb,
              noFornecedor: dataItem.noFornecedor,
              noEmpresa: dataItem.noEmpresa,
              nrForNf: dataItem.nrForNf,
              nrOdometro: dataItem.nrOdometro,
              nrSeqPocOrcamento: dataItem.nrSeqPocOrcamento,
              nrSeqManutencaoVeiculoOS: dataItem.nrSeqManutencaoVeiculoOS,
              nrSeqManutencaoVeiculoItem: dataItem.nrSeqManutencaoVeiculoItem,
              status: GridView.EnumStatus.Inserir,
              produto: new Produto(dataItem.produto ?? {}),
              localArmazenagem: new AlmoxarifadoLocal(
                dataItem.localArmazenagem ?? {}
              ),
            });
            produtosDuplicados.push(produto);
            dataNfItemList.push(produto);
          }
          gridViewProdutosVeiculos.current.setDataSource(produtosDuplicados);
          setDataNfItemList(dataNfItemList);
        }
      }
    }
  };

  const onSendSave = (status, msg, novoProdutos) => {
    props.onSave(status, msg, novoProdutos);
  };

  const onVeiculo = async (e) => {
    const { veiculos } = await getVeiculoAutoCompleteResumido({
      placa: e,
    });

    return veiculos;
  };

  const onSearchOrdemServicoNfItem = async () => {
    const {
      data: manutencaoVeiculoOS,
    } = await getManutencaoVeiculoOSAutoCompleteByOpenDate({
      nrSeqVeiculo: dataNfItem.nrSeqVeiculo,
      flgConcluido: false,
    });

    return manutencaoVeiculoOS;
  };

  const onClickEditProduto = (e, datasource) => {
    const dataProduto = datasource.find((item) => item === e);

    if (dataProduto.produtoNaoCadastrado) {
      return false;
    }

    if (dataProduto.status !== GridView.EnumStatus.Inserir) {
      dataProduto.status = GridView.EnumStatus.Alterar;
    }

    setDataNfItem(dataProduto);

    const dataProdutos = datasource.filter((item) => item !== e);
    if (gridViewProdutosVeiculos && gridViewProdutosVeiculos.current) {
      gridViewProdutosVeiculos.current.setDataSource(dataProdutos);
    }
    return true;
  };

  const salvarProdutos = () => {
    const lista = gridViewProdutosVeiculos.current.getDataSource() ?? [];
    const produtosComVeiculo = lista.filter(
      (p) => p.nrSeqVeiculo !== null && p.nrSeqVeiculo !== undefined
    );
    const produtosSemVeiculo = lista.filter(
      (p) => p.nrSeqVeiculo === null || p.nrSeqVeiculo === undefined
    );

    if (produtosSemVeiculo.length > 0) {
      const agrupado = produtosSemVeiculo.reduce(
        (acc, item) => {
          acc.qtdeItNf += 1;
          acc.vlrTotal += item.vlrUnit;
          acc.vlrDespesa += item.vlrDespesa || 0;
          acc.vlrServico += item.vlrServico || 0;
          acc.vlrDesc += item.vlrDesc || 0;
          acc.vlrFrete += item.vlrFrete || 0;
          acc.vlrBaseCalcIcms += item.vlrBaseCalcIcms || 0;
          acc.vlrBaseCalcIpi += item.vlrBaseCalcIpi || 0;
          acc.vlrIcms += item.vlrIcms || 0;
          acc.vlrIcmsFrete += item.vlrIcmsFrete || 0;
          acc.vlrIpi += item.vlrIpi || 0;
          acc.vlrPis += item.vlrPis || 0;
          acc.vlrCofins += item.vlrCofins || 0;
          acc.saldo += item.saldo || 0;
          return acc;
        },
        {
          ...produtosSemVeiculo[0],
          qtdeItNf: 0,
          vlrTotal: 0,
          vlrDespesa: 0,
          vlrServico: 0,
          vlrDesc: 0,
          vlrFrete: 0,
          vlrBaseCalcIcms: 0,
          vlrBaseCalcIpi: 0,
          vlrIcms: 0,
          vlrIcmsFrete: 0,
          vlrIpi: 0,
          vlrPis: 0,
          vlrCofins: 0,
          saldo: 0,
        }
      );

      agrupado.vlrUnit = agrupado.vlrTotal / agrupado.qtdeItNf;
      agrupado.nrSeqVeiculo = null;
      agrupado.status = GridView.EnumStatus.Inserir;
      produtosComVeiculo.push(agrupado);
    }

    const listaFinal = produtosComVeiculo;
    onSendSave(true, 'Produtos processados com sucesso!', listaFinal);
  };

  useEffect(() => {
    load();
  }, [show]);

  const columnsProdutos = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    { key: 'veiculo.placa', title: 'Veiculo' },
    { key: 'produto.noProduto', title: 'Produto', colorText: 'corProduto' },
    {
      key: 'vlrFrete',
      title: 'Frete',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrDesc',
      title: 'Desconto',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'qtdeItNf',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrUnit',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
    },
    {
      key: 'manutencaoVeiculoOS.cdManutencaoVeiculoOS',
      title: 'Manutenção Veículo OS',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
  ];

  const onAdicionaProduto = () => {
    dataNfItem.status = GridView.EnumStatus.Inserir;
    if (dataNfItem.produto.nrSeqProduto === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Produto novos não podem ser adicionados.'
      );
      return;
    }
    const lista = gridViewProdutosVeiculos.current.getDataSource() ?? [];
    lista.push(dataNfItem);

    if (gridViewProdutosVeiculos && gridViewProdutosVeiculos.current)
      gridViewProdutosVeiculos.current.setDataSource(lista);

    setDataNfItem(
      new NfItem({
        qtdeItNf: 0,
        vlrDesc: 0,
        vlrFrete: 0,
        vlrTotal: 0,
        vlrUnit: 0,
      })
    );
  };

  const onSend = async () => {
    clearFatura();
    props.onClose();
  };

  return (
    <Modal
      show={show}
      title='Separar Quantidade'
      onClose={() => onSend()}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'credit-card']}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3 align-items-center'>
          <div className='row mb-3'>
            <div className='col-12'>
              <Autocomplete
                label='Produto'
                readOnly
                selectedItem={dataNfItem.produto}
                onSelectItem={(produto) => {
                  setDataNfItem({
                    ...dataNfItem,
                    produto,
                    nrSeqProduto: produto.nrSeqProduto,
                  });
                }}
                dataSourceTextProperty='noProduto'
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Quantidade'
                readOnly
                text={dataNfItem.qtdeItNf}
                mask={MaskTypes.DecimalCustom}
                decimalPlaces={6}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Valor Unitário'
                required
                readOnly
                text={dataNfItem.vlrUnit}
                mask={MaskTypes.DecimalCustom}
                decimalPlaces={6}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2'>
              <Autocomplete
                label='Placa'
                searchDataSource={onVeiculo}
                selectedItem={dataNfItem.veiculo}
                onSelectItem={async (item) => {
                  const veiculoAux = item;
                  if (item.nrSeqVeiculo != null) {
                    const {
                      data: abastecimento,
                    } = await getUltimoAbastecimento(item.nrSeqVeiculo);
                    veiculoAux.ultOdometro = abastecimento.nrOdometro;
                    setDataNfItem({
                      ...dataNfItem,
                      veiculo: veiculoAux,
                      nrSeqVeiculo: veiculoAux.nrSeqVeiculo,
                    });
                  } else {
                    setDataNfItem({
                      ...dataNfItem,
                      nrSeqManutencaoVeiculoOS: null,
                      manutencaoVeiculoOS: undefined,
                    });
                  }
                }}
                dataSourceTextProperty='placa'
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Odômetro'
                maxLength={20}
                text={dataNfItem.nrOdometro}
                mask={MaskTypes.Integer}
                onChangedValue={(nrOdometro) => {
                  setDataNfItem({
                    ...dataNfItem,
                    nrOdometro,
                  });
                }}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Último Odômetro'
                maxLength={20}
                readOnly
                text={dataNfItem.veiculo?.ultOdometro}
                mask={MaskTypes.Integer}
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Manutenção Veículo OS'
                searchDataSource={onSearchOrdemServicoNfItem}
                selectedItem={dataNfItem.manutencaoVeiculoOS}
                onSelectItem={(manutencaoVeiculoOS) => {
                  setDataNfItem({
                    ...dataNfItem,
                    manutencaoVeiculoOS,
                    nrSeqManutencaoVeiculoOS:
                      manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS,
                  });
                }}
                dataSourceTextProperty='cdManutencaoVeiculoOSDtAbertura'
              />
            </div>
            <div className='col-1 mt-4'>
              <Button
                text='Adicionar'
                icon='plus'
                size={BootstrapSizes.Small}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={onAdicionaProduto}
                outline
              />
            </div>
          </div>
        </div>
        <div className='row pb-5'>
          <div className='col'>
            <GridView
              ref={gridViewProdutosVeiculos}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsProdutos}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => salvarProdutos()}
          text='Salvar'
        />
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
