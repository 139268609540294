import qs from 'qs';
import Pagination from 'core/models/pagination';
import AgrupamentoRomaneio from 'core/models/VEN/agrupamentoRomaneio';
import api from '../api';

const baseURL = `/VEN/AgrupamentoRomaneio`;

export const getAgrupamentoRomaneioList = async (filters) => {
  const response = await api.get(`/$AgrupamentoRomaneio/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AgrupamentoRomaneio(json)) ?? [],
  };
};

export const getAgrupamentoRomaneioPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AgrupamentoRomaneio(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAgrupamentoRomaneioItem = async (filters) => {
  const response = await api.get(`${baseURL}/SearchItem`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AgrupamentoRomaneio(json)) ?? [],
  };
};

export const getAgrupamentoRomaneio = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AgrupamentoRomaneio(value) : {};
};

export const saveAgrupamentoRomaneio = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAgrupamentoRomaneio = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAgrupamentoRomaneio = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAgrupamentoRomaneio = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAgrupamentoRomaneio = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
