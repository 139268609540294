import GrupoIcms from 'core/models/FIS/grupoIcms';
import ProdutoUnidadeMedida from 'core/models/EST/produtoUnidadeMedida';

export default class FreteMercadoria {
  constructor(jsonObject = {}) {
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.noFreteMercadoria = jsonObject.noFreteMercadoria;
    this.cdFreteMercadoria = jsonObject.cdFreteMercadoria;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqGrupoIcmsCif = jsonObject.nrSeqGrupoIcmsCif;
    this.nrSeqGrupoIcmsFob = jsonObject.nrSeqGrupoIcmsFob;
    this.peso = jsonObject.peso;
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.noMarca = jsonObject.noMarca;
    this.cdCnm = jsonObject.cdCnm;
    this.flgAlimenticio = jsonObject.flgAlimenticio;
    this.grupoIcmsFob = new GrupoIcms(jsonObject.grupoIcmsFob ?? {});
    this.grupoIcmsCif = new GrupoIcms(jsonObject.grupoIcmsCif ?? {});
    this.produtoUnidadeMedida = new ProdutoUnidadeMedida(
      jsonObject.produtoUnidadeMedida ?? {}
    );
  }

  toJson = () => JSON.stringify(this);
}
