import qs from 'qs';
import Pagination from 'core/models/pagination';
import ConvenioExtrato from 'core/models/ASS/convenioExtrato';
import api from '../api';

const baseURL = `/ASS/ConvenioExtrato`;

export const getConvenioExtratoList = async (filters) => {
  const response = api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    conveniosExtrato: value?.map((json) => new ConvenioExtrato(json)) ?? [],
  };
};

export const getImprimirDetalhadoConvenioExtrato = async (parameters) => {
  const response = await api.post(`${baseURL}/OnPrint`, parameters);
  return response.data ?? {};
};

export const getConvenioExtratoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ConvenioExtrato(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getConvenioExtrato = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ConvenioExtrato(value) : {};
};

export const saveConvenioExtrato = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteConvenioExtrato = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printConvenioExtrato = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelConvenioExtrato = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllConvenioExtrato = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
