import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  DatePicker,
  Button,
  ToolbarButtons,
  Confirm,
  Switch,
  Steps,
} from 'ui/components';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import {
  getAcertoMotorista,
  saveAcertoMotorista,
  deleteAcertoMotorista,
  efetivarAcertoMotorista,
  salvarMesAno,
  printAcertoMotoristaResumo,
  printRecibo,
  getTipoDescontoAcerto,
  getValorBonus,
  estornarAcertoMotorista,
  printResumo,
} from 'core/services/FIN/acertoMotorista';
import { setTransaction } from 'core/services/api';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import Viagens from './Viagens';
import Adiantamentos from './Adiantamentos';
import Abastecimentos from './Abastecimentos';
import ManutencaoModal from './ManutencaoModal';
import Pedagio from './Pedagio';
import OutrasDespesas from './OutrasDespesas';
import ExtratoFinanceiro from './ExtratoFinanceiro';
import Receitas from './Receitas';
import Premio from './premio';
import ModalGerarRecibo from './GerarRecibo';
import Bonus from './Bonus';
import ModalAlterarPlacas from './ModalAlterarPlacas';

export default function AcertoMotoristaItem({
  registryKey,
  reload,
  onSelectPage,
  onOpenTransaction,
  findTransaction,
  transaction,
  isActive,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const [showConfirmaEstorno, setShowConfirmaEstorno] = useState(false);
  const [data, setData] = useState({ flgSave: true, flgNewSave: true });
  const [acerto, setAcerto] = useState({});
  const [bonus, setBonus] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState({});
  const [message, setMessage] = useState(null);
  const [stepVisible, setStepVisible] = useState(false);
  const stepPoco = useRef();
  const cadastroClienteRef = useRef({});
  const cadastroPocoRef = useRef({});
  const outrasDespesasRef = useRef({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  function getData() {
    return data;
  }

  let lista = [];

  const StepCadastroViagem = () => (
    <div>
      <Viagens
        ref={cadastroClienteRef}
        data={data}
        setData={setData}
        acerto={acerto}
        transaction={transaction}
        onOpenReport={onOpenReport}
      />
    </div>
  );
  const StepCadastroAbastecimento = () => (
    <div>
      <Abastecimentos
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        transaction={transaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        data={data}
        acerto={acerto}
        setData={setData}
      />
    </div>
  );
  const StepManutencaoModal = () => (
    <div>
      <ManutencaoModal
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        data={data}
        acerto={acerto}
        setData={setData}
      />
    </div>
  );
  const stepPedagio = () => (
    <div>
      <Pedagio
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        data={data}
        acerto={acerto}
        setData={setData}
      />
    </div>
  );
  const StepOutrasDespesas = () => (
    <div>
      <OutrasDespesas
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        getData={getData}
        ref={outrasDespesasRef}
        transaction={transaction}
        bonus={bonus}
        setTransaction={setTransaction}
        setLoading={setLoading}
        data={data}
        acerto={acerto}
        setData={setData}
      />
    </div>
  );

  const StepExtratoFinanceiro = () => (
    <div>
      <ExtratoFinanceiro
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        data={data}
        acerto={acerto}
        setData={setData}
      />
    </div>
  );
  const StepPremio = () => (
    <div>
      <Premio
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        data={data}
        acerto={acerto}
        setData={setData}
      />
    </div>
  );

  const StepBonus = () => (
    <div>
      <Bonus
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        data={data}
        bonus={bonus}
        setBonus={setBonus}
      />
    </div>
  );

  const StepAdiantamento = () => (
    <div>
      <Adiantamentos
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroPocoRef}
        data={data}
        setData={setData}
        onSetLoading={(flgLoading) => setLoading(flgLoading)}
      />
    </div>
  );

  const step1CadastroCliente = React.createElement(StepCadastroViagem);
  const step2Adiantamento = React.createElement(StepAdiantamento);
  const Step3CadastroAbastecimento = React.createElement(
    StepCadastroAbastecimento
  );
  const Step4ManutencaoModal = React.createElement(StepManutencaoModal);
  const step5Pedagio = React.createElement(stepPedagio);
  const step6OutrasDespesas = React.createElement(StepOutrasDespesas);
  const step8ExtratoFinanceiro = React.createElement(StepExtratoFinanceiro);
  const step9premio = React.createElement(StepPremio);
  const step10Bonus = React.createElement(StepBonus);

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      setData({ nrSeqAcertoMotorista: 0 });
      const obj = await getAcertoMotorista(registryKey);
      lista = [];
      if (obj.viagens !== null || obj.viagens !== undefined) {
        for (let i = 0; i < obj.viagens.length; ) {
          obj.motorista = obj.viagens[0].motorista;
          obj.veiculo = obj.viagens[0].veiculoPrincipal;
          i += 1;
        }
      }
      if (
        obj.acertoMotoristaReceitaS !== null ||
        obj.acertoMotoristaReceitaS !== undefined
      ) {
        for (let i = 0; i < obj.acertoMotoristaReceitaS.length; ) {
          if (obj.acertoMotoristaReceitaS[i].flgGeraComissao === true) {
            lista.push(obj.acertoMotoristaReceitaS[i]);
          }
          i += 1;
        }
      }

      if (obj.vlrBonus > 0) {
        obj.vlrBonusRecebido = obj.vlrBonus;
      }

      if (obj.coletaEmbarqueS !== undefined && obj.coletaEmbarqueS !== null) {
        obj.viagens.forEach((item) => {
          item.vlrCteComplementar = 0;
        });

        obj.coletaEmbarqueS.forEach((item) => {
          if (item.tpCte === '1') {
            item.comp = true;
            obj.viagens.forEach((viagem) => {
              if (viagem.nrSeqViagem === item.nrSeqViagem) {
                viagem.vlrCteComplementar += item.vlrFrete;
              }
            });
          } else {
            item.comp = false;
          }
        });
      }

      setAcerto({
        nrOdometroInicial: obj.nrOdometroInicial,
        nrOdomentroFinal: obj.nrOdomentroFinal,
        dtChegada: obj.dtChegada,
        dtSaida: obj.dtSaida,
        mesAnoRef: obj.mesAnoRef,
        noObs: obj.noObs,
        flgHora: obj.flgHora,
        horaChegada: obj.horaChegada,
        horaSaida: obj.horaSaida,
      });

      setData({
        flgSave: true,
        flgNewSave: true,
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasReceitasComissao: lista,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        outrasDespesas: obj.outrasDespesas,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        totalLitrosComp: obj.totalLitrosComp,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrSaldoCartao: obj.vlrSaldoCartao,
        placasVinculadas: obj.placasVinculadas,
      });

      setBonus({
        bonus: obj.bonus,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
      });

      data.viagens = obj.viagens;

      setStepVisible(true);
      setLoading(false);
    } else {
      const tipodesconto = await getTipoDescontoAcerto({});
      const vlrBonusBusca = await getValorBonus({});

      setAcerto({});
      setData({
        viagens: [],
        dtAcerto: null,
        flgSave: true,
        flgNewSave: true,
      });
      setBonus({
        bonus: tipodesconto.bonus,
        vlrBonus: vlrBonusBusca,
      });

      onSetMessage(
        ResponseStatus.Error,
        'Se no acerto forem adicionados dois veiculos principais, a media não sera calculada.'
      );
      setStepVisible(true);
    }
  }, [registryKey]);

  const StepReceitas = () => (
    <div>
      <Receitas
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
        registryKey={registryKey}
        ref={cadastroClienteRef}
        transaction={transaction}
        onOpenReport={onOpenReport}
        setLoading={setLoading}
        bonus={bonus}
        setTransaction={setTransaction}
        data={data}
        acerto={acerto}
        setData={setData}
      />
    </div>
  );

  const step7Receitas = React.createElement(StepReceitas);

  const steps = [
    {
      index: 1,
      title: 'Viagens',
      visible: stepVisible,
      content: () => step1CadastroCliente,
    },
    {
      index: 2,
      title: 'Adiantamentos',
      visible: stepVisible,
      content: () => step2Adiantamento,
    },
    {
      index: 3,
      title: 'Abastecimento',
      visible: stepVisible,
      content: () => Step3CadastroAbastecimento,
    },
    {
      index: 4,
      title: 'Manutenção',
      visible: stepVisible,
      content: () => Step4ManutencaoModal,
    },
    {
      index: 5,
      title: 'Pedagios',
      visible: stepVisible,
      content: () => step5Pedagio,
    },
    {
      index: 6,
      title: 'Outras Despesas',
      visible: stepVisible,
      content: () => step6OutrasDespesas,
    },
    {
      index: 7,
      title: 'Receitas ',
      visible: stepVisible,
      content: () => step7Receitas,
    },
    {
      index: 8,
      title: 'Extrato Financeiro',
      visible: stepVisible,
      content: () => step8ExtratoFinanceiro,
    },
    {
      index: 9,
      title: 'Prêmio',
      visible: stepVisible,
      content: () => step9premio,
    },
    {
      index: 10,
      title: 'Bônus',
      visible: stepVisible,
      content: () => step10Bonus,
    },
  ];

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      ...acerto,
      ...data,
      ...bonus,
      nrSeqTransacao: transaction.nrSeqTransacao,
    };

    const { status, message: msg, value } = await saveAcertoMotorista(obj);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      data.totalOutrasDespesasVista = value.totalOutrasDespesasVista;

      setData({
        flgSave: false,
        flgNewSave: true,
        totalLitrosComp: value.totalLitrosComp,
        veiculoComp: value.veiculoComp,
        veiculoCompB: value.veiculoCompB,
        veiculoCompC: value.veiculoCompC,
        placasVinculadas: value.placasVinculadas,
        coletaEmbarqueS: value.coletaEmbarqueS,
        viagens: value.viagens,
        premios: value.viagens,
        outrasDespesas: value.outrasDespesas,
        outrasReceitasComissao: value.outrasReceitasComissao,
        abastecimentos: value.abastecimentos,
        acertoMotoristaDespesa: value.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: value.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          value.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          value.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: value.adiantamentos,
        bonus: value.bonus,
        cdAcertoMotorista: value.cdAcertoMotorista,
        cliente: value.cliente,
        dtAcerto: value.dtAcerto,
        dtAlteracao: value.dtAlteracao,
        dtCadastro: value.dtCadastro,
        dtCheque: value.dtCheque,
        extratoFinanceiroS: value.extratoFinanceiroS,
        formaPagamento: value.formaPagamento,
        manutencaoVeiculoS: value.manutencaoVeiculoS,
        mediaKM: value.mediaKM,
        mediaOutrosKM: value.mediaOutrosKM,
        motorista: value.motorista,
        motoristaTipoDescontoPeriodoS: value.motoristaTipoDescontoPeriodoS,
        nrCheque: value.nrCheque,
        nrSeqAcertoMotorista: value.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: value.nrSeqFormaPagamento,
        nrSeqPessoaMot: value.nrSeqPessoaMot,
        nrSeqTituloPagar: value.nrSeqTituloPagar,
        nrSeqTituloReceber: value.nrSeqTituloReceber,
        nrSeqVeiculoComp: value.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: value.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: value.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: value.nrSeqVeiculoPrinc,
        pedagioS: value.pedagioS,
        pesoReceitas: value.pesoReceitas,
        pesoTotal: value.pesoTotal,
        pesoTotalReceitas: value.pesoTotalReceitas,
        pessoaMot: value.pessoaMot,
        recebimentos: value.recebimentos,
        tipoAcertoMotorista: value.tipoAcertoMotorista,
        tipoReceita: value.tipoReceita,
        totalAbastecimentos: value.totalAbastecimentos,
        totalAbastecimentosVista: value.totalAbastecimentosVista,
        totalAdiantamentos: value.totalAdiantamentos,
        totalComissao: value.totalComissao,
        totalConhecimento: value.totalConhecimento,
        totalKMRodados: value.totalKMRodados,
        totalLitros: value.totalLitros,
        totalManutencoes: value.totalManutencoes,
        totalManutencoesAVista: value.totalManutencoesAVista,
        totalMotorista: value.totalMotorista,
        totalOutrasDespesas: value.totalOutrasDespesas,
        totalOutrasDespesasVista: value.totalOutrasDespesasVista,
        totalPedagios: value.totalPedagios,
        totalReceitas: value.totalReceitas,
        totalPeso: value.totalPeso,
        veiculo: value.veiculo,
        vlrAcerto: value.vlrAcerto,
        vlrBonificacaoViagem: value.vlrBonificacaoViagem,
        vlrBonus: value.vlrBonus,
        vlrBonusRecebido: value.vlrBonusRecebido,
        vlrSaldoCartao: value.vlrSaldoCartao,
      });
      formSubmit.current.reset();
    }

    setStepVisible(true);
    setLoading(false);

    outrasDespesasRef.current.setDataSource(value);
  };

  const SalvarMesAno = async () => {
    setLoading(true);
    if (
      data.nrSeqAcertoMotorista === null ||
      data.nrSeqAcertoMotorista === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Para Salvar o Ano Mes, o acerto ja deve estar salvo'
      );
      setLoading(false);
      return;
    }
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      ...acerto,
      nrSeqAcertoMotorista: data.nrSeqAcertoMotorista,
      nrSeqTransacao: transaction.nrSeqTransacao,
    };
    const { status, message: msg } = await salvarMesAno(obj);

    setStepVisible(true);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setAcerto(status === ResponseStatus.Success ? {} : acerto);

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onClickModalAlterarPlacas = async () => {
    setModalShow({
      ...modalShow,
      alteraPlaca: true,
      geraManutencaoValor: data,
      vlrAcerto: acerto,
    });
  };

  const EfetivarAcerto = async () => {
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      ...acerto,
      ...data,
      ...bonus,
      nrSeqTransacao: transaction.nrSeqTransacao,
    };

    const { status, message: msg, value } = await efetivarAcertoMotorista(obj);

    if (status === ResponseStatus.Success) {
      setData({ nrSeqAcertoMotorista: 0 });
    }
    setStepVisible(true);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (status === ResponseStatus.Success) {
      setData({
        totalLitrosComp: value.totalLitrosComp,
        veiculoComp: value.veiculoComp,
        veiculoCompB: value.veiculoCompB,
        veiculoCompC: value.veiculoCompC,
        placasVinculadas: value.placasVinculadas,
        coletaEmbarqueS: value.coletaEmbarqueS,
        viagens: value.viagens,
        premios: value.viagens,
        outrasDespesas: value.outrasDespesas,
        outrasReceitasComissao: value.outrasReceitasComissao,
        abastecimentos: value.abastecimentos,
        acertoMotoristaDespesa: value.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: value.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          value.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          value.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: value.adiantamentos,
        bonus: value.bonus,
        cdAcertoMotorista: value.cdAcertoMotorista,
        cliente: value.cliente,
        dtAcerto: value.dtAcerto,
        dtAlteracao: value.dtAlteracao,
        dtCadastro: value.dtCadastro,
        dtCheque: value.dtCheque,
        extratoFinanceiroS: value.extratoFinanceiroS,
        formaPagamento: value.formaPagamento,
        manutencaoVeiculoS: value.manutencaoVeiculoS,
        mediaKM: value.mediaKM,
        mediaOutrosKM: value.mediaOutrosKM,
        motorista: value.motorista,
        motoristaTipoDescontoPeriodoS: value.motoristaTipoDescontoPeriodoS,
        nrCheque: value.nrCheque,
        nrSeqAcertoMotorista: value.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: value.nrSeqFormaPagamento,
        nrSeqPessoaMot: value.nrSeqPessoaMot,
        nrSeqTituloPagar: value.nrSeqTituloPagar,
        nrSeqTituloReceber: value.nrSeqTituloReceber,
        nrSeqVeiculoComp: value.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: value.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: value.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: value.nrSeqVeiculoPrinc,
        pedagioS: value.pedagioS,
        pesoReceitas: value.pesoReceitas,
        pesoTotal: value.pesoTotal,
        pesoTotalReceitas: value.pesoTotalReceitas,
        pessoaMot: value.pessoaMot,
        recebimentos: value.recebimentos,
        tipoAcertoMotorista: value.tipoAcertoMotorista,
        tipoReceita: value.tipoReceita,
        totalAbastecimentos: value.totalAbastecimentos,
        totalAbastecimentosVista: value.totalAbastecimentosVista,
        totalAdiantamentos: value.totalAdiantamentos,
        totalComissao: value.totalComissao,
        totalConhecimento: value.totalConhecimento,
        totalKMRodados: value.totalKMRodados,
        totalLitros: value.totalLitros,
        totalManutencoes: value.totalManutencoes,
        totalManutencoesAVista: value.totalManutencoesAVista,
        totalMotorista: value.totalMotorista,
        totalOutrasDespesas: value.totalOutrasDespesas,
        totalOutrasDespesasVista: value.totalOutrasDespesasVista,
        totalPedagios: value.totalPedagios,
        totalReceitas: value.totalReceitas,
        totalPeso: value.totalPeso,
        veiculo: value.veiculo,
        vlrAcerto: value.vlrAcerto,
        vlrBonificacaoViagem: value.vlrBonificacaoViagem,
        vlrBonus: value.vlrBonus,
        vlrBonusRecebido: value.vlrBonusRecebido,
        vlrSaldoCartao: value.vlrSaldoCartao,
      });
      formSubmit.current.reset();
    }

    if (status === ResponseStatus.Success) {
      onOpenReport(transaction.noTransacao, value.stringPrint);
    }
    setLoading(false);
  };

  function RenderTextMask(props) {
    const { label, value, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  }
  function RenderTextMaskPessoa(props) {
    const { label, value, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? '', masktype)}
        </div>
      </div>
    );
  }

  const reset = true;

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAcertoMotorista(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      onSetMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onPrint = async () => {
    setLoading(true);

    const obj = {
      ...data,
      ...acerto,
      ...bonus,
    };

    const { value } = await printAcertoMotoristaResumo(obj);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintResumo = async () => {
    setLoading(true);

    const obj = {
      ...data,
      ...acerto,
      ...bonus,
    };

    const { value } = await printResumo(obj);

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const GerarRecibo = async () => {
    setModalShow({
      ...modalShow,
      openGerarRecibo: true,
      gerarRecibo: data,
    });
  };

  const ValorString = async () => {
    if (acerto.horaSaida !== undefined && acerto.horaSaida !== null) {
      if (acerto.horaSaida.length === 1) {
        const valor = `${acerto.horaSaida}0:00:00`;
        setAcerto({ ...acerto, horaSaida: valor });
      }
      if (acerto.horaSaida.length === 2) {
        const valor = `${acerto.horaSaida}:00:00`;
        setAcerto({ ...acerto, horaSaida: valor });
      }
      if (acerto.horaSaida.length === 3) {
        const resultado = acerto.horaSaida.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}0:00`;
        setAcerto({ ...acerto, horaSaida: valor });
      }
      if (acerto.horaSaida.length === 4) {
        const resultado = acerto.horaSaida.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:00`;

        setAcerto({ ...acerto, horaSaida: valor });
      }
      if (acerto.horaSaida.length === 5) {
        const resultado = acerto.horaSaida.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:${resultado[4]}0`;

        setAcerto({ ...acerto, horaSaida: valor });
      }
    }
    if (acerto.horaChegada !== undefined && acerto.horaChegada !== null) {
      if (acerto.horaChegada.length === 1) {
        const valor = `${acerto.horaChegada}0:00:00`;
        setAcerto({ ...acerto, horaChegada: valor });
      }
      if (acerto.horaChegada.length === 2) {
        const valor = `${acerto.horaChegada}:00:00`;
        setAcerto({ ...acerto, horaChegada: valor });
      }
      if (acerto.horaChegada.length === 3) {
        const resultado = acerto.horaChegada.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}0:00`;
        setAcerto({ ...acerto, horaChegada: valor });
      }
      if (acerto.horaChegada.length === 4) {
        const resultado = acerto.horaChegada.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:00`;

        setAcerto({ ...acerto, horaChegada: valor });
      }
      if (acerto.horaChegada.length === 5) {
        const resultado = acerto.horaChegada.split('');

        const valor = `${resultado[0]}${resultado[1]}:${resultado[2]}${resultado[3]}:${resultado[4]}0`;

        setAcerto({ ...acerto, horaChegada: valor });
      }
    }
  };

  const New = useCallback(async () => {
    const tipodesconto = await getTipoDescontoAcerto({});
    const vlrBonusBusca = await getValorBonus({});

    setAcerto({});
    setData({
      viagens: [],
      dtAcerto: null,
      flgSave: true,
      flgNewSave: true,
    });
    setBonus({
      bonus: tipodesconto.bonus,
      vlrBonus: vlrBonusBusca,
    });
  });

  const EstornarAcerto = async (confirmaAlteracao = false) => {
    setLoading(true);

    const obj = {
      ...data,
      ...acerto,
      ...bonus,
      flgConfirmaEstorno: confirmaAlteracao,
    };

    const { status, message: msg, value } = await estornarAcertoMotorista(obj);

    if (msg === 'ConfirmaExtorno') {
      setShowConfirmaEstorno(true);
    } else {
      if (status === ResponseStatus.Success) {
        setData({ nrSeqAcertoMotorista: 0 });
      }

      setStepVisible(true);

      setShowConfirmaEstorno(false);

      if (status === ResponseStatus.Success) {
        setData({
          veiculoComp: value.veiculoComp,
          veiculoCompB: value.veiculoCompB,
          veiculoCompC: value.veiculoCompC,
          coletaEmbarqueS: value.coletaEmbarqueS,
          viagens: value.viagens,
          premios: value.viagens,
          outrasDespesas: value.outrasDespesas,
          outrasReceitasComissao: value.outrasReceitasComissao,
          abastecimentos: value.abastecimentos,
          acertoMotoristaDespesa: value.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: value.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            value.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            value.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: value.adiantamentos,
          bonus: value.bonus,
          cdAcertoMotorista: value.cdAcertoMotorista,
          cliente: value.cliente,
          dtAcerto: value.dtAcerto,
          dtAlteracao: value.dtAlteracao,
          dtCadastro: value.dtCadastro,
          dtCheque: value.dtCheque,
          extratoFinanceiroS: value.extratoFinanceiroS,
          formaPagamento: value.formaPagamento,
          manutencaoVeiculoS: value.manutencaoVeiculoS,
          mediaKM: value.mediaKM,
          mediaOutrosKM: value.mediaOutrosKM,
          motorista: value.motorista,
          motoristaTipoDescontoPeriodoS: value.motoristaTipoDescontoPeriodoS,
          nrCheque: value.nrCheque,
          nrSeqAcertoMotorista: value.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: value.nrSeqFormaPagamento,
          nrSeqPessoaMot: value.nrSeqPessoaMot,
          nrSeqTituloPagar: value.nrSeqTituloPagar,
          nrSeqTituloReceber: value.nrSeqTituloReceber,
          nrSeqVeiculoComp: value.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: value.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: value.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: value.nrSeqVeiculoPrinc,
          pedagioS: value.pedagioS,
          pesoReceitas: value.pesoReceitas,
          pesoTotal: value.pesoTotal,
          pesoTotalReceitas: value.pesoTotalReceitas,
          pessoaMot: value.pessoaMot,
          recebimentos: value.recebimentos,
          tipoAcertoMotorista: value.tipoAcertoMotorista,
          tipoReceita: value.tipoReceita,
          totalAbastecimentos: value.totalAbastecimentos,
          totalAbastecimentosVista: value.totalAbastecimentosVista,
          totalAdiantamentos: value.totalAdiantamentos,
          totalComissao: value.totalComissao,
          totalConhecimento: value.totalConhecimento,
          totalKMRodados: value.totalKMRodados,
          totalLitros: value.totalLitros,
          totalManutencoes: value.totalManutencoes,
          totalManutencoesAVista: value.totalManutencoesAVista,
          totalMotorista: value.totalMotorista,
          totalOutrasDespesas: value.totalOutrasDespesas,
          totalOutrasDespesasVista: value.totalOutrasDespesasVista,
          totalPedagios: value.totalPedagios,
          totalReceitas: value.totalReceitas,
          totalPeso: value.totalPeso,
          veiculo: value.veiculo,
          vlrAcerto: value.vlrAcerto,
          vlrBonificacaoViagem: value.vlrBonificacaoViagem,
          vlrBonus: value.vlrBonus,
          vlrBonusRecebido: value.vlrBonusRecebido,
          vlrSaldoCartao: value.vlrSaldoCartao,
          placasVinculadas: value.placasVinculadas,
          totalLitrosComp: value.totalLitrosComp,
        });

        formSubmit.current.reset();
      }
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onOpenTituloPagar = (selectedValue) => {
    const transactionContaChamada = findTransaction(
      TransacationCodes.TituloPagar
    );

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = selectedValue;

      onOpenTransaction(transactionContaChamada, true);
    }
  };
  const onOpenTituloReceber = (selectedValue) => {
    const transactionContaChamada = findTransaction(
      TransacationCodes.TituloReceber
    );

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = selectedValue;

      onOpenTransaction(transactionContaChamada, true);
    }
  };

  const vlrCompetence = async () => {
    if (acerto.mesAnoRef !== undefined && acerto.mesAnoRef !== null) {
      if (acerto.mesAnoRef.length === 1) {
        const year = new Date().getFullYear();
        const valor = `0${acerto.mesAnoRef}/${year}`;

        setAcerto({ ...acerto, mesAnoRef: valor });
      } else if (acerto.mesAnoRef.length === 2) {
        const numeroInteiro = parseInt(acerto.mesAnoRef, 10);
        if (numeroInteiro > 12) {
          acerto.mesAnoRef = '12';
        }

        const year = new Date().getFullYear();
        const valor = `${acerto.mesAnoRef}/${year}`;

        setAcerto({ ...acerto, mesAnoRef: valor });
      } else if (acerto.mesAnoRef.length > 2 && acerto.mesAnoRef.length < 7) {
        const numeroInteiro = parseInt(acerto.mesAnoRef.split('/')[0], 10);
        if (numeroInteiro > 12) {
          const year = new Date().getFullYear();
          const month = '12';
          const valor = `${month}/${year}`;
          setAcerto({ ...acerto, mesAnoRef: valor });
        } else {
          const year = new Date().getFullYear();
          const month = acerto.mesAnoRef.split('/')[0];
          const valor = `${month}/${year}`;

          setAcerto({ ...acerto, mesAnoRef: valor });
        }
      } else {
        const numeroInteiro = parseInt(acerto.mesAnoRef.split('/')[0], 10);
        if (numeroInteiro > 12) {
          const year = new Date().getFullYear();
          const month = '12';
          const valor = `${month}/${year}`;
          setAcerto({ ...acerto, mesAnoRef: valor });
        }
      }
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Acerto Motorista'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={New}
      onDelete={data?.nrSeqAcertoMotorist > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        {data.dtAcerto === null && (
          <ToolbarButtons.Button
            text='Salvar'
            icon={['far', 'save']}
            onClick={save}
            type='submit'
          />
        )}

        {data.dtAcerto === null && (
          <ToolbarButtons.Button
            text='Efetivar Acerto'
            icon={['far', 'comment-alt']}
            onClick={EfetivarAcerto}
          />
        )}

        {data.dtAcerto === undefined && (
          <ToolbarButtons.Button
            text='Salvar'
            icon={['far', 'save']}
            onClick={save}
            type='submit'
          />
        )}

        {data.dtAcerto === undefined && (
          <ToolbarButtons.Button
            text='Efetivar Acerto'
            icon={['far', 'comment-alt']}
            onClick={EfetivarAcerto}
          />
        )}
        {data.dtAcerto === null && (
          <ToolbarButtons.Button
            text='Alterar Placas '
            icon={['far', 'comment-alt']}
            onClick={onClickModalAlterarPlacas}
          />
        )}

        {data.dtAcerto === undefined && (
          <ToolbarButtons.Button
            text='Alterar Placas '
            icon={['far', 'comment-alt']}
            onClick={onClickModalAlterarPlacas}
          />
        )}

        {data.nrSeqAcertoMotorista > 0 && (
          <ToolbarButtons.Button
            text='Imprimir'
            icon={['print']}
            onClick={onPrint}
          />
        )}
        {data.nrSeqAcertoMotorista > 0 && (
          <ToolbarButtons.ButtonList
            onPrintFornecedorAgrupado
            icon='caret-down'
            items={[
              {
                text: 'Resumo',
                onClick: () => onPrintResumo(),
              },
            ]}
          />
        )}
        {data.nrSeqAcertoMotorista > 0 && (
          <ToolbarButtons.Button
            text='Recibo'
            icon={['print']}
            onClick={GerarRecibo}
          />
        )}
        {data.nrSeqAcertoMotorista > 0 &&
          data.dtAcerto !== null &&
          data.dtAcerto !== undefined && (
            <ToolbarButtons.Button
              text='Estornar Acerto'
              onClick={() => EstornarAcerto()}
            />
          )}
        {data.nrSeqTituloPagar > 0 &&
          data.dtAcerto !== null &&
          data.dtAcerto !== undefined && (
            <ToolbarButtons.Button
              text='Visualizar Titulo Gerado'
              icon='layer-group'
              onClick={() => onOpenTituloPagar(data.nrSeqTituloPagar)}
            />
          )}
        {data.nrSeqTituloReceber > 0 &&
          data.dtAcerto !== null &&
          data.dtAcerto !== undefined && (
            <ToolbarButtons.Button
              text='Visualizar Titulo Gerado'
              icon='layer-group'
              onClick={() => onOpenTituloReceber(data.nrSeqTituloReceber)}
            />
          )}
      </ToolbarButtons>

      <Confirm
        show={showConfirmaEstorno}
        title='Confirmar Estorno'
        titleIcon='exclamation-triangle'
        text='O Acerto esta vinculado a um titulo a pagar, deseja extornar mesmo assim?'
        handleConfirm={() => {
          EstornarAcerto(true);
        }}
        handleCancel={() => setShowConfirmaEstorno(false)}
      />
      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Informar Hora'
            checked={acerto.flgHora}
            onChange={(flgHora) => setAcerto({ ...acerto, flgHora })}
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Saida'
            text={acerto.dtSaida}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtSaida) => setAcerto({ ...acerto, dtSaida })}
          />
        </div>
        {acerto.flgHora && (
          <div className='col-2 mb-3'>
            <Textbox
              label='Hora Saida'
              text={acerto.horaSaida}
              mask={MaskTypes.Time}
              onChangedValue={(horaSaida) =>
                setAcerto({ ...acerto, horaSaida })
              }
              onBlur={ValorString}
            />
          </div>
        )}
        <div className='col-2 mb-3'>
          <DatePicker
            label='Chegada'
            text={acerto.dtChegada}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtChegada) => setAcerto({ ...acerto, dtChegada })}
          />
        </div>
        {acerto.flgHora && (
          <div className='col-2 mb-3'>
            <Textbox
              label='Hora Chegada'
              text={acerto.horaChegada}
              mask={MaskTypes.Time}
              onChangedValue={(horaChegada) =>
                setAcerto({ ...acerto, horaChegada })
              }
              onBlur={ValorString}
            />
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            required
            label='Odometro Inicial'
            text={acerto.nrOdometroInicial}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOdometroInicial) =>
              setAcerto({ ...acerto, nrOdometroInicial })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            required
            label='Odometro Final'
            text={acerto.nrOdomentroFinal}
            mask={MaskTypes.Integer}
            onChangedValue={(nrOdomentroFinal) =>
              setAcerto({ ...acerto, nrOdomentroFinal })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={200}
            label='Competência'
            text={acerto.mesAnoRef}
            onBlur={vlrCompetence}
            mask={MaskTypes.Competence}
            onChangedValue={(mesAnoRef) => setAcerto({ ...acerto, mesAnoRef })}
          />
        </div>
        <div className='col-4 mt-3'>
          <Button
            icon={['far', 'save']}
            theme={Theme.Primary}
            template={Button.Templates.Button}
            text='Salvar Mes/Ano'
            onClick={SalvarMesAno}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='alert alert-info fs-8' role='alert'>
            <div className='row'>
              <div className='col-4 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Motorista'
                  value={data.pessoaMot?.noPessoa}
                />
              </div>
              <div className='col-4 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Placas'
                  value={data.placasVinculadas}
                />
              </div>
              {data.cdAcertoMotorista > 0 && (
                <div className='col-4 mb-2 float-right'>
                  <RenderTextMaskPessoa
                    label='Numero do Acerto'
                    value={data.cdAcertoMotorista}
                  />
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col-4 mb-2 float-right font-size:7'>
                <RenderTextMaskPessoa label='Resumo' />
              </div>
            </div>
            <div className='row'>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Conhecimentos'
                  value={data?.totalConhecimento}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Adiantamentos'
                  value={data?.totalAdiantamentos}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Abast. Prazo'
                  value={data?.totalAbastecimentos}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Abast. Av'
                  value={data?.totalAbastecimentosVista}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Manut. Prazo '
                  value={data?.totalManutencoes}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Manut. à Vista '
                  value={data?.totalManutencoesAVista}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask label='Pedágios ' value={data?.totalPedagios} />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask label='Receitas' value={data?.totalReceitas} />
              </div>
            </div>
            <div className='row'>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Outras Desp. Prazo'
                  value={data?.totalOutrasDespesas}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Outras Desp. à Vista'
                  value={data?.totalOutrasDespesasVista}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask label='Prêmio' value={data?.totalComissao} />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMask
                  label='Saldo Motorista '
                  value={data?.totalMotorista}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='KM Rodados '
                  value={data?.totalKMRodados}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Consumo Lts (Veiculo Princ.)'
                  value={data?.totalLitros}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Consumo Lts (Veiculo Comp.)'
                  value={data?.totalLitrosComp}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Média Geral KM/L '
                  value={data?.mediaKM}
                />
              </div>
              {data.nrSeqAcertoMotorista > 0 && (
                <div className='col-3 mb-2 float-right'>
                  <RenderTextMask
                    label='Valor bônus recebido'
                    value={bonus?.vlrBonusRecebido}
                  />
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Peso Total '
                  value={data?.totalPeso}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Peso Receitas '
                  value={data?.pesoReceitas}
                />
              </div>
              <div className='col-3 mb-2 float-right'>
                <RenderTextMaskPessoa
                  label='Peso Total + Peso Receitas '
                  value={data?.pesoTotalReceitas}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 mb-2'>
                <Textbox
                  label='Observação'
                  text={acerto?.noObs}
                  onChangedValue={(noObs) => setAcerto({ ...acerto, noObs })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <Steps
            propriedade={1}
            steps={steps}
            theme={Theme.Primary}
            reset={reset}
            align='horizontal'
            registryKey={data.nrSeqAcertoMotorista}
            data={data}
            setData={setData}
            getData={getData}
            ref={stepPoco}
            id='txtSteps'
          />
        </div>
      </div>
      <ModalAlterarPlacas
        show={modalShow.alteraPlaca}
        data={data}
        onSave={(alteraPlacas) => {
          setData({
            ...data,
            nrSeqVeiculoComp: alteraPlacas.nrSeqVeiculoComp,
            nrSeqVeiculoCompB: alteraPlacas.nrSeqVeiculoCompB,
            nrSeqVeiculoCompC: alteraPlacas.nrSeqVeiculoCompC,
            veiculoComp: alteraPlacas.veiculoComp,
            veiculoCompB: alteraPlacas.veiculoCompB,
            veiculoCompC: alteraPlacas.veiculoCompC,
            placasVinculadas: alteraPlacas.placasVinculadas,
          });
          setModalShow({
            ...modalShow,
            alteraPlaca: false,
          });
        }}
        onClose={() => {
          setModalShow({
            ...modalShow,
            alteraPlaca: false,
          });
        }}
      />
      <ModalGerarRecibo
        show={modalShow.openGerarRecibo}
        gerarRecibo={modalShow.gerarRecibo}
        onSave={(gerarRecibo) => {
          setData({ ...data, gerarRecibo });
          setModalShow({
            ...modalShow,
            gerarRecibo: modalShow.gerarRecibo,
          });
        }}
        onClose={async ({ message: msg, theme, obj }) => {
          setModalShow({
            ...modalShow,
            openGerarRecibo: false,
          });
          setMessage({
            message: msg,
            theme,
          });
          if (theme === ResponseStatus.Success) {
            if (obj !== null) {
              const recibo = await printRecibo(obj);
              onOpenReport(transaction.noTransacao, recibo.value);
            }
          }
        }}
      />
    </CSDManutPage>
  );
}
