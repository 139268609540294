import React, { useRef, useState, useEffect } from 'react';
import {
  BootstrapSizes,
  ResponseStatus,
  Theme,
  PageTypes,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  DropdownMulti,
  GridView,
  Panel,
  RadioButton,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import ModalGerarFaturamentoMassivo from './modalGerarFaturamentoMassivo.tsx';
import { getClienteAutoComplete } from '../../../../core/services/FIN';
import {
  getEmpresaAutoComplete,
  getPessoaAutoCompleteRemDesCli,
} from '../../../../core/services/SEG';
import { getTipoEmbarqueList } from '../../../../core/services/FRO/tipoEmbarque';
import {
  getColetaEmbarquePaginedFaturamentoPendente,
  printConhecimentoFaturas,
} from '../../../../core/services/FRO/coletaEmbarque';

// #region COLUNAS
const columnsConhecimentos = [
  { key: 'nrSeqColetaEmbarque', type: GridView.ColumnTypes.Checkbox },
  { key: 'nrSeqViagem', title: 'Viagem', visible: false },
  { key: 'cdColetaEmbarque', title: 'Número' },
  { key: 'cliente.noPessoa', title: 'Cliente' },
  { key: 'empresa.noPessoa', title: 'Empresa' },
  { key: 'remetente.noPessoaRem', title: 'Origem' },
  { key: 'destinatario.noPessoaDes', title: 'Destino' },
  {
    key: 'dtEmissao',
    title: 'Data de Emissão',
    format: GridView.DataTypes.Date,
  },
  { key: 'vlrTotal', title: 'Valor', format: GridView.DataTypes.Money },
  { key: 'faturasConcat', title: 'Nr Fatura' },
];
// #endregion

export default function ConhecimentosFaturar({
  register,
  transaction,
  isActive,
  onOpenReport,
  onOpenPage,
}) {
  // #region Inicializadores
  const gridViewConhecimentos = useRef();
  const dropDownTipoEmbarque = useRef();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [filtros, setFiltros] = useState({ flgFaturados: 'N' });
  const [conhecimentosData, setConhecimentosData] = useState({});
  const [detalhes, setDetalhes] = useState({
    quantidadeTotal: 0,
    valorTotal: 0,
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [modalShow, setModalShow] = useState({ show: false });
  // #endregion

  // #region FUNÇÕES PADRÕES
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const obj = {
      nrSeqPessoaCli: filtros.nrSeqPessoaCli,
      nrSeqEmpresa: filtros.nrSeqEmpresa,
      nrSeqPessoaRem: filtros.nrSeqPessoaRem,
      nrSeqPessoaDes: filtros.nrSeqPessoaDes,
      dtEmissaoInicial: filtros.dtEmissaoInicial,
      dtEmissaoFinal: filtros.dtEmissaoFinal,
      flgFaturados: filtros.flgFaturados,
      tipoEmbarqueInts: filtros.tipoEmbarqueInts,
    };

    const {
      data,
      pagination,
    } = await getColetaEmbarquePaginedFaturamentoPendente(params ?? obj);

    if (gridViewConhecimentos?.current)
      gridViewConhecimentos.current.setDataSource(data, pagination);

    if (data.length > 0) {
      const vlrTotal = data.reduce((a, v) => a + v.vlrTotal, 0);

      setDetalhes({
        quantidadeTotal: pagination.totalRows,
        valorTotal: vlrTotal,
      });

      setConhecimentosData(data);
    }

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ConhecimentosFaturar/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printConhecimentoFaturas(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  // #endregion

  // #region FUNÇÕES BOTÕES
  const verificaSeTemFaturamenteEfetuado = (arrayDeObjetos) => {
    let temFaturado = false;

    for (let i = 0; i < arrayDeObjetos.length; i += 1) {
      if (arrayDeObjetos[i].faturas.length > 0) {
        temFaturado = true;
        break;
      }
    }

    return temFaturado;
  };

  const verificaSeTemClienteDiferente = (arrayDeObjetos) => {
    let temClienteDiferente = false;

    const conjuntoNrSeq = new Set();
    conjuntoNrSeq.add(arrayDeObjetos[0].nrSeqPessoaCli);

    for (let i = 1; i < arrayDeObjetos.length; i += 1) {
      if (!conjuntoNrSeq.has(arrayDeObjetos[i].nrSeqPessoaCli)) {
        temClienteDiferente = true;
        break;
      }
    }

    return temClienteDiferente;
  };

  const onGerarFaturamentoAcerto = async () => {
    setLoading(true);

    const selecteds = gridViewConhecimentos.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const nrSeqsColetas = selecteds.map((item) =>
        item.find((prop) => prop.key === 'nrSeqColetaEmbarque')
      );

      const coletasLista = [];
      for (let i = 0; i < selecteds.length; i += 1) {
        const coleta = conhecimentosData.find(
          (item) => item.nrSeqColetaEmbarque === nrSeqsColetas[i].value
        );

        coletasLista.push(coleta);
      }

      const temNrFatura = verificaSeTemFaturamenteEfetuado(coletasLista);
      const temNrSeqDiferente = verificaSeTemClienteDiferente(coletasLista);

      if (temNrFatura) {
        onSetMessage(
          ResponseStatus.Warning,
          'Alguns conhecimentos selecionados já foram faturados.'
        );
      } else if (temNrSeqDiferente) {
        onSetMessage(
          ResponseStatus.Warning,
          'Os conhecimentos selecionados tem clientes diferentes.'
        );
      } else {
        onOpenMaintenance({ coletasLista, cliente: coletasLista[0].cliente });
      }
    } else {
      onSetMessage(
        ResponseStatus.Warning,
        'Selecionar ao menos um Conhecimento.'
      );
    }

    setLoading(false);
  };

  const onGerarFaturamentoMassivo = async () => {
    setLoading(true);

    const selecteds = gridViewConhecimentos.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const nrSeqsColetas = selecteds.map((item) =>
        item.find((prop) => prop.key === 'nrSeqColetaEmbarque')
      );

      const coletasLista = [];
      for (let i = 0; i < selecteds.length; i += 1) {
        const coleta = conhecimentosData.find(
          (item) => item.nrSeqColetaEmbarque === nrSeqsColetas[i].value
        );

        coletasLista.push(coleta);
      }

      const temNrFatura = verificaSeTemFaturamenteEfetuado(coletasLista);

      if (temNrFatura) {
        onSetMessage(
          ResponseStatus.Warning,
          'Alguns conhecimentos selecionados já foram faturados.'
        );
      } else {
        setModalShow({ show: true, coletasLista });
      }
    } else {
      onSetMessage(
        ResponseStatus.Warning,
        'Selecionar ao menos um Conhecimento.'
      );
    }

    setLoading(false);
  };
  // #endregion

  // #region FILTROS
  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchUnidadeOrigem = async (e) => {
    const {
      status,
      message: msg,
      empresas: empresa,
    } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresa;
  };

  const onSearchRemetente = async (e) => {
    const {
      status,
      message: msg,
      data: remetentes,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'REM',
    });
    if (msg) onSetMessage(status, msg);
    return remetentes;
  };

  const onSearchDestinatario = async (e) => {
    const {
      status,
      message: msg,
      data: destinatarios,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'DES',
    });
    if (msg) onSetMessage(status, msg);
    return destinatarios;
  };

  const onSearchTipoEmbarque = async () => {
    const {
      status,
      message: msg,
      data: tiposEmbarques,
    } = await getTipoEmbarqueList();

    if (msg) {
      onSetMessage(status, msg);
    }

    return tiposEmbarques;
  };

  useEffect(() => {
    dropDownTipoEmbarque.current?.loadDataSource(onSearchTipoEmbarque);

    if (Array.isArray(register)) {
      if (register.length > 0) {
        search({
          nrSeqsColetaEmbarque: register,
        });
      }
    }
  }, []);

  const jaFaturados = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];
  // #endregion

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Conhecimentos a Faturar'
      loading={loading}
      transaction={transaction}
      onSearch={() => search()}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Gerar Faturamento / Acerto'
          onClick={() => onGerarFaturamentoAcerto()}
        />
        <ToolbarButtons.Button
          text='Gerar Faturamento Massivo'
          onClick={() => onGerarFaturamentoMassivo()}
        />
      </ToolbarButtons>

      {/* FILTROS */}
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            {/* Linha 1 */}
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Cliente:'
                  searchDataSource={onSearchCliente}
                  selectedItem={filtros.cliente}
                  onSelectItem={(pessoaCli) => {
                    setFiltros({
                      ...filtros,
                      cliente: pessoaCli,
                      nrSeqPessoaCli: pessoaCli.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col'>
                <Autocomplete
                  label='Unidade Origem:'
                  searchDataSource={onSearchUnidadeOrigem}
                  selectedItem={filtros.unidadeOrigem}
                  onSelectItem={(unidadeOrigem) => {
                    setFiltros({
                      ...filtros,
                      unidadeOrigem,
                      nrSeqEmpresa: unidadeOrigem.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>

            {/* Linha 2 */}
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Remetente:'
                  searchDataSource={onSearchRemetente}
                  selectedItem={filtros.remetente}
                  onSelectItem={(remetente) => {
                    setFiltros({
                      ...filtros,
                      remetente,
                      nrSeqPessoaRem: remetente.nrSeqPessoa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col'>
                <Autocomplete
                  label='Destinatário:'
                  searchDataSource={onSearchDestinatario}
                  selectedItem={filtros.destinatario}
                  onSelectItem={(destinatario) => {
                    setFiltros({
                      ...filtros,
                      destinatario,
                      nrSeqPessoaDes: destinatario.nrSeqPessoa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>

            {/* Linha 3 */}
            <div className='row mb-3'>
              <div className='col-6'>
                <DropdownMulti
                  label='Embarque:'
                  ref={dropDownTipoEmbarque}
                  dataSourcePropertyText='noTipoEmbarque'
                  dataSourcePropertyValue='nrSeqTipoEmbarque'
                  selectedItems={selectedItems.tipoEmbarqueInts ?? []}
                  onSelectItem={(tipoEmbarqueInts) => {
                    setSelectedItems({ ...selectedItems, tipoEmbarqueInts });
                    setFiltros({
                      ...filtros,
                      tipoEmbarqueInts: tipoEmbarqueInts?.map(
                        (p) => p.nrSeqTipoEmbarque
                      ),
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Emitidos de:'
                  text={filtros.dtEmissaoInicial}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtEmissaoInicial) => {
                    setFiltros({ ...filtros, dtEmissaoInicial });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Emitidos até:'
                  text={filtros.dtEmissaoFinal}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtEmissaoFinal) => {
                    setFiltros({ ...filtros, dtEmissaoFinal });
                  }}
                />
              </div>
              <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Já Faturados?'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.flgFaturados}
                  buttons={jaFaturados}
                  onChange={(flgFaturados) => {
                    setFiltros({
                      ...filtros,
                      flgFaturados,
                    });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      {/* DETALHES */}
      <div className='col-sm-12'>
        <div className='alert alert-info fs-8' role='alert'>
          <div className='row'>
            <div className='col float-right'>
              Quantidade Total: {detalhes?.quantidadeTotal}
            </div>
            <div className='col float-right'>
              Valor Total: R${detalhes?.valorTotal.toFixed(2)}
            </div>
          </div>
        </div>
      </div>

      {/* CONHECIMENTOS GRID */}
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Conhecimentos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='col'>
              <GridView
                canControlVisibility
                ref={gridViewConhecimentos}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsConhecimentos}
                onColumnSort={onColumnSort}
                onPageSizeChange={onPageSizeChange}
                enableExcelExport
                onPageChange={onPageChange}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <ModalGerarFaturamentoMassivo
        show={modalShow.show}
        onClose={() => setModalShow({ show: false })}
        coletasLista={modalShow.coletasLista}
      />
    </CSDSelPage>
  );
}
