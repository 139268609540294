import { getTipoDocumentoPessoaAutoComplete } from 'core/services/SEG';
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Autocomplete,
  Button,
  DatePicker,
  FileUpload,
  GridView,
  Textarea,
  Textbox,
  Panel,
} from 'ui/components';
import {
  Theme,
  ResponseStatus,
  BootstrapSizes,
  ColumnTypes,
  ColumnDataTypes,
  StatusGrid,
} from 'ui/Helpers/enums';
import { JustifyContent, MimeTypes, toBase64 } from 'ui/Helpers/utils';
// @ts-expect-error
import { GridviewColumns } from 'ui/Helpers/interfaces.ts';
import { IColetaConsignatario } from 'core/interfaces/FRO';
import { IPessoaDocumento } from 'core/interfaces/SEG';

interface IDocumentsPanelProps {
  consignatarioData: IColetaConsignatario;
  setConsignatarioData: (e: Object) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
}

interface IDocumentData extends IPessoaDocumento {
  status?: StatusGrid;
  flgExibeDownload?: boolean;
  importedFile?: any;
}

const DocumentsPanel = forwardRef(
  (
    {
      consignatarioData,
      setConsignatarioData,
      onSetMessage,
    }: IDocumentsPanelProps,
    ref
  ) => {
    const gridView = useRef<any>();

    const [documentData, setDocumentData] = useState<IDocumentData>({});
    const [importedFile, setImportedFile] = useState<any>([]);

    const fillGridView = () => {
      if (
        gridView &&
        gridView.current &&
        consignatarioData.consignatarioDocumentos &&
        consignatarioData.consignatarioDocumentos.length > 0
      ) {
        const gridList = consignatarioData.consignatarioDocumentos.map(
          (item) => {
            if (item.noImagem) {
              item.flgExibeDownload = true;
            } else {
              item.flgExibeDownload = false;
            }
            return item;
          }
        );
        gridView.current.setDataSource(gridList);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setDocumentData({ obs: '' });
        gridView?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaDocsGrid = gridView?.current?.getDataSource() ?? [];
        return listaDocsGrid;
      },
    }));

    const onSearchTipoDocumentoPessoa = async (e: any) => {
      const res = await getTipoDocumentoPessoaAutoComplete({
        noTipoDocumentoPessoa: e,
        flgConsignatario: true,
      });

      return res.data;
    };

    const onClickAddDocumento = async () => {
      let validadeDeAcordo = true;

      // #region validação se controla validade
      const controlaDtValidade =
        documentData.tipoDocumentoPessoa &&
        documentData.tipoDocumentoPessoa?.flgControlaValidade;

      if (controlaDtValidade) {
        validadeDeAcordo =
          !!documentData.dtIniValidade && !!documentData.dtFimValidade;
      }

      // #endregion

      if (
        documentData.tipoDocumentoPessoa &&
        documentData.nrDocumento &&
        validadeDeAcordo
      ) {
        const obj = {
          ...documentData,
          status: documentData.status ?? StatusGrid.Inserir,
          flgExibeDownload: false,
        };

        if (obj.status === StatusGrid.Inserir && importedFile.length > 0) {
          const noImagemBase64 = await toBase64(importedFile[0]);

          const noImagem = String(noImagemBase64).substring(
            String(noImagemBase64).indexOf(',') + 1
          );

          obj.noImagem = noImagem;
          obj.tipo = importedFile[0].type;
          obj.tamanho = String(importedFile[0].size);
          obj.flgExibeDownload = true;
          obj.file = importedFile;
        }

        const list = gridView.current.getDataSource();
        list.push(obj);

        if (gridView && gridView.current) gridView.current.setDataSource(list);

        setConsignatarioData({
          ...consignatarioData,
          consignatarioDocumentos: list,
        });
        setImportedFile([]);
        setDocumentData({ obs: '' });
      } else if (!documentData.nrDocumento) {
        onSetMessage(ResponseStatus.Error, 'Informe o Número do Documento');
      } else if (!documentData.tipoDocumentoPessoa) {
        onSetMessage(ResponseStatus.Error, 'Informe o Tipo Documento');
      } else if (
        documentData.tipoDocumentoPessoa?.flgControlaValidade &&
        (!documentData.dtIniValidade || !documentData.dtFimValidade)
      ) {
        onSetMessage(
          ResponseStatus.Error,
          'Informe as Datas de Inicio e Fim da Validade'
        );
      }
    };

    const onClickRemoveDocumento = async (e: any) => {
      const list = gridView.current.getDataSource();

      list.filter((el: any) => el === e)[0].status = StatusGrid.Remover;

      if (gridView && gridView.current) gridView.current.setDataSource(list);
    };

    const onClickDownload = async (e: { tipo: any; noImagem: string }) => {
      const linkSource = `data:${e.tipo};base64, ${e.noImagem}`;

      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = e.tipoDocumentoPessoa.noTipoDocumentoPessoa;
      downloadLink.click();
    };

    const onClickBaixarTodos = async () => {
      const list = gridView.current.getDataSource();

      list.forEach((e: { noImagem: string; status: StatusGrid }) => {
        if (e.noImagem && e.status !== StatusGrid.Remover) {
          const linkSource = `data:${e.tipo};base64, ${e.noImagem}`;

          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = e.tipoDocumentoPessoa.noTipoDocumentoPessoa;
          downloadLink.click();
        }
      });
    };

    const columns: Array<GridviewColumns> = [
      {
        key: 'tipoDocumentoPessoa.noTipoDocumentoPessoa',
        title: 'Tipo',
        sortable: false,
      },
      { key: 'nrDocumento', title: 'Número', sortable: false },
      {
        key: 'dtIniValidade',
        title: 'Inicio da Vigência',
        sortable: false,
        format: ColumnDataTypes.Date,
      },
      {
        key: 'dtFimValidade',
        title: 'Fim da Vigência',
        sortable: false,
        format: ColumnDataTypes.Date,
      },
      {
        key: 'obs',
        title: 'Observação',
        sortable: false,
      },
      {
        key: 'nrSeqPessoaDocumento_btnDownload',
        type: ColumnTypes.Button,
        title: 'Baixar',
        onClick: (e: any) => onClickDownload(e),
        theme: Theme.Success,
        icon: 'download',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Download',
        tooltipDirection: 'bottom',
        visibleDynamic: 'flgExibeDownload',
      },
      {
        key: 'nrSeqPessoaDocumento_btnExcluir',
        type: ColumnTypes.Button,
        title: 'Excluir',
        onClick: (e: any) => onClickRemoveDocumento(e),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header
            title='Documentos Consignatário'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Número do Documento'
                  text={documentData.nrDocumento}
                  maxLength={20}
                  onChangedValue={(nrDocumento: string) =>
                    setDocumentData({ ...documentData, nrDocumento })
                  }
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Tipo Documento'
                  searchDataSource={onSearchTipoDocumentoPessoa}
                  selectedItem={documentData.tipoDocumentoPessoa}
                  onSelectItem={(tipoDocumentoPessoa: any) =>
                    setDocumentData({
                      ...documentData,
                      tipoDocumentoPessoa,
                      nrSeqTipoDocumentoPessoa:
                        tipoDocumentoPessoa.nrSeqTipoDocumentoPessoa,
                    })
                  }
                  dataSourceTextProperty='noTipoDocumentoPessoa'
                />
              </div>
              {/* Perido de Validade */}
              {documentData.tipoDocumentoPessoa?.flgControlaValidade && (
                <>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <DatePicker
                      label='Inicio da Vigência'
                      text={documentData.dtIniValidade}
                      onChange={(dtIniValidade: string) =>
                        setDocumentData({ ...documentData, dtIniValidade })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <DatePicker
                      label='Fim da Vigência'
                      text={documentData.dtFimValidade}
                      onChange={(dtFimValidade: string) =>
                        setDocumentData({ ...documentData, dtFimValidade })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            {/* Upload de Imagem */}
            {documentData.tipoDocumentoPessoa?.flgAceitaImg && (
              <div className='row mb-3'>
                <div className='col'>
                  <FileUpload
                    files={importedFile}
                    onChange={(files: any) => {
                      setImportedFile(files);
                      setDocumentData({ ...documentData, importedFile: files });
                    }}
                    allowedMimeTypes={[
                      MimeTypes.Types.Image,
                      MimeTypes.Types.PDF,
                    ]}
                    maxFiles={1}
                    dropzone
                  />
                </div>
              </div>
            )}
            <div className='row mb-3'>
              <div className='col-10'>
                {/* @ts-expect-error */}
                <Textarea
                  label='Observações'
                  text={documentData.obs}
                  maxLength={200}
                  onChangedValue={(obs: string) =>
                    setDocumentData({ ...documentData, obs })
                  }
                />
              </div>
              <div className='col d-flex align-items-end'>
                <Button
                  onClick={onClickAddDocumento}
                  outline
                  theme={Theme.Success}
                  text='Adicionar'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  // @ts-expect-error
                  dataColumns={columns}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
            {((consignatarioData.consignatarioDocumentos &&
              consignatarioData.consignatarioDocumentos?.length > 0) ||
              (gridView &&
                gridView.current &&
                gridView.current
                  .getDataSource()
                  .findIndex(
                    (el: { flgExibeDownload: boolean; status: StatusGrid }) =>
                      el.flgExibeDownload === true &&
                      el.status !== StatusGrid.Remover
                  ) !== -1)) && (
              <div className='row'>
                <div className='col d-flex justify-content-end'>
                  <Button
                    onClick={onClickBaixarTodos}
                    outline
                    theme={Theme.Success}
                    text='Baixar Todos'
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </div>
    );
  }
);

export default DocumentsPanel;
