export default class ProdutoCor {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqProdutoCor = jsonObject.nrSeqProdutoCor;
    this.cdProdutoCor = jsonObject.cdProdutoCor;
    this.noProdutoCor = jsonObject.noProdutoCor;
  }

  toJson = () => JSON.stringify(this);
}
