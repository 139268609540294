import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  BootstrapSizes,
  Theme,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getOrdemServicoPagined,
  deleteAllOrdemServico,
  printOrdemServicoRelatorio,
  excelOrdemServico,
} from 'core/services/FIN/ordemServico';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Panel,
  ToolbarButtons,
  RadioButton,
} from 'ui/components';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getPocPocoAutoComplete } from 'core/services/POC/pocPoco';
import { getPocOrcamentoAutoComplete } from 'core/services/POC/pocOrcamento';
import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';
import { getPocOrcamentoStatusAutoComplete } from 'core/services/POC/pocOrcamentoStatus.tsx';

const columns = [
  { key: 'nrSeqOrdemServico', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdOrdemServico', title: 'Código' },
  { key: 'noCliente', title: 'Cliente' },
  { key: 'pocOrcamento.noLocalObra', title: 'Local da Obra' },
  { key: 'pocOrcamento.cdProposta', title: 'Código Orçamento' },
  { key: 'dtEncerrado', title: 'Encerrado', format: GridView.DataTypes.Date },
  { key: 'status', title: 'Status' },
  { key: 'pocTipoOrcamento.noPocTipoOrcamento', title: 'Tipo Orçamento' },
  { key: 'flgDIP', title: 'DIP' },
];

export default function OrdemServico({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    typeStatus: 'All',
    typeDIP: 'All',
    typeTipoOrcamento: 'All',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const {
      nrSeqPessoaCli,
      nrSeqPocOrcamento,
      nrSeqPocTipoOrcamento,
      nrSeqPocPoco,
      dtEmissaoInicial,
      dtEmissaoFinal,
      dtEncerramentoInicial,
      dtEncerramentoFinal,
      typeStatus,
      typeDIP,
      typeTipoOrcamento,
      nrSeqPocOrcamentoStatus,
    } = filtros;

    const { data, pagination } = await getOrdemServicoPagined(
      params ?? {
        nrSeqPessoaCli,
        nrSeqPocOrcamento,
        nrSeqPocTipoOrcamento,
        nrSeqPocPoco,
        dtEmissaoInicial,
        dtEmissaoFinal,
        dtEncerramentoInicial,
        dtEncerramentoFinal,
        typeStatus,
        typeDIP,
        typeTipoOrcamento,
        nrSeqPocOrcamentoStatus,
        flgOrdemServico: true,
      }
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getOrdemServicoPagined({
        flgOrdemServico: true,
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'OrdemServico/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqOrdemServico = sourceRow.find(
      (e) => e.key === 'nrSeqOrdemServico'
    );
    onOpenMaintenance(nrSeqOrdemServico.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printOrdemServicoRelatorio(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const obj = { ...filtros };

    const value = await excelOrdemServico(obj);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `OrdemDeServico.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllOrdemServico(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchPessoaCli = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return clientes;
  };

  const onSearchPoco = async (e) => {
    const { status, message: msg, data: pocos } = await getPocPocoAutoComplete({
      cdPocPoco: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return pocos;
  };

  const onSearchPocOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: orcamentos,
    } = await getPocOrcamentoAutoComplete({
      cdProposta: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return orcamentos;
  };

  const onSearchPocTipoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: orcamentos,
    } = await getPocTipoOrcamentoAutoComplete({
      noPocTipoOrcamento: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return orcamentos;
  };
  const onSearchPocOrcamentoStatus = async (e) => {
    const {
      status,
      message: msg,
      data: statusOrcamento,
    } = await getPocOrcamentoStatusAutoComplete({
      noPocOrcamentosStatus: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return statusOrcamento;
  };

  const typeStatus = [
    { text: 'Todos', value: 'All' },
    { text: 'Atendida', value: 'True' },
    { text: 'Não atendida', value: 'False' },
  ];

  const typeDIP = [
    { text: 'Todos', value: 'All' },
    { text: 'DIP', value: 'True' },
  ];
  const typeTipoOrcamento = [
    { text: 'Todos', value: 'All' },
    { text: 'Manutenção', value: 'M' },
    { text: 'Perfuração', value: 'P' },
  ];

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      title='Seleção de Ordem de Serviço'
      loading={loading}
      onSearch={() => search()}
      // onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel('Padrao')}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-3'>
              <Autocomplete
                label='Cliente'
                searchDataSource={onSearchPessoaCli}
                selectedItem={filtros.cliente}
                onSelectItem={(cliente) =>
                  setFiltros({
                    ...filtros,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  })
                }
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-4'>
              <Autocomplete
                label='Orçamento'
                searchDataSource={onSearchPocOrcamento}
                selectedItem={filtros.orcamento}
                onSelectItem={(orcamento) =>
                  setFiltros({
                    ...filtros,
                    orcamento,
                    nrSeqPocOrcamento: orcamento.nrSeqPocOrcamento,
                  })
                }
                dataSourceTextProperty='cdProposta'
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Inicial'
                text={filtros.dtEmissaoInicial}
                mask={MaskTypes.Date}
                onChange={(dtEmissaoInicial) =>
                  setFiltros({
                    ...filtros,
                    dtEmissaoInicial,
                  })
                }
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Final'
                text={filtros.dtEmissaoFinal}
                mask={MaskTypes.Date}
                onChange={(dtEmissaoFinal) =>
                  setFiltros({
                    ...filtros,
                    dtEmissaoFinal,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-7'>
              <Autocomplete
                label='Poço'
                searchDataSource={onSearchPoco}
                selectedItem={filtros.poco}
                onSelectItem={(poco) => {
                  setFiltros({
                    ...filtros,
                    poco,
                    nrSeqPocPoco: poco.nrSeqPocPoco,
                  });
                }}
                dataSourceTextPropertyLabel='Código Poço: '
                dataSourceTextProperty='cdPocPoco'
                dataSourceTextPropertyLabel2='Cidade: '
                dataSourceTextProperty2='noCidadePoco'
                dataSourceTextPropertyLabel3='Profundidade: '
                dataSourceTextProperty3='nrProfundidade'
                dataSourceTextPropertyLabel4='Cliente: '
                dataSourceTextProperty4='noCliente'
              />
            </div>
            <div className='col-3'>
              <RadioButton
                label='Status'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.typeStatus}
                buttons={typeStatus}
                onChange={(obj) =>
                  setFiltros({
                    ...filtros,
                    typeStatus: obj,
                  })
                }
              />
            </div>
            <div className='col-2'>
              <RadioButton
                label='Documento'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.typeDIP}
                buttons={typeDIP}
                onChange={(obj) =>
                  setFiltros({
                    ...filtros,
                    typeDIP: obj,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-7'>
              <Autocomplete
                label='Tipo Orçamento'
                searchDataSource={onSearchPocTipoOrcamento}
                selectedItem={filtros.pocTipoOrcamento}
                onSelectItem={(pocTipoOrcamento) =>
                  setFiltros({
                    ...filtros,
                    pocTipoOrcamento,
                    nrSeqPocTipoOrcamento:
                      pocTipoOrcamento.nrSeqPocTipoOrcamento,
                  })
                }
                dataSourceTextProperty='noPocTipoOrcamento'
              />
            </div>
            <div className='col-3'>
              <RadioButton
                label='Tipo Orçamento'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.typeTipoOrcamento}
                buttons={typeTipoOrcamento}
                onChange={(obj) =>
                  setFiltros({
                    ...filtros,
                    typeTipoOrcamento: obj,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-7'>
              <Autocomplete
                label='Status Orçamento'
                searchDataSource={onSearchPocOrcamentoStatus}
                selectedItem={filtros.pocOrcamentoStatus}
                onSelectItem={(pocOrcamentoStatus) =>
                  setFiltros({
                    ...filtros,
                    nrSeqPocOrcamentoStatus:
                      pocOrcamentoStatus.nrSeqPocOrcamentoStatus,
                  })
                }
                dataSourceTextProperty='noPocOrcamentoStatus'
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Encerramento Inicial'
                text={filtros.dtEncerramentoInicial}
                mask={MaskTypes.Date}
                onChange={(dtEncerramentoInicial) =>
                  setFiltros({
                    ...filtros,
                    dtEncerramentoInicial,
                  })
                }
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Encerramento Final'
                text={filtros.dtEncerramentoFinal}
                mask={MaskTypes.Date}
                onChange={(dtEncerramentoFinal) =>
                  setFiltros({
                    ...filtros,
                    dtEncerramentoFinal,
                  })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              onRowDoubleClick={onRowDoubleClick}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
