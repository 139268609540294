import Usuario from 'core/models/SEG/usuario';
import Pessoa from 'core/models/SEG/pessoa';
import PocOrcamento from 'core/models/POC/pocOrcamento.tsx';
import PocTipoOrcamento from 'core/models/POC/pocTipoOrcamento';
import OrdemServicoProd from 'core/models/FIN/ordemServicoProd';
import OrdemServicoTecnico from 'core/models/FIN/ordemServicoTecnico';
import Empresa from 'core/models/SEG/empresa';

export default class OrdemServico {
  constructor(jsonObject = {}) {
    this.nrSeqOrdemServico = jsonObject.nrSeqOrdemServico;
    this.cdOrdemServico = jsonObject.cdOrdemServico;
    this.cdCompletoOrdemServico = jsonObject.cdCompletoOrdemServico;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.dtEmissao = jsonObject.dtEmissao;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.flgDIP = jsonObject.flgDIP;
    this.flgRomaneio = jsonObject.flgRomaneio;
    this.flgOrdemServico = jsonObject.flgOrdemServico;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.cdOrcamento = jsonObject.cdOrcamento;
    this.noUsuario = jsonObject.noUsuario;
    this.noCliente = jsonObject.noCliente;
    this.noObservacao = jsonObject.noObservacao;
    this.noObservacaoEndereco = jsonObject.noObservacaoEndereco;
    this.noProfundidade = jsonObject.noProfundidade;
    this.noDiametro = jsonObject.noDiametro;
    this.noPoco = jsonObject.noPoco;
    this.usuarioCad = new Usuario(jsonObject.usuarioCad ?? {});
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.pessoaEmp = new Pessoa(jsonObject.pessoaEmp ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.pocOrcamento = new PocOrcamento(jsonObject.pocOrcamento ?? {});
    this.pocTipoOrcamento = new PocTipoOrcamento(
      jsonObject.pocTipoOrcamento ?? {}
    );
    this.dtEncerrado = jsonObject.dtEncerrado;
    this.dtAtendimento = jsonObject.dtAtendimento;
    this.status = jsonObject.status;
    this.dtEmissaoInicial = jsonObject.dtEmissaoInicial;
    this.dtEncerramentoFinal = jsonObject.dtEncerramentoFinal;
    this.dtEncerramentoInicial = jsonObject.dtEncerramentoInicial;
    this.dtEmissaoFinal = jsonObject.dtEmissaoFinal;
    this.typeStatus = jsonObject.typeStatus;
    this.typeDip = jsonObject.typeDip;
    this.ordemServicoProds = jsonObject.ordemServicoProds
      ? jsonObject.ordemServicoProds.map(
          (item) => new OrdemServicoProd(item ?? {})
        )
      : [];
    this.tecnicos = jsonObject.tecnicos
      ? jsonObject.tecnicos.map((item) => new OrdemServicoTecnico(item ?? {}))
      : [];
  }

  toJson = () => JSON.stringify(this);
}
