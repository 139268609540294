export default class PneuAlinhamento {
  constructor(jsonObject = {}) {
    this.nrSeqPneuAlinhamento = jsonObject.nrSeqPneuAlinhamento;
    this.nrSeqPneu = jsonObject.nrSeqPneu;
    this.dtAlinhamento = jsonObject.dtAlinhamento;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;

    // Instancias
    this.usuarioCad = jsonObject.usuarioCad;
    this.veiculo = jsonObject.veiculo;
  }
}
