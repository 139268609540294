import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  GridView,
  Panel,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getPneu, savePneu, deletePneu } from 'core/services/FRO/pneu';
import { getPneuVidaAutoComplete } from 'core/services/FRO/pneuVida';
import { getPneuSituacaoAutoComplete } from 'core/services/FRO/pneuSituacao';
import { getPneuModeloAutoComplete } from 'core/services/FRO/pneuModelo';
import { getParametro } from 'core/services/SEG/parametro';
import { getPneuRecapagemList } from 'core/services/FRO/pneuRecapagem';
import { getPneuSulcagemList } from 'core/services/FRO/pneuSulcagem';
import { getPneuCalibragemList } from 'core/services/FRO/pneuCalibragem';
import { getPneuAlinhamentoList } from 'core/services/FRO/pneuAlinhamento';
import { ModalEditarPneuVeiculo } from './ModalEditarPneuVeiculo.tsx';

export default function PneuItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ flgCdPneuAutomatico: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showModal, setShowModal] = useState({ show: false, data: {} });
  const gridView = useRef();
  const gridViewRecapagem = useRef();
  const gridViewSulcagem = useRef();
  const gridViewCalibragem = useRef();
  const gridViewAlinhamento = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const buscaParametroCdPneuAutomatico = async () => {
    const parametro = await getParametro('CODIGO_PNEU_AUTOMATICO', 1);

    if (parametro.noConteudo === 'S') return true;

    return false;
  };

  const onNew = async () => {
    setLoading(true);

    formSubmit.current.reset();

    const geraCdPneuAutomatico = await buscaParametroCdPneuAutomatico();

    setData({
      flgCdPneuAutomatico: geraCdPneuAutomatico,
      nrSulcoInicial: null,
      nrSulcoFinal: null,
      nrSulcoAtual: null,
    });

    if (gridView && gridView.current) gridView.current.setDataSource([]);

    if (gridViewSulcagem && gridViewSulcagem.current)
      gridViewSulcagem.current.setDataSource([]);

    if (gridViewCalibragem && gridViewCalibragem.current)
      gridViewCalibragem.current.setDataSource([]);

    setLoading(false);
  };

  const formatarPneuVeiculoS = (pneuVeiculoS) => {
    if (Array.isArray(pneuVeiculoS)) {
      pneuVeiculoS.forEach((item) => {
        item.flgExibeBtn = false;

        if (item.dtFim === null) {
          item.status = 'Sim';
          item.kmFim = '';
          item.flgExibeBtn = true;
        } else {
          item.status = 'Não';
        }

        if (item.kmAcumulado === null || item.kmAcumulado <= 0) {
          item.kmAcumulado = '';
        }

        if (item.nrSeqPneuMotivoTroca === null) {
          item.pneuMotivoTroca = {
            nrSeqPneuMotivoTroca: null,
            noPneuMotivoTroca: '',
          };
        }

        item.tracao = 'Não';
        if (item.eixo?.flgTracao) {
          item.tracao = 'Sim';
        }
      });
    }
    return pneuVeiculoS;
  };

  const getSulcagemAtual = (sulcagemList, sulcoInicial = 0) => {
    let sulcoAtual = Number.MAX_VALUE;

    if (sulcagemList.length > 0) {
      sulcagemList.forEach((el) => {
        if (el.nrSulco < sulcoAtual) sulcoAtual = el.nrSulco;
      });
    } else {
      sulcoAtual = sulcoInicial;
    }

    return sulcoAtual;
  };

  const validaPlacas = (veiculos) => {
    const placasList = [];

    if (veiculos.length > 0) {
      veiculos.forEach((item) => {
        if (item.veiculo === null) {
          item.veiculo = {
            placa: 'Não informado.',
          };
        }

        placasList.push(item);
      });
    }

    return placasList;
  };

  const validaRecapagens = (recapagens) => {
    const recapagensFormatadas = recapagens.map((item) => {
      if (!item.manutencaoVeiculoOS) {
        item.manutencaoVeiculoOS = {
          vlrFechamentoOS: 0,
          cdManutencaoVeiculoOS: '',
        };
      }

      if (!item.usuarioCad) {
        item.usuarioCad = {
          noPessoa: '',
        };
      }

      return item;
    });

    return recapagensFormatadas;
  };

  const validaAlinhamentos = (alinhamentos) => {
    const alinhamentosFormatados = alinhamentos.map((item) => {
      if (!item.usuarioCad) {
        item.usuarioCad = {
          noPessoa: '',
        };
      }

      return item;
    });

    return alinhamentosFormatados;
  };

  const getValorPorKmRodado = (
    vinculos = [],
    vlrCompra = 0,
    recapagens = []
  ) => {
    let vlrKmRodado = 0;
    let kmAcumulado = 0;

    if (vinculos.length > 0) {
      vinculos.forEach((item) => {
        if (item.kmAcumulado > 0) kmAcumulado += item.kmAcumulado;
      });
    }

    if (recapagens.length > 0) {
      recapagens.forEach((item) => {
        if (item.manutencaoVeiculoOS?.vlrFechamentoOS > 0)
          vlrCompra += item.manutencaoVeiculoOS?.vlrFechamentoOS;
      });
    }

    if (vlrCompra > 0 && kmAcumulado > 0) {
      vlrKmRodado = vlrCompra / kmAcumulado;
    }

    return vlrKmRodado;
  };

  const load = useCallback(async () => {
    const geraCdPneuAutomatico = await buscaParametroCdPneuAutomatico();

    formSubmit.current.reset();

    if (registryKey > 0) {
      setLoading(true);
      const obj = await getPneu(registryKey);

      let pneuVeiculoS = [];
      if (obj.pneuVeiculoS)
        pneuVeiculoS = formatarPneuVeiculoS(obj.pneuVeiculoS);

      const { data: recapagemData } = await getPneuRecapagemList({
        nrSeqPneu: registryKey,
      });
      const recapagemList = validaRecapagens(recapagemData);

      const { data: sulcagemData } = await getPneuSulcagemList({
        nrSeqPneu: registryKey,
      });
      const sulcagemList = validaPlacas(sulcagemData);
      const sulcoAtual = getSulcagemAtual(sulcagemList, obj.nrSulcoInicial);

      const { data: calibragemData } = await getPneuCalibragemList({
        nrSeqPneu: registryKey,
      });
      const calibragemList = validaPlacas(calibragemData);

      const { data: alinhamentoData } = await getPneuAlinhamentoList({
        nrSeqPneu: registryKey,
      });
      const alinhamentosList = validaAlinhamentos(alinhamentoData);

      const vlrKmRodado = getValorPorKmRodado(
        pneuVeiculoS,
        obj.vlrCompra,
        recapagemList
      );

      setData({
        ...obj,
        pneuVeiculoS,
        nrSulcoAtual: sulcoAtual,
        flgCdPneuAutomatico: geraCdPneuAutomatico,
        recapagens: recapagemList,
        sulcagens: sulcagemList,
        calibragens: calibragemList,
        alinhamentos: alinhamentosList,
        vlrKmRodado,
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.pneuVeiculoS);

      if (gridViewRecapagem && gridViewRecapagem.current)
        gridViewRecapagem.current.setDataSource(recapagemList);

      if (gridViewSulcagem && gridViewSulcagem.current)
        gridViewSulcagem.current.setDataSource(sulcagemList);

      if (gridViewCalibragem && gridViewCalibragem.current)
        gridViewCalibragem.current.setDataSource(calibragemList);

      if (gridViewAlinhamento && gridViewAlinhamento.current)
        gridViewAlinhamento.current.setDataSource(alinhamentosList);

      setLoading(false);
    } else {
      setData({ flgCdPneuAutomatico: geraCdPneuAutomatico });

      if (gridView && gridView.current) gridView.current.setDataSource([]);
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const objSave = {
      nrSeqPneu: data.nrSeqPneu,
      cdPneu: data.cdPneu,
      nrOrdem: data.nrOrdem,
      kmAcumulada: data.kmAcumulada,
      numeroSerie: data.numeroSerie,
      dtCompra: data.dtCompra,
      nrSeqPneuSituacao: data.nrSeqPneuSituacao,
      nrSeqPneuModelo: data.nrSeqPneuModelo,
      nrSeqPneuVida: data.nrSeqPneuVida,
      nrSulcoInicial: data.nrSulcoInicial,
      nrSulcoFinal: data.nrSulcoFinal,
      dtTermino: data.dtTermino,
      nrDot: data.nrDot,
      nrSeqProduto: data.nrSeqProduto,
      nrSeqNf: data.nrSeqNf,
      vlrCompra: data.vlrCompra,
      kmInicial: data.kmInicial || 0,
      nrLacre: data.nrLacre,
    };

    const { status, message: msg } = await savePneu(objSave);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePneu(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPneuVida = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuVidaAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onSearchPneuSituacao = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuSituacaoAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onSearchPneuModelo = async (e) => {
    const {
      status,
      message: msg,
      pneuModelo,
    } = await getPneuModeloAutoComplete({ noSearchField: e });
    if (msg) onSetMessage(status, msg);
    return pneuModelo;
  };

  const onClickEditPneuVeiculo = (e, datasource) => {
    const pneuVeiculo = {
      pneuVeiculo: e,
      dataList: datasource,
      nrSeqPneu: e.nrSeqPneu,
      placa: e.veiculo.placa,
      dtInicioAlt: e.dtInicio,
      kmInicioAlt: e.kmInicio,
    };

    return setShowModal({ show: true, data: pneuVeiculo });
  };

  const columns = [
    {
      key: 'nrSeqPneu',
      visible: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'eixo.noEixo',
      title: 'Eixo',
    },
    {
      key: 'nrOrdem',
      title: 'Posição',
    },
    {
      key: 'dtInicio',
      title: 'Data de Início',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'kmInicio',
      title: 'Odomêtro de Início',
    },
    {
      key: 'dtFim',
      title: 'Data Final',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'kmFim',
      title: 'Odomêtro Final',
    },
    {
      key: 'kmAcumulado',
      title: 'KMs Rodado',
    },
    {
      key: 'status',
      title: 'Rodando',
    },
    {
      key: 'pneuMotivoTroca.noPneuMotivoTroca',
      title: 'Descrição',
    },
    {
      key: 'noObsMotivoTroca',
      title: 'Observações',
    },
    {
      key: 'nrSeqPneuVeiculo',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditPneuVeiculo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  const columnsRecapagens = [
    {
      key: 'dtRecapagem',
      title: 'Data de Registro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'pneuModeloRecapagem.noPneuModeloRecapagem',
      title: 'Modelo Recapagem',
    },
    {
      key: 'nomeBanda',
      title: 'Nome Banda',
    },
    {
      key: 'pneuVida.noPneuVida',
      title: 'Pneu Vida',
    },
    {
      key: 'manutencaoVeiculoOS.cdManutencaoVeiculoOS',
      title: 'Ordem de Serviço',
    },
    {
      key: 'manutencaoVeiculoOS.vlrFechamentoOS',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'usuarioCad.noPessoa',
      title: 'Cadastrado por',
    },
  ];

  const columnsSulcagens = [
    {
      key: 'dtRegistro',
      title: 'Data de Registro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqPneuSulcagem',
      visible: false,
    },
    {
      key: 'nrSulco',
      title: 'Sulco',
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'usuarioCad.noPessoa',
      title: 'Cadastrado por',
    },
  ];

  const columnsCalibragens = [
    {
      key: 'dtRegistro',
      title: 'Data de Registro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqPneuCalibragem',
      visible: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'usuarioCad.noPessoa',
      title: 'Cadastrado por',
    },
  ];

  const columnsAlinhamentos = [
    {
      key: 'dtAlinhamento',
      title: 'Data de Registro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqPneuAlinhamento',
      visible: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'usuarioCad.noPessoa',
      title: 'Cadastrado por',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Pneu'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      transaction={transaction}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.nrSeqPneu > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      {/* Linha 1 */}
      <div className='row mb-3'>
        <div className='col'>
          <Textbox
            maxLength={20}
            label='Código Pneu'
            text={data?.cdPneu}
            readOnly={data?.flgCdPneuAutomatico}
            onChangedValue={(cdPneu) => setData({ ...data, cdPneu })}
          />
        </div>

        <div className='col'>
          <Textbox
            maxLength={20}
            label='Numero Série'
            text={data?.numeroSerie}
            onChangedValue={(numeroSerie) => setData({ ...data, numeroSerie })}
          />
        </div>

        <div className='col'>
          <Textbox
            maxLength={10}
            label='Número Dot'
            text={data?.nrDot}
            onChangedValue={(nrDot) => setData({ ...data, nrDot })}
          />
        </div>

        <div className='col'>
          <Textbox
            label='Nr. Sulco Inicial'
            text={data?.nrSulcoInicial}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(nrSulcoInicial) =>
              setData({ ...data, nrSulcoInicial })
            }
          />
        </div>

        <div className='col'>
          <Textbox
            label='Sulco Final Máximo Permitido'
            text={data?.nrSulcoFinal}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(nrSulcoFinal) =>
              setData({ ...data, nrSulcoFinal })
            }
          />
        </div>

        <div className='col'>
          <Textbox
            label='Sulco Atual'
            readOnly
            text={data?.nrSulcoAtual}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(nrSulcoAtual) =>
              setData({ ...data, nrSulcoAtual })
            }
          />
        </div>
      </div>

      {/* Linha 2 */}
      <div className='row mb-3'>
        <div className='col-1'>
          <Textbox
            label='Km Inicial'
            readOnly={data?.nrSeqPneu}
            text={data.kmInicial}
            mask={MaskTypes.Integer}
            onChangedValue={(kmInicial) => setData({ ...data, kmInicial })}
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Km Acumulada'
            readOnly
            text={data.kmAcumulada}
            mask={MaskTypes.Integer}
            onChangedValue={(kmAcumulada) => setData({ ...data, kmAcumulada })}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pneu Vida'
            searchDataSource={onSearchPneuVida}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.PneuVida}
            nrseqTransactionToOpen='nrSeqPneuVida'
            selectedItem={data.pneuVida}
            onSelectItem={(pneuVida) => {
              setData({
                ...data,
                pneuVida,
                nrSeqPneuVida: pneuVida.nrSeqPneuVida,
              });
            }}
            dataSourceTextProperty='noPneuVida'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pneu Situação'
            searchDataSource={onSearchPneuSituacao}
            selectedItem={data.pneuSituacao}
            onSelectItem={(pneuSituacao) => {
              setData({
                ...data,
                pneuSituacao,
                nrSeqPneuSituacao: pneuSituacao.nrSeqPneuSituacao,
              });
            }}
            dataSourceTextProperty='noPneuSituacao'
          />
        </div>

        <div className='col'>
          <DatePicker
            label='Data Compra'
            text={data.dtCompra}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCompra) => setData({ ...data, dtCompra })}
          />
        </div>
        <div className='col mb'>
          <DatePicker
            label='Data Término'
            text={data.dtTermino}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtTermino) => setData({ ...data, dtTermino })}
          />
        </div>
      </div>

      {/* Linha 3 */}
      <div className='row mb-5'>
        <div className='col'>
          <Autocomplete
            label='Modelo'
            searchDataSource={onSearchPneuModelo}
            selectedItem={data.pneuModelo}
            onSelectItem={(pneuModelo) => {
              setData({
                ...data,
                pneuModelo,
                nrSeqPneuModelo: pneuModelo.nrSeqPneuModelo,
              });
            }}
            dataSourceTextProperty='noPneuModelo'
          />
        </div>
        <div className='col-2'>
          <Textbox
            maxLength={20}
            label='Valor Compra'
            text={data.vlrCompra}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrCompra) => setData({ ...data, vlrCompra })}
          />
        </div>
        <div className='col-2'>
          <Textbox label='Nota Fiscal' readOnly text={data?.nf?.nrForNf} />
        </div>
        <div className='col-2'>
          <Textbox
            maxLength={20}
            label='Valor por Km Rodado'
            readOnly
            text={data.vlrKmRodado}
            mask={MaskTypes.DecimalCustom}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Lacre'
            maxLength={20}
            text={data.nrLacre}
            onChangedValue={(nrLacre) => setData({ ...data, nrLacre })}
          />
        </div>
      </div>

      {/* Grids */}
      {data?.nrSeqPneu && data?.pneuVeiculoS.length > 0 && (
        <>
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Histórico de Vínculos'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='col-12 mb-3'>
                  {data?.pneuVeiculoS.length > 0 ? (
                    <GridView
                      ref={gridView}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  ) : (
                    <p>Sem histórico.</p>
                  )}
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Histórico de Recapagem'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='col-12 mb-3'>
                  {data?.pneuVeiculoS.length > 0 ? (
                    <GridView
                      ref={gridViewRecapagem}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsRecapagens}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  ) : (
                    <p>Sem histórico.</p>
                  )}
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='row mb-3'>
            <Panel>
              <Panel.Header title='Histórico do Sulco' theme={Theme.Primary} />
              <Panel.Body>
                <div className='col-12 mb-3'>
                  {data?.pneuVeiculoS.length > 0 ? (
                    <GridView
                      ref={gridViewSulcagem}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsSulcagens}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  ) : (
                    <p>Sem histórico.</p>
                  )}
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Histórico de Calibragem'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='col-12 mb-3'>
                  {data?.pneuVeiculoS.length > 0 ? (
                    <GridView
                      ref={gridViewCalibragem}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsCalibragens}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  ) : (
                    <p>Sem histórico.</p>
                  )}
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='row mb-3'>
            <Panel>
              <Panel.Header
                title='Histórico de Alinhamento'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='col-12 mb-3'>
                  {data?.pneuVeiculoS.length > 0 ? (
                    <GridView
                      ref={gridViewAlinhamento}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsAlinhamentos}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  ) : (
                    <p>Sem histórico.</p>
                  )}
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </>
      )}

      <ModalEditarPneuVeiculo
        show={showModal?.show}
        setLoading={setLoading}
        data={showModal?.data}
        onClose={async (isUpdated) => {
          setShowModal({ show: false, data: {} });

          if (isUpdated) await load();
        }}
      />
    </CSDManutPage>
  );
}
