import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus } from 'ui/Helpers/utils';
import {
  getEnergiaUnidadeConsumidoraPagined,
  deleteAllEnergiaUnidadeConsumidora,
  getEnergiaUnidadeConsumidoraAutoComplete,
} from 'core/services/ENE/energiaUnidadeConsumidora';
import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Panel,
  Switch,
  Textbox,
} from 'ui/components';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import { ColumnTypes, Theme } from 'ui/Helpers/enums';
import { getClienteAutoComplete } from 'core/services/FIN';
import { Cliente } from 'core/models/FIN';
import UnidadeConsumidora from 'core/models/ENE/energiaUnidadeConsumidora';
import { getEnergiaDistribuidoraAutoComplete } from 'core/services/ENE/energiaDistribuidora';
import EnergiaDistribuidora from 'core/models/ENE/energiaDistribuidora';
import { getEnergiaTipoTarifaAutoComplete } from 'core/services/ENE/energiaTipoTarifa';
import EnergiaTipoTarifa from 'core/models/ENE/energiaTipoTarifa';

const columns: GridviewColumns[] = [
  {
    key: 'nrSeqEnergiaUnidadeConsumidora',
    type: ColumnTypes.Checkbox,
  },
  { key: 'cliente.noPessoa', title: 'Titular', sortKey: 'noPessoa' },
  { key: 'nrUc', title: 'UC' },
  { key: 'cdEnergiaUnidadeConsumidora', title: 'Código' },
  { key: 'nrTensao', title: 'Tensão' },
  { key: 'nrTensaoMin', title: 'Tensão Min.' },
  { key: 'nrTensaoMax', title: 'Tensão Max.' },
  { key: 'nrClienteUc', title: 'Cod. Cliente' },
  { key: 'tipoFornecimentoExtenso', title: 'Tipo Fornecimento' },
  {
    key: 'tipoTarifa.noEnergiaTipoTarifa',
    title: 'Classificação',
    sortKey: 'noEnergiaTipoTarifa',
  },
  {
    key: 'distribuidora.noEnergiaDistribuidora',
    title: 'Distribuidora',
    sortKey: 'noEnergiaDistribuidora',
  },
  {
    key: 'flgGeradora',
    title: 'Geradora',
    sortable: false,
  },
];

export default function EnergiaUnidadeConsumidora({
  transaction,
  onOpenPage,
  isActive,
}: Page) {
  const gridView: any = useRef();

  const [filtros, setFiltros] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const onSetMessage = (status: number, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params: any = null): Promise<void> => {
    setLoading(true);

    setFiltros({ ...filtros, page: 1 });

    const { data, pagination } = await getEnergiaUnidadeConsumidoraPagined(
      params ?? filtros
    );

    if (gridView && gridView.current) {
      gridView.current.setDataSource(data, pagination);
    }

    setLoading(false);
  };

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);

      const { data, pagination } = await getEnergiaUnidadeConsumidoraPagined(
        {}
      );

      if (gridView && gridView.current) {
        gridView.current.setDataSource(data, pagination);
      }

      setLoading(false);
    };
    load();
  }, []);

  const onColumnSort = async (sortBy: string): Promise<void> => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page: number): Promise<void> => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey: number | null = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'EnergiaUnidadeConsumidora/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow: any[]): void => {
    const nrSeqEnergiaUnidadeConsumidora = sourceRow.find(
      (e) => e.key === 'nrSeqEnergiaUnidadeConsumidora'
    );
    onOpenMaintenance(nrSeqEnergiaUnidadeConsumidora.value);
  };

  const onDelete = async (): Promise<void> => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllEnergiaUnidadeConsumidora(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchUc = async (e: string): Promise<UnidadeConsumidora[]> => {
    const {
      status,
      message: msg,
      ucs,
    }: {
      status: number;
      message: string;
      ucs: UnidadeConsumidora[];
    } = await getEnergiaUnidadeConsumidoraAutoComplete({
      nrUc: e,
    });

    onSetMessage(status, msg);

    return ucs;
  };

  const onSearchCliente = async (e: string): Promise<Cliente[]> => {
    const {
      status,
      message: msg,
      clientes,
    }: {
      status: number;
      message: string;
      clientes: Cliente[];
    } = await getClienteAutoComplete({
      noPessoa: e,
    });

    onSetMessage(status, msg);

    return clientes;
  };

  const onSearchTipoTarifa = async (
    e: string
  ): Promise<EnergiaTipoTarifa[]> => {
    const {
      status,
      message: msg,
      tipoTarifas,
    }: {
      status: number;
      message: string;
      tipoTarifas: EnergiaTipoTarifa[];
    } = await getEnergiaTipoTarifaAutoComplete({
      cdEnergiaTipoTarifa: e,
    });

    onSetMessage(status, msg);

    return tipoTarifas;
  };

  const onSearchDistribuidora = async (
    e: string
  ): Promise<EnergiaDistribuidora[]> => {
    const {
      status,
      message: msg,
      distribuidoras,
    }: {
      status: number;
      message: string;
      distribuidoras: EnergiaDistribuidora[];
    } = await getEnergiaDistribuidoraAutoComplete({
      noEnergiaDistribuidora: e,
    });

    onSetMessage(status, msg);

    return distribuidoras;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      transaction={transaction}
      title='Seleção de Unidade Consumidora'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div>
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Filtros' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-3'>
                  <Autocomplete
                    label='UC'
                    searchDataSource={onSearchUc}
                    selectedItem={filtros.uc}
                    onSelectItem={(uc: UnidadeConsumidora) => {
                      setFiltros({
                        ...filtros,
                        uc,
                        nrUc: uc.nrUc,
                      });
                    }}
                    dataSourceTextProperty='nrUc'
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Titular'
                    searchDataSource={onSearchCliente}
                    selectedItem={filtros.cliente}
                    onSelectItem={(cliente: Cliente) => {
                      setFiltros({
                        ...filtros,
                        cliente,
                        nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-2'>
                  <Autocomplete
                    label='Classificação'
                    searchDataSource={onSearchTipoTarifa}
                    selectedItem={filtros.tipoTarifa}
                    onSelectItem={(tipoTarifa: EnergiaTipoTarifa) => {
                      setFiltros({
                        ...filtros,
                        tipoTarifa,
                        nrSeqEnergiaTipoTarifa:
                          tipoTarifa.nrSeqEnergiaTipoTarifa,
                      });
                    }}
                    dataSourceTextProperty='cdEnergiaTipoTarifa'
                  />
                </div>
                <div className='col-2'>
                  <Autocomplete
                    label='Distribuidora'
                    searchDataSource={onSearchDistribuidora}
                    selectedItem={filtros.distribuidora}
                    onSelectItem={(distribuidora: EnergiaDistribuidora) => {
                      setFiltros({
                        ...filtros,
                        distribuidora,
                        nrSeqEnergiaDistribuidora:
                          distribuidora.nrSeqEnergiaDistribuidora,
                      });
                    }}
                    dataSourceTextProperty='noEnergiaDistribuidora'
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Switch
                    formControl
                    label='Filtrar por Unidade Geradora'
                    checked={filtros.flgGeradora}
                    onChange={(value: boolean) => {
                      setFiltros({
                        ...filtros,
                        flgGeradora: value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Código'
                    text={filtros.cdEnergiaUnidadeConsumidora}
                    maxLength={10}
                    onChangedValue={(value: string) =>
                      setFiltros({
                        ...filtros,
                        cdEnergiaUnidadeConsumidora: value,
                      })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
