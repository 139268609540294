import PneuRecapagem from 'core/models/FRO/pneuRecapagem';
import api from '../api';

const baseURL = `/FRO/PneuRecapagem`;

export const getPneuRecapagemList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuRecapagem(json)) ?? [],
  };
};
