import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Textbox } from 'ui/components';
import {
  getTituloPagarTipoDocumento,
  saveTituloPagarTipoDocumento,
  deleteTituloPagarTipoDocumento,
} from 'core/services/FIN/tituloPagarTipoDocumento';

export default function TituloPagarTipoDocumentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getTituloPagarTipoDocumento(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTituloPagarTipoDocumento(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTituloPagarTipoDocumento(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo de Documento de Título a Pagar'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTituloPagarTipoDocumento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={20}
            label='Código'
            required
            text={data.cdTituloPagarTipoDocumento}
            onChangedValue={(cdTituloPagarTipoDocumento) =>
              setData({ ...data, cdTituloPagarTipoDocumento })
            }
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Nome'
            required
            text={data.noTituloPagarTipoDocumento}
            onChangedValue={(noTituloPagarTipoDocumento) =>
              setData({ ...data, noTituloPagarTipoDocumento })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
