import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Modal,
  Loader,
  Notification,
  Button,
  Panel,
  ControlTires,
  GridView,
} from 'ui/components';
import {
  BootstrapSizes,
  Theme,
  ResponseStatus,
  JustifyContent,
} from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import {
  getVeiculo,
  // getVeiculoVehicleInformation,
} from 'core/services/FRO/veiculo';
import { getSearchByVehicle } from 'core/services/FRO/veiculoModeloEixo';

type Props = {
  show: boolean;
  nrSeqVeiculo: number;
  onClose: () => void;
};

interface TirePosition {
  posicao: string;
  marca?: string;
  modelo?: string;
  medida?: string;
  nFogo?: string;
  dot?: string;
  reforma?: string;
  origem?: string;
  destino?: string;
}

export default function ModalImpressaoPneuVeiculo({
  show,
  nrSeqVeiculo,
  onClose,
}: Props) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [pneusAlocados, setPneusAlocados] = React.useState([]);

  const vinculoPneuVeiculo = useRef<any>();
  const gridSaidas = useRef<any>();
  const gridEntradas = useRef<any>();

  const columns = [
    { key: 'posicao', title: 'Posição' },
    { key: 'marca', title: 'Marca' },
    { key: 'modelo', title: 'Modelo' },
    { key: 'medida', title: 'Medida' },
    { key: 'nFogo', title: 'Nº Fogo' },
    { key: 'dot', title: 'Dot' },
    { key: 'reforma', title: 'Reforma' },
    { key: 'origem', title: 'Origem' },
    { key: 'destino', title: 'Destino' },
  ];

  const onSetMessage = (status: ResponseStatus, msg: string): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const formatEixoWithTwoTires = (
    flgRoda: boolean,
    description: string,
    pneus: any
  ) => {
    const pneusPositions = [];

    if (!flgRoda) description = 'STP';

    const dianteiroEsq: TirePosition = {
      posicao: `${pneus[0].nrOrdem} - ${description}Esq`,
    };
    pneusPositions.push(dianteiroEsq);

    const dianteiroDir: TirePosition = {
      posicao: `${pneus[1].nrOrdem} - ${description}Dir`,
    };
    pneusPositions.push(dianteiroDir);

    return pneusPositions;
  };

  const formatEixoWithFourTires = (
    flgRoda: boolean,
    pneus: any,
    description: string
  ) => {
    const pneusPositions = [];
    const positionDetails = ['Externo', 'Interno'];

    if (!flgRoda) description = 'STP';

    const pneusEsq = pneus.slice(0, pneus.length / 2);
    for (let i = 0; i < pneusEsq.length; i += 1) {
      const positionEsq: TirePosition = {
        posicao: `${pneus[i].nrOrdem} - ${description}E - ${positionDetails[i]}`,
      };
      pneusPositions.push(positionEsq);
    }

    const pneusDir = pneus.slice(pneus.length / 2, pneus.length);
    for (let i = pneusDir.length - 1; i >= 0; i -= 1) {
      const positionDir: TirePosition = {
        posicao: `${pneus[i].nrOrdem} - ${description}D - ${positionDetails[i]}`,
      };
      pneusPositions.push(positionDir);
    }

    return pneusPositions;
  };

  const loadTirePositions = async () => {
    const { data: vehicle } = await getSearchByVehicle({
      nrSeqVeiculo,
    });

    if (vehicle.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Veículo selecionado não possui Eixo cadastrado.'
      );
    } else {
      const eixos = vehicle.map((item: any) => item.eixo);

      const pneusPosicoes = [];

      for (let i = 0; i < eixos.length; i += 1) {
        let description = 'I';
        if (i === 0) {
          description = 'D';
        } else if (i === eixos.length - 1) {
          description = 'T';
        }

        let pneus = [];
        if (eixos[i].pneus.length === 2) {
          pneus = formatEixoWithTwoTires(
            eixos[i].flgRoda,
            description,
            eixos[i].pneus
          );
        } else {
          pneus = formatEixoWithFourTires(
            eixos[i].flgRoda,
            eixos[i].pneus,
            description
          );
        }

        pneusPosicoes.push(...pneus);
      }

      gridSaidas.current?.setDataSource(pneusPosicoes);
      gridEntradas.current?.setDataSource(pneusPosicoes);
    }
  };

  const load = async () => {
    setLoading(true);

    if (nrSeqVeiculo) {
      const veiculo: any = await getVeiculo(nrSeqVeiculo);

      loadTirePositions();

      if (!veiculo) {
        onSetMessage(ResponseStatus.Error, 'Erro ao buscar veículo.');
      } else {
        setData({
          veiculoPrincipal: veiculo,
          veiculoEixos: veiculo,
          nrSeqVeiculo: veiculo.nrSeqVeiculo,
          pneusAlocados,
        });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      load();
    }
  }, [show]);

  const onHandleClose = () => {
    setData(null);
    onClose();
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Impressão Pneus Vinculados'
      icon='print'
      size={BootstrapSizes.Large}
      onClose={() => onHandleClose()}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row m-3' ref={vinculoPneuVeiculo}>
          <div className='row mb-3'>
            {/* @ts-expect-error */}
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title={`Placa: ${data?.veiculoPrincipal.placa}`}
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row d-flex justify-content-center'>
                  {/* @ts-expect-error */}
                  <ControlTires
                    nrSeqVeiculo={data?.nrSeqVeiculo}
                    onMessage={onSetMessage}
                    onSetLoading={setLoading}
                    setPneusAlocados={setPneusAlocados}
                    isPrintModel
                    print
                  />
                </div>
              </Panel.Body>
            </Panel>
          </div>

          <div className='row  mb-3'>
            {/* @ts-expect-error */}
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Saídas'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridSaidas}
                    // @ts-expect-error
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel>
          </div>

          <div className='row'>
            {/* @ts-expect-error */}
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Entradas'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <div className='row'>
                <div className='col-12'>
                  <GridView
                    ref={gridEntradas}
                    // @ts-expect-error
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          text='Imprimir'
          icon='print'
          theme={Theme.Success}
          onClick={useReactToPrint({
            content: () => vinculoPneuVeiculo.current,
          })}
        />
      </Modal.Footer>
    </Modal>
  );
}
