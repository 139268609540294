import React from 'react';
import PneuVeiculo from 'core/models/FRO/pneuVeiculo';
import Tire from '../Tire';
import style from './index.module.css';

function Axis({ modeloEixo, filledTires, onClick, first, last, isPrintModel }) {
  const { qtdePneuEsquerda, qtdePneuDireita } = modeloEixo.eixo;
  const arrayItensEsquerda = [...Array(qtdePneuEsquerda).keys()];
  const arrayItensDireita = [...Array(qtdePneuDireita).keys()];

  const click = (data) => {
    onClick({ ...data, nrSeqEixo: modeloEixo.nrSeqEixo });
  };

  const fillTiresLeft = () => {
    const tiresLeft = arrayItensEsquerda.map((item) => {
      const nrOrdem = 1 + item + filledTires;
      let current = modeloEixo.eixo.pneus.find((x) => x.nrOrdem === nrOrdem);
      if (!current)
        current = new PneuVeiculo({
          nrSeqEixo: modeloEixo.NrSeqEixo,
        });
      return (
        <Tire
          left
          position={item}
          nrOrdem={nrOrdem}
          data={current}
          onClick={click}
          active={modeloEixo.eixo.flgRoda}
          isPrintModel={isPrintModel}
        />
      );
    });
    return tiresLeft;
  };

  const fillTiresRight = () => {
    const contInicialTiresRight = 1 + filledTires + qtdePneuEsquerda;
    const tiresRight = arrayItensDireita.map((item) => {
      const nrOrdem = contInicialTiresRight + item;
      let current = modeloEixo.eixo.pneus.find((x) => x.nrOrdem === nrOrdem);
      if (!current)
        current = new PneuVeiculo({
          nrSeqEixo: modeloEixo.NrSeqEixo,
        });
      return (
        <Tire
          position={item}
          nrOrdem={nrOrdem}
          data={current}
          onClick={click}
          active={modeloEixo.eixo.flgRoda}
          isPrintModel={isPrintModel}
        />
      );
    });
    return tiresRight;
  };

  const fillBodyStructure = () => {
    let border = style.without_border_top_bottom;

    if (first) border = style.withou_bottom_border;
    else if (last) border = style.without_top_border;

    return (
      <div className={`${style.body_structure_area_without_tire} ${border}`}>
        <div
          className={`${style.body_structure_area_without_tire_left} ${border}`}
        />
        <div
          className={`${style.body_structure_area_without_tire_right} ${border}`}
        />
      </div>
    );
  };

  return (
    <>
      {modeloEixo.eixo.flgTracao && (
        <div className={style.engine}>
          <div className={style.engine_block} />
        </div>
      )}

      {!modeloEixo.eixo.flgTracao && (
        <div className={style.engine}>
          <div className={style.without_engine} />
        </div>
      )}

      <div className={style.central_collumn} />
      <div className={style.axis}>
        <div className={`${style.axis_side} ${style.axis_side_left}`}>
          {fillTiresLeft()}
        </div>
        {fillBodyStructure()}
        <div className={`${style.axis_side} ${style.axis_side_right}`}>
          {fillTiresRight()}
        </div>
      </div>
    </>
  );
}

export default Axis;
