import React, { useState, useRef, useEffect } from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Modal,
  Autocomplete,
  Textbox,
  DatePicker,
  Button,
  GridView,
  Notification,
} from 'ui/components';
import {
  getPneuVidaAutoComplete,
  getPneuVidaList,
} from 'core/services/FRO/pneuVida';
import {
  getPneuSituacaoAutoComplete,
  getPneuSituacaoList,
} from 'core/services/FRO/pneuSituacao';
import { getPneuModeloAutoComplete } from 'core/services/FRO/pneuModelo';
import { getParametro } from 'core/services/SEG/parametro';

function newDateFormat() {
  const newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();
  return `${year}-${month}-${day}T00:00:00.0`;
}

export default function ModalDetalhesPneu({
  show,
  data,
  produtos,
  onClose,
  onSave,
}) {
  const quantidade = useRef();
  const gridViewPneus = useRef();
  const [message, setMessage] = useState(null);
  const [pneu, setPneu] = useState({
    flgCdPneuAutomatico: false,
    isEdit: false,
  });
  const [pneusRegistrados, setPneusRegistrados] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const buscaParametroCdPneuAutomatico = async () => {
    const parametro = await getParametro('CODIGO_PNEU_AUTOMATICO', 1);

    if (parametro.noConteudo === 'S') return true;

    return false;
  };

  const carregaFiltrosPadrao = async (geraCdPneuAutomatico) => {
    const pneuVidaList = await getPneuVidaList({ noPneuVida: 'NOVO' });

    const pneuSituacaoList = await getPneuSituacaoList({
      noPneuSituacao: 'ESTOQUE',
    });

    const objFiltros = {
      dtCompra: newDateFormat(),
      kmAcumulada: 0,
      nrSeqPneuVida:
        pneuVidaList.data.length > 0
          ? pneuVidaList.data[0].nrSeqPneuVida
          : undefined,
      pneuVida: pneuVidaList.data.length > 0 ? pneuVidaList.data[0] : undefined,
      nrSeqPneuSituacao:
        pneuSituacaoList.data.length > 0
          ? pneuSituacaoList.data[0].nrSeqPneuSituacao
          : undefined,
      pneuSituacao:
        pneuSituacaoList.data.length > 0 ? pneuSituacaoList.data[0] : undefined,
      flgCdPneuAutomatico: geraCdPneuAutomatico,
      isEdit: false,
    };

    return objFiltros;
  };

  const validatePneuData = (dataSource) => {
    if (!pneu.flgCdPneuAutomatico && !dataSource.cdPneu) {
      setMessage({
        message: 'Preencha o campo Código Pneu.',
        theme: Theme.Danger,
      });

      return false;
    }

    if (
      !dataSource.nrSeqProduto ||
      !dataSource.dtCompra ||
      !dataSource.nrSeqPneuVida ||
      !dataSource.nrSeqPneuSituacao
    ) {
      setMessage({
        message: 'Preencha todos os campos.',
        theme: Theme.Danger,
      });

      return false;
    }

    return true;
  };

  const save = async () => {
    const listPneus = gridViewPneus.current.getDataSource();

    let isValidSave = true;
    for (let i = 0; i < listPneus.length; i += 1) {
      if (!validatePneuData(listPneus[i])) {
        isValidSave = false;
        break;
      }
    }

    if (isValidSave) {
      if (listPneus.length < quantidade.current) {
        setMessage({
          message: `Voce deve registrar todos pneus. Falta registrar ${
            quantidade.current - listPneus.length
          }`,
          theme: Theme.Danger,
        });
      } else if (listPneus.length > quantidade.current) {
        setMessage({
          message: `Voce registrou ${
            listPneus.length - quantidade.current
          } pneu${
            listPneus.length - quantidade.current === 1 ? '' : 's'
          } a mais`,
          theme: Theme.Danger,
        });
      } else {
        onSave(listPneus, true);
        onClose();
      }
    }
  };

  const onCloseModal = () => onClose();

  const onSearchPneuVida = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuVidaAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onSearchPneuSituacao = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuSituacaoAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const validateQtdProdutoAdd = (lista, prodAdd) => {
    const count = lista.filter((e) => e.nrSeqProduto === prodAdd.nrSeqProduto)
      .length;
    const qtdMaxProduto = produtos.find(
      (e) => e.nrSeqProduto === prodAdd.nrSeqProduto
    ).qtdeItNf;

    return count < qtdMaxProduto;
  };

  const onClickAddPneu = () => {
    const pneus = gridViewPneus.current.getDataSource() ?? [];

    if (!validateQtdProdutoAdd(pneus, pneu)) {
      onSetMessage(
        ResponseStatus.Danger,
        'Quantidade máxima de pneus para este produto já foi atingida.'
      );
    } else {
      if (!pneu.status) pneu.status = GridView.EnumStatus.Inserir;
      if (validatePneuData(pneu)) {
        delete pneu.flgCdPneuAutomatico;
        pneus.push(pneu);

        if (gridViewPneus && gridViewPneus.current) {
          gridViewPneus.current.setDataSource(pneus);
        }
        if (pneus.length === quantidade) {
          setPneusRegistrados(true);
        } else {
          setPneusRegistrados(false);
        }
        setPneu({ flgCdPneuAutomatico: false });
      }
    }
  };

  const dataIncrementOrUndefined = (value, count) => {
    if (value.length > 0) {
      const numValue = parseInt(value, 10);
      return (numValue + count).toString();
    }

    return undefined;
  };

  const onClickAplicarTodos = async () => {
    if (validatePneuData(pneu)) {
      const produtosList = gridViewPneus.current.getDataSource();
      const filtered = produtosList.filter(
        (el) => el.nrSeqProduto === pneu.nrSeqProduto
      );

      let count = 0;

      const newList = filtered.map((item) => {
        const cdPneu = dataIncrementOrUndefined(pneu.cdPneu, count);
        const numeroSerie = dataIncrementOrUndefined(pneu.numeroSerie, count);
        const nrDot = dataIncrementOrUndefined(pneu.nrDot, count);
        count += 1;

        return {
          ...item,
          ...pneu,
          produto: item.produto,
          nrSeqProduto: item.nrSeqProduto,
          cdPneu,
          numeroSerie,
          nrDot,
        };
      });

      // if (!pneu.nrSeqProduto) {
      //   newList = produtosList.map((item) => {
      //     const cdPneu = dataIncrementOrUndefined(pneu.cdPneu, count);
      //     const numeroSerie = dataIncrementOrUndefined(pneu.numeroSerie, count);
      //     const nrDot = dataIncrementOrUndefined(pneu.nrDot, count);
      //     count += 1;

      //     return {
      //       ...item,
      //       ...pneu,
      //       produto: item.produto,
      //       nrSeqProduto: item.nrSeqProduto,
      //       cdPneu,
      //       numeroSerie,
      //       nrDot,
      //     };
      //   });
      // } else {
      //   newList = produtosList.map((item) => {
      //     const cdPneu = dataIncrementOrUndefined(pneu.cdPneu, count);
      //     const numeroSerie = dataIncrementOrUndefined(pneu.numeroSerie, count);
      //     const nrDot = dataIncrementOrUndefined(pneu.nrDot, count);
      //     count += 1;

      //     if (item.nrSeqProduto === pneu.nrSeqProduto) {
      //       return {
      //         ...item,
      //         ...pneu,
      //         produto: item.produto,
      //         nrSeqProduto: item.nrSeqProduto,
      //         cdPneu,
      //         numeroSerie,
      //         nrDot,
      //       };
      //     }

      //     return { ...item };
      //   });
      // }

      if (gridViewPneus && gridViewPneus.current)
        gridViewPneus.current.setDataSource(newList);

      const geraCdPneuAutomatico = await buscaParametroCdPneuAutomatico();
      const filtros = await carregaFiltrosPadrao(geraCdPneuAutomatico);
      setPneu({ ...filtros });
    }
  };

  const onClickRemoverPneu = (e, datasource) => {
    const list = datasource.filter((el) => el !== e);

    if (gridViewPneus && gridViewPneus.current)
      gridViewPneus.current.setDataSource(list);

    if (list.length === quantidade) {
      setPneusRegistrados(true);
    } else {
      setPneusRegistrados(false);
    }
  };

  const onClickEditarPneu = (e, datasource) => {
    const item = datasource.find((el) => el === e);

    setPneu({ ...item, isEdit: true });

    const list = datasource.filter((el) => el !== e);

    if (gridViewPneus && gridViewPneus.current)
      gridViewPneus.current.setDataSource(list);
  };

  const columns = [
    { key: 'nrSeqPneu', title: 'NrSeqPneu', visible: false },
    { key: 'nrSeqProduto', title: 'NrSeqProduto', visible: false },
    { key: 'produto.noProduto', title: 'Produto' },
    { key: 'cdPneu', title: 'Código Pneu' },
    { key: 'nrDot', title: 'Nr. Dot' },
    { key: 'numeroSerie', title: 'Nr. Série' },
    { key: 'kmAcumulada', title: 'Km Acumulada', visible: false },
    { key: 'pneuModelo.noPneuModelo', title: 'Modelo' },
    { key: 'pneuVida.noPneuVida', title: 'Vida' },
    { key: 'pneuSituacao.noPneuSituacao', title: 'Situação' },
    { key: 'nrSulcoInicial', title: 'Nr. Sulco Inicial' },
    { key: 'nrSulcoFinal', title: 'Nr. Sulco Final' },
    {
      key: 'dtCompra',
      title: 'Dt. Compra',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtTermino',
      title: 'Dt. Término',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'vlrCompra',
      title: 'Valor Compra',
      format: GridView.DataTypes.Currency,
      visible: false,
    },
    {
      key: 'nrSeqPneu',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditarPneu(e, datasource),
      title: 'Editar',
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPneu',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoverPneu(e, datasource),
      title: 'Remover',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const searchProduto = () => {
    const produtosList = produtos.map((item) => item.produto);
    return produtosList;
  };

  const onSearchPneuModelo = async (e) => {
    const {
      status,
      message: msg,
      pneuModelo,
    } = await getPneuModeloAutoComplete({ noSearchField: e });
    if (msg) onSetMessage(status, msg);
    return pneuModelo;
  };

  const geraPneusPorProdutosLista = async (
    produtoLista,
    geraCdPneuAutomatico
  ) => {
    const pneuVidaList = await getPneuVidaList({ noPneuVida: 'NOVO' });

    const pneuSituacaoList = await getPneuSituacaoList({
      noPneuSituacao: 'ESTOQUE',
    });

    let qtdePneus = 0;
    const produtosPneuList = [];

    for (let i = 0; i < produtoLista.length; i += 1) {
      qtdePneus += produtoLista[i].qtdeItNf;

      for (let j = 0; j < produtoLista[i].qtdeItNf; j += 1) {
        produtosPneuList.push({
          dtCompra: newDateFormat(),
          vlrCompra: produtoLista[i].vlrUnitario,
          kmAcumulada: 0,
          nrSeqPneuVida:
            pneuVidaList.data.length > 0
              ? pneuVidaList.data[0].nrSeqPneuVida
              : undefined,
          pneuVida:
            pneuVidaList.data.length > 0 ? pneuVidaList.data[0] : undefined,
          nrSeqPneuSituacao:
            pneuSituacaoList.data.length > 0
              ? pneuSituacaoList.data[0].nrSeqPneuSituacao
              : undefined,
          pneuSituacao:
            pneuSituacaoList.data.length > 0
              ? pneuSituacaoList.data[0]
              : undefined,
          nrSeqProduto: produtoLista[i].produto.nrSeqProduto,
          produto: produtoLista[i].produto,
          status: produtoLista[i].status,
          flgCdPneuAutomatico: geraCdPneuAutomatico,
        });
      }
    }

    return { qtdePneus, produtosPneuList };
  };

  useEffect(async () => {
    if (gridViewPneus && gridViewPneus.current) {
      gridViewPneus.current.setDataSource(data?.pneus ?? []);
    }

    if (show) {
      const geraCdPneuAutomatico = await buscaParametroCdPneuAutomatico();

      const { qtdePneus, produtosPneuList } = await geraPneusPorProdutosLista(
        produtos,
        geraCdPneuAutomatico
      );

      quantidade.current = qtdePneus;

      const pneus =
        data.pneus && data.pneus.length > 0 ? data.pneus : produtosPneuList;
      if (gridViewPneus && gridViewPneus.current) {
        gridViewPneus.current.setDataSource(pneus);

        if (data.pneus && data.pneus.length !== quantidade.current) {
          setPneusRegistrados(false);
        }
      }

      const filtros = await carregaFiltrosPadrao(geraCdPneuAutomatico);

      setPneu({ ...filtros });
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title='Efetivar Pneus'
      onClose={onCloseModal}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row pb-5'>
          <div className='row mb-3 align-items-end'>
            <div className='col-5'>
              <Autocomplete
                label='Produto'
                searchDataSource={searchProduto}
                selectedItem={pneu.produto}
                onSelectItem={(produto) => {
                  setPneu({
                    ...pneu,
                    produto,
                    nrSeqProduto: produto.nrSeqProduto,
                  });
                }}
                dataSourceTextProperty='noProduto'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Modelo'
                searchDataSource={onSearchPneuModelo}
                selectedItem={pneu.pneuModelo}
                onSelectItem={(pneuModelo) => {
                  setPneu({
                    ...pneu,
                    pneuModelo,
                    nrSeqPneuModelo: pneuModelo.nrSeqPneuModelo,
                  });
                }}
                dataSourceTextProperty='noPneuModelo'
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Compra'
                text={pneu.dtCompra}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtCompra) => setPneu({ ...pneu, dtCompra })}
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Término'
                text={pneu.dtTermino}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtTermino) => setPneu({ ...pneu, dtTermino })}
              />
            </div>
          </div>

          <div className='row mb-3 align-items-end'>
            {!pneu?.flgCdPneuAutomatico && (
              <div className='col-2'>
                <Textbox
                  label='Código Pneu'
                  readOnly={pneu?.flgCdPneuAutomatico}
                  maxLength={20}
                  text={pneu.cdPneu}
                  onChangedValue={(cdPneu) => setPneu({ ...pneu, cdPneu })}
                />
              </div>
            )}

            <div className='col-2'>
              <Textbox
                maxLength={20}
                label='Nr. Série'
                text={pneu.numeroSerie}
                onChangedValue={(numeroSerie) =>
                  setPneu({ ...pneu, numeroSerie })
                }
              />
            </div>

            <div className='col-2'>
              <Textbox
                maxLength={10}
                label='Nr. Dot'
                text={pneu.nrDot}
                onChangedValue={(nrDot) => setPneu({ ...pneu, nrDot })}
              />
            </div>

            <div className='col-2'>
              <Textbox
                label='Nr. Sulco Inicial'
                text={pneu.nrSulcoInicial}
                mask={MaskTypes.Decimal}
                onChangedValue={(nrSulcoInicial) =>
                  setPneu({ ...pneu, nrSulcoInicial })
                }
              />
            </div>

            <div className='col-2'>
              <Textbox
                label='Nr. Sulco Final'
                text={pneu.nrSulcoFinal}
                mask={MaskTypes.Decimal}
                onChangedValue={(nrSulcoFinal) =>
                  setPneu({ ...pneu, nrSulcoFinal })
                }
              />
            </div>

            {!pneu?.isEdit && (
              <div className='col d-flex justify-content-end'>
                <Button
                  outline
                  size={BootstrapSizes.Medium}
                  theme={Theme.Success}
                  template={Button.Templates.Default}
                  onClick={onClickAplicarTodos}
                  text='Aplicar para todos'
                />
              </div>
            )}
          </div>

          <div className='row mb-3 align-items-end'>
            <div className='col-2'>
              <Textbox
                label='Km Acumulada'
                text={pneu.kmAcumulada}
                mask={MaskTypes.Integer}
                onChangedValue={(kmAcumulada) =>
                  setPneu({ ...pneu, kmAcumulada })
                }
              />
            </div>

            <div className='col-4'>
              <Autocomplete
                label='Pneu Vida'
                searchDataSource={onSearchPneuVida}
                selectedItem={pneu.pneuVida}
                onSelectItem={(pneuVida) => {
                  setPneu({
                    ...pneu,
                    pneuVida,
                    nrSeqPneuVida: pneuVida.nrSeqPneuVida,
                  });
                }}
                dataSourceTextProperty='noPneuVida'
              />
            </div>

            <div className='col-4'>
              <Autocomplete
                label='Pneu Situação'
                searchDataSource={onSearchPneuSituacao}
                selectedItem={pneu.pneuSituacao}
                onSelectItem={(pneuSituacao) => {
                  setPneu({
                    ...pneu,
                    pneuSituacao,
                    nrSeqPneuSituacao: pneuSituacao.nrSeqPneuSituacao,
                  });
                }}
                dataSourceTextProperty='noPneuSituacao'
              />
            </div>

            <div className='col d-flex justify-content-end'>
              <Button
                outline
                icon='plus'
                size={BootstrapSizes.Medium}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={onClickAddPneu}
                text='Adicionar produto'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridViewPneus}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                offlineData
                showPagination={false}
                showSelectSizes={false}
                canControlVisibility
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          theme={pneusRegistrados ? Theme.Success : Theme.Inactive}
          template={Button.Templates.Default}
          onClick={save}
          text='Efetivar'
        />
      </Modal.Footer>
    </Modal>
  );
}
