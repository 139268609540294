import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  GridView,
  Autocomplete,
  Button,
} from 'ui/components';
import {
  getAgrupamentoRomaneio,
  saveAgrupamentoRomaneio,
  deleteAgrupamentoRomaneio,
} from 'core/services/VEN/agrupamentoRomaneio';
import { getAgrupamentoRomaneioItemList } from 'core/services/VEN/agrupamentoRomaneioItem';

import { searchProdutoSubGrupoAutoComplete } from 'core/services/EST/produtoSubGrupo';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';

export default function AgrupamentoRomaneioItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const gridView = useRef();
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [grupoSubGrupo, setgrupoSubGrupo] = useState({
    produtoGrupo: {},
    produtoSubGrupo: {},
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      const fetchedData = await getAgrupamentoRomaneio(registryKey);
      setData(fetchedData);
      setgrupoSubGrupo({ produtoGrupo: {}, produtoSubGrupo: {} });
      if (gridView && gridView.current) {
        gridView.current.setDataSource(
          fetchedData.agrupamentoRomaneioItem || []
        );
      }
      setLoading(false);
    }
  }, [registryKey]);

  const onClickAddAgrupamentoRomaneioItem = async () => {
    if (
      grupoSubGrupo &&
      grupoSubGrupo.produtoGrupo.nrSeqProdutoGrupo &&
      grupoSubGrupo.produtoSubGrupo.nrSeqProdutoSubGrupo
    ) {
      const obj = {
        nrSeqAgrupamentoRomaneio: data.nrSeqAgrupamentoRomaneio,
        nrSeqProdutoSubGrupo: grupoSubGrupo.nrSeqProdutoSubGrupo,
        status: GridView.EnumStatus.Inserir,
        produtoSubGrupo: {
          ...grupoSubGrupo.produtoSubGrupo,
          produtoGrupo: grupoSubGrupo.produtoGrupo,
        },
      };
      const agrupamentoRomaneioItemList = await getAgrupamentoRomaneioItemList();

      // Verifique se o nrSeqProdutoSubGrupo já existe na lista
      let itemExisteAgrupamentoRomaneioItem = Boolean;
      if (agrupamentoRomaneioItemList) {
        itemExisteAgrupamentoRomaneioItem = agrupamentoRomaneioItemList.data.some(
          (item) =>
            item.nrSeqProdutoSubGrupo === grupoSubGrupo.nrSeqProdutoSubGrupo
        );
      }

      if (itemExisteAgrupamentoRomaneioItem) {
        onSetMessage(
          false,
          'Este item já existe em outro Agrupamento Romaneio.'
        );
        return;
      }
      // Verifica se o item já foi adicionado a lista da página, baseado no 'nrSeqProdutoSubGrupo'
      const listaAgrupamentoRomaneioItem = gridView.current.getDataSource();
      if (listaAgrupamentoRomaneioItem.length > 0) {
        const itemExisteListaAtual = listaAgrupamentoRomaneioItem.some(
          (item) =>
            item.nrSeqProdutoSubGrupo === grupoSubGrupo.nrSeqProdutoSubGrupo
        );

        if (itemExisteListaAtual) {
          onSetMessage(false, 'Este item já foi adicionado a esta lista.');
          return;
        }
      }

      listaAgrupamentoRomaneioItem.push(obj);
      if (gridView && gridView.current) {
        gridView.current.setDataSource(listaAgrupamentoRomaneioItem);
      }

      setData((prevData) => ({
        ...prevData,
        agrupamentoRomaneioItem: listaAgrupamentoRomaneioItem,
      }));
    } else {
      onSetMessage(false, 'Informe o Grupo e SubGrupo.');
    }
  };

  const onClickRemoveAgrupamentoRomaneioItem = async (
    selectedValue,
    datasource
  ) => {
    const listaAgrupamentoRomaneioItem = datasource.filter(
      (el) => el !== selectedValue
    );

    if (selectedValue.status !== GridView.EnumStatus.Inserir) {
      selectedValue.status = GridView.EnumStatus.Remover;

      listaAgrupamentoRomaneioItem.push(selectedValue);
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(listaAgrupamentoRomaneioItem);

    setData((prevData) => ({
      ...prevData,
      agrupamentoRomaneioItem: listaAgrupamentoRomaneioItem,
    }));
  };

  const onSearchProdutoSubGrupo = async (e, nrSeqProdutoGrupo) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await searchProdutoSubGrupoAutoComplete({
      noSearchField: e,
      nrSeqProdutoGrupo,
    });

    if (grupos.length === 0) {
      onSetMessage(false, 'Este Grupo não possue SubGrupos.');
    }
    if (msg) onSetMessage(status, msg);
    return grupos;
  };
  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: grupos,
    } = await getProdutoGrupoAutoComplete({
      noProdutoGrupo: e,
    });

    if (msg) onSetMessage(status, msg);

    return grupos;
  };
  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveAgrupamentoRomaneio(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    if (gridView && gridView.current) {
      gridView.current.setDataSource([]);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteAgrupamentoRomaneio(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);
  useEffect(() => {
    (async function func() {
      // Verificar se grupoSubGrupo e produtoGrupo existem antes de acessar Object.keys
      if (
        grupoSubGrupo &&
        grupoSubGrupo.produtoGrupo &&
        Object.keys(grupoSubGrupo.produtoGrupo).length === 0
      ) {
        setgrupoSubGrupo({});
      }
    })();
  }, [grupoSubGrupo.produtoGrupo]);

  const { id: idSelecao } = PageTypes.Selection;

  const columns = [
    { key: 'produtoSubGrupo.produtoGrupo.noProdutoGrupo', title: 'Grupo' },
    { key: 'produtoSubGrupo.noProdutoSubGrupo', title: 'SubGrupo' },
    {
      key: 'nrSeqPagamento',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Danger,
      onClick: (e, dataSource) =>
        onClickRemoveAgrupamentoRomaneioItem(e, dataSource),
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Agrupamento Romaneio'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqAgrupamentoRomaneio > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={10}
            label='Código'
            required
            text={data.cdAgrupamentoRomaneio}
            onChangedValue={(cdAgrupamentoRomaneio) =>
              setData({ ...data, cdAgrupamentoRomaneio })
            }
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            maxLength={60}
            label='Descrição'
            required
            text={data.noAgrupamentoRomaneio}
            onChangedValue={(noAgrupamentoRomaneio) =>
              setData({ ...data, noAgrupamentoRomaneio })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-5 mb-2'>
          <Autocomplete
            label='Produto Grupo'
            searchDataSource={onSearchProdutoGrupo}
            selectedItem={grupoSubGrupo.produtoGrupo}
            onSelectItem={(produtoGrupo) =>
              setgrupoSubGrupo({
                ...grupoSubGrupo,
                produtoGrupo,
                nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
              })
            }
            dataSourceTextProperty='noProdutoGrupo'
          />
        </div>
        <div className='col-5 mb-2'>
          <Autocomplete
            readOnly={
              !grupoSubGrupo.produtoGrupo || !grupoSubGrupo.nrSeqProdutoGrupo
            }
            enabled={
              !!grupoSubGrupo.produtoGrupo && !!grupoSubGrupo.nrSeqProdutoGrupo
            }
            label='Produto SubGrupo'
            searchDataSource={(e) =>
              onSearchProdutoSubGrupo(e, grupoSubGrupo.nrSeqProdutoGrupo)
            }
            selectedItem={grupoSubGrupo.produtoSubGrupo}
            onSelectItem={(produtoSubGrupo) => {
              setgrupoSubGrupo({
                ...grupoSubGrupo,
                produtoSubGrupo,
                nrSeqProdutoSubGrupo: produtoSubGrupo.nrSeqProdutoSubGrupo,
              });
            }}
            dataSourceTextProperty='noProdutoSubGrupo'
          />
        </div>
        <div className='col-2 mb-2 mt-3 d-flex align-itens-end'>
          <Button
            disabled={!grupoSubGrupo.produtoSubGrupo}
            outline
            icon='plus'
            size={BootstrapSizes.Medium}
            theme={Theme.Primary}
            template={Button.Templates.Default}
            onClick={onClickAddAgrupamentoRomaneioItem}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            showSelectSizes={false}
            showPagination={false}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
