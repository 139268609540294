import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Button } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';
import { getDownloadContaAssociado } from '../../../../core/services/ASS/associado';

export default function DownloadContaAssociado() {
  const { idConta } = useParams();
  const [data, setData] = useState({});
  const [corTexto, setCorTexto] = useState('black');
  const [icone, setIcone] = useState('copy');
  const [texto, setTexto] = useState('Copia e Cola PIX');

  useEffect(() => {
    async function load() {
      const codigodownload = idConta;
      const { data: ret } = await getDownloadContaAssociado(codigodownload);
      if (ret) {
        setData(ret);
      }
    }
    load();
  }, []);

  const downloadBoleto = () => {
    if (data.boletoBase64) {
      const linkSource = `data:application/pdf;base64, ${data.boletoBase64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Boleto.pdf';
      downloadLink.click();
    }
  };

  const downloadRelMensal = () => {
    if (data.relMensalBase64) {
      const linkSource = `data:application/pdf;base64, ${data.relMensalBase64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'RelatorioMensal.pdf';
      downloadLink.click();
    }
  };

  const trocarCorTexto = () => {
    setCorTexto('green');
  };

  const trocarIcone = () => {
    setIcone('check');
  };

  const trocarTexto = () => {
    setTexto('Copiado!');
  };

  return (
    <div>
      <div>
        <div className='row mb-5 mt-5'>
          <div
            className='col'
            style={{
              textAlign: 'center',
            }}
          >
            <span
              style={{
                textAlign: 'center',
                fontSize: '50px',
              }}
            >
              {data.empresa}
            </span>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col'>
            <div className='card border-dark '>
              <div className='card-body'>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <div className='row mt-4'>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '36px',
                        }}
                      >
                        Cliente
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                        className='col'
                      >
                        <span style={{ fontWeight: 'bold', fontSize: '36px' }}>
                          {data.associado}
                        </span>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '36px',
                        }}
                      >
                        Fatura
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                        className='col'
                      >
                        <span style={{ fontWeight: 'bold', fontSize: '36px' }}>
                          {data.competencia}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-4 mt-5'>
                    <div className='row '>
                      <div className='row mb-3'>
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                          className='card border-danger mb-3'
                        >
                          <div className='card-body'>
                            <span
                              className='card-text'
                              style={{
                                fontWeight: 'bold',
                                fontSize: '36px',
                                color: 'darkblue',
                              }}
                            >
                              {data.valor}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                          className='col'
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                              fontSize: '20px',
                              color: 'darkblue',
                            }}
                          >
                            Valor da Fatura
                          </span>
                        </div>
                      </div>
                      {data.qrCodeBase64 && (
                        <div className='row mb-3'>
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                            className='card border-danger mb-3'
                          >
                            <div className='card-body'>
                              <span
                                className='card-text'
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '36px',
                                  color: 'darkblue',
                                }}
                              >
                                {data.vencimento}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                            className='col'
                          >
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '20px',
                                color: 'darkblue',
                              }}
                            >
                              Vencimento
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {data.boletoBase64 && (
                    <div className='col-4'>
                      <div className='row mb-3'>
                        <div
                          className='col'
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <img
                            src={data.qrCodeBase64}
                            className='img-thumbnail'
                            alt='QR Code Boleto'
                          />
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div
                          className='col'
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {/* *trocar a cor do texto ao clicar no botão */}

                          <Button
                            style={{
                              fontSize: '20px',
                              textAlign: 'center',
                              backgroundColor: 'white',
                              color: corTexto,
                            }}
                            theme={Theme.Primary}
                            text={texto}
                            icon={icone}
                            className='width80 btn-height btn-xl'
                            onClick={() => {
                              navigator.clipboard.writeText(data.emv);
                              trocarCorTexto();
                              trocarIcone();
                              trocarTexto();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Button
              theme={Theme.Primary}
              text='Relatório Mensal'
              icon='download'
              className='width100 btn-height btn-xl'
              onClick={() => downloadRelMensal()}
            />
          </div>
          {data.boletoBase64 && (
            <div className='col'>
              <Button
                theme={Theme.Success}
                text='Boleto'
                icon='download'
                className='width100 btn-height btn-xl'
                onClick={() => downloadBoleto()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
