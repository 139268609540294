import React, { useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getAcertoMotoristaPagined,
  excelAcertoMotorista,
  printAcertoMotoristaSelecao,
  printAcertoMotoristaSelecaoPeriodo,
  PeriodoReceita,
  ExcelAgrupMotorista,
  ExcelAgrupMedia,
  printAcertoMotoristaMdfe,
  excelAcertoMotoristaMdfe,
  onPrintDemonstrativoPeriodo2,
  excelPeriodo2,
} from 'core/services/FIN/acertoMotorista';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getCidadeEstadoAutoComplete } from 'core/services/SEG';
import { getClienteAutoComplete } from 'core/services/FIN';

const columns = [
  { key: 'nrSeqAcertoMotorista', type: GridView.ColumnTypes.Checkbox },
  { key: 'veiculo.noVeiculo', title: 'Placa', sortKey: 'noVeiculo' },
  { key: 'cdAcertoMotorista', title: 'Cod' },
  { key: 'statusAdicional', title: 'Status Adicional', visible: false },
  { key: 'motorista.noPessoa', title: 'Motorista', sortKey: 'noPessoa' },
  {
    key: 'dtSaida',
    title: 'Dt Saida',
    format: GridView.DataTypes.Date,
    sortKey: 'dtSaida',
  },
  {
    key: 'dtChegada',
    title: 'Dt Chegada',
    format: GridView.DataTypes.Date,
    sortKey: 'dtChegada',
  },
  {
    key: 'dtAcerto',
    title: 'dtEfetivacao',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'vlrSaldoMotorista',
    title: 'Saldo Motorista',
    format: GridView.DataTypes.Decimal,
  },
  {
    key: 'noCidadesReceita',
    title: 'Cidade Destino',
    visible: false,
  },
  {
    key: 'noClientes',
    title: 'Cliente Receita',
    visible: false,
  },
  {
    key: 'vlrTotalReceitas',
    title: 'Valor Total Receita',
    format: GridView.DataTypes.Decimal,
    visible: false,
  },
  {
    key: 'vlrLucroPercentual',
    title: 'Percentual Lucro Viagem',
    format: GridView.DataTypes.Decimal,
  },

  { key: 'mediaKM', title: 'km/L', format: GridView.DataTypes.Decimal },
  { key: 'pesoTotal', title: 'Peso Total', format: GridView.DataTypes.Decimal },
  { key: 'mesAnoRef', title: 'Ano/Mes' },
  {
    key: 'efetivado',
    title: 'Efetivado',
    color: 'noColor',
  },
];

export default function AcertoMotorista({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    tipoAcertoMotorista: 'NE',
    flgBuscaInfoReceitas: 'N',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  function breackLineValuecell(text) {
    return text
      .split('||')
      .map((curs, idx) => <p key={idx.toString}>{curs}</p>);
  }

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAcertoMotoristaPagined(
      params ?? filtros
    );

    for (let i = 0; i < data.length; ) {
      if (data[i].dtAcerto !== null) {
        data[i].efetivado = 'SIM';
        data[i].statusAdicional = 'Acerto Lançado';
      } else {
        data[i].efetivado = 'NÃO';
        if (data[i].pesoTotal > 0) {
          data[i].statusAdicional = 'Acerto Motorista';
        }
      }

      i += 1;
    }

    data.forEach((item) => {
      if (
        item.noCidadesReceita !== null &&
        item.noCidadesReceita !== undefined
      ) {
        item.noCidadesReceita = breackLineValuecell(item.noCidadesReceita);
      }

      if (item.noClientes !== null && item.noClientes !== undefined) {
        item.noClientes = breackLineValuecell(item.noClientes);
      }
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'AcertoMotorista/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAcertoMotorista = sourceRow.find(
      (e) => e.key === 'nrSeqAcertoMotorista'
    );
    onOpenMaintenance(nrSeqAcertoMotorista.value);
  };

  const buttonAcertoMotorista = [
    {
      text: 'Efetivado',
      value: 'E',
    },
    {
      text: 'Não Efetivado',
      value: 'NE',
    },
    {
      text: 'Todos',
      value: 'T',
    },
  ];

  const onSearchVeiculo = async (e) => {
    const { message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg);
    return veiculos;
  };

  const onPrint = async () => {
    setLoading(true);
    const { status, message: msg, value } = await printAcertoMotoristaSelecao(
      filtros
    );

    if (status === ResponseStatus.Success) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };
  const onPrintPeriodo = async () => {
    setLoading(true);
    const {
      status,
      message: msg,
      value,
    } = await printAcertoMotoristaSelecaoPeriodo(filtros);

    if (status === ResponseStatus.Success) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onPrintPeriodo2 = async () => {
    setLoading(true);
    const { status, message: msg, value } = await onPrintDemonstrativoPeriodo2(
      filtros
    );

    if (status === ResponseStatus.Success) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onPrintMdfe = async () => {
    setLoading(true);
    const { status, message: msg, value } = await printAcertoMotoristaMdfe(
      filtros
    );

    if (status === ResponseStatus.Success) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onExcelMdfe = async () => {
    setLoading(true);
    const { status, message: msg, value } = await excelAcertoMotoristaMdfe(
      filtros
    );
    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `AcertoMotorista.xls`;
        downloadLink.click();
      }
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onExcelPeriodo2 = async () => {
    setLoading(true);
    const { status, message: msg, value } = await excelPeriodo2(filtros);
    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `AcertoMotorista.xls`;
        downloadLink.click();
      }
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onExcel = async () => {
    setLoading(true);
    const { status, message: msg, value } = await excelAcertoMotorista(filtros);
    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `AcertoMotorista.xls`;
        downloadLink.click();
      }
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onExcelReceita = async () => {
    setLoading(true);
    const { status, message: msg, value } = await PeriodoReceita(filtros);
    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `AcertoMotorista.xls`;
        downloadLink.click();
      }
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onExcelAgrupMotorista = async () => {
    setLoading(true);
    const { status, message: msg, value } = await ExcelAgrupMotorista(filtros);
    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `AcertoMotorista.xls`;
        downloadLink.click();
      }
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onExcelAgrupMedia = async () => {
    setLoading(true);
    const { status, message: msg, value } = await ExcelAgrupMedia(filtros);
    if (status === ResponseStatus.Success) {
      if (value) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `AcertoMotorista.xls`;
        downloadLink.click();
      }
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onSearchCidade = async (e) => {
    const { cidades } = await getCidadeEstadoAutoComplete({
      noCidade: e,
    });
    return cidades;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const vlrCompetence = async () => {
    if (filtros.mesAnoRef !== undefined && filtros.mesAnoRef !== null) {
      if (filtros.mesAnoRef.length === 1) {
        const year = new Date().getFullYear();
        const valor = `0${filtros.mesAnoRef}/${year}`;
        setFiltros({ ...filtros, mesAnoRef: valor });
      } else if (filtros.mesAnoRef.length === 2) {
        const numeroInteiro = parseInt(filtros.mesAnoRef, 10);
        if (numeroInteiro > 12) {
          filtros.mesAnoRef = '12';
        }
        const year = new Date().getFullYear();
        const valor = `${filtros.mesAnoRef}/${year}`;
        setFiltros({ ...filtros, mesAnoRef: valor });
      } else if (filtros.mesAnoRef.length > 2 && filtros.mesAnoRef.length < 7) {
        const numeroInteiro = parseInt(filtros.mesAnoRef.split('/')[0], 10);
        if (numeroInteiro > 12) {
          const year = new Date().getFullYear();
          const month = '12';
          const valor = `${month}/${year}`;
          setFiltros({ ...filtros, mesAnoRef: valor });
        } else {
          const year = new Date().getFullYear();
          const month = filtros.mesAnoRef.split('/')[0];
          const valor = `${month}/${year}`;
          setFiltros({ ...filtros, mesAnoRef: valor });
        }
      } else {
        const numeroInteiro = parseInt(filtros.mesAnoRef.split('/')[0], 10);
        if (numeroInteiro > 12) {
          const year = new Date().getFullYear();
          const month = '12';
          const valor = `${month}/${year}`;
          setFiltros({ ...filtros, mesAnoRef: valor });
        }
      }
    }
  };

  const onSearchPessoaMot = async (e) => {
    const { message: msg, motoristas } = await getMotoristaAutoComplete({
      noPessoa: e,
    });
    if (msg);
    return motoristas;
  };

  const PesquisaReceita = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      transaction={transaction}
      title='Seleção de Acerto Motorista'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Demonstrativo Por Periodo (PDF)'
          icon='print'
          onClick={() => onPrint('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onExcelFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Demonstrativo Por MDFE',
              onClick: () => onPrintMdfe(),
            },
            {
              text: 'Demonstrativo Por Periodo 2 (PDF)',
              onClick: () => onPrintPeriodo(),
            },
            {
              text: 'Demonstrativo Por Periodo Despesas Separadas (PDF)',
              onClick: () => onPrintPeriodo2(),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Demonstrativo Por Receita (Excel)'
          icon={['far', 'file-excel']}
          onClick={() => onExcelReceita()}
        />
        <ToolbarButtons.ButtonList
          onExcelFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Demonstrativo Por Período (Excel)',
              onClick: () => onExcel(),
            },
            {
              text: 'Demonstrativo Por Período (Agrup) Por Motorista (Excel) ',
              onClick: () => onExcelAgrupMotorista(),
            },
            {
              text: 'Demonstrativo Por Motorista Média Abastecimento (Excel) ',
              onClick: () => onExcelAgrupMedia(),
            },
            {
              text: 'Demonstrativo Por MDFE (Excel)',
              onClick: () => onExcelMdfe(),
            },
            {
              text: 'Demonstrativo Por Periodo Despesas Separadas (Excel)',
              onClick: () => onExcelPeriodo2(),
            },
          ]}
        />
      </ToolbarButtons>
      <Panel>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Cód Acerto'
                text={filtros?.cdAcertoMotorista}
                onChangedValue={(cdAcertoMotorista) =>
                  setFiltros({
                    ...filtros,
                    cdAcertoMotorista,
                  })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Motorista'
                searchDataSource={onSearchPessoaMot}
                selectedItem={filtros.pessoaMot}
                onSelectItem={(pessoaMot) => {
                  setFiltros({
                    ...filtros,
                    pessoaMot,
                    nrSeqPessoaMot: pessoaMot.nrSeqPessoaMot,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>

            <div className='col-3 mb-3'>
              <Textbox
                label='Compêtencia'
                text={filtros?.mesAnoRef}
                mask={MaskTypes.Competence}
                onBlur={vlrCompetence}
                onChangedValue={(mesAnoRef) =>
                  setFiltros({
                    ...filtros,
                    mesAnoRef,
                  })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Placa'
                searchDataSource={onSearchVeiculo}
                selectedItem={filtros.veiculo}
                onSelectItem={(veiculo) => {
                  setFiltros({
                    ...filtros,
                    veiculo,
                    NrSeqVeiculoPrinc: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='noVeiculo'
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Saida De'
                text={filtros.dtSaida}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtSaida) => setFiltros({ ...filtros, dtSaida })}
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='saida Até'
                text={filtros.dtSaidaAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtSaidaAte) =>
                  setFiltros({ ...filtros, dtSaidaAte })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Chegada'
                text={filtros.dtChegada}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtChegada) => setFiltros({ ...filtros, dtChegada })}
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Chegada Ate'
                text={filtros.dtChegadaAte}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtChegadaAte) =>
                  setFiltros({ ...filtros, dtChegadaAte })
                }
              />
            </div>
            <div className='col-4 mb-4'>
              <RadioButton
                label='Tipos de Acerto Motorista'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.tipoAcertoMotorista}
                buttons={buttonAcertoMotorista}
                onChange={(tipoAcertoMotorista) => {
                  setFiltros({
                    ...filtros,
                    tipoAcertoMotorista,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <Autocomplete
                label='Cliente (Receitas)'
                searchDataSource={onSearchCliente}
                selectedItem={filtros.cliente}
                nrseqTransactionToOpen='nrSeqPessoaCli'
                onSelectItem={(cliente) => {
                  setFiltros({
                    ...filtros,
                    cliente,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-4 mb-2'>
              <Autocomplete
                label='Cidade Destino (Receitas)'
                searchDataSource={onSearchCidade}
                selectedItem={filtros.cidade}
                onSelectItem={(cidade) => {
                  setFiltros({
                    ...filtros,
                    cidade,
                    nrSeqCidade: cidade.nrSeqCidade,
                  });
                }}
                dataSourceTextProperty='noCidadeEstado'
              />
            </div>
            <div className='col-2 mb-4'>
              <RadioButton
                label='Buscar Info Receitas'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.flgBuscaInfoReceitas}
                buttons={PesquisaReceita}
                onChange={(flgBuscaInfoReceitas) => {
                  setFiltros({
                    ...filtros,
                    flgBuscaInfoReceitas,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col'>
          <GridView
            enableExcelExport
            canControlVisibility
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
