import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import {
  getTipoCombustivel,
  saveTipoCombustivel,
} from 'core/services/FRO/tipoCombustivel';

import { getProdutoAutoComplete } from 'core/services/EST/produto';

export default function TipoCombustivelItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      setData(await getTipoCombustivel(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveTipoCombustivel(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noProduto: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo Combustivel'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={20}
            readOnly
            label='Código'
            text={data.cdTipoCombustivel}
            onChangedValue={(cdTipoCombustivel) =>
              setData({ ...data, cdTipoCombustivel })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-6 mb-3'>
          <Textbox
            maxLength={60}
            label='Nome'
            required
            text={data.noTipoCombustivel}
            onChangedValue={(noTipoCombustivel) =>
              setData({ ...data, noTipoCombustivel })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-6 mb-3'>
          <Autocomplete
            label='Produto'
            searchDataSource={onSearchProduto}
            selectedItem={data.produto}
            onSelectItem={(produto) => {
              setData({
                ...data,
                produto,
                nrSeqProduto: produto.nrSeqProduto,
              });
            }}
            dataSourceTextProperty='noProduto'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Compõe Media Geral'
            checked={data.flgCompoeMediaGeral}
            onChange={(flgCompoeMediaGeral) =>
              setData({ ...data, flgCompoeMediaGeral })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Calcular Media de Tanque não cheio'
            checked={data.flgCalculaMediaNaoCheio}
            onChange={(flgCalculaMediaNaoCheio) =>
              setData({ ...data, flgCalculaMediaNaoCheio })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col mb-3'>
          <Switch
            formControl
            label='Combustível Principal'
            checked={data.flgPrincipal}
            onChange={(flgPrincipal) => setData({ ...data, flgPrincipal })}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
