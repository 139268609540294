import React, { useRef, useEffect, useState, useCallback } from 'react';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { BootstrapSizes, PageTypes } from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  CSDManutPage,
  GridView,
  Panel,
  RadioButton,
  Switch,
  Textbox,
  ToolbarButtons,
} from 'ui/components';

import {
  getControleMercadoria,
  saveControleMercadoria,
  deleteControleMercadoria,
  generateTravel,

  // generateTravelUnique,
  removeDelivery,
  releaseForCounting,
  excelCheckCountingReport,
  printCheckMaterialsReport,
  printDeliveryReport,
} from 'core/services/FRO/controleMercadoria';

import {
  printColetaEmbarque,
  transmitCte,
  downloadCte,
} from 'core/services/FRO/coletaEmbarque';

import {
  transmitirMultiplosCtes,
  excelViagemRomaneio,
  manifestarViagem,
} from 'core/services/FRO/viagem';

import { getSearchPendingControlMerchandiseList } from 'core/services/COP/nf';

import { getMotoristaViagemAutoComplete } from 'core/services/FRO/motorista';

import {
  getVeiculoAutoComplete,
  getVeiculoPorVinculoAutoComplete,
} from 'core/services/FRO/veiculo';

import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';

import {
  getAlmoxarifadoAutoComplete,
  getVerifyMultipleWarehouses,
} from 'core/services/EST/almoxarifado';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ColumnTypes, ResponseStatus, Theme } from 'ui/Helpers/enums.ts';
import ControleMercadoria from 'core/models/FRO/controleMercadoria';
import { Empresa } from 'core/models/SEG';
import { Almoxarifado } from 'core/models/EST';
import Veiculo from 'core/models/FRO/veiculo';
import { Motorista, Viagem } from 'core/models/FRO';

import { NfControleMercadoriaModel } from 'core/models/FRO/models';
import ModalManifestarViagem from '../Viagem/modalManifestarViagem';
import ModalDetalhesControleMercadoria from '../ControleMercadoria/modalDetalhesControleMercadoria';

export default function ControleEmbarqueMercadoriastem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
  onOpenReport,
}: Page) {
  interface filtroNotas {
    noFornecedor: string;
    nrFornNf: string;
    noCidadeDestino: string;
    noUfDestino: string;
  }

  const formSubmit = useRef<any>();

  const [data, setData] = useState<ControleMercadoria | {}>({ noTipo: 'E' });
  const [filtros, setFiltros] = useState<filtroNotas | {}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [modalShowManifestar, setModalShowManifestar] = useState({});
  const [modalShowDetalhesNf, setModalShowDetalhesNf] = useState({
    nrSeqNfControleMercadoriaHist: undefined,
    show: false,
  });

  const gridViewDocumentosEmbarque = useRef<any>();
  const gridViewDocumentosEmbarqueVincularNotas = useRef<any>();
  const gridViewValidacaoContagemProdutos = useRef<any>();

  const style = {
    id: {
      fontWeight: 'bold',
      fontSize: '25px',
      textAlign: 'center',
      backgroundColor: 'white',
      border: '1px solid #80808052',
      padding: '20px',
    },
  };

  const onSetMessage = (status: ResponseStatus, msg: string) => {
    let theme;

    if (status === ResponseStatus.Success) theme = Theme.Success;
    else if (status === ResponseStatus.Warning) theme = Theme.Warning;
    else theme = Theme.Danger;

    if (msg)
      setMessage({
        message: msg,
        theme,
      });
  };

  const onClickOpenNf = async (sourceRow: any) => {
    const { nrSeqNf } = sourceRow;

    const transactionNotaEntrada = findTransaction(
      TransacationCodes.NotaFiscalEntrada
    );

    if (transactionNotaEntrada) {
      transactionNotaEntrada.registryKey = nrSeqNf;
      onOpenTransaction(transactionNotaEntrada, true, undefined, true);
    }
  };

  const onClickOpenViagem = async (e: any) => {
    const transactionViagem = findTransaction(TransacationCodes.Viagem);

    if (transactionViagem) {
      transactionViagem.registryKey = e.nrSeqViagem;
      onOpenTransaction(transactionViagem, true, undefined, true);
    }
  };

  const onClickDownload = async (e: any) => {
    setLoading(true);

    const { data: xmls, status, message: msg } = await downloadCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (status !== ResponseStatus.Success) {
      onSetMessage(status, msg);
    } else if (xmls) {
      for (let i = 0; i < xmls.length; i += 1) {
        const xmlURI = encodeURIComponent(xmls[i].xml);
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
        downloadLink.download = `CTe_${e.cdColetaEmbarque}.xml`;
        downloadLink.download = `${xmls[i].nome}_${e.cdColetaEmbarque}.xml`;
        downloadLink.click();
      }
    }

    setLoading(false);
  };

  const onClickPrintCheckMaterialsReport = async () => {
    setLoading(true);

    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    const { status, message: msg, value } = await printCheckMaterialsReport({
      nrSeqControleMercadoria,
    });

    if (status === ResponseStatus.Success) {
      onOpenReport(transaction.noTransacao, value);
      const downloadLink = document.createElement('a');
      const xmlURI = encodeURIComponent(value);
      downloadLink.href = `data:image/pdf;base64, ${xmlURI}`;
      downloadLink.download = `relatorio.pdf`;
      downloadLink.download = `relatorio.pdf`;
      downloadLink.click();
    } else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickPrintDeliveryReport = async () => {
    setLoading(true);

    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    const { status, message: msg, value } = await printDeliveryReport({
      nrSeqControleMercadoria,
    });

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickManifestarViagem = async () => {
    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const obj = new Viagem({
      nrSeqControleMercadoria,
    });

    const { status, message: msg, data: manifesto } = await manifestarViagem(
      obj
    );

    if (status === ResponseStatus.Error) {
      if (msg) onSetMessage(status, msg);
    } else {
      setModalShowManifestar({
        ...modalShowManifestar,

        show: true,
        data: {
          ...manifesto,
          viagem: obj,
        },
      });
    }
  };

  const onClickImprimirCte = async (e: any) => {
    setLoading(true);

    const { nrSeqColetaEmbarque } = e;
    const { status, message: msg, value } = await printColetaEmbarque({
      nrSeqColetaEmbarque,
    });

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickTrasmiteCte = async (e: any) => {
    setLoading(true);
    const { nrSeqColetaEmbarque } = e;
    const { status, messages: msgs } = await transmitCte({
      nrSeqColetaEmbarque,
    });

    if (msgs) onSetMessage(status, msgs[0].message);

    setLoading(false);
  };

  const onAutoCompleteTipoEmbarque = async (e: any, nrSeqEmpresa: any) => {
    const {
      status,
      message: msg,
      data: tiposEmbarque,
    } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
      // @ts-expect-error
      nrSeqEmpresa: nrSeqEmpresa ?? data.nrSeqEmpresa,
    });

    if (msg) onSetMessage(status, msg);

    return tiposEmbarque;
  };

  const saveOrderEmbarque = async (keyValue: any, value: any) => {
    const datasource = gridViewDocumentosEmbarque.current
      ? gridViewDocumentosEmbarque.current.getDataSource()
      : [];

    datasource.find((item: any) => item.nrSeqNf === keyValue).nrOrdem = value;

    if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
      gridViewDocumentosEmbarque.current.setDataSource(datasource);
  };

  const saveObsEmbarque = async (keyValue: any, value: any) => {
    const datasource = gridViewDocumentosEmbarque.current
      ? gridViewDocumentosEmbarque.current.getDataSource()
      : [];

    datasource.find(
      (item: any) => item.nrSeqNf === keyValue
    ).noObservacoes = value;

    if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
      gridViewDocumentosEmbarque.current.setDataSource(datasource);
  };

  const saveOrder = async (keyValue: any, value: any) => {
    saveOrderEmbarque(keyValue, value);
  };

  const saveObs = async (keyValue: any, value: any) => {
    saveObsEmbarque(keyValue, value);
  };

  const onClickOpenDetailsNf = async (e: any) => {
    const { nrSeqNfControleMercadoriaHist } = e;

    setModalShowDetalhesNf({
      show: true,
      nrSeqNfControleMercadoriaHist,
    });
  };

  const columns = [
    {
      key: 'nrSeqNf',
      type: 2,
      sortable: true,
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'nrSeqColetaEmbarque',
      visible: false,
    },
    {
      key: 'fornecedor.noPessoa',
      title: 'Remetente',
    },
    {
      key: 'destinatario.noPessoa',
      title: 'Destinatário',
    },

    {
      key: 'cidadeEntrega.noCidade',
      title: 'Cidade Entrega',
    },

    {
      key: 'cidadeEntrega.estado.cdEstado',
      title: 'UF Entrega',
    },

    { key: 'nrForNf', title: 'Número' },

    {
      key: 'flgReentrega',
      title: 'Reentrega',
      // @ts-expect-error
      format: GridView.DataTypes.Boolean,
    },

    { key: 'nrSeqViagem', title: 'Viagem' },

    { key: 'cdColetaEmbarque', title: 'CTE' },

    { key: 'averbado', title: 'Averbado', color: 'noColorAverbado' },
    {
      key: 'statusCte',
      title: 'status',
      color: 'noColorStatus',
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      // @ts-expect-error
      format: GridView.DataTypes.DecimalCustom,

      onBlur: saveOrder,
      maxLength: 10,
    },

    {
      key: 'noObservacoes',
      title: 'Obs',
      // @ts-expect-error
      type: GridView.ColumnTypes.Textbox,
      // @ts-expect-error
      format: GridView.DataTypes.DecimalCustom,

      onBlur: saveObs,
      maxLength: 100,
    },

    {
      key: 'nrSeqNf',
      title: 'Nf',
      type: ColumnTypes.Button,
      onClick: (e: any) => onClickOpenNf(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Abrir',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Env',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraTransmitir',
      onClick: (e: any) => onClickTrasmiteCte(e),
      theme: Theme.Success,
      icon: 'share',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Transmitir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Imp',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      visibleDynamic: 'flgMostraDownload',
      title: 'Dow',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickDownload(e),
      theme: Theme.Info,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqViagem',
      title: 'Viag',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickOpenViagem(e),
      theme: Theme.Danger,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Viagem',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqNf',
      title: 'Det',
      // @ts-expect-error
      type: GridView.ColumnTypes.Button,
      onClick: (e: any) => onClickOpenDetailsNf(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Detalhes',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsVincularNotas = [
    {
      key: 'nrSeqNf',
      type: 2,
      sortable: true,
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'nrSeqColetaEmbarque',
      visible: false,
    },
    {
      key: 'fornecedor.noPessoa',
      title: 'Remetente',
    },
    {
      key: 'destinatario.noPessoa',
      title: 'Destinatário',
    },

    {
      key: 'cidadeEntrega.noCidade',
      title: 'Cidade Entrega',
    },

    {
      key: 'cidadeEntrega.estado.cdEstado',
      title: 'UF Entrega',
    },

    { key: 'nrForNf', title: 'Número' },

    { key: 'nrSeqViagem', title: 'Viagem' },

    { key: 'cdColetaEmbarque', title: 'CTE' },
  ];

  const columnsContagemMercadorias = [
    {
      key: 'nrSeqPessoaFor',
      visible: false,
    },
    {
      key: 'noFornecedor',
      title: 'Fornecedor',
    },
    {
      key: 'nrSeqNf',
      title: 'nrSeqNf',
      visible: false,
    },

    {
      key: 'nrForNf',
      title: 'Número',
    },

    {
      key: 'nrSeqProduto',
      title: 'nrSeqProduto',
      visible: false,
    },

    { key: 'cdPeca', title: 'cdPeca' },
    { key: 'noProduto', title: 'Produto' },

    { key: 'noEan', title: 'EAN' },

    { key: 'qtdeItNf', title: 'Quant.Nota', format: 2 },

    { key: 'nrQuantidade', title: 'Contagem', format: 2 },

    { key: 'nrQuantidadeDiferenca', title: 'Diferença', format: 2 },
  ];

  const tipo = [
    {
      text: 'Embarque',
      value: 'E',
    },
  ];

  const checkCompanyHasMultipleWarehouses = async () => {
    const sessionData = sessionStorage.getItem('@dataAuth');
    const sessionUser = sessionData ? JSON.parse(sessionData).user : null;
    const { empresas } = await getEmpresaDefaultUsuario({
      nrSeqUsuario: sessionUser.nrSeqUsuario,
    });

    if (empresas && empresas.length > 0) {
      const nrseqempresa = empresas[0].nrSeqEmpresa;

      type Response = {
        data: Almoxarifado | {};
      };

      const almoxarifadoRes: Response = await getVerifyMultipleWarehouses({
        nrSeqEmpresa: nrseqempresa,
      });

      if (almoxarifadoRes.data && 'nrSeqAlmoxarifado' in almoxarifadoRes.data) {
        setData({
          ...data,
          noTipo: 'E',
          almoxarifado: almoxarifadoRes.data,
          nrSeqAlmoxarifado: almoxarifadoRes.data.nrSeqAlmoxarifado,
        });
      }
    }
  };
  const load = useCallback(
    async (nrseqcontrolemercadoria: number | undefined): Promise<void> => {
      let id;

      if (nrseqcontrolemercadoria) id = nrseqcontrolemercadoria;

      if (!id && !isNaN(registryKey)) {
        id = registryKey;
      }

      if (id) {
        setLoading(true);

        const value = await getControleMercadoria(id);
        if (value.noTipo === 'E') {
          if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
            gridViewDocumentosEmbarque.current.setDataSource(
              value.notasVinculadas
            );
        }

        if (
          gridViewDocumentosEmbarqueVincularNotas &&
          gridViewDocumentosEmbarqueVincularNotas.current
        )
          gridViewDocumentosEmbarqueVincularNotas.current.setDataSource([]);

        if (
          gridViewValidacaoContagemProdutos &&
          gridViewValidacaoContagemProdutos.current
        )
          gridViewValidacaoContagemProdutos.current.setDataSource(
            value.contagem
          );

        setData(value);
        setLoading(false);
        setFiltros({
          ...filtros,
          nrForNf: '',
        });
      } else {
        if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
          gridViewDocumentosEmbarque.current.setDataSource([]);

        if (
          gridViewDocumentosEmbarqueVincularNotas &&
          gridViewDocumentosEmbarqueVincularNotas.current
        )
          gridViewDocumentosEmbarqueVincularNotas.current.setDataSource([]);

        if (
          gridViewValidacaoContagemProdutos &&
          gridViewValidacaoContagemProdutos.current
        )
          gridViewValidacaoContagemProdutos.current.setDataSource([]);

        checkCompanyHasMultipleWarehouses();
      }
      setMessage(null);
    },
    [registryKey]
  );

  useEffect(() => {
    (async function func(): Promise<void> {
      await load(undefined);
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func(): Promise<void> {
      await load(undefined);
    })();
  }, [load, reload]);

  const onAutoCompleteVeiculo = async (e: any) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteVeiculoCompAB = async (e: any) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoPorVinculoAutoComplete({
      placa: e,
      nrSeqVeiculo: 'nrSeqVeiculo' in data ? data.nrSeqVeiculo ?? null : '',
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteMotorista = async (e: any) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaViagemAutoComplete({
      noPessoa: e,
      nrSeqVeiculo: 'nrSeqVeiculo' in data ? data.nrSeqVeiculo ?? null : null,
    });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const save = async (
    notasImportar: Array<string> | undefined,
    notasVincular: Array<NfControleMercadoriaModel> | undefined
  ): Promise<{ status: number; nrSeqControleMercadoria: number }> => {
    setLoading(true);

    const notasImportarArray = Array.isArray(notasImportar)
      ? notasImportar
      : undefined;
    const notasVincularArray = Array.isArray(notasVincular)
      ? notasVincular
      : undefined;

    const { status, message: msg, value } = await saveControleMercadoria({
      ...data,
      notasImportar: notasImportarArray,
      notasVincular: notasVincularArray,
    });

    if (status === ResponseStatus.Success) {
      setData(value);

      if (value.noTipo === 'E') {
        if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
          gridViewDocumentosEmbarque.current.setDataSource(
            value.notasVinculadas
          );
      }
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);

    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in value ? value.nrSeqControleMercadoria : 0;
    return { status, nrSeqControleMercadoria };
  };

  const onExcluir = async (): Promise<void> => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteControleMercadoria(
        registryKey
      );

      setData(status === ResponseStatus.Success ? { noTipo: 'E' } : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onAutoCompleteEmpresa = async (e: string) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const onSearchNotasNaoVinculadas = async () => {
    const {
      status,
      message: msg,
      data: notas,
    } = await getSearchPendingControlMerchandiseList({
      // @ts-expect-error
      noFornecedor: filtros.noFornecedor,
      // @ts-expect-error
      nrForNf: filtros.nrForNf,
      // @ts-expect-error
      noCidadeDestino: filtros.noCidadeDestino,
      // @ts-expect-error
      noUfDestino: filtros.noUfDestino,
      // @ts-expect-error
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (msg) onSetMessage(status, msg);
    return notas;
  };

  const onAddNotasNaoVinculadasNaGrid = async () => {
    const notas = await onSearchNotasNaoVinculadas();

    if (
      gridViewDocumentosEmbarqueVincularNotas &&
      gridViewDocumentosEmbarqueVincularNotas.current
    )
      gridViewDocumentosEmbarqueVincularNotas.current.setDataSource(notas);
  };

  const onAddNotasEmbarque = async () => {
    if (
      gridViewDocumentosEmbarqueVincularNotas &&
      gridViewDocumentosEmbarqueVincularNotas.current
    ) {
      const selecteds = gridViewDocumentosEmbarqueVincularNotas.current.getCheckBoxValuesAt(
        0
      );

      if (selecteds.length === 0) {
        onSetMessage(0, 'Nota não localizada para inclusão');
      }

      const { status, nrSeqControleMercadoria } = await save(
        undefined,
        selecteds.map((row: any) => {
          const item: NfControleMercadoriaModel = Object.create(
            NfControleMercadoriaModel.prototype
          );

          item.nrSeqNf = row[0].value;

          return item;
        })
      );
      if (status === ResponseStatus.Success) {
        await load(nrSeqControleMercadoria);
      }

      if (status === ResponseStatus.Success) {
        await onSearchNotasNaoVinculadas();
      }
    }
  };

  const onAddNotasEmbarqueOnEnter = async () => {
    const selecteds = await onSearchNotasNaoVinculadas();
    if (selecteds.length === 0) {
      onSetMessage(0, 'Nota não localizada para inclusão');
      return;
    }

    const { status, nrSeqControleMercadoria } = await save(
      undefined,
      selecteds.map((row: any) => {
        const item: NfControleMercadoriaModel = Object.create(
          NfControleMercadoriaModel.prototype
        );

        item.nrSeqNf = row.nrSeqNf;

        return item;
      })
    );

    if (status === ResponseStatus.Success) {
      await load(nrSeqControleMercadoria);
    }

    if (status === ResponseStatus.Success) {
      await onAddNotasNaoVinculadasNaGrid();
    }
  };

  const onGenerateTravel = async () => {
    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await generateTravel(
      nrseqcontrolemercadoria
    );

    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }
    if (msg) onSetMessage(status, msg);
  };

  /* const onGenerateTravelUnique = async () => {
    const selecteds = gridViewDocumentosEmbarque.current.getCheckBoxValuesAt(0);

    if (selecteds.length === 0) {
      onSetMessage(
        0,
        'Necessário selecionar as notas para geração do cte único'
      );

      return;
    }
     

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await generateTravelUnique(
      nrseqcontrolemercadoria,
      selecteds.map((row: any) => row[0].value)
    );

    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }
    if (msg) onSetMessage(status, msg);
  };
   */

  const transmitirCtesSelecionados = async () => {
    const selecteds = gridViewDocumentosEmbarque.current
      .getCheckBoxValuesAt(0)
      .map((row: any) => row[1].value);

    if (selecteds.length <= 0) {
      onSetMessage(1, 'Necessário selecionar pelo menos um conhecimento.');

      return;
    }

    setLoading(true);

    await transmitirMultiplosCtes({
      nrSeqsColetaEmbarque: selecteds,
    });

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    await load(nrseqcontrolemercadoria);

    onSetMessage(
      ResponseStatus.Success,
      'Ctes transmitidos, confirme o status de transmissão de cada cte.'
    );

    setLoading(false);
  };

  const onRemoveDelivery = async () => {
    const selecteds = gridViewDocumentosEmbarque.current.getCheckBoxValuesAt(0);

    if (selecteds.length === 0) {
      onSetMessage(0, 'Necessário selecionar as notas para remoção');

      return;
    }

    const nrseqcontrolemercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;

    const { status, message: msg } = await removeDelivery(
      selecteds.map((row: any) => row[0].value),
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0
    );

    if (status === ResponseStatus.Success) {
      await load(nrseqcontrolemercadoria);
    }
    if (msg) onSetMessage(status, msg);
  };

  const onSearchAlmoxarifadoEmpresaAutoComplete = async (e: any) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
      nrSeqEmpresa: 'nrSeqEmpresa' in data ? data.nrSeqEmpresa : 0,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onReleaseForCounting = async () => {
    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    await releaseForCounting(nrSeqControleMercadoria);

    await load(nrSeqControleMercadoria);
  };

  const onDownloadExcel = async () => {
    setLoading(true);
    const nrSeqControleMercadoria =
      'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0;
    const {
      value: base64,
      status,
      message: msg,
    } = await excelCheckCountingReport(nrSeqControleMercadoria);

    if (msg) onSetMessage(status, msg);
    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `planilha_${nrSeqControleMercadoria}.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onClickGerarImprimirRomaneio = async () => {
    setLoading(true);

    const { status, message: msg, value: base64 } = await excelViagemRomaneio({
      nrSeqControleMercadoria:
        'nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = 'Romaneio.csv';
        downloadLink.click();
      }
      onSetMessage(
        status,
        'O arquivo foi gerado com sucesso, confira nos seus downloads.'
      );
    }

    setLoading(false);
  };

  const handleNotasVincudas = () => {
    const notas = 'notasVinculadas' in data ? data.notasVinculadas : [];

    return notas?.length;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Controle Embarque Mercadorias'
      loading={loading}
      disableSaveEnter
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        setData({ noTipo: 'E' });

        if (gridViewDocumentosEmbarque && gridViewDocumentosEmbarque.current)
          gridViewDocumentosEmbarque.current.setDataSource([]);

        if (
          gridViewDocumentosEmbarqueVincularNotas &&
          gridViewDocumentosEmbarqueVincularNotas.current
        )
          gridViewDocumentosEmbarqueVincularNotas.current.setDataSource([]);

        if (
          gridViewValidacaoContagemProdutos &&
          gridViewValidacaoContagemProdutos.current
        )
          gridViewValidacaoContagemProdutos.current.setDataSource([]);
      }}
      onSave={save}
      onDelete={
        data &&
        ('nrSeqControleMercadoria' in data ? data.nrSeqControleMercadoria : 0) >
          0
          ? onExcluir
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {/* @ts-ignore */}
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Manifestar Viagem'
          onClick={() => onClickManifestarViagem()}
        />
        {/* @ts-ignore */}
        <ToolbarButtons.ButtonList
          text='Imprimir'
          icon='caret-down'
          items={[
            {
              text: 'Espelho das Notas',
              icon: 'print',
              onClick: () => onClickPrintCheckMaterialsReport(),
            },
            {
              text: 'Planilha de Conferência',
              icon: 'print',
              onClick: () => onDownloadExcel(),
            },
            {
              text: 'Romaneio',
              icon: 'print',
              onClick: () => onClickGerarImprimirRomaneio(),
            },

            {
              text: 'Ordem de Entrega',
              icon: 'print',
              onClick: () => onClickPrintDeliveryReport(),
            },
          ]}
        />
      </ToolbarButtons>

      <div className='row mb-3'>
        <div className='col-4 mb-4'>
          <RadioButton
            label='Tipo'
            disabled={false}
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={'noTipo' in data ? data.noTipo : null}
            buttons={tipo}
            onChange={(noTipo: string) => {
              setData({
                ...data,
                noTipo,
              });
            }}
          />
        </div>

        <div className='col-8 mt-3 text-end'>
          {/* @ts-ignore */}
          <span style={style.id}>
            {'nrSeqControleMercadoria' in data
              ? data.nrSeqControleMercadoria
              : null}
          </span>
        </div>
      </div>

      <>
        <div className='row mb-3'>
          <div className='col-6'>
            <Autocomplete
              label='Empresa'
              required
              searchDataSource={onAutoCompleteEmpresa}
              selectedItem={'empresa' in data ? data.empresa : null}
              onSelectItem={async (empresa: Empresa) => {
                setData({
                  ...data,
                  empresa,
                  nrSeqEmpresa: empresa.nrSeqEmpresa,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-3'>
            <Autocomplete
              label='Veículo'
              searchDataSource={onAutoCompleteVeiculo}
              selectedItem={'veiculo' in data ? data.veiculo : null}
              onSelectItem={(veiculo: Veiculo) =>
                setData({
                  ...data,
                  veiculo,
                  nrSeqVeiculo: veiculo.nrSeqVeiculo,
                })
              }
              dataSourceTextProperty='noVeiculo'
            />
          </div>

          <div className='col-3'>
            <Autocomplete
              label='Veículo Comp A'
              tooltip='Serão listados apenas os veículos vinculados ao veículo principal'
              searchDataSource={onAutoCompleteVeiculoCompAB}
              selectedItem={'veiculoComp' in data ? data.veiculoComp : null}
              onSelectItem={(veiculoComp: Veiculo) =>
                setData({
                  ...data,
                  veiculoComp,
                  nrSeqVeiculoComp: veiculoComp.nrSeqVeiculo,
                })
              }
              dataSourceTextProperty='noVeiculoComp'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Autocomplete
              label='Motorista'
              tooltip='É obrigatório a seleção do veículo e apenas os motoristas cadastrados para o veículo principal serão listados'
              searchDataSource={onAutoCompleteMotorista}
              selectedItem={'motorista' in data ? data.motorista ?? null : null}
              onSelectItem={(motorista: Motorista) => {
                setData({
                  ...data,
                  motorista,
                  nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                });
              }}
              dataSourceTextProperty='noPessoa'
            />
          </div>
        </div>
        <div className='row  mt-3'>
          <div className='col-6'>
            <Autocomplete
              label='Almoxarifado'
              required
              searchDataSource={onSearchAlmoxarifadoEmpresaAutoComplete}
              selectedItem={'almoxarifado' in data ? data.almoxarifado : null}
              onSelectItem={(almoxarifado: Almoxarifado) => {
                setData({
                  ...data,
                  almoxarifado,
                  nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                });
              }}
              dataSourceTextProperty='noAlmoxarifado'
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-6'>
            <Autocomplete
              label='Tipo Embarque'
              searchDataSource={onAutoCompleteTipoEmbarque}
              // @ts-expect-error
              selectedItem={data.tipoEmbarque}
              onSelectItem={async (tipoEmbarque: any) => {
                setData({
                  ...data,
                  tipoEmbarque,
                  nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                });
              }}
              dataSourceTextProperty='noTipoEmbarque'
            />
          </div>
        </div>
        <div className='row  mt-3'>
          <div className='col-2 mb-3'>
            {/* @ts-ignore */}
            <Switch
              formControl
              label='Exige Conferência'
              checked={
                'flgExigeConferencia' in data
                  ? data.flgExigeConferencia ?? false
                  : false
              }
              onChange={(flgExigeConferencia: boolean) =>
                setData({ ...data, flgExigeConferencia })
              }
            />
          </div>

          <div className='col-2'>
            {/* @ts-ignore */}
            <Textbox
              maxLength={20}
              readOnly
              label='Data Conferência'
              text={'dtConferencia' in data ? data.dtConferencia : null}
            />
          </div>

          <div className='col-2 mt-3'>
            <Button
              icon='plus'
              text='Liberar Conferência'
              size={BootstrapSizes.Large}
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={() => onReleaseForCounting()}
            />
          </div>
        </div>

        <div className='row mt-3'>
          {/* @ts-ignore */}
          <Panel>
            {/* @ts-ignore */}
            <Panel.Header title='Vincular notas' theme={Theme.Primary} />

            <Panel.Body>
              <div className='row mt-3 mt-1'>
                <div className='col-2'>
                  {/* @ts-ignore */}
                  <Textbox
                    label='Cliente'
                    text={
                      'noFornecedor' in filtros ? filtros.noFornecedor : null
                    }
                    onChangedValue={(noFornecedor: string) => {
                      setFiltros({
                        ...filtros,
                        noFornecedor,
                      });
                    }}
                  />
                </div>

                <div className='col-2'>
                  {/* @ts-ignore */}
                  <Textbox
                    label='Número Nota'
                    // @ts-expect-error
                    text={'nrFornNf' in filtros ? filtros.nrForNf : ''}
                    onChangedValue={(nrForNf: string) => {
                      setFiltros({
                        ...filtros,
                        nrForNf,
                      });
                    }}
                    onEnter={onAddNotasEmbarqueOnEnter}
                  />
                </div>

                <div className='col-3 mt-1'>
                  {/* @ts-ignore */}
                  <Textbox
                    label='Cidade Destino'
                    text={
                      'noCidadeDestino' in filtros
                        ? filtros.noCidadeDestino
                        : null
                    }
                    onChangedValue={(noCidadeDestino: string) => {
                      setFiltros({
                        ...filtros,
                        noCidadeDestino,
                      });
                    }}
                  />
                </div>

                <div className='col-2 mt-1'>
                  {/* @ts-ignore */}
                  <Textbox
                    label='Estado Destino'
                    text={
                      'noEstadoDestino' in filtros
                        ? filtros.noEstadoDestino
                        : null
                    }
                    onChangedValue={(noEstadoDestino: string) => {
                      setFiltros({
                        ...filtros,
                        noEstadoDestino,
                      });
                    }}
                  />
                </div>

                <div className='col-3 '>
                  <Button
                    text='Pesquisar Notas'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Medium}
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={onAddNotasNaoVinculadasNaGrid}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <GridView
                    ref={gridViewDocumentosEmbarqueVincularNotas}
                    // @ts-expect-error
                    dataColumns={columnsVincularNotas}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-4  '>
                  <Button
                    text='Adicionar Notas'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Large}
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={onAddNotasEmbarque}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mt-3'>
          {/* @ts-ignore */}
          <Panel>
            {/* @ts-ignore */}
            <Panel.Header
              title='Notas vinculadas neste embarque'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col-2  '>
                  <Button
                    text='Remover Notas'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Large}
                    theme={Theme.Danger}
                    template={Button.Templates.Default}
                    onClick={onRemoveDelivery}
                  />
                </div>
                <div className='col-3  '>
                  <Button
                    text='Gerar Viagem Para Motorista'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Large}
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    onClick={onGenerateTravel}
                  />
                </div>
                {/* <div className='col-3  '>
                    <Button
                      text='Gerar Conhecimento Agrupado'
                      className='mb-3 mt-4'
                      size={BootstrapSizes.Large}
                      theme={Theme.Success}
                      template={Button.Templates.Default}
                      onClick={onGenerateTravelUnique}
                    />
                  </div>
                  */}
                <div className='col-3 '>
                  <Button
                    theme={Theme.Success}
                    size={BootstrapSizes.Large}
                    className='mb-3 mt-4'
                    icon='share'
                    text='Transmitir CTes Selecionados'
                    onClick={transmitirCtesSelecionados}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <GridView
                    ref={gridViewDocumentosEmbarque}
                    // @ts-expect-error
                    dataColumns={columns}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <span className='form-label'>
                    Quantidade de Notas: {handleNotasVincudas()}
                  </span>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </>

      <>
        <div className='row mt-3'>
          {/* @ts-ignore */}
          <Panel>
            {/* @ts-ignore */}
            <Panel.Header
              title='Contagem de Mercadorias'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col'>
                  <GridView
                    // @ts-expect-error
                    enableExcelExport
                    ref={gridViewValidacaoContagemProdutos}
                    dataColumns={columnsContagemMercadorias}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>

        <div className='row'>
          <div className='col'>
            <ModalManifestarViagem
              // @ts-expect-error
              show={modalShowManifestar?.show}
              // @ts-expect-error
              data={modalShowManifestar?.data}
              onOpenReport={(e: any) => {
                onOpenReport(transaction.noTransacao, e);
              }}
              onClose={() => {
                setModalShowManifestar({
                  ...modalShowManifestar,

                  show: false,
                  data: undefined,
                });
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <ModalDetalhesControleMercadoria
              show={modalShowDetalhesNf?.show}
              nrSeqNfControleMercadoriaHist={
                modalShowDetalhesNf?.nrSeqNfControleMercadoriaHist
              }
              onClose={() => {
                setModalShowDetalhesNf({
                  ...modalShowDetalhesNf,
                  show: false,
                });
              }}
            />
          </div>
        </div>
      </>
    </CSDManutPage>
  );
}
