import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from 'ui/pages/login';
import Home from 'ui/pages/Home';

import DownloadConta from 'ui/pages/TEL/DownloadConta/selecao';
import DownloadNFS from 'ui/pages/FIN/DownloadNFS/selecao';
import DownloadContaAssociado from '../pages/ASS/DownloadContaAssociado/selecao';
import PrivateRoute from './privateRoute';

const Routes = (props) => {
  const { isAuthenticated } = props;

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute
          exact
          path='/'
          component={Home}
          isAuthenticated={isAuthenticated}
        />
        <Route path='/Login' component={Login} />
        <Route path='/DownloadConta/:idConta' component={DownloadConta} />
        <Route
          path='/DownloadContaAssociado/:idConta'
          component={DownloadContaAssociado}
        />
        <Route path='/DownloadNFS/:idNFS' component={DownloadNFS} />

        <Route path='*' component={() => <h1>Página não encontrada</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (store) => ({
  isAuthenticated: store.authState.isAuthenticated,
});
export default connect(mapStateToProps)(Routes);
