import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getOficina,
  saveOficina,
  deleteOficina,
} from 'core/services/FRO/oficina';
import { IOficina } from 'core/interfaces/FRO';
// @ts-expect-error
import OficinaPanel from './oficinaPanel.tsx';

interface IOficinaState extends IOficina {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Oficina({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const oficina = useRef<any>(null);

  const [oficinaData, setOficinaData] = useState<IOficinaState | any>({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const fillOficinaPanel = () => {
    oficina?.current?.fillGrid();
  };

  const clearOficinaPanel = () => {
    oficina?.current?.clear();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const onNew = (): void => {
    clearChildCustomer();

    setOficinaData({
      flgFisica: true,
      flgAtivo: true,
      pessoa: {
        nrSeqPessoa: undefined,
      },
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      setLoading(true);

      setOficinaData((prevData) => ({
        ...prevData,
        pessoa: { nrSeqPessoa: undefined },
      }));

      const retornoOficina: IOficina | any = await getOficina(nrSeqPessoa);

      if ('nrSeqPessoaOfi' in retornoOficina) {
        setOficinaData({
          ...retornoOficina,
          pessoa: { nrSeqPessoa: retornoOficina.nrSeqPessoaOfi },
        });

        if (
          retornoOficina.oficinaAlmoxarifados &&
          retornoOficina.oficinaAlmoxarifados.length > 0
        ) {
          fillOficinaPanel();
        } else clearOficinaPanel();
      }
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();
    const { status, message: msg, value } = await saveOficina({
      ...oficinaData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteOficina(registryKey);

      setOficinaData(status === ResponseStatus.Success ? {} : oficinaData);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Oficina'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={oficinaData?.nrSeqPessoaOfi > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={oficinaData.pessoa?.nrSeqPessoa}
            data={oficinaData}
            setData={setOficinaData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Oficina',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <OficinaPanel
                      oficinaData={oficinaData}
                      setOficinaData={setOficinaData}
                      onSetMessage={onSetMessage}
                      ref={oficina}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
