import React, { useRef, useEffect, useState } from 'react';
import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Panel,
  RadioButton,
  Switch,
  ToolbarButtons,
} from 'ui/components';
import {
  getMonitoramentoVeiculoList,
  printEquipamentoVeiculo,
  printMonitoramentoVeiculo,
  printMotoristaDocumento,
  printVeiculoDocumento,
  excelVeiculoDocumento,
  excelMonitoramentoVeiculo,
  excelMotoristaDocumento,
  excelEquipamentoVeiculo,
  printHistoricoOdometro,
} from 'core/services/FRO/MonitoramentoVeiculo';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import ModalVeiculosRodagens from './modalVeiculosRodagens.tsx';

export default function MonitoramentoVeiculo({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const gridView2 = useRef();
  const gridView3 = useRef();
  const gridView4 = useRef();
  const gridView5 = useRef();
  const gridView6 = useRef();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState({ show: false });
  const [filtros, setFiltros] = useState({ tipoMonitoramento: 'V' });
  const [data, setData] = useState({
    flgGridDocumentoMotorista: true,
    flgGridEquipamentoVeiculo: true,
    flgGridManutencao: true,
    flgGridVeiculo: true,
    flgVeiculosVinculadosRodagens: true,
  });

  // #region FUNÇÕES BÁSICAS
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onClickVerHistoricoRodagem = (e) => {
    let tipoHistorico = '';

    if (e.flgPrincipal) {
      tipoHistorico = 'Abastecimentos';
    } else {
      tipoHistorico = 'Veiculo Rodagens';
    }

    setModalShow({ show: true, veiculo: e, tipoHistorico });
  };
  // #endregion

  // #region COLUNAS DAS GRIDS + BUTTONS
  const columns = [
    { key: 'veiculo.noVeiculo', title: 'Veiculo' },
    { key: 'veiID', title: 'Id' },
    { key: 'vel', title: 'Velocidade' },
    { key: 'rua', title: 'Rua' },
    { key: 'mun', title: 'Cidade' },
    { key: 'uf', title: 'Estado' },
    { key: 'odometer', title: 'Odômetro' },
    { key: 'data', title: 'Data Gps', format: GridView.DataTypes.DateHour },
    { key: 'latitude', title: 'Latitude' },
    { key: 'longitude', title: 'Longitude' },
  ];
  const columns2 = [
    {
      key: 'placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'nrOdometroMaximo',
      title: 'Odomêtro para manutenção',
      colorText: 'noColorVeiculo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrOdometroInicial',
      title: 'Odomêtro Atual',
      colorText: 'noColorVeiculo',
      format: GridView.DataTypes.Decimal,
    },
  ];
  const columns3 = [
    {
      key: 'noPessoa',
      title: 'Motorista',
      format: GridView.DataTypes.Text,
      colorText: 'noColorDocumento',
    },
    {
      key: 'dtValidade',
      title: 'Data Validade',
      format: GridView.DataTypes.Date,
      colorText: 'noColorDocumento',
    },
    {
      key: 'noApelido',
      title: 'Documento',
      format: GridView.DataTypes.Text,
      colorText: 'noColorDocumento',
    },
  ];
  const columns4 = [
    {
      key: 'veiculo.placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
      format: GridView.DataTypes.Text,
      colorText: 'noColorVeiculo',
    },
    {
      key: 'dtDesinstalacao',
      title: 'Data Para Manutenção',
      colorText: 'noColorVeiculo',
      format: GridView.DataTypes.Date,
    },
  ];
  const columns5 = [
    {
      key: 'veiculo.placa',
      title: 'Placa',
      format: GridView.DataTypes.Text,
      colorText: 'noColorDocumento',
    },
    {
      key: 'dtFimValidade',
      title: 'Data Validade',
      format: GridView.DataTypes.Date,
      colorText: 'noColorDocumento',
    },
    {
      key: 'tipoDocumentoPessoa.noTipoDocumentoPessoa',
      title: 'Documento',
      format: GridView.DataTypes.Text,
      colorText: 'noColorDocumento',
    },
  ];
  const columns6 = [
    { key: 'nrSeqVeiculo', title: 'NrSeqVeiculo', visible: false },
    { key: 'placa', title: 'Placa' },
    { key: 'noTipoVeiculo', title: 'Tipo do Veículo' },
    { key: 'nrOdometro', title: 'Odômetro' },

    {
      key: 'nrSeqVeiculoRodagem',
      title: 'Ver Histórico',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickVerHistoricoRodagem(e),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ver Histórico',
      tooltipDirection: 'bottom',
    },
  ];
  const buttonMonitoramento = [
    {
      text: 'Vencimento/Proximos',
      value: 'V',
    },
    {
      text: 'Todos',
      value: 'T',
    },
  ];
  // #endregion

  // #region FUNÇÕES
  const onFormataDadosVeiculos = (veiculos) => {
    const veiculosArray = [];

    veiculos.forEach((item) => {
      const veiculoRodagem = {
        nrSeqVeiculo: item.nrSeqVeiculo,
        placa: item.placa,
        noTipoVeiculo: item.flgPrincipal ? 'Cavalo' : 'Carreta',
        nrOdometro: item.nrOdometro,
        flgPrincipal: item.flgPrincipal,
      };

      veiculosArray.push(veiculoRodagem);
    });

    return veiculosArray;
  };
  const search = async () => {
    setLoading(true);
    const {
      status,
      message: msg,
      value: monitoramentoveiculo,
    } = await getMonitoramentoVeiculoList(filtros);

    const veiculosFormatados = onFormataDadosVeiculos(
      monitoramentoveiculo.todosVeiculos
    );

    if (status === ResponseStatus.Error) {
      onSetMessage(ResponseStatus.Error, msg);
    } else {
      let flgSwitchs = {
        flgGridManutencao: false,
        flgGridDocumentoMotorista: false,
        flgGridEquipamentoVeiculo: false,
        flgGridVeiculo: false,
        flgVeiculosVinculadosRodagens: false,
      };

      if (gridView?.current && monitoramentoveiculo.ultimasPosicoes?.length > 0)
        gridView.current.setDataSource(monitoramentoveiculo.ultimasPosicoes);

      if (
        gridView2?.current &&
        monitoramentoveiculo.veiculoComManutencaoProxima?.length > 0
      ) {
        gridView2.current.setDataSource(
          monitoramentoveiculo.veiculoComManutencaoProxima
        );
        flgSwitchs = { ...flgSwitchs, flgGridManutencao: true };
      }

      if (
        gridView3?.current &&
        monitoramentoveiculo.motoristaComDocumentoAtrasado?.length > 0
      ) {
        gridView3.current.setDataSource(
          monitoramentoveiculo.motoristaComDocumentoAtrasado
        );
        flgSwitchs = { ...flgSwitchs, flgGridDocumentoMotorista: true };
      }

      if (
        gridView4?.current &&
        monitoramentoveiculo.equipamentoVeiculo?.length > 0
      ) {
        gridView4.current.setDataSource(
          monitoramentoveiculo.equipamentoVeiculo
        );
        flgSwitchs = { ...flgSwitchs, flgGridEquipamentoVeiculo: true };
      }

      if (
        gridView5?.current &&
        monitoramentoveiculo.veiculoDocumentoS?.length > 0
      ) {
        gridView5.current.setDataSource(monitoramentoveiculo.veiculoDocumentoS);
        flgSwitchs = { ...flgSwitchs, flgGridVeiculo: true };
      }

      if (gridView6?.current) {
        gridView6.current.setDataSource(veiculosFormatados);
        flgSwitchs = { ...flgSwitchs, flgVeiculosVinculadosRodagens: true };
      }

      setData({
        ...monitoramentoveiculo,
        veiculosRodagens: veiculosFormatados,
        ...flgSwitchs,
      });
    }

    setLoading(false);
  };
  const onSearchVeiculo = async (e) => {
    const { veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    return veiculos;
  };
  // #endregion

  // #region CARREGAMENTOS AUTOMÁTICOS
  useEffect(async () => {
    await search();
  }, []);
  useEffect(() => {
    async function carregaSwitch() {
      if (gridView && gridView.current)
        gridView.current.setDataSource(data.ultimasPosicoes);
      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(data.veiculoComManutencaoProxima);
      if (gridView3 && gridView3.current)
        gridView3.current.setDataSource(data.motoristaComDocumentoAtrasado);
      if (gridView4 && gridView4.current)
        gridView4.current.setDataSource(data.equipamentoVeiculo);
      if (gridView5 && gridView5.current)
        gridView5.current.setDataSource(data.veiculoDocumentoS);
      if (gridView6 && gridView6.current)
        gridView6.current.setDataSource(data.veiculosRodagens);
    }
    carregaSwitch();
  }, [
    data.flgGridDocumentoMotorista,
    data.flgGridEquipamentoVeiculo,
    data.flgGridManutencao,
    data.flgGridVeiculo,
    data.flgVeiculosVinculadosRodagens,
  ]);
  // #endregion

  // #region MÉTODOS PRINT
  const onPrint = async () => {
    setLoading(true);

    const value = await printMonitoramentoVeiculo(data);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintVeiculoDocumento = async () => {
    setLoading(true);

    const value = await printVeiculoDocumento(data);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintMotoristaDocumento = async () => {
    setLoading(true);

    const value = await printMotoristaDocumento(data);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintEquipamentoVeiculo = async () => {
    setLoading(true);

    const value = await printEquipamentoVeiculo(data);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onExcel = async () => {
    setLoading(true);
    const value = await excelMonitoramentoVeiculo(data);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `MonitoramentoVeiculo.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelVeiculoDocumento = async () => {
    setLoading(true);
    const value = await excelVeiculoDocumento(data);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `MonitoramentoVeiculo.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelMotoristaDocumento = async () => {
    setLoading(true);
    const value = await excelMotoristaDocumento(data);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `MonitoramentoVeiculo.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelEquipamentoVeiculo = async () => {
    setLoading(true);
    const value = await excelEquipamentoVeiculo(data);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `MonitoramentoVeiculo.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onPrintHistoricoOdometros = async () => {
    setLoading(true);

    if (!filtros?.nrSeqVeiculo) {
      onSetMessage(ResponseStatus.Error, 'Selecione um veículo');
    } else {
      const value = await printHistoricoOdometro(filtros);

      onOpenReport(transaction.noTransacao, value);
    }

    setLoading(false);
  };
  // #endregion

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Monitoramento Veículos'
      message={message}
      loading={loading}
      onSearch={search}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir Manutenção Preventiva'
          icon='print'
          onClick={() => onPrint('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Veiculo Documento',
              onClick: () => onPrintVeiculoDocumento('Agrupado'),
            },
            {
              text: 'Motorista Documento',
              onClick: () => onPrintMotoristaDocumento('Agrupado'),
            },
            {
              text: 'Equipamento Veiculos',
              onClick: () => onPrintEquipamentoVeiculo('Agrupado'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Histórico de Odômetros'
          icon='print'
          onClick={onPrintHistoricoOdometros}
        />
      </ToolbarButtons>
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir Manutenção Preventiva'
          icon={['far', 'file-excel']}
          onClick={() => onExcel('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Veiculo Documento',
              onClick: () => onExcelVeiculoDocumento('Agrupado'),
            },
            {
              text: 'Motorista Documento',
              onClick: () => onExcelMotoristaDocumento('Agrupado'),
            },
            {
              text: 'Equipamento Veiculos',
              onClick: () => onExcelEquipamentoVeiculo('Agrupado'),
            },
          ]}
        />
      </ToolbarButtons>
      <Panel className='mb-3'>
        <Panel.Header
          icon={['fas', 'user']}
          title='Filtros'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Grid Manutenção'
                checked={data.flgGridManutencao}
                onChange={(flgGridManutencao) =>
                  setData({ ...data, flgGridManutencao })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Grid Veiculo Manutenção'
                checked={data.flgGridVeiculo}
                onChange={(flgGridVeiculo) =>
                  setData({ ...data, flgGridVeiculo })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Grid Documento Motorista'
                checked={data.flgGridDocumentoMotorista}
                onChange={(flgGridDocumentoMotorista) =>
                  setData({ ...data, flgGridDocumentoMotorista })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Grid Equipamento Veiculos'
                checked={data.flgGridEquipamentoVeiculo}
                onChange={(flgGridEquipamentoVeiculo) =>
                  setData({ ...data, flgGridEquipamentoVeiculo })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Grid Veículos Rodagens'
                checked={data.flgVeiculosVinculadosRodagens}
                onChange={(flgVeiculosVinculadosRodagens) =>
                  setData({ ...data, flgVeiculosVinculadosRodagens })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-5 mb-3'>
              <Autocomplete
                label='Veiculo'
                searchDataSource={onSearchVeiculo}
                selectedItem={filtros.veiculo}
                onSelectItem={(veiculo) => {
                  setFiltros({
                    ...filtros,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='noVeiculo'
              />
            </div>
            <div className='col-4 mb-4'>
              <RadioButton
                label='Tipo de manutenções'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.tipoMonitoramento}
                buttons={buttonMonitoramento}
                onChange={(tipoMonitoramento) => {
                  setFiltros({
                    ...filtros,
                    tipoMonitoramento,
                  });
                }}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      {data.flgGridManutencao && (
        <Panel className='mb-3'>
          <Panel.Header
            icon={['fas', 'user']}
            title='Manutenções Pendências'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView2}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns2}
                  onPageSizeChange={false}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
      {data.flgGridVeiculo && (
        <Panel className='mb-3'>
          <Panel.Header
            icon={['fas', 'user']}
            title='Veiculos com documentos proximos ao vencimento'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView5}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns5}
                  onPageSizeChange={false}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
      {data.flgGridDocumentoMotorista && (
        <Panel className='mb-3'>
          <Panel.Header
            icon={['fas', 'user']}
            title='Motoristas com documentos proximos ao vencimento'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView3}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns3}
                  onPageSizeChange={false}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
      {data.flgGridEquipamentoVeiculo && (
        <Panel className='mb-3'>
          <Panel.Header
            icon={['fas', 'user']}
            title='Equipamento Veiculos Proximos ao Vencimento'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView4}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns4}
                  onPageSizeChange={false}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
      {data.ultimasPosicoes?.length > 0 && (
        <div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                onPageSizeChange={false}
                showSelectSizes={false}
                showPagination={false}
              />
            </div>
          </div>
        </div>
      )}
      {data.flgVeiculosVinculadosRodagens && (
        <Panel className='mb-3'>
          <Panel.Header
            icon={['fas', 'user']}
            title='Veículos Vinculados Rodagens'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView6}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns6}
                  onPageSizeChange={false}
                  showSelectSizes={false}
                  showPagination={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
      <ModalVeiculosRodagens
        setLoading={setLoading}
        modalShow={modalShow}
        onClose={() => setModalShow({ show: false })}
      />
    </CSDSelPage>
  );
}
