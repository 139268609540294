import PneuAlinhamento from 'core/models/FRO/pneuAlinhamento';
import api from '../api';

const baseURL = `/FRO/PneuAlinhamento`;

export const getPneuAlinhamentoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuAlinhamento(json)) ?? [],
  };
};
