import qs from 'qs';
import Pagination from 'core/models/pagination';
import PneuSituacao from 'core/models/FRO/pneuSituacao';
import api from '../api';

const baseURL = `/FRO/PneuSituacao`;

export const getPneuSituacaoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuSituacao(json)) ?? [],
  };
};

export const getPneuSituacaoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuSituacao(json)) ?? [],
  };
};

export const getPneuSituacaoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PneuSituacao(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const postUploadPneuSituacao = async (
  file,
  nrSeqPessoaPos,
  nrSeqFormaPagamento,
  dtVencimento
) => {
  const formData = new FormData();
  formData.append('nrSeqPessoaPos', nrSeqPessoaPos);
  formData.append('nrSeqFormaPagamento', nrSeqFormaPagamento);
  formData.append('dtVencimento', dtVencimento);
  formData.append('file', file);
  // formData.append('extension', MimeTypes.getExtension(file.type));
};

export const getPneuSituacao = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PneuSituacao(value) : {};
};

export const savePneuSituacao = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deletePneuSituacao = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printPneuSituacao = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPneuSituacao = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPneuSituacao = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
